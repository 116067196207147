import {
    IClientDataEntity,
    IClientEntity,
    IPlanningItemEntity,
    IPlanningItemSearchFilterEntity,
    Language,
    PaymentMethodType,
    PlanningItemReportState,
    PlanningItemType,
    PlanningState,
} from '@phrospr/lib-models';

export function newPlanningItem(): IPlanningItemEntity {
    return {
        creation_date: null,
        planning_item_type: null,
        client_id: null,
        assigned_calendar_item_id: null,
        state: PlanningState.toBePlanned,
        planning_item_type_number: null,
        data: {},
        lead_id: null,
        planning_item_sub_type: null,
    };
}

export function getPlanningItemTypeNl(planningItemType: PlanningItemType): string {
    switch (planningItemType) {
        case PlanningItemType.delivery:
            return 'Levering';
        case PlanningItemType.lead:
            return 'Lead';
        case PlanningItemType.maintenance:
            return 'Onderhoud';
        case PlanningItemType.placement:
            return 'Plaatsing';
        case PlanningItemType.repair:
            return 'Depannage';
        default:
            return 'Onbekend';
    }
}

export function parsePaymentMethodTypeReadable(paymentMethod: PaymentMethodType): string {
    if (paymentMethod === PaymentMethodType.CASH) return 'Cash';
    if (paymentMethod === PaymentMethodType.CARD) return 'Bank contact';
    if (paymentMethod === PaymentMethodType.MONEY_TRANSFER) return 'Overschrijving';
    if (paymentMethod === PaymentMethodType.WARRENTY) return 'Garantie';
    if (paymentMethod === PaymentMethodType.OTHER) return 'Andere';
    return paymentMethod;
}

const basicSearchFilter: IPlanningItemSearchFilterEntity = {
    // keep this up to date with filtercorentity
    planning_item_type: null,
    state: null,
    planning_item_type_number: null,
    from_suggested_date: null,
    to_suggested_date: null,
    from_created_date: null,
    to_created_date: null,
    from_planned_date: null,
    to_planned_date: null,
    client_number: null,
    client_name: null,
    postcode: null,
    quote_sold_by_user_name: null,
};

export function validatePlanningItemSearchFilterKey(filterKey: string): boolean {
    return basicSearchFilter[filterKey] === null;
}

export function validatePlanningItemSearchFilter(searchFilter: IPlanningItemSearchFilterEntity): boolean {
    for (const key in searchFilter) {
        if (basicSearchFilter[key] !== null) return false;
    }
    return true;
}

export function getReportActionOptions(
    planningType: string,
    all = false,
): { text: string; value: PlanningItemReportState }[] {
    if (planningType.toLowerCase() === 'lead') {
        const values = [
            { text: 'Afgewerkt zonder offerte', value: PlanningItemReportState.completedNoOffer },
            { text: 'Niet thuis', value: PlanningItemReportState.notHome },
            { text: 'Opnieuw inplannen', value: PlanningItemReportState.reschedule },
        ];
        if (all)
            values.push(
                { text: 'Afgewerkt met offerte', value: PlanningItemReportState.completedWithOffer },
                { text: 'Cancel afspraak', value: PlanningItemReportState.cancel },
            );
        return values;
    } else {
        const values = [
            { text: 'Afgewerkt', value: PlanningItemReportState.completed },
            { text: 'Niet thuis', value: PlanningItemReportState.notHome },
            { text: 'Opnieuw inplannen', value: PlanningItemReportState.reschedule },
        ];
        if (all) values.push({ text: 'Cancel afspraak', value: PlanningItemReportState.cancel });
        return values;
    }
}

const reportStateNames: { [state: string]: { [language: string]: string } } = {
    [PlanningItemReportState.completedNoOffer]: { nl: 'Afgewerkt zonder offerte' },
    [PlanningItemReportState.notHome]: { nl: 'Niet thuis' },
    [PlanningItemReportState.reschedule]: { nl: 'Opnieuw inplannen' },
    [PlanningItemReportState.completedWithOffer]: { nl: 'Afgewerkt met offerte' },
    [PlanningItemReportState.cancel]: { nl: 'Cancelled' },
    [PlanningItemReportState.completed]: { nl: 'Afgewerkt' },
};
export function getNameReportState(state: PlanningItemReportState, language: Language = Language.nl): string {
    return reportStateNames[state][language];
}

export function getNamePlanningItemType(planningItemType: PlanningItemType, language?: Language): string {
    if (!language) {
        language = Language.nl;
    }

    if (planningItemType === PlanningItemType.delivery) {
        if (language === 'nl') {
            return 'Levering';
        } else if (language === 'fr') {
            return 'Livraison';
        }
    }
    if (planningItemType === PlanningItemType.maintenance) {
        if (language === 'nl') {
            return 'Onderhoud';
        } else if (language === 'fr') {
            return 'Maintenance';
        }
    }
    if (planningItemType === PlanningItemType.lead) {
        if (language === 'nl') {
            return 'Lead';
        } else if (language === 'fr') {
            return 'Lead';
        }
    }
    if (planningItemType === PlanningItemType.placement) {
        if (language === 'nl') {
            return 'Plaatsing';
        } else if (language === 'fr') {
            return 'Placement';
        }
    }
    if (planningItemType === PlanningItemType.repair) {
        if (language === 'nl') {
            return 'Depannage';
        } else if (language === 'fr') {
            return 'Depannage';
        }
    }
    return 'Item';
}

export namespace PlanningItemEngine {
    export function getPlanningItemClientName(planning_item: IPlanningItemEntity): string {
        return getClientName(planning_item.client);
    }

    export function buildClientName(first_name: string | null, last_name: string | null): string {
        if (!first_name && !last_name) return '';
        if (first_name && !last_name) return first_name;
        if (!first_name && last_name) return last_name;
        return `${first_name} ${last_name}`;
    }

    export function getClientName(client?: IClientEntity): string {
        if (client && client.client_data) {
            return getClientDataName(client.client_data);
        }
        throw new Error('client nor lead is supplied');
    }

    export function getClientDataName(clientData: IClientDataEntity): string {
        if (clientData) {
            return buildClientName(clientData.first_name, clientData.last_name);
        }
        throw new Error('client nor lead is supplied');
    }
}
