import { Pipe, PipeTransform } from '@angular/core';
import { IUserEntity } from '@phrospr/api-backend';
import { PlanningItemEngine } from '@phrospr/lib-core';

@Pipe({
    name: 'getUserFullName',
})
export class GetUserFullName implements PipeTransform {
    transform(user: IUserEntity) {
        return PlanningItemEngine.buildClientName(user.first_name, user.last_name);
    }
}
