export enum PlanningItemReportColumns {
    planning_item_report_id = 'planning_item_report_id',
    creation_date = 'creation_date',
    state = 'state',
    created_by_user_id = 'created_by_user_id',
    calendar_item_id = 'calendar_item_id',
    planning_item_id = 'planning_item_id',
    data = 'data',
}

export interface PlanningItemReportAttributes {
    planning_item_report_id: number;
    creation_date: Date | null;
    state: string | null;
    created_by_user_id: number | null;
    calendar_item_id: number | null;
    planning_item_id: number | null;
    data: any | null;
}

export type PlanningItemReportOptionalAttributes =
    | 'planning_item_report_id'
    | 'creation_date'
    | 'state'
    | 'created_by_user_id'
    | 'calendar_item_id'
    | 'planning_item_id'
    | 'data';
export interface PlanningItemReportCreate {
    planning_item_report_id?: number;
    creation_date?: Date | null;
    state?: string | null;
    created_by_user_id?: number | null;
    calendar_item_id?: number | null;
    planning_item_id?: number | null;
    data?: any | null;
}
