<div>
  <clr-tabs>
    <clr-tab>
      <button (click)="setTab('toBePlannedTab')" clrTabLink>TO BE PLANNED</button>
      <ng-template [(clrIfActive)]="toBePlannedTab">
        <clr-tab-content>
          <pr-maintenance-to-be-planned-tab></pr-maintenance-to-be-planned-tab>
        </clr-tab-content>
      </ng-template>
    </clr-tab>

    <clr-tab>
      <button (click)="setTab('plannedTab')" clrTabLink>PLANNED</button>
      <ng-template [(clrIfActive)]="plannedTab">
        <clr-tab-content>
          <pr-maintenance-planned-tab></pr-maintenance-planned-tab>
        </clr-tab-content>
      </ng-template>
    </clr-tab>

    <clr-tab>
      <button (click)="setTab('completedTab')" clrTabLink>COMPLETED</button>
      <ng-template [(clrIfActive)]="completedTab">
        <clr-tab-content>
          <pr-maintenance-completed-tab></pr-maintenance-completed-tab>
        </clr-tab-content>
      </ng-template>
    </clr-tab>

    <!--<clr-tab>
      <button (click)="setTab('invoicedTab')" clrTabLink>INVOICED</button>
      <ng-template [(clrIfActive)]="invoicedTab">
        <clr-tab-content>
          <dh-maintenance-completed-tab></dh-maintenance-completed-tab>
        </clr-tab-content>
      </ng-template>
    </clr-tab>-->

    <clr-tab>
      <button (click)="setTab('inactiveTab')" clrTabLink>INACTIVE</button>
      <ng-template [(clrIfActive)]="inactiveTab">
        <clr-tab-content>
          <pr-maintenance-inactive-tab></pr-maintenance-inactive-tab>
        </clr-tab-content>
      </ng-template>
    </clr-tab>

    <!--<clr-tab>
      <button (click)="setTab('allTab')" clrTabLink>ALL</button>
      <ng-template [(clrIfActive)]="allTab">
        <clr-tab-content>
          test 4
        </clr-tab-content>
      </ng-template>
    </clr-tab>-->
  </clr-tabs>
</div>




