export enum UserColumns {
    user_id = 'user_id',
    email = 'email',
    first_name = 'first_name',
    last_name = 'last_name',
    data = 'data',
    roles = 'roles',
    creation_date = 'creation_date',
    last_updated = 'last_updated',
    deleted = 'deleted',
    phone_number = 'phone_number',
}

export interface UserAttributes {
    user_id: number;
    email: string | null;
    first_name: string | null;
    last_name: string | null;
    data: any | null;
    roles: any | null;
    creation_date: Date | null;
    last_updated: Date | null;
    deleted: boolean | null;
    phone_number: string | null;
}

export type UserOptionalAttributes =
    | 'user_id'
    | 'email'
    | 'first_name'
    | 'last_name'
    | 'data'
    | 'roles'
    | 'creation_date'
    | 'last_updated'
    | 'deleted'
    | 'phone_number';
export interface UserCreate {
    user_id?: number;
    email?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    data?: any | null;
    roles?: any | null;
    creation_date?: Date | null;
    last_updated?: Date | null;
    deleted?: boolean | null;
    phone_number?: string | null;
}
