<div class="planning-item-filter">
    <div class="card-content-search card-content search-content">
        <div class="search-item grid-item clr-row" *ngIf="suggestedDatePicker">
            <div class="clr-col-6">
                <input
                    (ngModelChange)="triggerDateFilter(filterKeys.FROM_SUGGESTED_DATE, $event)"
                    [(ngModel)]="fromSuggestedDateTrigger"
                    clrDate
                    placeholder="From"
                    type="date"
                />
            </div>
            <div class="clr-col-6">
                <input
                    (ngModelChange)="triggerDateFilter(filterKeys.TO_SUGGESTED_DATE, $event)"
                    [(ngModel)]="toSuggestedDateTrigger"
                    clrDate
                    placeholder="To"
                    type="date"
                />
            </div>
        </div>
        <div class="search-item grid-item clr-row" *ngIf="createdDatePicker">
            <div class="clr-col-6">
                <input
                    (ngModelChange)="triggerDateFilter(filterKeys.FROM_CREATED_DATE, $event)"
                    [(ngModel)]="fromCreatedDateTrigger"
                    clrDate
                    placeholder="From"
                    type="date"
                />
            </div>
            <div class="clr-col-6">
                <input
                    (ngModelChange)="triggerDateFilter(filterKeys.TO_CREATED_DATE, $event)"
                    [(ngModel)]="toCreatedDateTrigger"
                    clrDate
                    placeholder="To"
                    type="date"
                />
            </div>
        </div>
        <div class="search-item grid-item clr-row" *ngIf="plannedDatePicker">
            <div class="clr-col-6">
                <input
                    (ngModelChange)="triggerDateFilter(filterKeys.FROM_PLANNED_DATE, $event)"
                    [(ngModel)]="fromPlannedDateTrigger"
                    clrDate
                    placeholder="From"
                    type="date"
                />
            </div>
            <div class="clr-col-6">
                <input
                    (ngModelChange)="triggerDateFilter(filterKeys.TO_PLANNED_DATE, $event)"
                    [(ngModel)]="toPlannedDateTrigger"
                    clrDate
                    placeholder="To"
                    type="date"
                />
            </div>
        </div>
        <div class="search-item grid-item filter-text">
            <div>
                <input
                    (ngModelChange)="triggerFilter(filterKeys.TYPE_NUMBER, $event)"
                    [(ngModel)]="typeNumberId"
                    id="typeNumberId"
                    type="text"
                    placeholder="Zoek op ID"
                    style="width: 200px"
                />
            </div>
        </div>
        <div class="search-item grid-item filter-text">
            <div>
                <input
                    (ngModelChange)="triggerFilter(filterKeys.CLIENT_NAME, $event)"
                    [(ngModel)]="clientNameTrigger"
                    id="clientName"
                    type="text"
                    placeholder="Zoek op naam"
                    style="width: 200px"
                />
            </div>
        </div>
        <!--NOT POSSIBLE HERE FOR NOW AND WE DON'T WANT TO INVEST IN THIS-->
        <div class="search-item grid-item filter-text" *ngIf="searchQuoteSoldByName">
            <input
                (ngModelChange)="triggerFilter(filterKeys.QUOTE_SOLD_BY_USER_NAME, $event)"
                [(ngModel)]="quoteSoldByNameTrigger"
                id="quoteSoldByName"
                type="text"
                placeholder="Offerte gemaakt door"
                style="width: 200px"
            />
        </div>
        <div class="search-item grid-item filter-text">
            <div>
                <input
                    (ngModelChange)="triggerFilter(filterKeys.POSTCODE, $event)"
                    [(ngModel)]="zipCodeTrigger"
                    id="zipCode"
                    placeholder="Zoek postcode"
                    style="width: 200px"
                    type="text"
                />
            </div>
        </div>
        <div class="search-item grid-item">
            <button (click)="resetFilters()" class="btn btn-link btn-sm">
                <cds-icon shape="times"></cds-icon>
                Clear filters
            </button>
        </div>
    </div>
</div>
