import { Pipe, PipeTransform } from '@angular/core';
import { PlanningItemSubType } from '@phrospr/api-backend';

@Pipe({
    name: 'getNameOfPlanningItemSubtypePipe',
})
export class GetNameOfPlanningItemSubtype implements PipeTransform {
    transform(planningItemSubType: PlanningItemSubType) {
        if (planningItemSubType === PlanningItemSubType.newConstruction) {
            return 'Nieuwbouw';
        }
        if (planningItemSubType === PlanningItemSubType.service) {
            return 'Service';
        }
        if (planningItemSubType === PlanningItemSubType.renovation) {
            return 'Renovatie';
        } else {
            return 'undefined pipe for planningItemSubType';
        }
    }
}
