"use strict";
/* tslint:disable */
/* eslint-disable */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarApi = exports.CalendarApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
// @ts-ignore
const models_1 = require("../models");
/**
 * CalendarApi - axios parameter creator
 * @export
 */
const CalendarApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} from
         * @param {string} to
         * @param {string} [email]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents: (from, to, email, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'from' is not null or undefined
            (0, common_1.assertParamExists)('getEvents', 'from', from);
            // verify required parameter 'to' is not null or undefined
            (0, common_1.assertParamExists)('getEvents', 'to', to);
            const localVarPath = `/api/calendar/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }
            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }
            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.CalendarApiAxiosParamCreator = CalendarApiAxiosParamCreator;
/**
 * CalendarApi -
 */
var CalendarApi;
(function (CalendarApi) {
    /**
     *
     * @param {string} from
     * @param {string} to
     * @param {string} [email]
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof CalendarApi
     * url /api/calendar/events
     */
    function getEvents(from, to, email, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.CalendarApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.getEvents(from, to, email, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = res.data.map((x) => (0, models_1.parseICalendarEventLite)(x));
                return res;
            });
        });
    }
    CalendarApi.getEvents = getEvents;
})(CalendarApi = exports.CalendarApi || (exports.CalendarApi = {}));
