<pr-planning-item-list-filter
  [plannedDatePicker]="true"
  [planningItemSearchFilter]="planningItemSearchFilter"
  (updatePlanningItemSearchFilter)="updateFilters($event)">
</pr-planning-item-list-filter>

<pr-planning-item-list-slim-container
  [planningItemShow]="planningItemShow"
  [planningItemFilter]="planningItemSearchFilter"
  [planningItemFilterUpdate$]="planningItemSearchFilter$">
</pr-planning-item-list-slim-container>

