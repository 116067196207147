import { IConfiguration } from '@phrospr/lib-models';
import { GlobalApi, setDefaultConfiguration } from '@phrospr/api-backend';
import axios from 'axios';

export const apiUrl$: Promise<string> = axios
    .get('.configuration')
    .then(response => response.data.apiUrl)
    .catch(_ => {
        console.log('unable to retrieve configuration, using default instead');
        return 'http://localhost:3000';
    });

export let configuration: IConfiguration;
export const configuration$: Promise<IConfiguration> = (async () => {
    const basePath = await apiUrl$;
    // make sure it is not awaiting the access token
    setDefaultConfiguration({ basePath });
    configuration = (await GlobalApi.getConfiguration()).data;
    return configuration;
})();
