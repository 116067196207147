import {
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { UserRole } from '@phrospr/api-backend';
import React, { Component } from 'react';
import { HexColorPicker } from 'react-colorful';
import * as toastr from 'toastr';
import { UserEditDialogProps, UserEditDialogState } from './UserEditDialog.types';
import { UserApi } from '@phrospr/api-backend';

export class UserEditDialog extends Component<UserEditDialogProps, UserEditDialogState> {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: UserRole;
    deleted: boolean;
    ignoreUserInCalendarList: boolean;
    ignoreUserInSellerList: boolean;
    autoAcceptCalendarEvent: boolean;

    constructor(props: UserEditDialogProps) {
        super(props);
        const { user, open } = props;
        this.firstName = user.first_name ?? '';
        this.lastName = user.last_name ?? '';
        this.email = user.email ?? '';
        this.phoneNumber = user.phone_number ?? '';
        this.role = user.roles?.roles?.[0] ?? UserRole.guest;
        this.deleted = user.deleted ?? false;
        this.ignoreUserInCalendarList = user.data?.ignoreUserInCalendarList ?? false;
        this.ignoreUserInSellerList = user.data?.ignoreUserInSellerList ?? false;
        this.autoAcceptCalendarEvent = user.data?.autoAcceptCalendarEvent ?? true;
        this.state = {
            open,
            bgColorOpen: false,
            backgroundColor: user.data?.secondary ?? '#cccccc',
            colorOpen: false,
            color: user.data?.primary ?? '#999999',
        };
    }

    closeDialog = () => {
        this.setState({ open: false });
        if (this.props.dialogClosed) this.props.dialogClosed();
    };

    saveUser = async () => {
        const user = this.props.user;
        user.first_name = this.firstName;
        user.last_name = this.lastName;
        user.email = this.email;
        user.phone_number = this.phoneNumber;
        user.roles.roles = [this.role];
        user.deleted = this.deleted;
        if (!user.data) user.data = {};
        user.data.ignoreUserInCalendarList = this.ignoreUserInCalendarList;
        user.data.ignoreUserInSellerList = this.ignoreUserInSellerList;
        user.data.autoAcceptCalendarEvent = this.autoAcceptCalendarEvent;
        user.data.primary = this.state.color;
        user.data.secondary = this.state.backgroundColor;
        try {
            await UserApi.updateUser(user);
            if (this.props.userSaved) this.props.userSaved();
            this.closeDialog();
        } catch (e) {
            console.log(e);
            toastr.error('something went wrong');
        }
    };

    render() {
        return (
            <Dialog open={this.props.open}>
                <DialogContent>
                    <FormGroup>
                        <TextField
                            required
                            label={'First name'}
                            defaultValue={this.firstName}
                            onChange={event => (this.firstName = event.target.value)}
                            variant={'standard'}
                        />
                        <TextField
                            required
                            label={'Last name'}
                            defaultValue={this.lastName}
                            onChange={event => (this.lastName = event.target.value)}
                            variant={'standard'}
                        />
                    </FormGroup>
                    <FormGroup>
                        <TextField
                            disabled
                            label={'Email'}
                            defaultValue={this.email}
                            onChange={event => (this.email = event.target.value)}
                            variant={'standard'}
                        />
                        <TextField
                            label={'Phone number'}
                            defaultValue={this.phoneNumber}
                            onChange={event => (this.phoneNumber = event.target.value)}
                            variant={'standard'}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={this.deleted}
                                    onChange={event => (this.deleted = event.target.checked)}
                                />
                            }
                            label={'Deleted'}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            style={{ cursor: 'pointer', margin: '5px 0' }}
                            control={
                                <Chip style={{ backgroundColor: this.state.backgroundColor, cursor: 'pointer' }} />
                            }
                            label={'Background color'}
                            onClick={() => this.setState({ bgColorOpen: !this.state.bgColorOpen })}
                        />
                    </FormGroup>
                    {this.state.bgColorOpen ? (
                        <div style={{ marginTop: '5px', marginBottom: '5px' }}>
                            <HexColorPicker
                                color={this.state.backgroundColor}
                                onChange={event => this.setState({ backgroundColor: event })}
                            />
                        </div>
                    ) : undefined}
                    <FormGroup>
                        <FormControlLabel
                            style={{ cursor: 'pointer', margin: '5px 0' }}
                            control={<Chip style={{ backgroundColor: this.state.color, cursor: 'pointer' }} />}
                            label={'Color'}
                            onClick={() => this.setState({ colorOpen: !this.state.colorOpen })}
                        />
                    </FormGroup>
                    {this.state.colorOpen ? (
                        <div style={{ marginTop: '5px', marginBottom: '5px' }}>
                            <HexColorPicker
                                color={this.state.color}
                                onChange={event => this.setState({ color: event })}
                            />
                        </div>
                    ) : undefined}

                    <FormControl fullWidth>
                        <InputLabel id={'role-select-label'}>Role</InputLabel>
                        <Select
                            labelId={'role-select-label'}
                            value={this.role}
                            label={'Role'}
                            onChange={event => (this.role = event.target.value as UserRole)}
                        >
                            <MenuItem value={UserRole.guest}>Guest</MenuItem>
                            <MenuItem value={UserRole.user}>User</MenuItem>
                            <MenuItem value={UserRole.admin}>Admin</MenuItem>
                        </Select>
                    </FormControl>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={this.ignoreUserInCalendarList}
                                    onChange={event => (this.ignoreUserInCalendarList = event.target.checked)}
                                />
                            }
                            label={'Verberg gebruiker in kalender'}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={this.ignoreUserInSellerList}
                                    onChange={event => (this.ignoreUserInSellerList = event.target.checked)}
                                />
                            }
                            label={'Verberg gebruiker in verkopers'}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={this.autoAcceptCalendarEvent}
                                    onChange={event => (this.autoAcceptCalendarEvent = event.target.checked)}
                                />
                            }
                            label={'Agenda-uitnodiging automatisch accepteren'}
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button size={'small'} onClick={this.closeDialog}>
                        Cancel
                    </Button>
                    <Button color={'primary'} variant={'contained'} size={'small'} onClick={this.saveUser}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default UserEditDialog;
