import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICalendarEventLite } from '@phrospr/lib-models';
import { BASE_URL } from '../../../../environments/environment';

@Injectable()
export class Office365Service {
    constructor(private http: HttpClient) {}

    public async getEventsPlanningCalendarForUser(from: Date, to: Date, email: string): Promise<ICalendarEventLite[]> {
        return this.http
            .get<ICalendarEventLite[]>(
                `${BASE_URL}api/calendar/events?from=${from.toISOString()}&to=${to.toISOString()}&email=${email}`,
            )
            .toPromise();
    }
}
