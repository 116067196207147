import { IUserRolesEntity, UserRole } from './user-role';

export interface IUserEntity {
    user_id: number | null;
    email: string;
    first_name: string;
    last_name: string;
    data: Partial<IUserDataEntity>;
    roles: Partial<IUserRolesEntity>;
    deleted: boolean;
    phone_number: string | null;
    creation_date: Date;
    last_updated: Date;
}

export interface IUserDataEntity {
    address?: string;
    primary: string;
    secondary: string;
    ignoreUserInCalendarList?: boolean;
    ignoreUserInSellerList?: boolean;
    autoAcceptCalendarEvent?: boolean;
}

export function getDefaultUser(email: string): IUserEntity {
    return {
        user_id: null,
        email: email,
        first_name: null,
        last_name: null,
        data: {},
        roles: { roles: [UserRole.guest] },
        creation_date: null,
        last_updated: null,
        deleted: false,
        phone_number: null,
    };
}
