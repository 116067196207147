"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIWorkOrderEntity = void 0;
const iclient_entity_1 = require("./iclient-entity");
const icomment_entity_1 = require("./icomment-entity");
const iplanning_item_entity_1 = require("./iplanning-item-entity");
const iuser_entity_1 = require("./iuser-entity");
const iwork_order_data_entity_1 = require("./iwork-order-data-entity");
const iwork_order_line_entity_1 = require("./iwork-order-line-entity");
function parseIWorkOrderEntity(data) {
    if (typeof data.creation_date === 'string')
        data.creation_date = new Date(data.creation_date);
    if (data.data !== null && typeof data.data === 'object')
        data.data = (0, iwork_order_data_entity_1.parseIWorkOrderDataEntity)(data.data);
    if (data.work_order_lines !== null && typeof data.work_order_lines === 'object')
        data.work_order_lines = data.work_order_lines.map((x) => (0, iwork_order_line_entity_1.parseIWorkOrderLineEntity)(x));
    if (data.client !== null && typeof data.client === 'object')
        data.client = (0, iclient_entity_1.parseIClientEntity)(data.client);
    if (data.created_by_user !== null && typeof data.created_by_user === 'object')
        data.created_by_user = (0, iuser_entity_1.parseIUserEntity)(data.created_by_user);
    if (data.work_order_technician_user !== null && typeof data.work_order_technician_user === 'object')
        data.work_order_technician_user = (0, iuser_entity_1.parseIUserEntity)(data.work_order_technician_user);
    if (data.comments !== null && typeof data.comments === 'object')
        data.comments = data.comments.map((x) => (0, icomment_entity_1.parseICommentEntity)(x));
    if (data.planning_item !== null && typeof data.planning_item === 'object')
        data.planning_item = (0, iplanning_item_entity_1.parseIPlanningItemEntity)(data.planning_item);
    return data;
}
exports.parseIWorkOrderEntity = parseIWorkOrderEntity;
