import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BASE_URL } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { FILE_NAME_HEADER } from '@phrospr/lib-models';

@Injectable()
export class QuoteService {
    constructor(private http: HttpClient) {}

    public async downloadQuotePdf(quote_id: number): Promise<void> {
        const data = await (
            this.http.get(`${BASE_URL}api/quote/${quote_id}.pdf`, { responseType: 'blob', observe: 'response' }).pipe(
                map((res: HttpResponse<Blob>) => {
                    const fileName = res.headers.get(FILE_NAME_HEADER);
                    fileSaver.saveAs(res.body, fileName ? fileName : `${quote_id}.pdf`);
                    return null;
                }),
            ) as Observable<any>
        ).toPromise();
    }
}
