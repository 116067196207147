"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseICalendarItemEntity = void 0;
const icalendar_item_data_entity_1 = require("./icalendar-item-data-entity");
const iplanning_item_report_entity_1 = require("./iplanning-item-report-entity");
const iuser_entity_1 = require("./iuser-entity");
function parseICalendarItemEntity(data) {
    if (typeof data.date_time_start === 'string')
        data.date_time_start = new Date(data.date_time_start);
    if (typeof data.date_time_stop === 'string')
        data.date_time_stop = new Date(data.date_time_stop);
    if (data.data !== null && typeof data.data === 'object')
        data.data = (0, icalendar_item_data_entity_1.parseICalendarItemDataEntity)(data.data);
    if (data.created_by_user !== null && typeof data.created_by_user === 'object')
        data.created_by_user = (0, iuser_entity_1.parseIUserEntity)(data.created_by_user);
    if (data.in_calendar_of_user !== null && typeof data.in_calendar_of_user === 'object')
        data.in_calendar_of_user = (0, iuser_entity_1.parseIUserEntity)(data.in_calendar_of_user);
    if (data.visit_report !== null && typeof data.visit_report === 'object')
        data.visit_report = (0, iplanning_item_report_entity_1.parseIPlanningItemReportEntity)(data.visit_report);
    return data;
}
exports.parseICalendarItemEntity = parseICalendarItemEntity;
