import { Component } from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
    GlobalApi,
    IClientEntity,
    IPlanningItemEntity,
    LeadType,
    PlanningItemApi,
    PlanningItemType,
} from '@phrospr/api-backend';
import { Router } from '@angular/router';

@Component({
    selector: 'pr-planning-item-search-modal',
    templateUrl: './planning-item-search-modal.component.html',
    styleUrls: ['./planning-item-search-modal.component.scss'],
})
export class PlanningItemSearchModalComponent {
    PlanningItemType = PlanningItemType;
    LeadType = LeadType;

    searchValue = '';
    searchPlanningItemField$: ReplaySubject<any> = new ReplaySubject(1);

    planningItems: IPlanningItemEntity[] = [];
    clients: IClientEntity[] = [];
    leads: IClientEntity[] = [];

    showResults = false; // ONLY SHOW RESULTS AS LONG AS FOCUS ON INPUT FIELD

    constructor(private router: Router) {
        this.searchPlanningItemField$.pipe(debounceTime(300)).subscribe(_ => {
            this.searchItems();
        });
    }

    async searchItems() {
        if (this.searchValue !== '') {
            try {
                const { planningItems, leads, clients } = (await GlobalApi.getItemSearch(this.searchValue)).data;
                this.planningItems = planningItems;
                this.clients = leads;
                this.leads = clients;
                if (this.leads && this.leads.length)
                    this.leads.forEach(x => {
                        if (!x.lead_data) x.lead_data = { lead_type: LeadType.manual } as any;
                    });
                this.showResults = true;
            } catch (err) {
                console.error(err);
            }
        } else {
            this.showResults = false;
        }
    }

    async goToPlanningItem(planningItem: IPlanningItemEntity) {
        this.searchValue = '';
        this.showResults = false;
        await this.router.navigateByUrl(`/planning-item-detail?planning_item_id=${planningItem.planning_item_id}`);
    }

    async goToClient(client: IClientEntity) {
        this.showResults = false;
        this.searchValue = '';
        await this.router.navigateByUrl(`/client-detail?client_id=${client.client_id}`);
        return;
    }

    async goToLead(lead: IClientEntity) {
        this.showResults = false;
        this.searchValue = '';
        const { records } = (
            await PlanningItemApi.listPlanningItems({
                planning_item_filter: {
                    limit: 1,
                    filter: {
                        client_id: lead.client_id,
                    },
                },
            })
        ).data;
        await this.router.navigateByUrl(`/planning-item-detail?planning_item_id=${records[0].planning_item_id}`);
        return;
    }

    // WHY DO WE NEED THIS => THIS IS GIVING A LOT OF ISSUES FOR CLICKING ON THE <a>
    loseFocus() {
        // TIME OUT IS NEEDED OR ELSE THE CLICK FUNCTION DOESN4T WORK
        setTimeout(_ => (this.showResults = false), 300);
    }

    focus() {
        if (this.searchValue !== '') {
            this.showResults = true;
        }
    }
}
