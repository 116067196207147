<clr-modal [clrModalOpen]="visible" [clrModalClosable]="false" [clrModalSize]="'lg'">
    <h4 class="modal-title no-padding">Email samenstellen</h4>
    <div class="modal-body">
        <form id="send-mail-form" ngNativeValidate>
            <section class="form-block">
                <div class="form-group row">
                    <div class="group-section">
                        <div class="group-element full-length-screen">
                            <div
                                class="group-section-email"
                                *ngFor="let emailObject of newEmailObject.receivers; index as i"
                            >
                                <input
                                    required
                                    type="text"
                                    class="no-full-length-screen"
                                    [(ngModel)]="emailObject.email"
                                    [ngModelOptions]="{ standalone: true }"
                                    placeholder="Ontvanger"
                                />
                                <div class="close-div" (click)="deleteEmail(i)">
                                    <cds-icon shape="times"></cds-icon>
                                </div>
                            </div>
                        </div>
                        <div class="group-element">
                            <div class="btn btn-sm btn-primary btn-menu" (click)="addEmail()">+ add email</div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="group-section">
                        <div class="group-element" style="width: 85%">
                            <input
                                placeholder="Onderwerp toevoegen"
                                name="subject"
                                type="text"
                                [(ngModel)]="newEmailObject.subject"
                                [ngModelOptions]="{ standalone: true }"
                                id="subject"
                                class="full-length-screen no-border"
                            />
                        </div>
                        <div class="group-element">
                            <div class="clr-select-wrapper">
                                <select
                                    [(ngModel)]="newEmailObject.language"
                                    class="clr-select"
                                    id="language"
                                    [ngModelOptions]="{ standalone: true }"
                                    (change)="getMailTemplateMaintenancePlanned()"
                                >
                                    <option value="nl">NL</option>
                                    <option value="fr">FR</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="group-section">
                        <div class="group-element text-editor">
                            <div id="send-mail-quill-editor"></div>
                            <!--              <quill-editor [(ngModel)]="newEmailObject.content" [styles]="{height:'350px'}"-->
                            <!--                            [ngModelOptions]="{standalone: true}" class="no-border textarea-custom">-->
                            <!--              </quill-editor>-->
                        </div>
                    </div>
                </div>
            </section>
        </form>
    </div>
    <div class="modal-footer no-padding">
        <button class="btn btn-success" form="send-mail-form" (click)="confirmMailTemplate()">Opslaan</button>
        <button class="btn btn-link" (click)="cancelMailTemplate()">Cancel</button>
    </div>
</clr-modal>
