import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ROUTING } from '../../app.routing';
import { PreferencesPageComponent } from './containers/preferences-page/preferences-page.component';
import { SharedModule } from '../shared/shared.module';

const components = [];

const containers = [PreferencesPageComponent];

const imports = [CommonModule, FormsModule, ReactiveFormsModule, ROUTING, SharedModule];

const sandboxes = [];

const services = [];

@NgModule({
    imports: [...imports],
    declarations: [...containers, ...components],
    providers: [...sandboxes, ...services],
})
export class PreferencesModule {}
