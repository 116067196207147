import { Button, DialogActions, DialogContentText, Grid, TextareaAutosize, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IQuoteEntity, PlanningItemType, PlanningState } from '@phrospr/api-backend';
import React from 'react';
import './QuoteApproveDialog.scss';
import { QuoteApproveDialogProps, QuoteApproveDialogState } from './QuoteApproveDialog,types';
import { getQuoteVersion, newPlanningItem, PlanningItemEngine } from '@phrospr/lib-core';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import getClientName = PlanningItemEngine.getClientName;

export class QuoteApproveDialog extends React.Component<QuoteApproveDialogProps, QuoteApproveDialogState> {
    constructor(props: QuoteApproveDialogProps) {
        super(props);
        console.log('props');
        console.log(this.props);
        this.state = {
            showApproveQuoteModal: this.props.open,
            planningItemToAdd: {
                ...newPlanningItem(),
                planning_item_type: PlanningItemType.placement,
                state: PlanningState.toBePlanned,
                client_id: this.props.quoteToApprove ? this.props.quoteToApprove.client_id : null,
                data: {
                    suggested_date: this.props.quoteToApprove.data?.quote_create?.machineFiche
                        ?.voorkeurs_plaatsing_datum
                        ? new Date(
                              this.props.quoteToApprove.data?.quote_create?.machineFiche?.voorkeurs_plaatsing_datum,
                          )
                        : this.props.quoteToApprove.data.preferred_placement_date
                        ? new Date(this.props.quoteToApprove.data.preferred_placement_date)
                        : new Date(),
                    created_from_lead_planning_item_id: this.props.quoteToApprove.planning_item_lead
                        ? this.props.quoteToApprove.planning_item_lead.planning_item_id
                        : undefined,
                },
            },
        };
    }

    async editQuote(quote: IQuoteEntity) {
        const quoteVersion: number = getQuoteVersion(quote);
        this.props.goToEditQuote(quote.quote_id, quoteVersion);
    }

    handleClose() {
        console.log('closed');
    }

    cancelApproveQuote() {
        this.setState({ showApproveQuoteModal: false });
        this.props.dialogClosed();
    }

    approveQuote() {
        this.props.approveQuote(this.state.planningItemToAdd);
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.state.showApproveQuoteModal}
                    onClose={this.handleClose}
                    maxWidth={'md'}
                    fullWidth={true}
                >
                    <DialogTitle>Goedkeuren offerte</DialogTitle>
                    <DialogContent sx={{ 'min-height': '400px' }}>
                        <DialogContentText>
                            <Grid
                                sx={{
                                    'font-weight': 'bold',
                                    'background-color': '#d2f5fa',
                                    margin: '10px 0 10px 0',
                                    width: '92%',
                                }}
                            >
                                Klant / Lead details
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={7}>
                                    <div>
                                        <div>
                                            <label>
                                                {this.props.quoteToApprove
                                                    ? getClientName(this.props.quoteToApprove?.client)
                                                    : ''}
                                            </label>
                                        </div>
                                        <div>
                                            <label>{this.props.quoteToApprove?.client.client_data.email}</label>
                                        </div>
                                        <div>
                                            <label>
                                                {this.props.quoteToApprove?.client.client_data.telephone_phone
                                                    ? 'Tel nr: ' +
                                                      this.props.quoteToApprove?.client.client_data.telephone_phone
                                                    : ''}
                                                {this.props.quoteToApprove?.client.client_data.mobile_phone
                                                    ? ' Gsm: ' +
                                                      this.props.quoteToApprove?.client.client_data.mobile_phone
                                                    : ''}
                                            </label>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={5}>
                                    <div>
                                        <div>
                                            <label>
                                                {this.props.quoteToApprove?.client.client_data.main_address.address}
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                {this.props.quoteToApprove?.client.client_data.main_address.city}
                                                {this.props.quoteToApprove?.client.client_data.main_address.zip_code}
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                {this.props.quoteToApprove?.client.client_data.main_address.countryName}{' '}
                                                -{this.props.quoteToApprove?.client.client_data.main_address.country}
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                {this.props.quoteToApprove?.client.client_data.main_address.stateName}
                                            </label>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    'font-weight': 'bold',
                                    'background-color': '#d2f5fa',
                                    margin: '10px 0 10px 0',
                                    width: '92%',
                                }}
                            >
                                Offerte details
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Grid sx={{ height: 30 }}>
                                        <label>Offerte prijs</label>
                                    </Grid>

                                    <Grid sx={{ height: 70, 'padding-top': 15 }}>
                                        <label>Gewenste Plaatsing datum</label>
                                    </Grid>
                                    <Grid sx={{ height: 40 }}>
                                        <label>Omschrijving</label>
                                    </Grid>
                                    <Grid></Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid sx={{ height: 30 }}>
                                        <label>
                                            {this.props.quoteToApprove?.data.quote_create?.machineFiche?.total ?? '0'
                                                ? this.props.quoteToApprove?.final_price_to_pay
                                                : '0'}
                                            EUR
                                        </label>
                                    </Grid>

                                    <Grid sx={{ height: 70 }}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                label={'From'}
                                                value={this.state.planningItemToAdd.data.suggested_date}
                                                onChange={event =>
                                                    this.setState({
                                                        planningItemToAdd: {
                                                            ...this.state.planningItemToAdd,
                                                            data: {
                                                                ...this.state.planningItemToAdd.data,
                                                                suggested_date: event,
                                                            },
                                                        },
                                                    })
                                                }
                                                renderInput={params => <TextField size="small" {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid sx={{ height: 40 }}>
                                        <TextareaAutosize
                                            aria-label={'minimum height'}
                                            minRows={4}
                                            defaultValue={this.state.planningItemToAdd.data?.description}
                                            onChange={event =>
                                                this.setState({
                                                    planningItemToAdd: {
                                                        ...this.state.planningItemToAdd,
                                                        data: {
                                                            ...this.state.planningItemToAdd.data,
                                                            description: event.target.value,
                                                        },
                                                    },
                                                })
                                            }
                                            placeholder={'Type u opmerkingen of notities voor de offerte hier.'}
                                            style={{ width: 600 }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" variant="text" onClick={() => this.cancelApproveQuote()}>
                            Cancel
                        </Button>
                        <Button size="small" variant="text" onClick={() => this.editQuote(this.props.quoteToApprove)}>
                            Edit offerte
                        </Button>
                        <Button size="small" variant="text" onClick={() => this.approveQuote()}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default QuoteApproveDialog;
