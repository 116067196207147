export enum WorkOrderActivityColumns {
    activity_id = 'activity_id',
    work_order_id = 'work_order_id',
}

export interface WorkOrderActivityAttributes {
    activity_id: number;
    work_order_id: number;
}

export type WorkOrderActivityCreate = WorkOrderActivityAttributes;
