import { IClientEntity, IPlanningItemEntity } from '@phrospr/lib-models';
import { getNamePlanningItemType, PlanningItemEngine } from './planning-item.engine';

export namespace MailEngine {
    export function getPlanningMailContent(planningItem: IPlanningItemEntity, client?: IClientEntity) {
        const { planning_item_type } = planningItem;
        if (planning_item_type === 'maintenance' && client) {
            return getPlanningMailClientContent(client);
        }
        if (planningItem.planning_item_type === 'lead' && client) {
            return getPlanningMailLeadContent(client);
        }
        let content = `
    ${getNamePlanningItemType(planningItem.planning_item_type)} nummer: ${planningItem.planning_item_type_number}\n`;
        content += getPlanningMailClientContent(client);
        content += `
Beschrijving ${getNamePlanningItemType(planningItem.planning_item_type)}
${planningItem.data.description}`;
        return content;
    }

    export function getPlanningMailClientContent(client: IClientEntity): string {
        console.log(client);
        const { client_data } = client;
        const { main_address, company_data } = client_data;
        return `Klant nummer: ${client.exact_client_number ? client.exact_client_number : ''}
Klant naam: ${PlanningItemEngine.getClientDataName(client_data)}
Klant email: ${client_data.email}
Klant telefoon nr: ${client_data.telephone_phone ? client_data.telephone_phone : '/'}
Klant gsm nr: ${client_data.mobile_phone ? client_data.mobile_phone : '/'}

Klant BTW: ${company_data && company_data.vat_number ? company_data.vat_number : '/'}

Hoofd adres
${main_address.address}
${main_address.city} ${main_address.zip_code}
${
    main_address.stateName
        ? `
${main_address.stateName}`
        : ''
}
${main_address.country} ${main_address.countryName}
${
    client_data.delivery_addresses
        ? `

Uitvoerings adres
${client_data.delivery_addresses.length ? client_data.delivery_addresses[0].address : '/'}

`
        : ''
}${
            client_data.remarks
                ? `

Klant opmerkingen uit Exact
${client_data.remarks}
`
                : ''
        }`;
    }

    export function getPlanningMailLeadContent(lead: IClientEntity): string {
        return `Client nummer Aquagroup: ${lead.client_id}
Lead nummer ${lead.lead_data.lead_type}: ${lead.lead_data.lead_custom_id}
Lead naam: ${PlanningItemEngine.getClientName(lead)}
Lead email: ${lead.client_data.email}${
            lead.client_data.company_data && lead.client_data.company_data.company_name
                ? `
Lead bedrijf naam: ${lead.client_data.company_data.company_name}`
                : ''
        }${
            lead.client_data.company_data && lead.client_data.company_data.vat_number
                ? `
Lead vat nummer: ${lead.client_data.company_data.vat_number}`
                : ''
        }${
            lead.client_data.mobile_phone
                ? `
Lead mobiel nummer: ${lead.client_data.mobile_phone}`
                : ''
        }${
            lead.client_data.telephone_phone
                ? `
Lead telefoon nummer: ${lead.client_data.telephone_phone}`
                : ''
        }

Hoofd adres
${lead.client_data.main_address.address}
${lead.client_data.main_address.city} ${lead.client_data.main_address.zip_code}
${
    lead.client_data.main_address.country
        ? `
Land code: ${lead.client_data.main_address.country}`
        : ''
}

Lead omschrijving: ${lead.lead_data.description}${
            lead.lead_data.detail
                ? `
Lead detail info: ${lead.lead_data.detail}`
                : ''
        }
`;
    }
}

export function isValidEmail(email: string): boolean {
    const emailRegexp =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegexp.test(email);
}
