<button (click)="newQuote2()" class="btn btn-primary">+ Nieuwe Offerte</button>

<pr-quote-filter
    [quoteItemSearchFilter]="quoteSearch.filter"
    [createdDatePicker]="true"
    [createdByName]="false"
    (updateQuoteItemSearchFilter)="updateFilters($event)"
>
</pr-quote-filter>

<div *ngIf="quotes" id="quotes-overview-container">
    <ngx-datatable
        class="material striped"
        [rows]="quotes"
        [columnMode]="'flex'"
        [rowHeight]="30"
        [headerHeight]="50"
        [footerHeight]="50"
        [selectionType]="'single'"
        [externalPaging]="true"
        [externalSorting]="true"
        (activate)="onActivate($event)"
        [count]="page.count"
        [offset]="page.offset"
        [limit]="page.limit"
        [sortType]="'single'"
        (page)="pageCallback($event)"
        (sort)="sortCallback($event)"
        [rowClass]="getRowClass"
        [reorderable]="reorderable"
        [loadingIndicator]="loading"
    >
        <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="quote_id">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer"> Offerte ID </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.quote.quote_id }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="name">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer"> Naam </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                <!--Todo make sure we visualise te correct name but also do correct search based on this-->
                <!-- <span >{{row.quote.data.quote_create.clientFiche.first_name + ' ' + row.quote.data.quote_create.clientFiche.last_name}}</span>-->
                <span *ngIf="!!row.quote.data && !!row.quote.data.quote_create">
                    {{
                        row.quote.data.quote_create.clientFiche.first_name +
                            ' ' +
                            row.quote.data.quote_create.clientFiche.last_name
                    }}</span
                >
                <span *ngIf="!!row.quote.client && !row.quote.data.quote_create">
                    {{ row.quote.client.client_data.first_name + ' ' + row.quote.client.client_data.last_name }}</span
                >
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="lead_postcode">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer"> Postcode </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="!!row.quote.data && !!row.quote.data.quote_create">
                    {{
                        row.quote.data.quote_create.clientFiche.zip_code
                            ? row.quote.data.quote_create.clientFiche.zip_code + ' - '
                            : ''
                    }}
                    {{
                        row.quote.data.quote_create.clientFiche.city ? row.quote.data.quote_create.clientFiche.city : ''
                    }}
                </span>
                <span *ngIf="!!row.quote.client && !row.quote.data.quote_create && !!row.quote.data.delivery_address">
                    {{
                        row.quote.data.delivery_address.zip_code ? row.quote.data.delivery_address.zip_code + ' - ' : ''
                    }}
                    {{ row.quote.data.delivery_address.city ? row.quote.data.delivery_address.city : '' }}
                </span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="creation_date">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer"> Creatie datum </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.quote.creation_date | dateFormatWithTime }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="sub_total_price">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer"> Totaal (excl btw) </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.quote.sub_total_price + row.quote.placement_cost + ' €' }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="sold_by_user">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer"> Verkocht door </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.quote.sold_by_user ? row.quote.sold_by_user.first_name : '' }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="planning_item_id_lead">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer"> Lead Item </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div *ngIf="row.quote.planning_item_lead">
                    <button
                        class="btn btn-sm btn-link"
                        (click)="goToPlanningItemDetail(row.quote.planning_item_lead.planning_item_id)"
                    >
                        {{ 'lead  ' + row.quote.planning_item_lead.planning_item_type_number }}
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="client_id">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer"> Client ID exact </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div *ngIf="row.quote.client && row.quote.client.exact_client_number">
                    <button class="btn btn-sm btn-link" (click)="goToClientDetail(row.quote.client.client_id)">
                        {{
                            row.quote.client.exact_client_number ? 'Klant  ' + row.quote.client.exact_client_number : ''
                        }}
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="planning_item_id_placement">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer"> Plaatsing Item </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div *ngIf="row.quote.planning_item_placement">
                    <button
                        (click)="goToPlanningItemDetail(row.quote.planning_item_placement.planning_item_id)"
                        class="btn btn-sm btn-link"
                    >
                        {{ 'Plaatsing ' + row.quote.planning_item_placement.planning_item_type_number }}
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1" [maxWidth]="150" name="rating">
            <ng-template let-column="column" ngx-datatable-header-template>Rating</ng-template>
            <ng-template class="datatable-body-cell" let-row="row" ngx-datatable-cell-template>
                <div>
                    <ngb-rating
                        [(rate)]="row.ratingEdit.value"
                        [max]="row.ratingEdit.max"
                        (click)="
                            $event.stopPropagation(); this.updateQuoteRating = row; this.updateQuoteRatingModal = true
                        "
                        style="color: orange"
                    ></ngb-rating>
                </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [flexGrow]="1" [maxWidth]="80" name="download">
            <ng-template let-column="column" ngx-datatable-header-template></ng-template>
            <ng-template class="datatable-body-cell" let-row="row" ngx-datatable-cell-template>
                <div *ngIf="row.quote?.pdf_raw === QUOTE_PDF_RAW_PLACEHOLDER">
                    <button
                        (click)="$event.preventDefault(); $event.stopPropagation(); downloadQuote(row.quote)"
                        class="btn btn-outline btn-sm btn-icon"
                        title="Download"
                    >
                        <cds-icon shape="download"></cds-icon>
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [flexGrow]="1" name="approved" [minWidth]="150">
            <ng-template let-column="column" ngx-datatable-header-template></ng-template>
            <ng-template class="datatable-body-cell" let-row="row" ngx-datatable-cell-template>
                <div class="btn-group btn-sm" *ngIf="row.quote.status === QuoteStatus.created" style="padding-top: 3px">
                    <button
                        (click)="$event.preventDefault(); $event.stopPropagation(); editQuote(row.quote)"
                        class="btn btn-outline btn-sm btn-icon"
                        title="Edit"
                    >
                        <cds-icon shape="pencil"></cds-icon>
                    </button>
                    <button
                        (click)="$event.preventDefault(); $event.stopPropagation(); showFinishQuote(row.quote)"
                        class="btn btn-success btn-sm btn-icon"
                    >
                        <cds-icon shape="check"></cds-icon>
                    </button>
                    <button
                        (click)="$event.preventDefault(); $event.stopPropagation(); showCancelQuote(row.quote)"
                        class="btn btn-danger btn-sm btn-icon"
                    >
                        <cds-icon shape="times"></cds-icon>
                    </button>
                </div>
                <div *ngIf="row.quote.status === QuoteStatus.cancelled">
                    <span class="label label-danger" style="margin-top: 4px">Cancelled</span>
                </div>
                <div *ngIf="row.quote.status === QuoteStatus.approved">
                    <span class="label label-success" style="margin-top: 4px">Approved</span>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>

<pr-quote-approve-modal
    (cancel)="cancelApproveQuote()"
    (yes)="ApproveQuoteModal($event)"
    [currentPlanningItem]="planningItemToApproveQuote"
    [quote]="quoteToApprove"
    [visible]="approveQuoteModal"
>
</pr-quote-approve-modal>

<pr-delete-modal [visible]="cancelQuoteModal" (cancel)="cancelCancelQuote()" (ok)="cancelQuote()">
    Bent u zeker dat u de offerte/quote wilt annuleren?
</pr-delete-modal>

<pr-delete-modal [visible]="updateQuoteRatingModal" (cancel)="cancelUpdateQuoteRating()" (ok)="updateRating()">
    <div id="rating-modal">
        <ngb-rating
            *ngIf="updateQuoteRating"
            [readonly]="true"
            [(rate)]="updateQuoteRating.ratingEdit.value"
            [max]="updateQuoteRating.ratingEdit.max"
            style="color: orange"
        ></ngb-rating>
        <div>Bent u zeker dat u de rating wil aanpassen?</div>
    </div>
</pr-delete-modal>

<pr-quote-page2-component *ngIf="false"
    (goToPlanningItem)="goToPlanningItemDetail($event)"
    (goToClient)="goToClientDetail($event)"
    (goToQuoteEdit)="editQuote2($event)"
>

</pr-quote-page2-component>
