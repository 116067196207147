"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseILeadDataEntity = void 0;
const ilead_data_entity_created_at_1 = require("./ilead-data-entity-created-at");
const ilead_data_entity_location_1 = require("./ilead-data-entity-location");
const ilead_data_entity_products_1 = require("./ilead-data-entity-products");
const ilead_doubles_1 = require("./ilead-doubles");
function parseILeadDataEntity(data) {
    if (typeof data.lead_viewed === 'string')
        data.lead_viewed = new Date(data.lead_viewed);
    if (data.lead_doubles !== null && typeof data.lead_doubles === 'object')
        data.lead_doubles = (0, ilead_doubles_1.parseILeadDoubles)(data.lead_doubles);
    if (data.created_at !== null && typeof data.created_at === 'object')
        data.created_at = (0, ilead_data_entity_created_at_1.parseILeadDataEntityCreatedAt)(data.created_at);
    if (data.products !== null && typeof data.products === 'object')
        data.products = data.products.map((x) => (0, ilead_data_entity_products_1.parseILeadDataEntityProducts)(x));
    if (data.location !== null && typeof data.location === 'object')
        data.location = (0, ilead_data_entity_location_1.parseILeadDataEntityLocation)(data.location);
    return data;
}
exports.parseILeadDataEntity = parseILeadDataEntity;
