"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parsePlanningItemPlanBody = void 0;
const icalendar_create_event_body_entity_1 = require("./icalendar-create-event-body-entity");
const icalendar_item_entity_1 = require("./icalendar-item-entity");
const planning_item_plan_body_planning_item_mail_1 = require("./planning-item-plan-body-planning-item-mail");
function parsePlanningItemPlanBody(data) {
    if (data.calendarItem !== null && typeof data.calendarItem === 'object')
        data.calendarItem = (0, icalendar_item_entity_1.parseICalendarItemEntity)(data.calendarItem);
    if (data.planningItemMail !== null && typeof data.planningItemMail === 'object')
        data.planningItemMail = (0, planning_item_plan_body_planning_item_mail_1.parsePlanningItemPlanBodyPlanningItemMail)(data.planningItemMail);
    if (data.calendarEventToAdd !== null && typeof data.calendarEventToAdd === 'object')
        data.calendarEventToAdd = (0, icalendar_create_event_body_entity_1.parseICalendarCreateEventBodyEntity)(data.calendarEventToAdd);
    return data;
}
exports.parsePlanningItemPlanBody = parsePlanningItemPlanBody;
