import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import * as ReactDom from 'react-dom/client';
import * as React from 'react';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteOverview, ShadowDom } from '@phrospr/frontend-react';
import { REACT_CONTAINER } from '../../../../../constants';

@Component({
    selector: 'pr-quote-page2-component',
    template: `<span #${REACT_CONTAINER}></span>`,
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class QuotePage2Component implements OnChanges, OnDestroy, AfterViewInit {
    @ViewChild(REACT_CONTAINER, { static: true }) containerRef: ElementRef;
    private component: ReactDom.Root;
    element: HTMLElement;

    @Output() public goToPlanningItem = new EventEmitter<number>();
    @Output() public goToClient = new EventEmitter<number>();
    @Output() public goToQuoteEdit = new EventEmitter<{ quoteId: number; quoteVersion: number }>();

    constructor(private router: Router, private route: ActivatedRoute) {}

    async ngAfterViewInit() {
        this.element = this.containerRef.nativeElement as HTMLElement;
        this.component = ReactDom.createRoot(this.element);
        await this.render();
    }

    async ngOnChanges(changes: SimpleChanges) {
        await this.render();
    }

    ngOnDestroy(): void {
        this.component.unmount();
    }

    private async render() {
        if (!this.component) return console.log('failed to render, no component yet', typeof this);
        this.component.render(
            <ShadowDom element={this.element}>
                <QuoteOverview
                    goToPlanningItem={planningItemId => this.goToPlanningItem.emit(planningItemId)}
                    goToClient={clientId => this.goToClient.emit(clientId)}
                    goToEditQuote={(quoteId: number, quoteVersion: number) =>
                        this.goToQuoteEdit.emit({ quoteId, quoteVersion })
                    }
                />
            </ShadowDom>,
        );
    }
}
