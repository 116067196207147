import { Card as BaseCard, CardContent } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';
import { PhrosprThemeProvider } from '../../theme';
import './Card.scss';
import * as Style from './Card.style';

export interface CardProps {
    title: string;
    sx?: SxProps<Theme>;
}

export function Card({ children, title, sx }: React.PropsWithChildren<CardProps>) {
    return (
        <PhrosprThemeProvider>
            <BaseCard sx={sx}>
                <Style.CardHeader title={title} />
                <CardContent>{children}</CardContent>
            </BaseCard>
        </PhrosprThemeProvider>
    );
}

export default Card;
