import { Component, OnInit } from '@angular/core';
import {
    ClientApi,
    ClientState,
    IPlanningItemAttachment,
    IPlanningItemEntity,
    IQuoteEntity,
    PlanningItemApi,
    PlanningItemReportState,
    PlanningItemType,
    PlanningState,
    QuoteApi,
    QuoteStatus,
    WorkOrderApi,
    WorkOrderStatus,
} from '@phrospr/api-backend';
import * as toastr from 'toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientManager } from '../../../shared/manager/client.manager';
import { LeadManager } from '../../../shared/manager/lead.manager';
import { Location } from '@angular/common';
import { QuoteService } from '../../../shared/services/quote.service';
import { PlanningItemService } from '../../../shared/services/planning-item.service';
import { IPlanningItemFinishPortal } from '@phrospr/lib-core';
import { IConfiguration, IWorkOrderEntity } from '@phrospr/lib-models';
import { configuration$, downloadFileFromApi } from '@phrospr/lib-web-core';

@Component({
    selector: 'pr-planning-item-detail-page',
    styleUrls: ['./planning-item-detail-page.component.scss'],
    templateUrl: './planning-item-detail-page.component.html',
})
export class PlanningItemDetailPageComponent implements OnInit {
    planningItem: IPlanningItemEntity;
    planningItemID: number;

    PlanningState = PlanningState;
    PlanningItemType = PlanningItemType;
    ClientState = ClientState;
    QuoteStatus = QuoteStatus;

    historyOfClient: IPlanningItemEntity[];

    quotesForLead: { records: IQuoteEntity[]; total: number };
    workOrders: { records: IWorkOrderEntity[]; total: number };
    WorkOrderStatus = WorkOrderStatus;

    editModeLeadDetails = false;
    editModeLeadRemarks = false;
    editModeDescriptionPlanningItem = false;

    showAddAttachment = false;
    showPictureDialog = false;
    showDeleteConfirmationModal = false;
    pictureAttachment: IPlanningItemAttachment = null;

    finishManualModal = false;

    configuration: IConfiguration;

    constructor(
        private route: ActivatedRoute,
        private leadManager: LeadManager,
        public router: Router,
        private clientManager: ClientManager,
        private location: Location,
        private quoteService: QuoteService,
        private planningItemService: PlanningItemService,
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            if (!params.planning_item_id) {
                return;
            }
            this.planningItemID = parseInt(params.planning_item_id, 10);
            this.getPlanningItem(this.planningItemID);
        });
        configuration$.then(c => (this.configuration = c));
    }

    async getNewComments(planningItem: IPlanningItemEntity) {
        const newPlanningItem = (
            await PlanningItemApi.getPlanningItemDetail(undefined, this.planningItem.planning_item_id)
        ).data;
        planningItem.comments = newPlanningItem.comments;
        if (planningItem.comments === null) planningItem.comments = [];
        planningItem.comments.sort((a, b) => Number(a.creation_date) - Number(b.creation_date));
        planningItem.visit_reports = newPlanningItem.visit_reports;
        planningItem.activities = newPlanningItem.activities;
    }

    async getPlanningItem(planningItemNumber: number) {
        try {
            this.planningItem = (await PlanningItemApi.getPlanningItemDetail(undefined, planningItemNumber)).data;
            if (this.planningItem.comments === null) this.planningItem.comments = [];
            this.planningItem.comments.sort((a, b) => Number(a.creation_date) - Number(b.creation_date));
            if (this.planningItem.client) {
                this.historyOfClient = (
                    await PlanningItemApi.getPlanningItemClientHistory(this.planningItem.client_id)
                ).data;
                const quote_states = [QuoteStatus.approved];
                if (this.planningItem.client.state === this.ClientState.lead) quote_states.push(QuoteStatus.created);
                if ((await configuration$).quotes?.enabled) {
                    this.quotesForLead = (
                        await QuoteApi.getQuoteListPost({
                            quote_filter: {
                                filter: {
                                    planning_item_id: this.planningItem.planning_item_id,
                                    quote_states: quote_states,
                                },
                                show: {
                                    planning_item_lead: true,
                                    sold_by_user: true,
                                    created_by_user: true,
                                    quote_lines: true,
                                },
                            },
                        })
                    ).data;
                }
                if ((await configuration$).workOrders?.enabled) {
                    this.workOrders = (
                        await WorkOrderApi.getWorkOrderListPost({
                            work_order_filter: {
                                filter: {
                                    planning_item_id: this.planningItem.planning_item_id,
                                },
                                show: {
                                    work_order_technician_user: true,
                                    created_by_user: true,
                                    work_order_lines: true,
                                },
                            },
                        })
                    ).data;
                }
            }
        } catch (err) {
            toastr.error('Er ging iets fout met het laden van de data' + err);
        }
    }

    async planPlanningItem() {
        await this.router.navigateByUrl('/calendar-view?planning_item_id=' + this.planningItem.planning_item_id);
    }

    backToOverview() {
        this.location.back();
    }

    async createQuoteForLead2() {
        await this.router.navigateByUrl(
            `/quote-upsert2?client_id=${this.planningItem.client_id}&planning_item_id=${this.planningItem.planning_item_id}`,
        );
    }

    async goToPlanningItem(planningItemID: number) {
        await this.router.navigateByUrl('/planning-item-detail?planning_item_id=' + planningItemID);
    }

    async goToPlanningItemByLeadId(leadID: number) {
        const planningtItemIdForLeadID = (
            await PlanningItemApi.listPlanningItems({
                planning_item_filter: {
                    filter: { lead_id: leadID },
                },
            })
        ).data.records[0].planning_item_id;
        await this.router.navigateByUrl('/planning-item-detail?planning_item_id=' + planningtItemIdForLeadID);
    }

    async setInactive() {
        try {
            await ClientApi.setClientMaintenanceInActive(this.planningItem.client_id);
            await this.getPlanningItem(this.planningItemID);
        } catch (httpError) {
            toastr.error(httpError.error.message);
        }
    }

    async setActive() {
        try {
            await ClientApi.setClientMaintenanceActive(this.planningItem.client_id);
            await this.getPlanningItem(this.planningItemID);
        } catch (httpError) {
            console.error(httpError);
            toastr.error('Kon de klant niet activeren!' + httpError);
        }
    }

    goToHistoryClient(client_id: number) {
        this.router.navigateByUrl(
            `/planning-item-client-history?client_id=${client_id}?planning_item_id=${this.planningItem.planning_item_id}`,
        );
    }

    editModeLeadDetailsClicked() {
        this.editModeLeadDetails = true;
    }

    async saveClicked() {
        this.editModeLeadDetails = false;
        await this.updateLead();
    }

    cancelEditLeadDetails() {
        this.editModeLeadDetails = false;
    }

    editModeDescriptionPlanningItemClicked() {
        this.editModeDescriptionPlanningItem = true;
    }

    async saveDescriptionPlanningItemClicked() {
        this.editModeDescriptionPlanningItem = false;
        await PlanningItemApi.updatePlanningItemDescription(
            this.planningItem.planning_item_id,
            this.planningItem.data.description,
        );
    }

    cancelEditDescriptionPlanningItem() {
        this.editModeDescriptionPlanningItem = false;
    }

    editModeLeadRemarksClicked() {
        this.editModeLeadRemarks = true;
    }

    async saveLeadRemarksClicked() {
        this.editModeLeadRemarks = false;
        await this.updateLead();
    }

    cancelEditLeadRemarks() {
        this.editModeLeadRemarks = false;
    }

    updateLead() {
        this.leadManager.updateLead(this.planningItem.client, this.planningItem.planning_item_id).then(lead => {
            this.getPlanningItem(this.planningItemID);
            toastr.info('Lead updated!');
        });
    }

    async protestLead() {
        if (this.planningItem.state === PlanningState.toBePlanned) {
            this.planningItem.state = PlanningState.protest;
            await PlanningItemApi.updatePlanningItem({
                planningItem: this.planningItem,
            });
            await this.getPlanningItem(this.planningItemID);
        } else {
            toastr.error(
                'De lead staat in een status verschillend  van Nieuwe Lead. Deze actie is dan niet toegelaten.',
            );
        }
    }

    showFinishManualPlanningItemModal() {
        this.finishManualModal = true;
    }

    cancelManualFinish() {
        this.finishManualModal = false;
    }

    async finishManualPlanningItem(newPlanningItemReport: IPlanningItemFinishPortal) {
        // TODO also need to check if this can be done in all states of a planningItem??
        if (
            newPlanningItemReport.state === PlanningItemReportState.completedWithOffer ||
            newPlanningItemReport.state === PlanningItemReportState.completedNoOffer ||
            newPlanningItemReport.state === PlanningItemReportState.completed
        ) {
            try {
                await PlanningItemApi.finishManualPlanningItem({
                    planning_item_id: this.planningItem.planning_item_id,
                    comment: newPlanningItemReport.comment,
                    action: newPlanningItemReport.state,
                });
                toastr.success(
                    `${this.planningItem.planning_item_type}-${this.planningItem.planning_item_type_number} is afgewerkt!`,
                );
                this.finishManualModal = false;
            } catch (err) {
                toastr.error(`Kon ${this.planningItem.planning_item_type}-${this.planningItem.planning_item_type_number} niet afwerken.
           Een mogelijke reden is omdat dit al ingepland is.`);
                console.error(err);
            }
            await this.getPlanningItem(this.planningItemID);
            this.finishManualModal = false;
        } else {
            toastr.error('Gelieve een afgewerkte status te kiezen');
        }
        this.finishManualModal = false;
    }

    goToClient() {
        // WHEN THE LEAD BECAME A CLIENT ADD LOGIC HERE SO THAT HE IS REFERRED TO THE CLIENT IMMEDIATELY
    }

    async moveLeadToLeadToBePlanned() {
        if (this.planningItem.planning_item_type === PlanningItemType.lead) {
            if (
                this.planningItem.state === PlanningState.completedNoOffer ||
                this.planningItem.state === PlanningState.protest
            ) {
                this.planningItem.state = PlanningState.toBePlanned;
                await PlanningItemApi.updatePlanningItem({
                    planningItem: this.planningItem,
                });
                this.backToOverview();
            } else {
                toastr.error(
                    'De lead staat in een status verschillend  van Completed ' +
                        'No Offer of protest. Deze actie is dan niet toegelaten.',
                );
            }
        }
    }

    async downloadQuote({ quote_id }: IQuoteEntity) {
        try {
            await downloadFileFromApi(await QuoteApi.downloadQuote(quote_id, { responseType: 'blob' }));
        } catch (err) {
            if (err?.status === 404) {
                toastr.error('Geen PDF beschikbaar');
            } else {
                console.error(err);
                toastr.error('Fout tijdens het ophalen van de pdf');
            }
        }
    }

    async downloadWorkOrder(work_order_id: number) {
        try {
            await downloadFileFromApi(await WorkOrderApi.downloadWorkOrder(work_order_id, { responseType: 'blob' }));
        } catch (err) {
            if (err?.status === 404) {
                toastr.error('Geen PDF beschikbaar');
            } else {
                console.error(err);
                toastr.error('Fout tijdens het ophalen van de pdf');
            }
        }
    }

    async goToClientDetail(clientID: number) {
        await this.router.navigateByUrl('/client-detail?client_id=' + clientID);
    }

    async uploadFile() {
        const fileElement = document.getElementById(`attachmentFile`) as any;
        if (!fileElement.files.length) {
            return toastr.error(`Gelieve eerst een bestand te selecteren`);
        }
        const file = fileElement.files[0];
        const data = new FormData();
        data.append('file_to_upload', file, file.name);
        await this.planningItemService.uploadPlanningItemAttachment(this.planningItem.planning_item_id, data);
        this.showAddAttachment = false;
        await this.getPlanningItem(this.planningItemID);
    }

    async deleteAttachment() {
        try {
            await this.planningItemService.deleteAttachment(this.pictureAttachment.planning_item_attachment_id);
            await this.getPlanningItem(this.planningItemID);
        } catch (error) {
            console.log(error);
            toastr.error(`Er ging iets mis tijdens het verwijderen van de foto!`);
        }
        this.showDeleteConfirmationModal = false;
        this.pictureAttachment = null;
    }

    async downloadAttachment(fileName: string) {
        await this.planningItemService.downloadAttachment(fileName);
    }

    openPicture(attachment: IPlanningItemAttachment) {
        this.pictureAttachment = attachment;
        this.showPictureDialog = true;
    }

    openDeleteConfirmationModal(attachment: IPlanningItemAttachment) {
        this.pictureAttachment = attachment;
        this.showDeleteConfirmationModal = true;
    }
}
