"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseICalendarEventLiteAttendees = void 0;
const icalendar_event_lite_email_address_1 = require("./icalendar-event-lite-email-address");
const icalendar_event_lite_status_1 = require("./icalendar-event-lite-status");
function parseICalendarEventLiteAttendees(data) {
    if (data.emailAddress !== null && typeof data.emailAddress === 'object')
        data.emailAddress = (0, icalendar_event_lite_email_address_1.parseICalendarEventLiteEmailAddress)(data.emailAddress);
    if (data.status !== null && typeof data.status === 'object')
        data.status = (0, icalendar_event_lite_status_1.parseICalendarEventLiteStatus)(data.status);
    return data;
}
exports.parseICalendarEventLiteAttendees = parseICalendarEventLiteAttendees;
