import { Component, Input, OnInit } from '@angular/core';
import { IClientEntity, IActivityEntity } from '@phrospr/api-backend';

export interface IPortalActivity extends IActivityEntity {
    hide?: boolean;
}

@Component({
    selector: 'pr-client-activity-section',
    styleUrls: ['./client-activity-section.component.scss'],
    templateUrl: './client-activity-section.component.html',
})
export class ClientActivitySectionComponent implements OnInit {
    @Input() activities: IPortalActivity[];
    @Input() client: IClientEntity;

    numberOfActivitiesToShow = 5;
    olderActivities = true;

    state = 'activity';

    constructor() {}

    ngOnInit(): void {}

    showAllActivities() {
        this.numberOfActivitiesToShow = 9999;
        this.olderActivities = false;
    }
}
