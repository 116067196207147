export let defaultMailFooter = `
<html>
<head>
		<!--[if gte mso 9]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]-->
		<title>Aquagroup_signature</title>
		<meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
		<meta name='viewport' content='width=device-width, initial-scale=1.0' />
		<style type='text/css'>
			a:hover{text-decoration:none!important;}
			.h-u a{text-decoration:none;}
			.h-u a:hover{text-decoration:underline!important;}
			a[x-apple-data-detectors]{color:inherit!important; text-decoration:none!important;}
			a[href^="tel"]:hover{text-decoration:none!important;}
			.active-i a:hover,
			.active-t:hover{opacity:0.8;}
			.active-i a,
			.active-t{transition:all 0s ease;}
			a img{border:none;}
			b, strong{font-weight:700;}
			p{margin:0;}
			table td{mso-line-height-rule:exactly;}
			.ns span, .ns a{color:inherit!important; text-decoration:none!important; border:none!important;}
			@media (prefers-dark-interface) {body{-apple-color-filter:none;}}
		</style>
	</head>
<body style='background:#fffffe; margin:0; padding:0; -webkit-text-size-adjust:100%; -ms-text-size-adjust:100%;'>
		<table width='100%' cellpadding='0' cellspacing='0'>
			<tr>
				<td style='padding:27px 25px;'>
					<table width='100%' cellpadding='0' cellspacing='0'>
						<tr>
							<td style='padding:0 0 17px; font:13px/15px Arial, Helvetica, sans-serif; color:#000;'>
								Met vriendelijke groet,
							</td>
						</tr>
						<tr>
							<td style='padding:0 0 27px;'>
								<table align='left' cellpadding='0' cellspacing='0'>
									<tr>
										<td valign='top' style='padding:0 36px 20px 0;'>
											<p style='margin:0pt;'>
												<img src='https://mcusercontent.com/8acec313acd700682937b26e0/images/10ef29ca-c6d5-8bd3-ef25-7c62ee7d4e87.png' width='172' style='font:14px/16px Arial, Helvetica, sans-serif; color:#000; vertical-align:top;' alt='Aquagroup / Het begint bij zacht water' />
											</p>
										</td>
									</tr>
								</table>
								<table align='left' cellpadding='0' cellspacing='0'>
									<tr>
										<td style='font:11px/15px Arial, Helvetica, sans-serif; color:#000;'>
											<p style='margin:0pt;'>
												<a style='text-decoration:none; color:#000;' href='tel:+3256615162'>+32 56 61 51 62</a> <br />
												G&#65279;entse&#65279;weg 7&#65279;15, 8&#65279;79&#65279;3 W&#65279;aregem <br />
												<b><a style='text-decoration:none; color:#008BC7;' href='https://www.waterverzachteraquagroup.be/'>www.waterverzachteraquagroup.be</a></b>
											</p>
										</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td valign='top' style='border-bottom:1px solid #008BC7;'>
								<table align='left' cellpadding='0' cellspacing='0'>
									<tr>
										<td valign='top' style='padding:0 0 27px;'>
											<table width='100%' cellpadding='0' cellspacing='0'>
												<tr>
													<td width='98' align='left' valign='top'>
														<table width='100%' cellpadding='0' cellspacing='0'>
															<tr>
																<td align='center' style='padding:0 0 10px;'>
																	<p style='margin:0pt;'>
																		<img src='https://mcusercontent.com/8acec313acd700682937b26e0/images/0734709f-f04e-7ad2-5cdc-3eea7e4cf583.png' width='70' style='vertical-align:top;' alt='webshop' />
																	</p>
																</td>
															</tr>
															<tr>
																<td align='center' style='font:700 8px/10px Arial, Helvetica, sans-serif; color:#000; text-transform:uppercase;'>
																	<p style='margin:0pt;'>
																		WEBSHOP
																	</p>
																</td>
															</tr>
														</table>
													</td>
													<td width='5'></td>
													<td width='98' align='left' valign='top'>
														<table width='100%' cellpadding='0' cellspacing='0'>
															<tr>
																<td align='center' style='padding:0 0 10px;'>
																	<p style='margin:0pt;'>
																		<img src='https://mcusercontent.com/8acec313acd700682937b26e0/images/00077233-fa19-2e94-231b-510ec2be9484.png' width='70' style='vertical-align:top;' alt='waterverzachters' />
																	</p>
																</td>
															</tr>
															<tr>
																<td align='center' style='font:700 8px/10px Arial, Helvetica, sans-serif; color:#000; text-transform:uppercase;'>
																	<p style='margin:0pt;'>
																		WATERVERZACHTERS
																	</p>
																</td>
															</tr>
														</table>
													</td>
													<td width='5'></td>
												</tr>
											</table>
										</td>
									</tr>
								</table>
								<table align='left' cellpadding='0' cellspacing='0'>
									<tr>
										<td valign='top' style='padding:0 0 27px;'>
											<table width='100%' cellpadding='0' cellspacing='0'>
												<tr>
													<td width='98' align='left' valign='top'>
														<table width='100%' cellpadding='0' cellspacing='0'>
															<tr>
																<td align='center' style='padding:0 0 10px;'>
																	<p style='margin:0pt;'>
																		<img src='https://mcusercontent.com/8acec313acd700682937b26e0/images/d14e4d02-fa86-f177-8e04-a4448dfd9cf7.png' width='70' style='vertical-align:top;' alt='drinkwaterfilters' />
																	</p>
																</td>
															</tr>
															<tr>
																<td align='center' style='font:700 8px/10px Arial, Helvetica, sans-serif; color:#000; text-transform:uppercase;'>
																	<p style='margin:0pt;'>
																		DRINKWATERFILTERS
																	</p>
																</td>
															</tr>
														</table>
													</td>
													<td width='5'></td>
													<td width='98' align='left' valign='top'>
														<table width='100%' cellpadding='0' cellspacing='0'>
															<tr>
																<td align='center' style='padding:0 0 10px;'>
																	<p style='margin:0pt;'>
																		<img src='https://mcusercontent.com/8acec313acd700682937b26e0/images/cb2f36c3-f4f1-414e-356a-2eca8c5421a0.png' width='70' style='vertical-align:top;' alt='drinkwatersystemen' />
																	</p>
																</td>
															</tr>
															<tr>
																<td align='center' style='font:700 8px/10px Arial, Helvetica, sans-serif; color:#000; text-transform:uppercase;'>
																	<p style='margin:0pt;'>
																		DRINKWATERSYSTEMEN
																	</p>
																</td>
															</tr>
														</table>
													</td>
													<td width='5'></td>
												</tr>
											</table>
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</td>
			</tr>
		</table>
	</body>
</html>
`;

export function setDefaultMailFooter(mailFooter: string) {
    defaultMailFooter = mailFooter;
}
