import { ThemeProvider as BaseThemeProvider, Typography } from '@mui/material';
import { createTheme, Theme } from '@mui/material/styles';
import React from 'react';
import { ANGULAR_FONT_SIZE } from './constants';

export const defaultTheme: Theme = createTheme({
    palette: {
        primary: {
            main: '#2ea8db',
        },
    },
    typography: {
        fontFamily: '"Avenir Next", Helvetica, Arial, sans-serif',
        fontSize: ANGULAR_FONT_SIZE,
    },
});

export const PhrosprThemeProvider = ({ children }: React.PropsWithChildren) => (
    <BaseThemeProvider theme={defaultTheme}>
        <Typography>{children}</Typography>
    </BaseThemeProvider>
);
