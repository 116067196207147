import { Component, EventEmitter, Output } from '@angular/core';
import { IClientEntity, LeadType } from '@phrospr/api-backend';
import { newClientEntity } from '@phrospr/lib-core';
import { GetNameOfLeadType } from '../../../shared/pipes/lead-type-name.pipe';

@Component({
    selector: 'pr-lead-create',
    templateUrl: './create-lead.component.html',
    styleUrls: ['./create-lead.component.scss'],
})
export class CreateLeadComponent {
    @Output() lead = new EventEmitter<IClientEntity>();

    newLead: IClientEntity = newClientEntity(true) as IClientEntity;

    LeadType = LeadType;

    selectedClient: IClientEntity = newClientEntity();

    setClient(client: IClientEntity) {
        this.newLead.lead_data.leadViaCustomer = parseInt(client.exact_client_number, 10);
        this.selectedClient = client;
        this.sendLeadToComponent();
    }

    sendLeadToComponent() {
        this.lead.emit(this.newLead);
    }
}
