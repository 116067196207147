import { styled } from '@mui/system';

export const Agenda = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 20px;
`;

export const TitleAndUsers = styled('div')`
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    min-width: 200px;
`;

export const Title = styled('div')`
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
`;

export const Users = styled('div')`
    max-height: 600px;
    overflow-y: auto;
    padding-right: 8px;
`;

export const User = styled('div')<{
    color: string;
}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    border-bottom: 1px solid ${props => props.color};
`;
