import { Component, EventEmitter, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { IClientEntity, IConfiguration, IPlanningItemEntity, LeadType, PlanningItemSubType } from '@phrospr/lib-models';
import { newClientEntity, newPlanningItem } from '@phrospr/lib-core';
import { configuration, configuration$ } from '@phrospr/lib-web-core';

@Component({
    selector: 'pr-placement-create',
    templateUrl: './create-placement.component.html',
    styleUrls: ['./create-placement.component.scss'],
    providers: [{ provide: LOCALE_ID, useValue: 'fr' }],
})
export class CreatePlacementComponent implements OnInit {
    @Input() clientSelectionVisible: boolean;

    @Output() planningItem = new EventEmitter<IPlanningItemEntity>();

    newPlanningItemPlacement: IPlanningItemEntity = newPlanningItem();

    selectedClient: IClientEntity = newClientEntity();

    planningItemSubType = PlanningItemSubType;

    configuration: IConfiguration;

    constructor() {}

    ngOnInit(): void {
        this.loadConfig();
    }

    async loadConfig() {
        this.configuration = await configuration$;
    }

    setClient(client: IClientEntity) {
        /* this.newDelivery.client_number = client.number;*/
        this.selectedClient = client;
        this.newPlanningItemPlacement.client_id = client.client_id;
        this.sendPlacementToComponent();
    }

    sendPlacementToComponent() {
        this.planningItem.emit(this.newPlanningItemPlacement);
    }
}
