"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseISolvariLeadEntity = void 0;
const isolvari_lead_entity_location_1 = require("./isolvari-lead-entity-location");
const isolvari_lead_entity_products_1 = require("./isolvari-lead-entity-products");
function parseISolvariLeadEntity(data) {
    if (data.location !== null && typeof data.location === 'object')
        data.location = (0, isolvari_lead_entity_location_1.parseISolvariLeadEntityLocation)(data.location);
    if (data.products !== null && typeof data.products === 'object')
        data.products = data.products.map((x) => (0, isolvari_lead_entity_products_1.parseISolvariLeadEntityProducts)(x));
    return data;
}
exports.parseISolvariLeadEntity = parseISolvariLeadEntity;
