export enum LeadColumns {
    lead_id = 'lead_id',
    lead_type = 'lead_type',
    lead_custom_id = 'lead_custom_id',
    creation_date = 'creation_date',
    state = 'state',
    data = 'data',
    data_raw = 'data_raw',
}

export interface LeadAttributes {
    lead_id: number;
    lead_type: string | null;
    lead_custom_id: string | null;
    creation_date: Date | null;
    state: string | null;
    data: any | null;
    data_raw: any | null;
}

export type LeadOptionalAttributes =
    | 'lead_id'
    | 'lead_type'
    | 'lead_custom_id'
    | 'creation_date'
    | 'state'
    | 'data'
    | 'data_raw';
export interface LeadCreate {
    lead_id?: number;
    lead_type?: string | null;
    lead_custom_id?: string | null;
    creation_date?: Date | null;
    state?: string | null;
    data?: any | null;
    data_raw?: any | null;
}
