export enum WorkOrderLineColumns {
    work_order_line_id = 'work_order_line_id',
    work_order_id = 'work_order_id',
    product_id = 'product_id',
    creation_date = 'creation_date',
    quantity = 'quantity',
    data = 'data',
}

export interface WorkOrderLineAttributes {
    work_order_line_id: number;
    work_order_id: number | null;
    product_id: number | null;
    creation_date: Date | null;
    quantity: number | null;
    data: any | null;
}

export type WorkOrderLineOptionalAttributes =
    | 'work_order_line_id'
    | 'work_order_id'
    | 'product_id'
    | 'creation_date'
    | 'quantity'
    | 'data';
export interface WorkOrderLineCreate {
    work_order_line_id?: number;
    work_order_id?: number | null;
    product_id?: number | null;
    creation_date?: Date | null;
    quantity?: number | null;
    data?: any | null;
}
