export enum ClientColumns {
    client_id = 'client_id',
    exact_client_number = 'exact_client_number',
    exact_last_sync = 'exact_last_sync',
    state = 'state',
    inactive = 'inactive',
    client_data = 'client_data',
    client_settings = 'client_settings',
    lead_data = 'lead_data',
    creation_data = 'creation_data',
}

export interface ClientAttributes {
    client_id: number;
    exact_client_number: string | null;
    exact_last_sync: Date | null;
    state: string | null;
    inactive: boolean | null;
    client_data: any | null;
    client_settings: any | null;
    lead_data: any | null;
    creation_data: Date | null;
}

export type ClientOptionalAttributes =
    | 'client_id'
    | 'exact_client_number'
    | 'exact_last_sync'
    | 'state'
    | 'inactive'
    | 'client_data'
    | 'client_settings'
    | 'lead_data'
    | 'creation_data';
export interface ClientCreate {
    client_id?: number;
    exact_client_number?: string | null;
    exact_last_sync?: Date | null;
    state?: string | null;
    inactive?: boolean | null;
    client_data?: any | null;
    client_settings?: any | null;
    lead_data?: any | null;
    creation_data?: Date | null;
}
