"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parsePlanningItemReportState = exports.PlanningItemReportState = void 0;
var lib_models_1 = require("@phrospr/lib-models");
Object.defineProperty(exports, "PlanningItemReportState", { enumerable: true, get: function () { return lib_models_1.PlanningItemReportState; } });
function parsePlanningItemReportState(data) {
    return data;
}
exports.parsePlanningItemReportState = parsePlanningItemReportState;
