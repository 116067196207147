export enum WorkOrderColumns {
    work_order_id = 'work_order_id',
    client_id = 'client_id',
    work_order_type = 'work_order_type',
    creation_date = 'creation_date',
    status = 'status',
    planning_item_id = 'planning_item_id',
    data = 'data',
    pdf_raw = 'pdf_raw',
}

export interface WorkOrderAttributes {
    work_order_id: number;
    client_id: number | null;
    work_order_type: string | null;
    creation_date: Date | null;
    status: string | null;
    planning_item_id: number | null;
    data: any | null;
    pdf_raw: string | null;
}

export type WorkOrderOptionalAttributes =
    | 'work_order_id'
    | 'client_id'
    | 'work_order_type'
    | 'creation_date'
    | 'status'
    | 'planning_item_id'
    | 'data'
    | 'pdf_raw';
export interface WorkOrderCreate {
    work_order_id?: number;
    client_id?: number | null;
    work_order_type?: string | null;
    creation_date?: Date | null;
    status?: string | null;
    planning_item_id?: number | null;
    data?: any | null;
    pdf_raw?: string | null;
}
