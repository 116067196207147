import { styled } from '@mui/system';

export const Container = styled('div')`
    flex: 1;
    position: relative;
`;

export const ProgressContainer = styled('div')`
    position: absolute;
    top: 425px;
    left: 0;
    right: 0;
    margin: auto auto;
    width: fit-content;
    z-index: 1000;
`;

export const Header = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const DayLiteral = styled('div')`
    display: flex;
`;

export const DayNumeric = styled('div')`
    display: flex;
    opacity: 0.5;
`;
