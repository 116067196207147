export enum PlanningItemColumns {
    planning_item_id = 'planning_item_id',
    planning_item_type = 'planning_item_type',
    planning_item_type_number = 'planning_item_type_number',
    creation_date = 'creation_date',
    state = 'state',
    client_id = 'client_id',
    lead_id = 'lead_id',
    assigned_calendar_item_id = 'assigned_calendar_item_id',
    data = 'data',
}

export interface PlanningItemAttributes {
    planning_item_id: number;
    planning_item_type: string | null;
    planning_item_type_number: number | null;
    creation_date: Date | null;
    state: string | null;
    client_id: number | null;
    lead_id: number | null;
    assigned_calendar_item_id: number | null;
    data: any | null;
}

export type PlanningItemOptionalAttributes =
    | 'planning_item_id'
    | 'planning_item_type'
    | 'planning_item_type_number'
    | 'creation_date'
    | 'state'
    | 'client_id'
    | 'lead_id'
    | 'assigned_calendar_item_id'
    | 'data';
export interface PlanningItemCreate {
    planning_item_id?: number;
    planning_item_type?: string | null;
    planning_item_type_number?: number | null;
    creation_date?: Date | null;
    state?: string | null;
    client_id?: number | null;
    lead_id?: number | null;
    assigned_calendar_item_id?: number | null;
    data?: any | null;
}
