export enum ClientOldColumns {
    client_id = 'client_id',
    client_number = 'client_number',
    first_name = 'first_name',
    last_name = 'last_name',
    email = 'email',
    phone = 'phone',
    maintenance_active = 'maintenance_active',
    maintenance_inactive_date = 'maintenance_inactive_date',
    data = 'data',
}

export interface ClientOldAttributes {
    client_id: number | null;
    client_number: string;
    first_name: string | null;
    last_name: string | null;
    email: string | null;
    phone: string | null;
    maintenance_active: boolean | null;
    maintenance_inactive_date: Date | null;
    data: any | null;
}

export type ClientOldOptionalAttributes =
    | 'client_id'
    | 'first_name'
    | 'last_name'
    | 'email'
    | 'phone'
    | 'maintenance_active'
    | 'maintenance_inactive_date'
    | 'data';
export interface ClientOldCreate {
    client_id?: number | null;
    client_number: string;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
    phone?: string | null;
    maintenance_active?: boolean | null;
    maintenance_inactive_date?: Date | null;
    data?: any | null;
}
