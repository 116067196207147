"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIUserEntity = void 0;
const partial_iuser_data_entity_1 = require("./partial-iuser-data-entity");
const partial_iuser_roles_entity_1 = require("./partial-iuser-roles-entity");
function parseIUserEntity(data) {
    if (typeof data.creation_date === 'string')
        data.creation_date = new Date(data.creation_date);
    if (typeof data.last_updated === 'string')
        data.last_updated = new Date(data.last_updated);
    if (data.data !== null && typeof data.data === 'object')
        data.data = (0, partial_iuser_data_entity_1.parsePartialIUserDataEntity)(data.data);
    if (data.roles !== null && typeof data.roles === 'object')
        data.roles = (0, partial_iuser_roles_entity_1.parsePartialIUserRolesEntity)(data.roles);
    return data;
}
exports.parseIUserEntity = parseIUserEntity;
