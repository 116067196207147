<div>
    <div *ngIf="!isNewQuote && !!upsertQuote">
        <h3>Edit offerte</h3>
    </div>
    <div *ngIf="isNewQuote && !!upsertQuote">
        <h3>Opmaak offerte</h3>
    </div>
</div>

<div class="row2" id="upsert-quote-page">
    <div class="grid-item">
        <div class="card">
            <div class="title">
                <div class="grid-item">
                    <p class="bold">Verkoper fiche</p>
                </div>
            </div>
            <div class="card-content card-content">
                <mat-card>
                    <mat-card-subtitle style="margin: 0px">Gemaakt door</mat-card-subtitle>
                    <mat-card-content>
                        <div *ngIf="!isNewQuote && !!upsertQuote">
                            <mat-label>
                                {{
                                    (upsertQuote.created_by_user.first_name
                                        ? upsertQuote.created_by_user.first_name
                                        : '') +
                                        ' ' +
                                        (upsertQuote.created_by_user.last_name
                                            ? upsertQuote.created_by_user.last_name
                                            : '')
                                }}
                            </mat-label>
                            <br />
                            {{ upsertQuote.created_by_user.email }}
                        </div>

                        <form *ngIf="isNewQuote && !!upsertQuote" [formGroup]="vendorFormGroup">
                            <mat-label>
                                {{
                                    (loggedInUser.first_name ? loggedInUser.first_name : '') +
                                        ' ' +
                                        (loggedInUser.last_name ? loggedInUser.last_name : '')
                                }}
                            </mat-label>
                            <br />
                            <mat-label>Ingelogd als: {{ loggedInUser.email }}</mat-label>
                        </form>
                    </mat-card-content>
                    <mat-card-subtitle style="margin: 0px">Verkoper:</mat-card-subtitle>
                    <mat-card-content>
                        <div *ngIf="!isNewQuote && !!upsertQuote">
                            <mat-label>
                                <!--make sure we have the name for the sold_by_user (to visualize)-->
                                {{
                                    (upsertQuote.created_by_user.first_name
                                        ? upsertQuote.created_by_user.first_name
                                        : '') +
                                        ' ' +
                                        (upsertQuote.created_by_user.last_name
                                            ? upsertQuote.created_by_user.last_name
                                            : '')
                                }}
                            </mat-label>
                            <br />
                            {{ upsertQuote.created_by_user.email }}
                        </div>

                        <form *ngIf="isNewQuote && !!upsertQuote" [formGroup]="settingsQuoteFormGroup">
                            <mat-form-field *ngIf="userList">
                                <mat-label>Verkoper</mat-label>
                                <mat-select formControlName="sold_by_user_id">
                                    <mat-option *ngFor="let user of userList" [value]="user.user_id">
                                        {{ user.first_name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div class="card">
            <div class="title">
                <div class="grid-item">
                    <p class="bold">Klanten fiche</p>
                </div>
            </div>
            <div class="card-content card-content">
                <mat-card>
                    <!-- <mat-card-subtitle>Klant gegevens</mat-card-subtitle>-->
                    <mat-card-content>
                        <form [formGroup]="clientFormGroup">
                            <div class="stepper-row-2">
                                <mat-form-field>
                                    <mat-label>Taal</mat-label>
                                    <mat-select formControlName="language" required>
                                        <mat-option value="nl">Nederlands</mat-option>
                                        <mat-option value="fr">Frans</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Voorkeur plaatsing datum</mat-label>
                                    <input
                                        (ngModelChange)="updatePlacementDate($event)"
                                        [matDatepicker]="picker"
                                        formControlName="voorkeurs_plaatsing_datum"
                                        matInput
                                    />
                                    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div *ngIf="isNewQuote && selectClient">
                                <mat-slide-toggle [(ngModel)]="isExistingClient" [ngModelOptions]="{ standalone: true }"
                                    >Bestaande klant
                                </mat-slide-toggle>

                                <pr-client-search-modal
                                    (client)="setClient($event)"
                                    *ngIf="isExistingClient"
                                    class="stepper-row-1"
                                >
                                </pr-client-search-modal>
                                <div *ngIf="!isExistingClient">Nieuwe lead afgewerkt met offerte wordt aangemaakt!</div>
                            </div>

                            <div class="stepper-row-2">
                                <mat-form-field>
                                    <mat-label>Voornaam</mat-label>
                                    <input formControlName="first_name" matInput placeholder="Voornaam" required />
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Achternaam</mat-label>
                                    <input formControlName="last_name" matInput placeholder="Achternaam" />
                                </mat-form-field>
                            </div>

                            <div class="stepper-row-2">
                                <mat-form-field>
                                    <mat-label>Straat</mat-label>
                                    <input formControlName="address" matInput placeholder="Straat" required />
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Stad</mat-label>
                                    <input formControlName="city" matInput placeholder="Stad" required />
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Postcode</mat-label>
                                    <input formControlName="zip_code" matInput placeholder="Postcode" required />
                                </mat-form-field>
                            </div>

                            <div class="stepper-row-2">
                                <mat-form-field>
                                    <mat-label>Tel nr.</mat-label>
                                    <input formControlName="phone" matInput placeholder="Tel nr." />
                                    <mat-hint align="end">(0)000 00 00 00</mat-hint>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Email</mat-label>
                                    <input formControlName="email" matInput placeholder="Email" required />
                                </mat-form-field>
                            </div>

                            <div class="stepper-row-2">
                                <mat-form-field>
                                    <mat-label>BE nr.</mat-label>
                                    <input
                                        formControlName="bank_account"
                                        matInput
                                        placeholder="BE nr."
                                        (ngModelChange)="enableBtwOptionZeroPercentage()"
                                    />
                                    <mat-hint align="end">Format BE0000000000 (BE + 10 cijfers)</mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="stepper-row-1">
                                <mat-form-field>
                                    <mat-label>Opmerking</mat-label>
                                    <textarea
                                        formControlName="remarks"
                                        matInput
                                        placeholder="Opmerking"
                                        rows="4"
                                    ></textarea>
                                </mat-form-field>
                            </div>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <div class="grid-item">
        <div class="card">
            <div class="title">
                <div class="grid-item">
                    <p class="bold">Technische fiche</p>
                </div>
            </div>
            <div class="card-content card-content">
                <mat-card>
                    <!-- <mat-card-subtitle>Technische fiche</mat-card-subtitle>-->
                    <mat-card-content>
                        <form [formGroup]="technicalFormGroup">
                            <div class="stepper-row-2">
                                <mat-form-field>
                                    <mat-label>Analyse °F</mat-label>
                                    <input
                                        formControlName="analyse_f"
                                        matInput
                                        placeholder="Analyse °F"
                                        required
                                        type="number"
                                    />
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Leidingen</mat-label>
                                    <input formControlName="leidingen" matInput placeholder="Leidingen" required />
                                </mat-form-field>
                            </div>
                            <div class="stepper-row-2">
                                <mat-form-field>
                                    <mat-label>Keer klep</mat-label>
                                    <input formControlName="keer_klep" matInput placeholder="Keer klep" required />
                                </mat-form-field>
                            </div>
                            <div class="stepper-row-2">
                                <mat-form-field>
                                    <mat-label>Stopcontact</mat-label>
                                    <input formControlName="stopcontact" matInput placeholder="Stopcontact" />
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Afvoer</mat-label>
                                    <input formControlName="afvoer" matInput placeholder="Afvoer" />
                                </mat-form-field>
                            </div>
                            <div class="stepper-row-2" style="padding-top: 5px">
                                <label>Rating</label>
                                <ngb-rating
                                    [(rate)]="rating.value"
                                    [max]="rating.max"
                                    (rateChange)="rateChanged = true"
                                    style="color: orange"
                                ></ngb-rating>
                            </div>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div class="card">
            <div class="title">
                <div class="grid-item">
                    <p class="bold">Type Toestel</p>
                </div>
            </div>
            <div class="card-content card-content">
                <mat-card>
                    <!--<mat-card-subtitle>Type toestel</mat-card-subtitle>-->
                    <mat-card-content>
                        <form [formGroup]="machineFormGroup">
                            <div class="stepper-row-2">
                                <mat-form-field>
                                    <mat-label>Klep</mat-label>
                                    <input formControlName="klep" matInput placeholder="Klep" required />
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Bypass</mat-label>
                                    <input formControlName="bypass" matInput placeholder="Bypass" />
                                </mat-form-field>
                            </div>
                            <div class="stepper-row-2">
                                <mat-form-field>
                                    <mat-label>Aantal liters</mat-label>
                                    <input
                                        formControlName="n_liters"
                                        matInput
                                        placeholder="Aantal liters"
                                        required
                                        type="number"
                                    />
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Type harsen</mat-label>
                                    <input formControlName="type_harsen" matInput placeholder="Type harsen" />
                                </mat-form-field>
                            </div>
                            <div class="stepper-row-2">
                                <mat-form-field>
                                    <mat-label>Filter</mat-label>
                                    <input formControlName="filter" matInput placeholder="Filter" />
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>KDF filter</mat-label>
                                    <input formControlName="kdf_filters" matInput placeholder="KDF filter" />
                                </mat-form-field>
                            </div>
                            <div class="stepper-row-2">
                                <mat-form-field>
                                    <mat-label>Drukregelaar</mat-label>
                                    <input formControlName="drukregelaar" matInput placeholder="Drukregelaar" />
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Vlotter</mat-label>
                                    <input formControlName="vlotter" matInput placeholder="Vlotter" />
                                </mat-form-field>
                            </div>
                            <div class="stepper-row-2">
                                <mat-form-field>
                                    <mat-label>Plaatsing kost</mat-label>
                                    <input
                                        formControlName="plaatsing_kost"
                                        matInput
                                        placeholder="Plaatsing kost"
                                        required
                                        type="number"
                                    />
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Zout (kg)</mat-label>
                                    <input formControlName="zout" matInput placeholder="Zout (kg)" />
                                </mat-form-field>
                            </div>
                            <div class="stepper-row-2">
                                <mat-form-field>
                                    <mat-label>Subtotaal</mat-label>
                                    <input
                                        formControlName="subtotal"
                                        matInput
                                        placeholder="Subtotaal"
                                        required
                                        type="number"
                                    />
                                    <span matPrefix>€&nbsp;</span>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>BTW</mat-label>
                                    <mat-select formControlName="btw">
                                        <mat-option *ngFor="let btwOption of btwOptions" [value]="btwOption.value">{{
                                            btwOption.name
                                        }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <form [formGroup]="settingsQuoteFormGroup" *ngIf="isNewQuote">
                                <div class="stepper-row-2">
                                    <mat-checkbox formControlName="mail_quote_to_client" matInput
                                        >Email offerte naar klant</mat-checkbox
                                    >
                                    <mat-checkbox formControlName="sold" matInput>Verkocht</mat-checkbox>
                                </div>
                            </form>

                            <div class="stepper-row-2">
                                <div class="total-amount-quote">Totaal: {{ getTotal() + ' €' }}</div>
                            </div>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
<div class="row1" style="margin-top: 15px; text-align: right">
    <div class="grid-item">
        <div *ngIf="!saving">
            <button (click)="cancel()" class="btn">Cancel</button>
            <button (click)="isNewQuote ? createQuote() : updateQuote()" class="btn btn-primary">Opslaan</button>
        </div>
        <div *ngIf="saving">
            <span class="spinner spinner-inline"> Loading... </span>
            <span> Saving... </span>
        </div>
    </div>
</div>
