import { Pipe, PipeTransform } from '@angular/core';
import { PlanningItemService } from '../services/planning-item.service';

@Pipe({
    name: 'authImage',
})
export class AuthImagePipe implements PipeTransform {
    constructor(private planningItemService: PlanningItemService) {}

    async transform(fileName: string): Promise<string> {
        const imageBlob = await this.planningItemService.downloadAttachment(fileName);
        const reader = new FileReader();
        return new Promise<string>(resolve => {
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.readAsDataURL(imageBlob);
        });
    }
}
