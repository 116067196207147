import { styled } from '@mui/system';

export const Content = styled('div')`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    margin-left: -16px;
`;

export const Date = styled('div')`
    font-size: 14px;
    text-align: right;
    color: #8c8c8c;
`;

export const Label = styled('a')`
    font-size: 14px;
    margin-left: 4px;
    cursor: pointer;

    &: hover {
        outline-width: 0;
        color: #0079b8;
        text-decoration: underline;
    }
`;

export const PlanningItemNumber = styled('span')`
    margin-left: 4px;
`;

export const Watermeterstand = styled('span')`
    margin-left: 4px;
`;
