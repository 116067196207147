<div *ngIf="planningItems.length > 0">
  <div *ngFor="let planningItem of planningItems " class="row1" id="card-list2">
    <pr-planning-item-list-detail [planningItem]="planningItem"></pr-planning-item-list-detail>
  </div>
</div>
<div *ngIf="planningItems.length == 0">
  no results found
</div>

<div class="center" *ngIf="planningItems.length > 0">
  <div class="pagination">
    <a [class.active]="currentPageIndex > 0" (click)="setPage(currentPageIndex - 1)">&laquo;</a>
    <a *ngIf="maxPages === 0" (click)="setPage(1)" class="active">1</a>
    <a *ngIf="currentPageIndex > 6">...</a>
    <div *ngFor="let pageNo of maxPages| times let i = index">
      <a *ngIf="pageNo >= currentPageIndex - 5 && pageNo <= currentPageIndex + 5"
         (click)="setPage(pageNo - 1)"
         [class.active]="pageNo===currentPageIndex + 1">
        {{pageNo}}
      </a>
    </div>
    <a *ngIf="currentPageIndex < maxPages - 5">...</a>
    <a [class.active]="currentPageIndex < maxPages - 1" (click)="setPage(currentPageIndex + 1)">&raquo;</a>
  </div>
</div>
<div class="center" *ngIf="planningItems.length > 0">
  <div class="clr-select-wrapper">
    <select id="select-pages" class="clr-select" [(ngModel)]="currentPageSize" (ngModelChange)="updatePageSize($event)">
      <option *ngFor="let pageSize of pageSizes" [value]="pageSize">{{pageSize}}</option>
    </select>
  </div>
</div>


