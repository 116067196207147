"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIQuoteLineCreateEntity = void 0;
const iquote_line_product_document_core_entity_1 = require("./iquote-line-product-document-core-entity");
const product_attributes_1 = require("./product-attributes");
function parseIQuoteLineCreateEntity(data) {
    if (typeof data.creation_date === 'string')
        data.creation_date = new Date(data.creation_date);
    if (data.product_document_free_entry !== null && typeof data.product_document_free_entry === 'object')
        data.product_document_free_entry = (0, iquote_line_product_document_core_entity_1.parseIQuoteLineProductDocumentCoreEntity)(data.product_document_free_entry);
    if (data.product !== null && typeof data.product === 'object')
        data.product = (0, product_attributes_1.parseProductAttributes)(data.product);
    return data;
}
exports.parseIQuoteLineCreateEntity = parseIQuoteLineCreateEntity;
