"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parsePartialIClientDataEntity = void 0;
const iaddress_1 = require("./iaddress");
const iclient_data_entity_company_data_1 = require("./iclient-data-entity-company-data");
function parsePartialIClientDataEntity(data) {
    if (data.main_address !== null && typeof data.main_address === 'object')
        data.main_address = (0, iaddress_1.parseIAddress)(data.main_address);
    if (data.invoice_address !== null && typeof data.invoice_address === 'object')
        data.invoice_address = (0, iaddress_1.parseIAddress)(data.invoice_address);
    if (data.delivery_addresses !== null && typeof data.delivery_addresses === 'object')
        data.delivery_addresses = data.delivery_addresses.map((x) => (0, iaddress_1.parseIAddress)(x));
    if (data.company_data !== null && typeof data.company_data === 'object')
        data.company_data = (0, iclient_data_entity_company_data_1.parseIClientDataEntityCompanyData)(data.company_data);
    return data;
}
exports.parsePartialIClientDataEntity = parsePartialIClientDataEntity;
