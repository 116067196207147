"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIQuoteCreateEntity = void 0;
const iquote_client_fiche_entity_1 = require("./iquote-client-fiche-entity");
const iquote_data_rating_1 = require("./iquote-data-rating");
const iquote_machine_fiche_entity_1 = require("./iquote-machine-fiche-entity");
const iquote_technical_fiche_entity_1 = require("./iquote-technical-fiche-entity");
function parseIQuoteCreateEntity(data) {
    if (data.technicalFiche !== null && typeof data.technicalFiche === 'object')
        data.technicalFiche = (0, iquote_technical_fiche_entity_1.parseIQuoteTechnicalFicheEntity)(data.technicalFiche);
    if (data.clientFiche !== null && typeof data.clientFiche === 'object')
        data.clientFiche = (0, iquote_client_fiche_entity_1.parseIQuoteClientFicheEntity)(data.clientFiche);
    if (data.machineFiche !== null && typeof data.machineFiche === 'object')
        data.machineFiche = (0, iquote_machine_fiche_entity_1.parseIQuoteMachineFicheEntity)(data.machineFiche);
    if (data.rating !== null && typeof data.rating === 'object')
        data.rating = (0, iquote_data_rating_1.parseIQuoteDataRating)(data.rating);
    return data;
}
exports.parseIQuoteCreateEntity = parseIQuoteCreateEntity;
