import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Environment, CreateEventInfo, PhrosprCalendar } from '@phrospr/frontend-react';
import { CalendarEvent } from '@phrospr/frontend-react/dist/reusable/Calendar/Calendar.types';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

const containerElementName = 'CalendarComponent';

@Component({
    selector: 'pr-calendar',
    template: `<span #${containerElementName}></span>`,
})
export class CalendarComponent implements OnChanges, OnDestroy, AfterViewInit {
    @ViewChild(containerElementName, { static: true }) containerRef: ElementRef;
    @Input() public environment: Environment;
    @Input() public planningItemId?: number;
    @Input() public eventToCreate?: CalendarEvent;
    @Output() public createEvent = new EventEmitter<CreateEventInfo>();
    @Output() public clickEvent = new EventEmitter<CalendarEvent>();

    constructor() {}

    ngAfterViewInit(): void {
        this.render();
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.render();
    }

    ngOnDestroy(): void {
        ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
    }

    private render() {
        ReactDOM.render(
            <PhrosprCalendar
                environment={this.environment}
                eventToCreate={this.eventToCreate}
                planningItemId={this.planningItemId}
                onCreateEvent={e => this.createEvent.emit(e)}
                onClickEvent={e => this.clickEvent.emit(e)}
            />,
            this.containerRef.nativeElement,
        );
    }
}
