"use strict";
/* tslint:disable */
/* eslint-disable */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityApi = exports.ActivityApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
// @ts-ignore
const models_1 = require("../models");
// @ts-ignore
const models_2 = require("../models");
/**
 * ActivityApi - axios parameter creator
 * @export
 */
const ActivityApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} planningItemId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivitiesForPlanningItem: (planningItemId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'planningItemId' is not null or undefined
            (0, common_1.assertParamExists)('getActivitiesForPlanningItem', 'planningItemId', planningItemId);
            const localVarPath = `/api/activity/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (planningItemId !== undefined) {
                localVarQueryParameter['planning_item_id'] = planningItemId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IRequestActivityListBody} iRequestActivityListBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityList: (iRequestActivityListBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iRequestActivityListBody' is not null or undefined
            (0, common_1.assertParamExists)('getActivityList', 'iRequestActivityListBody', iRequestActivityListBody);
            const localVarPath = `/api/activity/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iRequestActivityListBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.ActivityApiAxiosParamCreator = ActivityApiAxiosParamCreator;
/**
 * ActivityApi -
 */
var ActivityApi;
(function (ActivityApi) {
    /**
     *
     * @param {number} planningItemId
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof ActivityApi
     * url /api/activity/list
     */
    function getActivitiesForPlanningItem(planningItemId, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.ActivityApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.getActivitiesForPlanningItem(planningItemId, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = res.data.map((x) => (0, models_2.parseIActivityEntity)(x));
                return res;
            });
        });
    }
    ActivityApi.getActivitiesForPlanningItem = getActivitiesForPlanningItem;
    /**
     *
     * @param {IRequestActivityListBody} iRequestActivityListBody
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof ActivityApi
     * url /api/activity/list
     */
    function getActivityList(iRequestActivityListBody, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.ActivityApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.getActivityList(iRequestActivityListBody, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_1.parseBatchResponseIActivityEntity)(res.data);
                return res;
            });
        });
    }
    ActivityApi.getActivityList = getActivityList;
})(ActivityApi = exports.ActivityApi || (exports.ActivityApi = {}));
