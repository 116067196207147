export enum ExactTokenColumns {
    exact_token_id = 'exact_token_id',
    value = 'value',
    data = 'data',
    creation_date = 'creation_date',
}

export interface ExactTokenAttributes {
    exact_token_id: number;
    value: string | null;
    data: any | null;
    creation_date: Date | null;
}

export type ExactTokenOptionalAttributes = 'exact_token_id' | 'value' | 'data' | 'creation_date';
export interface ExactTokenCreate {
    exact_token_id?: number;
    value?: string | null;
    data?: any | null;
    creation_date?: Date | null;
}
