import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IConfiguration, IPlanningItemEntity, PaymentMethodType, PlanningItemReportState } from '@phrospr/api-backend';
import * as toastr from 'toastr';
import { getReportActionOptions, IPlanningItemFinishPortal, parsePaymentMethodTypeReadable } from '@phrospr/lib-core';
import { configuration$ } from '@phrospr/lib-web-core';

@Component({
    selector: 'pr-planning-item-finish-modal',
    styleUrls: ['./planning-item-finish-modal.component.scss'],
    templateUrl: './planning-item-finish-modal.component.html',
})
export class PlanningItemFinishModalComponent implements OnInit {
    state: PlanningItemReportState = PlanningItemReportState.completed;
    comment: string;
    selectedPaymentMethod: PaymentMethodType;

    _planningItem: IPlanningItemEntity;
    @Input('planningItem') get planningItem() {
        return this._planningItem;
    }

    set planningItem(planningItem: IPlanningItemEntity) {
        this._planningItem = planningItem;
        if (!this.planningItem) return;
        this.actionOptions = getReportActionOptions(this.planningItem.planning_item_type, true);
        if (this.actionOptions.length) this.state = this.actionOptions[0].value;
    }

    @Input() visible: boolean;

    @Output() cancel = new EventEmitter<any>();
    @Output() createPlanningItemReport = new EventEmitter<IPlanningItemFinishPortal>();

    actionOptions: Array<{ text: string; value: PlanningItemReportState }> = [];

    paymentMethodTypes: PaymentMethodType[] = Object.keys(PaymentMethodType).map(key => PaymentMethodType[key]);
    configuration: IConfiguration;

    constructor() {}

    async ngOnInit(): Promise<void> {
        this.configuration = await configuration$;
    }

    finishPlannedPlanningItem() {
        if (!this.comment || (this.configuration.planning.paymentMethod && !this.state)) {
            return toastr.error('Gelieve een comment & een actie & betaal methode in te vullen');
        }
        if (
            this.configuration.planning.paymentMethod &&
            (this.state === PlanningItemReportState.completed ||
                this.state === PlanningItemReportState.completedWithOffer) &&
            !this.selectedPaymentMethod
        ) {
            return toastr.error('Gelieve een betaal methode in te vullen als je iets afwerkt');
        }
        this.createPlanningItemReport.emit({
            calendar_item: null,
            calendar_item_id: null,
            comment: this.comment,
            created_by_user: null,
            created_by_user_id: null,
            date_time: null,
            state: this.state,
            payment_method: this.selectedPaymentMethod,
        });
        this.visible = false;
    }

    sendCancel() {
        this.cancel.emit();
        this.visible = false;
    }

    parsePaymentMethodTypeReadable(paymentMethod: PaymentMethodType) {
        return parsePaymentMethodTypeReadable(paymentMethod);
    }
}
