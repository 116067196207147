import Email from '@mui/icons-material/Email';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhone from '@mui/icons-material/LocalPhone';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { Button, ButtonGroup, IconButton, TextareaAutosize } from '@mui/material';
import { ICommentEntity, PlanningItemCommentApi, QuoteCommentApi } from '@phrospr/api-backend';
import React, { ChangeEvent, Component } from 'react';
import * as toastr from 'toastr';
import './CommentAdd.scss';
import { CommentAddProps, CommentAddState } from './CommentAdd.types';

export class CommentAdd extends Component<CommentAddProps, CommentAddState> {
    comment: string = '';

    constructor(props: CommentAddProps) {
        super(props);
        this.state = { phoned: false, emailed: false };
    }

    onCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.comment = event.target.value;
    };

    cancelComment = () => {
        if (this.props.onCancel) this.props.onCancel();
    };

    addComment = async () => {
        if (!this.comment) return toastr.error('please fill in a comment');
        const rawComment: ICommentEntity = {
            comment_id: null,
            creation_date: new Date(),
            created_by_user_id: null,
            data: {
                comment: this.comment,
                contacted_info: {
                    mailed: this.state.emailed,
                    phone: this.state.phoned,
                },
            },
        };
        let comment: ICommentEntity | null = null;
        const { planningItemId, quoteId, onAdd } = this.props;
        if (planningItemId !== undefined)
            comment = (await PlanningItemCommentApi.addPlanningItemComment(planningItemId, rawComment)).data;
        else if (quoteId !== undefined) comment = (await QuoteCommentApi.addQuoteComment(quoteId, rawComment)).data;
        else return toastr.error('missing comment input');

        if (!onAdd) return;
        onAdd(comment);
    };

    render() {
        return (
            <div className={'comment-add'} style={{ padding: '5px' }}>
                <div>
                    <IconButton
                        size={'small'}
                        style={{
                            backgroundColor: this.state.phoned ? '#ccc' : undefined,
                            color: this.state.phoned ? 'green' : undefined,
                        }}
                        aria-label={'local-phone'}
                        onClick={() => this.setState({ phoned: !this.state.phoned })}
                    >
                        {this.state.phoned ? (
                            <LocalPhone fontSize={'inherit'} />
                        ) : (
                            <LocalPhoneOutlinedIcon fontSize={'inherit'} />
                        )}
                    </IconButton>
                    <IconButton
                        size={'small'}
                        aria-label={'email'}
                        style={{
                            backgroundColor: this.state.emailed ? '#ccc' : undefined,
                            color: this.state.emailed ? '#ad0000' : undefined,
                        }}
                        onClick={() => this.setState({ emailed: !this.state.emailed })}
                    >
                        {this.state.emailed ? (
                            <Email fontSize={'inherit'} />
                        ) : (
                            <EmailOutlinedIcon fontSize={'inherit'} />
                        )}
                    </IconButton>
                </div>
                <div style={{ marginBottom: '7px', marginTop: '7px' }}>
                    <TextareaAutosize
                        aria-label={'empty textarea'}
                        placeholder={'Comment'}
                        minRows={3}
                        style={{ minWidth: 250, width: '100%' }}
                        onChange={this.onCommentChange}
                    />
                </div>

                <ButtonGroup variant={'contained'} size={'small'} aria-label={'outlined primary button group small'}>
                    <Button onClick={this.addComment}>Add</Button>
                    <Button variant={'outlined'} onClick={this.cancelComment}>
                        Cancel
                    </Button>
                </ButtonGroup>
            </div>
        );
    }
}

export default CommentAdd;
