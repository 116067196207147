import { Component, Input, LOCALE_ID, OnInit } from '@angular/core';
import {
    IPlanningItemEntity,
    LeadType,
    PlanningItemApi,
    PlanningItemCommentApi,
    PlanningItemType,
} from '@phrospr/api-backend';
import { Router } from '@angular/router';
import * as toastr from 'toastr';
import { ClientState } from '@phrospr/lib-models';

@Component({
    selector: 'pr-planning-item-list-detail',
    styleUrls: ['./planning-item-list-detail.component.scss'],
    templateUrl: './planning-item-list-detail.component.html',
    providers: [{ provide: LOCALE_ID, useValue: 'fr' }],
})
export class PlanningItemListDetailComponent implements OnInit {
    @Input() planningItem: IPlanningItemEntity;

    showEditSuggestedMaintenance = false;
    editSuggestedMaintenanceDateValue: Date = null;

    PlanningItemType = PlanningItemType;
    ClientState = ClientState;

    LeadType = LeadType;

    constructor(private router: Router) {}

    ngOnInit(): void {
        if (this.planningItem.planning_item_type === PlanningItemType.maintenance) {
            this.showEditSuggestedMaintenance = false;
            this.editSuggestedMaintenanceDateValue = this.planningItem.data.suggested_date;
        }
    }

    async planPlanningItem() {
        await this.router.navigateByUrl('/calendar-view?planning_item_id=' + this.planningItem.planning_item_id);
    }

    async goToPlanningItemDetail(planning_item_id?: number) {
        if (planning_item_id) {
            await this.router.navigateByUrl('/planning-item-detail?planning_item_id=' + planning_item_id);
        } else {
            await this.router.navigateByUrl(
                '/planning-item-detail?planning_item_id=' + this.planningItem.planning_item_id,
            );
        }
    }

    async goToClientDetail(clientNumber: number) {
        await this.router.navigateByUrl('/client-detail?client_id=' + clientNumber);
    }

    async editSuggestedPlanningItemDate() {
        if (!this.editSuggestedMaintenanceDateValue) {
            return toastr.error('please fill in a date!');
        }
        this.planningItem.data.suggested_date = this.editSuggestedMaintenanceDateValue;
        await PlanningItemApi.updatePlanningItem({
            planningItem: this.planningItem,
        });
        this.showEditSuggestedMaintenance = false;
    }

    // CAN BE USED WHEN ADDING A NEW COMMENT THAT WE DON4T HAVE TO RELOAD EVERYTHING
    async getNewComments(planningItem: IPlanningItemEntity) {
        const newComments = (await PlanningItemCommentApi.listPlanningItemComments(planningItem.planning_item_id)).data
            .map(x => ({ ...x, creation_date: new Date(x.creation_date) }))
            .sort((a, b) => Number(a.creation_date) - Number(b.creation_date));
        if (newComments.length) {
            planningItem.comments = newComments;
        }
    }
}
