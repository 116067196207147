export enum WorkOrderCommentColumns {
    comment_id = 'comment_id',
    work_order_id = 'work_order_id',
}

export interface WorkOrderCommentAttributes {
    comment_id: number;
    work_order_id: number;
}

export type WorkOrderCommentCreate = WorkOrderCommentAttributes;
