"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIDashboard = void 0;
const icount_calendar_items_per_type_per_date_1 = require("./icount-calendar-items-per-type-per-date");
const icount_lead_sources_1 = require("./icount-lead-sources");
const icount_of_finished_planning_item_types_per_user_per_planning_type_1 = require("./icount-of-finished-planning-item-types-per-user-per-planning-type");
const icount_per_planning_item_type_1 = require("./icount-per-planning-item-type");
const isum_quote_total_per_month_1 = require("./isum-quote-total-per-month");
function parseIDashboard(data) {
    if (data.count_to_be_planned_per_planning_item_type !== null && typeof data.count_to_be_planned_per_planning_item_type === 'object')
        data.count_to_be_planned_per_planning_item_type = data.count_to_be_planned_per_planning_item_type.map((x) => (0, icount_per_planning_item_type_1.parseICountPerPlanningItemType)(x));
    if (data.count_lead_sources !== null && typeof data.count_lead_sources === 'object')
        data.count_lead_sources = data.count_lead_sources.map((x) => (0, icount_lead_sources_1.parseICountLeadSources)(x));
    if (data.count_calendar_items_per_type_per_date !== null && typeof data.count_calendar_items_per_type_per_date === 'object')
        data.count_calendar_items_per_type_per_date = data.count_calendar_items_per_type_per_date.map((x) => (0, icount_calendar_items_per_type_per_date_1.parseICountCalendarItemsPerTypePerDate)(x));
    if (data.count_finished_calendar_items_per_type !== null && typeof data.count_finished_calendar_items_per_type === 'object')
        data.count_finished_calendar_items_per_type = data.count_finished_calendar_items_per_type.map((x) => (0, icount_per_planning_item_type_1.parseICountPerPlanningItemType)(x));
    if (data.count_planned_calendar_items_per_type !== null && typeof data.count_planned_calendar_items_per_type === 'object')
        data.count_planned_calendar_items_per_type = data.count_planned_calendar_items_per_type.map((x) => (0, icount_per_planning_item_type_1.parseICountPerPlanningItemType)(x));
    if (data.count_finished_planning_item_type_per_user !== null && typeof data.count_finished_planning_item_type_per_user === 'object')
        data.count_finished_planning_item_type_per_user = data.count_finished_planning_item_type_per_user.map((x) => (0, icount_of_finished_planning_item_types_per_user_per_planning_type_1.parseICountOfFinishedPlanningItemTypesPerUserPerPlanningType)(x));
    if (data.sum_quote_total_per_month_created !== null && typeof data.sum_quote_total_per_month_created === 'object')
        data.sum_quote_total_per_month_created = data.sum_quote_total_per_month_created.map((x) => (0, isum_quote_total_per_month_1.parseISumQuoteTotalPerMonth)(x));
    if (data.sum_quote_total_per_month_approved !== null && typeof data.sum_quote_total_per_month_approved === 'object')
        data.sum_quote_total_per_month_approved = data.sum_quote_total_per_month_approved.map((x) => (0, isum_quote_total_per_month_1.parseISumQuoteTotalPerMonth)(x));
    return data;
}
exports.parseIDashboard = parseIDashboard;
