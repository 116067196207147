import PropTypes from 'prop-types';
import React from 'react';
import { Navigate, stringOrDate, ViewProps } from 'react-big-calendar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import { FunctionalViewComponent } from './types';
import { configuration } from '@phrospr/lib-web-core';

let _minDate: Date;
const getMinDate = () => {
    if (_minDate) return _minDate;
    _minDate = new Date();
    _minDate.setHours(configuration.planning.minHour, 0, 0, 0);
    return _minDate;
};

let _maxDate: Date;
const getMaxDate = () => {
    if (_maxDate) return _maxDate;
    _maxDate = new Date();
    _maxDate.setHours(configuration.planning.maxHour, 0, 0, 0);
    return _maxDate;
};

export const CustomDayView: FunctionalViewComponent = ({
    date,
    localizer,
    min = getMinDate(),
    max = getMaxDate(),
    ...props
}: ViewProps) => {
    const currRange = React.useMemo(() => CustomDayView.range(date, { localizer }), [date, localizer]);

    return (
        <TimeGrid date={date} eventOffset={10} localizer={localizer} max={max} min={min} range={currRange} {...props} />
    );
};

CustomDayView.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    localizer: PropTypes.object,
    max: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    scrollToTime: PropTypes.instanceOf(Date),
};

CustomDayView.range = function (date: stringOrDate, { localizer }) {
    return [localizer.startOf(date, 'day')];
};

CustomDayView.navigate = function (date: stringOrDate, action, { localizer }) {
    switch (action) {
        case Navigate.PREVIOUS:
            return localizer.add(date, -1, 'day');
        case Navigate.NEXT:
            return localizer.add(date, 1, 'day');
        default:
            return date;
    }
};

CustomDayView.title = function (date, { localizer }) {
    return localizer.format(date, 'dayHeaderFormat');
};
