import { CircularProgress } from '@mui/material';
import moment from 'moment';
import 'moment/locale/nl-be';
import React from 'react';
import {
    Calendar as BaseCalendar,
    EventProps,
    momentLocalizer,
    SlotInfo,
    stringOrDate,
    View,
} from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import './Calendar.scss';
import * as Style from './Calendar.style';
import { CalendarEvent } from './Calendar.types';
import { CustomDayView } from './Views/day';
import { CustomWeekView } from './Views/week';

moment.locale('nl-be');
const mainLocalizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(BaseCalendar);

export interface CalendarProps {
    loading: boolean;
    events: CalendarEvent[];
    selectable?: boolean;
    onChangeDate: (date: Date) => void;
    onEventResize?: (args: OnEventResizeArgs) => void;
    onRenderEvent: (event: CalendarEvent) => JSX.Element;
    onSelectSlot?: (slotInfo: SlotInfo) => void;
    draggableAccessor?: (event: CalendarEvent) => boolean;
    resizableAccessor?: (event: CalendarEvent) => boolean;
}

export interface OnEventResizeArgs {
    event: CalendarEvent;
    start: stringOrDate;
    end: stringOrDate;
    isAllDay: boolean;
}

export function Calendar({
    loading,
    events,
    selectable = false,
    onSelectSlot,
    onRenderEvent,
    onChangeDate,
    onEventResize,
    draggableAccessor,
    resizableAccessor,
}: CalendarProps) {
    const [view, setView] = React.useState<View>('week');

    return (
        <Style.Container>
            {loading && (
                <Style.ProgressContainer>
                    <CircularProgress size={64} />
                </Style.ProgressContainer>
            )}
            <DnDCalendar
                culture="nl-BE"
                defaultDate={moment().toDate()}
                defaultView={view}
                events={events}
                localizer={mainLocalizer}
                onSelectSlot={onSelectSlot}
                onEventResize={onEventResize}
                onView={setView}
                style={{ height: '100%', opacity: loading ? 0.5 : 1 }}
                tooltipAccessor={undefined}
                draggableAccessor={draggableAccessor}
                resizableAccessor={resizableAccessor}
                selectable={selectable}
                views={{
                    week: CustomWeekView,
                    day: CustomDayView,
                }}
                onNavigate={onChangeDate}
                eventPropGetter={(e: CalendarEvent) => ({
                    style: { backgroundColor: e.color, border: '2px solid rgba(0,0,0, 0.1)' },
                })}
                components={{
                    event: ({ event }: EventProps<CalendarEvent>) => onRenderEvent(event),
                    week: {
                        header: ({ date, localizer }) => (
                            <Style.Header>
                                <Style.DayLiteral>{localizer.format(date, 'dddd')}</Style.DayLiteral>
                                <Style.DayNumeric>
                                    {date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' })}
                                </Style.DayNumeric>
                            </Style.Header>
                        ),
                    },
                }}
            />
        </Style.Container>
    );
}

export default Calendar;
