"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parsePlanningItemPlanBodyPlanningItemMail = void 0;
const planning_item_plan_body_planning_item_mail_senders_1 = require("./planning-item-plan-body-planning-item-mail-senders");
function parsePlanningItemPlanBodyPlanningItemMail(data) {
    if (data.senders !== null && typeof data.senders === 'object')
        data.senders = data.senders.map((x) => (0, planning_item_plan_body_planning_item_mail_senders_1.parsePlanningItemPlanBodyPlanningItemMailSenders)(x));
    if (data.receivers !== null && typeof data.receivers === 'object')
        data.receivers = data.receivers.map((x) => (0, planning_item_plan_body_planning_item_mail_senders_1.parsePlanningItemPlanBodyPlanningItemMailSenders)(x));
    return data;
}
exports.parsePlanningItemPlanBodyPlanningItemMail = parsePlanningItemPlanBodyPlanningItemMail;
