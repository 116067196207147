import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import {
    IPlanningItemSearchFilterEntity,
    IPlanningItemSearchShowEntity,
    PlanningItemType,
    PlanningState,
} from '@phrospr/api-backend';

@Component({
    selector: 'pr-new-leads-page',
    styleUrls: ['./new-leads-page.component.scss'],
    templateUrl: './new-leads-page.component.html',
})
export class NewLeadsPageComponent implements OnInit {
    planningItemShow: IPlanningItemSearchShowEntity = {
        client: true,
        assigned_calendar_item: false,
        comments: true,
        activities: false,
        calendar_items: false,
        last_completed_planning_item: true,
    };
    planningItemSearchFilter: IPlanningItemSearchFilterEntity = {
        planning_item_type: PlanningItemType.lead,
        state: PlanningState.toBePlanned,
        client_name: null,
        client_nullable: true,
        lead_nullable: true,
    };

    planningItemSearchFilter$: ReplaySubject<void> = new ReplaySubject();

    constructor() {}

    ngOnInit(): void {}

    updateFilters(filters: IPlanningItemSearchFilterEntity) {
        this.planningItemSearchFilter = filters;
        this.planningItemSearchFilter$.next();
    }
}
