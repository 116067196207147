
<pr-planning-item-list-filter
  [planningItemSearchFilter]="planningItemSearchFilter"
  [suggestedDatePicker]="true"
  (updatePlanningItemSearchFilter)="updateFilters($event)">
</pr-planning-item-list-filter>

<pr-planning-item-list-container
  [planningItemShow]="planningItemShow"
  [planningItemFilter]="planningItemSearchFilter"
  [planningItemFilterUpdate$]="planningItemSearchFilter$"
></pr-planning-item-list-container>
