<div>
    <pr-calendar
        [environment]="environment"
        [planningItemId]="planningItemId"
        [eventToCreate]="createEventInfo?.event"
        (createEvent)="startCreateEvent($event)"
        (clickEvent)="clickEvent($event)"
    ></pr-calendar>

    <clr-modal
        *ngIf="eventToView"
        [(clrModalOpen)]="eventToView"
        [clrModalSize]="'lg'"
        data-target="#modal"
        id="planMaintenanceModal"
        (close)="closeViewModal()"
        [clrModalClosable]="false"
    >
        <div class="modal-header">
            <button (close)="closeViewModal()" type="button">
                <span aria-hidden="true"></span>
            </button>
        </div>
        <div class="modal-body">
            <div>
                <div class="title-div">
                    <div class="title-input">
                        <input
                            disabled
                            placeholder="Title toevoegen"
                            name="title-Clicked"
                            type="text"
                            id="title-Clicked"
                            class="full-length-screen"
                            [(ngModel)]="eventToView.subject"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="icon-modal">
                        <cds-icon shape="clock"></cds-icon>
                    </div>
                    <div class="group-section">
                        <div style="display: flex; flex-flow: row">
                            <input
                                [(clrDate)]="eventToView.start"
                                disabled
                                clrDate
                                placeholder="From"
                                [disabled]="true"
                                type="date"
                                class="date-input group-element no-border"
                            />
                            <div class="group-element no-border">
                                <label class="group-element" disabled style="vertical-align: baseline">
                                    {{ eventToView.start.getHours() + ':' + eventToView.start.getMinutes() }}
                                </label>
                            </div>
                            <div style="flex: 0 0 10px" class="group-element">-</div>
                            <input
                                [(clrDate)]="eventToView.end"
                                clrDate
                                placeholder="To"
                                disabled
                                [disabled]="true"
                                type="date"
                                class="date-input group-element no-border"
                            />
                            <div class="group-element no-border">
                                <label class="group-element" disabled style="vertical-align: baseline">
                                    {{ eventToView.end.getHours() + ':' + eventToView.end.getMinutes() }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="icon-modal">
                        <cds-icon shape="calendar"></cds-icon>
                    </div>
                    <div class="group-section">
                        <div class="full-length-screen input-select">
                            <div class="full-length-screen" style="padding-right: 16px">
                                <label class="group-element full-length-screen">{{ eventToView.user }} </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="icon-modal">
                        <cds-icon shape="map-marker"></cds-icon>
                    </div>
                    <div class="group-section">
                        <div class="group-element" style="width: 95%">
                            <input
                                disabled
                                placeholder="Locatie toevoegen"
                                name="location-clicked"
                                type="text"
                                id="location-clicked"
                                [(ngModel)]="eventToView.location"
                                class="full-length-screen no-border"
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div class="icon-modal"></div>
                    <div class="group-section">
                        <div class="group-element" style="width: 95%">
                            <clr-textarea-container class="no-margin">
                                <textarea
                                    clrTextarea
                                    [(ngModel)]="this.eventToView.content"
                                    name="description"
                                    class="full-length-screen no-border textarea-custom"
                                    disabled
                                >
                                </textarea>
                            </clr-textarea-container>
                        </div>
                    </div>
                </div>
            </div>
            <!--   DON'T DELETE => USEFUL WHEN DEBUGGING
     <div>
         Action:
         <pre>{{ modalData?.action }}</pre>
       </div>
       <div>
         Event:
         <pre>{{ modalData?.event | json }}</pre>
       </div>-->
        </div>
        <div class="modal-footer">
            <button (click)="closeViewModal()" class="btn btn-secondary" type="button">close</button>
        </div>
    </clr-modal>

    <clr-modal
        *ngIf="createEventInfo"
        [(clrModalOpen)]="createEventInfo"
        [clrModalSize]="'lg'"
        data-target="#modal"
        id="planPlanningItemModal"
        (close)="closeCreateModal()"
        [clrModalClosable]="false"
    >
        <div class="modal-header">
            <button type="button" (close)="closeCreateModal()">
                <span aria-hidden="true"></span>
            </button>
        </div>
        <div class="modal-body">
            <div>
                <div class="title-div">
                    <div class="title-input">
                        <input
                            placeholder="Title toevoegen"
                            name="title-Created"
                            type="text"
                            id="title-Created"
                            class="full-length-screen"
                            [(ngModel)]="createEventInfo.event.subject"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="icon-modal">
                        <cds-icon shape="clock"></cds-icon>
                    </div>
                    <div class="group-section">
                        <div style="display: flex; flex-flow: row">
                            <input
                                [(clrDate)]="createEventInfo.event.start"
                                clrDate
                                placeholder="From"
                                type="date"
                                class="date-input group-element no-border"
                            />
                            <div class="clr-select-wrapper group-element no-border">
                                <select
                                    id="select-date-from"
                                    class="clr-select no-border"
                                    #hourFrom
                                    (change)="setTime(hourFrom.value, true)"
                                >
                                    <option
                                        *ngFor="let hour of hours"
                                        [ngValue]="hour.hour"
                                        [selected]="selectRightTime(createEventInfo.event.start, hour.hour)"
                                    >
                                        {{ hour.hour }}
                                    </option>
                                </select>
                            </div>
                            <div class="group-element" style="flex: 0 0 10px">-</div>
                            <input
                                [(clrDate)]="createEventInfo.event.end"
                                clrDate
                                placeholder="To"
                                type="date"
                                class="date-input group-element no-border"
                            />
                            <div class="clr-select-wrapper group-element no-border">
                                <select
                                    id="select-date-to"
                                    class="clr-select no-border"
                                    #hourTo
                                    (change)="setTime(hourTo.value, false)"
                                >
                                    <option
                                        *ngFor="let hour of hours"
                                        [ngValue]="hour.hour"
                                        [selected]="selectRightTime(createEventInfo.event.end, hour.hour)"
                                    >
                                        {{ hour.hour }}
                                    </option>
                                </select>
                            </div>
                            <div class="group-element no-border" *ngIf="configuration.planning.repeatable">
                                <label>Herhaal voor</label>
                                <input
                                    style="width: 50px; text-align: center"
                                    name="numberOfDays"
                                    type="number"
                                    id="numberOfDays"
                                    [(ngModel)]="this.createEventInfo.event.recurringNumberOfDays"
                                    class="group-element no-border"
                                />
                                <label>dagen</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="icon-modal">
                        <cds-icon shape="calendar"></cds-icon>
                    </div>
                    <div class="group-section">
                        <div class="input-select full-length-screen">
                            <div
                                *ngIf="createEventInfo?.users"
                                class="clr-select-wrapper full-length-screen"
                                style="padding-right: 16px"
                            >
                                <select
                                    id="select-user"
                                    class="clr-select group-element no-border full-length-screen"
                                    [(ngModel)]="this.createEventInfo.event.user"
                                >
                                    <option
                                        *ngFor="let user of createEventInfo?.users; let i = index"
                                        [value]="user.email"
                                    >
                                        {{ user.first_name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="icon-modal">
                        <cds-icon shape="map-marker"></cds-icon>
                    </div>
                    <div class="group-section">
                        <div class="group-element" style="width: 95%">
                            <input
                                placeholder="Locatie toevoegen"
                                name="location"
                                type="text"
                                id="location"
                                [(ngModel)]="this.createEventInfo.event.location"
                                class="full-length-screen no-border"
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div class="icon-modal"></div>
                    <div class="group-section">
                        <div class="group-element" style="width: 95%">
                            <clr-textarea-container class="no-margin">
                                <textarea
                                    clrTextarea
                                    [(ngModel)]="this.createEventInfo.event.content"
                                    name="description"
                                    class="full-length-screen no-border textarea-custom"
                                    placeholder="beschrijving toevoegen"
                                >
                                </textarea>
                            </clr-textarea-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="modal-footer" *ngIf="!this.creatingEvent">
                <div>
                    <button
                        *ngIf="configuration.plannedNotification"
                        type="button"
                        class="btn btn-link"
                        (click)="openMailLayout()"
                        [disabled]="createEventInfo.emailConfig.receivers.length === 0"
                    >
                        {{ createEventInfo.emailConfig.receivers.length > 0 ? 'mail layout' : 'Geen mail gevonden' }}
                    </button>
                    <button type="button" class="btn btn-primary" (click)="createEvent()">opslaan</button>
                </div>
                <button type="button" class="btn btn-secondary" (click)="closeCreateModal()">close</button>
            </div>
            <div *ngIf="this.creatingEvent">
                <span class="spinner spinner-inline"> Loading... </span>
                <span> Saving... </span>
            </div>
        </div>
    </clr-modal>
</div>

<pr-send-mail-card
    *ngIf="isMailLayoutVisible"
    [visible]="isMailLayoutVisible"
    [email_object]="createEventInfo.emailConfig"
    (ok)="editMailLayout($event)"
    (cancel)="closeMailLayout()"
    (language)="getMailTemplateObjectPlanned($event)"
>
</pr-send-mail-card>
