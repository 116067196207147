import moment from 'moment';
import * as toastr from 'toastr';
import { CalendarEvent } from '../../../reusable/Calendar/Calendar.types';
import { IPortalUserEntity } from '../../../types/entity';
import { getEndDate, getStartDate, parseCalendarEventLite, splitEventsInWeeks } from './helpers';
import { CalendarApi } from '@phrospr/api-backend';

export async function loadEvents3Weeks(users: IPortalUserEntity[], date: Date): Promise<CalendarEvent[]> {
    const startOfWeek = moment(date).startOf('week');
    const startOfWeekDate = startOfWeek.format('YYYY-MM-DD');
    const previousWeek = moment(startOfWeek).subtract(1, 'weeks').startOf('week');
    const previousWeekDate = previousWeek.format('YYYY-MM-DD');
    const nextWeek = moment(startOfWeek).add(1, 'weeks').startOf('week');
    const nextWeekDate = nextWeek.format('YYYY-MM-DD');
    const endOfNextWeek = nextWeek.endOf('week');

    return (
        await Promise.all(
            users.map(async user => {
                if (!user.weekEvents) user.weekEvents = {};
                if (user.weekEvents[startOfWeekDate]) return user.weekEvents[startOfWeekDate];

                const startDate = getStartDate(user, previousWeek, previousWeekDate, startOfWeek);
                const endDate = getEndDate(user, endOfNextWeek, nextWeekDate, startOfWeek);

                try {
                    let userEvents = await getUserEvents(user, startDate, endDate);

                    let arrayOfRecurringEventsToAdd: CalendarEvent[] = [];

                    for (let i = 0; i < userEvents.length; i++) {
                        if (userEvents[i].recurringNumberOfDays) {
                            let addDaysForSkippingTheWeekendDays: number = 0;
                            for (let d = 0; d < userEvents[i].recurringNumberOfDays; d++) {
                                let eventToAdd = { ...userEvents[i] };

                                // Add the days and adjust for skipping weekends
                                let adjustedStartDate = moment(eventToAdd.start).add(
                                    d + addDaysForSkippingTheWeekendDays,
                                    'days',
                                );
                                let adjustedEndDate = moment(eventToAdd.end).add(
                                    d + addDaysForSkippingTheWeekendDays,
                                    'days',
                                );

                                // Check if the adjusted date is a weekend day (Saturday then add 2 days)
                                if (adjustedStartDate.day() === 0 || adjustedStartDate.day() === 6) {
                                    // If it's a Saterday , add 2 more day to skip the weekend
                                    adjustedStartDate.add(2, 'days');
                                    adjustedEndDate.add(2, 'days');
                                    addDaysForSkippingTheWeekendDays += 2; // Increment the count of skipped days
                                }

                                eventToAdd.start = adjustedStartDate.toDate();
                                eventToAdd.end = adjustedEndDate.toDate();

                                arrayOfRecurringEventsToAdd.push(eventToAdd);
                                eventToAdd = null;
                            }
                        }
                    }

                    userEvents = userEvents.concat(arrayOfRecurringEventsToAdd);

                    user.weekEvents = {
                        ...user.weekEvents,
                        ...splitEventsInWeeks(userEvents),
                    };

                    if (!user.weekEvents[startOfWeekDate]) user.weekEvents[startOfWeekDate] = [];
                    if (!user.weekEvents[previousWeekDate]) user.weekEvents[previousWeekDate] = [];
                    if (!user.weekEvents[nextWeekDate]) user.weekEvents[nextWeekDate] = [];

                    return [
                        ...user.weekEvents[previousWeekDate],
                        ...user.weekEvents[startOfWeekDate],
                        ...user.weekEvents[nextWeekDate],
                    ];
                } catch (error) {
                    toastr.error(`failed to load ${user.email} please try again`);
                    return [];
                }
            }),
        )
    ).reduce((acc, x) => [...acc, ...x], []);
}

async function getUserEvents(user: IPortalUserEntity, startDate: Date, endDate: Date) {
    return (await CalendarApi.getEvents(startDate.toISOString(), endDate.toISOString(), user.email)).data.map(event =>
        parseCalendarEventLite(event, user),
    );
}
