<div>

  <div>
    <pr-activity-filter
    [createdByName]="true"
    [createdDatePicker]="true"
    (updateActivityItemSearchFilter)="updateFilters($event)"
    [activityItemSearchFilter]="activitySearch.filter">
    </pr-activity-filter>
  </div>


  <ngx-datatable
    [columnMode]="'flex'"
    [count]="page.count"
    [externalPaging]="true"
    [externalSorting]="true"
    [footerHeight]="50"
    [headerHeight]="50"
    [limit]="page.limit"
    [loadingIndicator]="loading"
    [offset]="page.offset"
    [reorderable]="true"
    [rowHeight]="30"
    [rows]="activityData"
    [selectionType]="'single'"
    [sortType]="'single'"
    (page)="pageCallBack($event)"
    class="material striped"

  >
    <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="activity_id">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
        <span>ID</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.activity_id}}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="quote_id">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
        <span>Tijdstip</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.creation_date | date:'dd/MM/yy HH:mm:ss'}}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [flexGrow]="2" [sortable]="true" name="user">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
        <span>Gebruiker</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.user ? (row.user | getUserFullName) : ''}}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [flexGrow]="2" [sortable]="true" name="user">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
        <span>Activity_type</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.activity_type | parseActivityType}}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [flexGrow]="2" [sortable]="true" name="planning_item">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
        <span>Planning</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div *ngIf="!!row.planning_item">
          <button (click)="goToPlanningItemDetail(row.planning_item.planning_item_id)"
                  class="btn btn-sm btn-link">
            {{row.planning_item.planning_item_type + ' ' + row.planning_item.planning_item_type_number}}
          </button>
        </div>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>
</div>
