"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIWorkOrderLineEntity = void 0;
const iproduct_entity_1 = require("./iproduct-entity");
function parseIWorkOrderLineEntity(data) {
    if (typeof data.creation_date === 'string')
        data.creation_date = new Date(data.creation_date);
    if (data.product !== null && typeof data.product === 'object')
        data.product = (0, iproduct_entity_1.parseIProductEntity)(data.product);
    return data;
}
exports.parseIWorkOrderLineEntity = parseIWorkOrderLineEntity;
