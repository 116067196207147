"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseClientAttributes = void 0;
function parseClientAttributes(data) {
    if (typeof data.exact_last_sync === 'string')
        data.exact_last_sync = new Date(data.exact_last_sync);
    if (typeof data.creation_data === 'string')
        data.creation_data = new Date(data.creation_data);
    return data;
}
exports.parseClientAttributes = parseClientAttributes;
