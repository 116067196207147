"use strict";
/* tslint:disable */
/* eslint-disable */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExactControllerApi = exports.ExactControllerApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * ExactControllerApi - axios parameter creator
 * @export
 */
const ExactControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUrl: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/exact/auth_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectExact: (code, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'code' is not null or undefined
            (0, common_1.assertParamExists)('redirectExact', 'code', code);
            const localVarPath = `/api/exact/redirect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAll: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/exact/sync_all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PartialIClientExactEntity} partialIClientExactEntity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExactClient: (partialIClientExactEntity, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partialIClientExactEntity' is not null or undefined
            (0, common_1.assertParamExists)('updateExactClient', 'partialIClientExactEntity', partialIClientExactEntity);
            const localVarPath = `/api/exact/client/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(partialIClientExactEntity, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.ExactControllerApiAxiosParamCreator = ExactControllerApiAxiosParamCreator;
/**
 * ExactControllerApi -
 */
var ExactControllerApi;
(function (ExactControllerApi) {
    /**
     *
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof ExactControllerApi
     * url /api/exact/auth_url
     */
    function getAuthUrl(options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.ExactControllerApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.getAuthUrl(options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                return res;
            });
        });
    }
    ExactControllerApi.getAuthUrl = getAuthUrl;
    /**
     *
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof ExactControllerApi
     * url /api/exact/redirect
     */
    function redirectExact(code, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.ExactControllerApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.redirectExact(code, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                return res;
            });
        });
    }
    ExactControllerApi.redirectExact = redirectExact;
    /**
     *
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof ExactControllerApi
     * url /api/exact/sync_all
     */
    function syncAll(options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.ExactControllerApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.syncAll(options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                return res;
            });
        });
    }
    ExactControllerApi.syncAll = syncAll;
    /**
     *
     * @param {PartialIClientExactEntity} partialIClientExactEntity
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof ExactControllerApi
     * url /api/exact/client/update
     */
    function updateExactClient(partialIClientExactEntity, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.ExactControllerApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.updateExactClient(partialIClientExactEntity, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration));
        });
    }
    ExactControllerApi.updateExactClient = updateExactClient;
})(ExactControllerApi = exports.ExactControllerApi || (exports.ExactControllerApi = {}));
