export interface IGetCalendarsResponse {
    '@odata.context': string;
    value: ICalendarEntity[];
}

export interface ICalendarEntity {
    id: string;
    name: string;
    color: string;
    changeKey: string;
    canShare: boolean;
    canViewPrivateItems: boolean;
    canEdit: boolean;
    owner: {
        name: string;
        address: string;
    };
} // Fuzo8080

export enum ICalendarCreateEventBodyContentType {
    HTML = 'HTML',
}
export enum ICalendarCreateEventBodyTimeZone {
    UTC = 'UTC',
}
export enum ICalendarCreateEventBodyAttendeeType {
    Required = 'required',
    Optional = 'optional',
}
export interface ICalendarCreateEventBodyEntity {
    subject: string;
    body?: {
        contentType: ICalendarCreateEventBodyContentType;
        content: string;
    };
    start: {
        datetime: string; // iso
        timeZone: ICalendarCreateEventBodyTimeZone;
    };
    end: {
        datetime: string; // iso
        timeZone: ICalendarCreateEventBodyTimeZone;
    };
    location?: {
        displayName: string;
    };
    attendees?: {
        emailAddress: {
            address: string;
            name: string | null;
        };
        type: ICalendarCreateEventBodyAttendeeType;
    }[];
    recurrence?: {
        range?: {
            type: string;
            startDate?: string;
            numberOfOccurrences: number;
        };
        pattern?: {
            type: string;
            interval: number;
            daysOfWeek: string[];
        };
    };
    recurringDays?: number;
}

export enum CalendarEventTimeZone {
    UTC = 'UTC',
}

export interface ICalendarEvent {
    // '@odata.context'?: string;
    // '@odata.etag': string;
    id: string;
    createdDateTime: string; // iso
    lastModifiedDateTime: string; // iso
    changeKey: string;
    categories: string[];
    originalStartTimeZone: string; // UTC
    originalEndTimeZone: string; // UTC
    iCalUId: string;
    reminderMinutesBeforeStart: number;
    isReminderOn: boolean;
    hasAttachments: boolean;
    subject: string;
    bodyPreview: string;
    importance: string;
    sensitivity: string;
    isAllDay: boolean;
    isCancelled: boolean;
    isOrganizer: boolean;
    responseRequested: boolean;
    seriesMasterId: string;
    showAs: string;
    type: string;
    webLink: string;
    onlineMeetingUrl: string;
    recurrence: any;
    responseStatus: {
        response: string;
        time: string; // iso
    };
    body: {
        contentType: string; // 'html'
        content: string;
    };
    start: {
        datetime: string; // iso
        timeZone: string; // UTC
    };
    end: {
        datetime: string; // iso
        timeZone: string; // UTC
    };
    location: {
        displayName: string;
        locationType: string;
        uniqueIdType: string;
        address: any;
        coordinates: any;
    };
    locations: any;
    attendees: {
        type: string;
        status: {
            response: string; // none
            time: string; // '0001-01-01T00:00:00Z'
        };
        emailAddress: {
            name: string | null;
            address: string;
        };
    }[];
    organizer: {
        emailAddress: {
            name: string;
            address: string;
        };
    };
}

export interface ICalendarEventLite {
    id: string;
    iCalUId: string;
    subject: string;
    start: {
        dateTime: string; // iso
        timeZone: string; // UTC
    };
    end: {
        dateTime: string; // iso
        timeZone: string; // UTC
    };
    attendees: {
        type: string;
        status: {
            response: string; // 'none'
            time: string; // '0001-01-01T00:00:00Z'
        };
        emailAddress: {
            name: string | null;
            address: string;
        };
    }[];
    location: {
        displayName: string;
    };
    body: {
        contentType: string; // 'HTML'
        content: string;
    };
    recurrence?: {
        range?: {
            type?: string;
            startDate?: string;
            numberOfOccurrences?: number;
        };
        pattern?: {
            type?: string;
            interval?: number;
            daysOfWeek?: string[];
        };
    };
    recurringDays?: number;
}

export interface IGetCalendarEvents {
    '@odata.context'?: string;
    value: ICalendarEvent[];
}
