import {
    ICalendarCreateEventBodyEntity,
    ICalendarItemEntity,
    Language,
    PlanningItemApi,
    PlanningItemPlanBodyPlanningItemMail,
} from '@phrospr/api-backend';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarItemEngine, newCalendarItemEntity } from '@phrospr/lib-core';
import { ChangeDetectionStrategy, Component, LOCALE_ID, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { getContent, getNewTime } from './helpers';
import { getMailTemplatePlanningItem } from '@phrospr/lib-core';
import { HOURS, PLANNING_ITEM_TYPE_ROUTES, WithHour } from './constants';
import { IConfiguration, IEmailObjectEntity } from '@phrospr/lib-models';
import * as toastr from 'toastr';
import { IPortalUserEntity } from '../../../shared/manager/entity/user.entity';
import { CalendarEvent } from 'angular-calendar';
import { CreateEventInfo, Environment, formatNumber } from '@phrospr/frontend-react';
import { configuration, configuration$ } from '@phrospr/lib-web-core';

@Component({
    selector: 'pr-calendar-view-page',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./calendar-view-page.component.scss', '../../../../app.component.css'],
    templateUrl: './calendar-view-page.component.html',
    providers: [{ provide: LOCALE_ID, useValue: 'fr' }],
})
export class CalendarViewPageComponent implements OnInit {
    @ViewChild('modalContent') modalContent: TemplateRef<any>;

    environment: Environment = environment;
    planningItemId?: number;
    users: IPortalUserEntity[];
    hours: WithHour[] = HOURS;
    eventToView?: CalendarEvent;

    createEventInfo?: CreateEventInfo;
    creatingEvent = false;
    isMailLayoutVisible = false;
    configuration: IConfiguration;

    constructor(private route: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            if (params['planning_item_id']) this.planningItemId = params['planning_item_id'];
        });
        this.loadConfig();
    }

    async loadConfig() {
        try {
            this.configuration = await configuration$;
        } catch (e) {
            console.log(e);
        }
    }

    clickEvent(event: CalendarEvent) {
        this.eventToView = event;
    }

    closeViewModal() {
        this.eventToView = undefined;
    }

    startCreateEvent(info: CreateEventInfo) {
        this.createEventInfo = info;
        this.createEventInfo.event.recurringNumberOfDays = 1;
    }

    closeCreateModal() {
        if (!this.createEventInfo) return;
        this.createEventInfo = undefined;
    }

    async createEvent() {
        this.creatingEvent = true;
        if (!this.createEventInfo) return;

        const { event, planningItem, emailConfig } = this.createEventInfo;
        if (!planningItem.planning_item_id) return;

        const start = event.start;
        const end = event.end;

        if (!start || !end || !event.user || !event.location) {
            this.creatingEvent = false;
            return toastr.error('Fill in all fields');
        }

        const start_time = new Date(start);
        const stop_time = new Date(end);
        if (start_time.getTime() > stop_time.getTime()) {
            this.creatingEvent = false;
            return toastr.error(`Start time is later than stop time`);
        }

        const calendarItem: ICalendarItemEntity = {
            ...newCalendarItemEntity(),
            date_time_start: start_time,
            date_time_stop: stop_time,
        };

        // Create calendar event to add in outlook
        const calendarEventToAdd: ICalendarCreateEventBodyEntity = CalendarItemEngine.generateCalendarCreateBody(
            {
                location: event.location,
                email: event.user,
                start_time,
                stop_time,
                recurringDays: this.createEventInfo.event.recurringNumberOfDays
                    ? this.createEventInfo.event.recurringNumberOfDays
                    : 1,
            },
            planningItem,
            configuration.planning.titlePrefixCalendar,
            planningItem.client,
            getContent(event, planningItem),
            location.origin,
        );

        try {
            await PlanningItemApi.planPlanningItem({
                calendarItem,
                planning_item_id: planningItem.planning_item_id,
                planningItemMail: emailConfig,
                mail: event.user,
                calendarEventToAdd,
            });

            if (planningItem.planning_item_type && PLANNING_ITEM_TYPE_ROUTES[planningItem.planning_item_type]) {
                await this.router.navigateByUrl(PLANNING_ITEM_TYPE_ROUTES[planningItem.planning_item_type]);
            }

            this.createEventInfo = undefined;
            this.creatingEvent = false;
        } catch (err) {
            console.error(err);
            this.creatingEvent = false;
            toastr.error('Something went wrong, are all the fields correct?');
        }
    }

    setTime(newTime: string, isStart = true) {
        if (!this.createEventInfo) return;
        const { event } = this.createEventInfo;

        const time = getNewTime(event, newTime, isStart);
        this.createEventInfo = { ...this.createEventInfo, event: { ...event, [isStart ? 'start' : 'end']: time } };

        if (isStart) this.getMailTemplateObjectPlanned(Language.nl);
    }

    selectRightTime(timeFromCalendarEvent: Date, timeFromSelectOption: string) {
        if (!timeFromCalendarEvent || !timeFromSelectOption) return;

        const formattedHours = String(formatNumber(timeFromCalendarEvent.getHours()));
        const formattedMinutes = String(formatNumber(timeFromCalendarEvent.getMinutes()));
        const formattedTime = `${formattedHours}:${formattedMinutes}`;

        return formattedTime === timeFromSelectOption;
    }

    getMailTemplateObjectPlanned(language: Language) {
        if (!this.createEventInfo) return;

        const { planningItem, event } = this.createEventInfo;
        let { emailConfig } = this.createEventInfo;

        const { content, subject }: IEmailObjectEntity = getMailTemplatePlanningItem(
            planningItem,
            event.start,
            language,
            configuration.companyName,
        );
        emailConfig.content = content;
        emailConfig.subject = subject;
        this.createEventInfo.emailConfig = JSON.parse(JSON.stringify(emailConfig));
    }

    openMailLayout() {
        this.isMailLayoutVisible = true;
    }

    closeMailLayout() {
        this.isMailLayoutVisible = false;
    }

    editMailLayout(emailConfig: PlanningItemPlanBodyPlanningItemMail) {
        if (!this.createEventInfo) return;

        this.createEventInfo.emailConfig = emailConfig;
        this.isMailLayoutVisible = false;
    }
}
