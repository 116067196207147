import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from '../../../../environments/environment';

@Injectable()
export class ExactService {
    constructor(private http: HttpClient) {}

    public updateAllClientsFromExactInDB(): Observable<any> {
        return this.http.get(`${BASE_URL}api/exact/updateAllClientsinDB`);
    }
}
