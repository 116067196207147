export enum PlanningState {
    toBePlanned = 'toBePlanned',
    planned = 'planned',
    inactive = 'inactive',
    completed = 'completed',
    completedWithOffer = 'completedWithOffer',
    completedAndQuoteApproved = 'completedAndQuoteApproved',
    completedAndQuoteCancelled = 'completedAndQuoteCancelled',
    completedNoOffer = 'completedNoOffer',
    protest = 'protest',
    cancelled = 'cancelled',
}
