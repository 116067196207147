import { Tooltip } from '@mui/material';
import {
    IPlanningItemEntity,
    PlanningItemApi,
    PlanningItemPlanBodyPlanningItemMail,
    UserApi,
} from '@phrospr/api-backend';
import React from 'react';
import { SlotInfo } from 'react-big-calendar';
import { Agenda } from '../../reusable/Agenda/Agenda';
import { CalendarEvent } from '../../reusable/Calendar/Calendar.types';
import { loadEvents3Weeks } from '../../shared/manager/user/user.manager';
import { StateEnum } from '../../types';
import { IPortalUserEntity } from '../../types/entity';
import { getEmailConfig, getEvent } from './helpers';
import * as Style from './style';
import moment from 'moment';

export interface PhrosprCalendarProps {
    environment: Environment;
    eventToCreate?: CalendarEvent;
    planningItemId?: number;
    onCreateEvent: (info: CreateEventInfo) => void;
    onClickEvent: (e: CalendarEvent) => void;
}

export interface CreateEventInfo {
    planningItem: IPlanningItemEntity;
    event: CalendarEvent;
    emailConfig: PlanningItemPlanBodyPlanningItemMail;
    users: IPortalUserEntity[];
}

export interface Environment {
    production: boolean;
    testing: boolean;
}

export function PhrosprCalendar({
    environment,
    planningItemId,
    eventToCreate,
    onCreateEvent,
    onClickEvent,
}: PhrosprCalendarProps) {
    const [stateOfLoad, setStateOfLoad] = React.useState(StateEnum.Initial);
    const [viewDate, setViewDate] = React.useState<Date>(new Date());
    const [users, setUsers] = React.useState<IPortalUserEntity[]>([]);
    const [events, setEvents] = React.useState<CalendarEvent[]>([]);
    const [planningItem, setPlanningItem] = React.useState<IPlanningItemEntity | undefined>(undefined);

    React.useEffect(() => {
        load();
    }, []);

    async function load() {
        try {
            const { data } = await UserApi.listUsers();
            const filteredUsers = data.filter(user => !user.data.ignoreUserInCalendarList);
            setUsers(filteredUsers);

            if (planningItemId) await getPlanningItem(planningItemId);
        } catch (error) {
            console.log(error);
        }
    }

    async function onSelectUser(userId: number, selected: boolean) {
        const updatedUsers = users.map(x => (x.user_id === userId ? { ...x, selected } : x));
        setUsers(updatedUsers);

        await getEvents(updatedUsers, viewDate);
    }

    //TO DO check if this should trigger more
    async function getEvents(portalUsers: IPortalUserEntity[], date: Date) {
        setStateOfLoad(StateEnum.Pending);

        const selectedUsers = portalUsers.filter(x => x.selected);
        let updatedEvents = await loadEvents3Weeks(selectedUsers, date);

        setEvents(updatedEvents);

        setStateOfLoad(StateEnum.Ready);
    }

    async function onChangeDate(date: Date) {
        setViewDate(date);
        await getEvents(users, date);
    }

    async function getPlanningItem(id: number) {
        try {
            const { data } = await PlanningItemApi.getPlanningItemDetail(undefined, id);
            setPlanningItem(data);
        } catch (err) {
            console.error(err);
        }
    }

    async function onSelectSlot(slotInfo: SlotInfo) {
        const event = getEvent(slotInfo, planningItem, users, events);
        const emailConfig = await getEmailConfig(environment, planningItem, event);

        onCreateEvent({ planningItem, event, emailConfig, users });
    }

    return (
        <Agenda
            loading={stateOfLoad === StateEnum.Pending}
            users={users}
            events={eventToCreate ? [...events, eventToCreate] : events}
            draggableAccessor={() => false}
            resizableAccessor={e => e.previewing}
            selectable={planningItemId !== undefined}
            onSelectSlot={planningItem ? onSelectSlot : undefined}
            onRenderEvent={e => onRenderEvent(e, onClickEvent)}
            onSelectUser={onSelectUser}
            onChangeDate={onChangeDate}
        />
    );
}

export function onRenderEvent(e: CalendarEvent, onClickEvent: PhrosprCalendarProps['onClickEvent']) {
    return (
        <Tooltip title={onRenderTooltip(e)}>
            <Style.Event onClick={() => onClickEvent(e)}>
                <Style.EventTitle>{e.subject}</Style.EventTitle>
                <Style.EventLocation>{e.location}</Style.EventLocation>
            </Style.Event>
        </Tooltip>
    );
}

function onRenderTooltip(e: CalendarEvent) {
    return (
        <Style.Tooltip>
            <Style.TooltipRow>{e.user}</Style.TooltipRow>
            <Style.TooltipRow>{e.subject}</Style.TooltipRow>
            <Style.TooltipRow>{e.location}</Style.TooltipRow>
        </Style.Tooltip>
    );
}

export default PhrosprCalendar;
