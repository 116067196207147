export enum QuoteColumns {
    quote_id = 'quote_id',
    client_id = 'client_id',
    lead_id = 'lead_id',
    creation_date = 'creation_date',
    status = 'status',
    sub_total_price = 'sub_total_price',
    final_price_to_pay = 'final_price_to_pay',
    placement_cost = 'placement_cost',
    data = 'data',
    pdf_raw = 'pdf_raw',
}

export interface QuoteAttributes {
    quote_id: number;
    client_id: number | null;
    lead_id: number | null;
    creation_date: Date | null;
    status: string | null;
    sub_total_price: number | null;
    final_price_to_pay: number | null;
    placement_cost: number | null;
    data: any | null;
    pdf_raw: string | null;
}

export type QuoteOptionalAttributes =
    | 'quote_id'
    | 'client_id'
    | 'lead_id'
    | 'creation_date'
    | 'status'
    | 'sub_total_price'
    | 'final_price_to_pay'
    | 'placement_cost'
    | 'data'
    | 'pdf_raw';
export interface QuoteCreate {
    quote_id?: number;
    client_id?: number | null;
    lead_id?: number | null;
    creation_date?: Date | null;
    status?: string | null;
    sub_total_price?: number | null;
    final_price_to_pay?: number | null;
    placement_cost?: number | null;
    data?: any | null;
    pdf_raw?: string | null;
}
