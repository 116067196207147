
<clr-modal [(clrModalOpen)]="visible" [clrModalSize]="'lg'" *ngIf="planningItem">
  <h3 class="modal-title">{{planningItem.planning_item_type | getNameOfPlanningItemTypePipe: 'nl'}} afwerken</h3>
  <div class="modal-body" *ngIf="visible">

    <form id="finishMaintenance" ngNativeValidate>
      <div class="form-group row">
        <div class="clr-col-12 clr-col-lg-4">
          <label for="selectStatus">Actie</label>
        </div>
        <div class="clr-col-12 clr-col-lg-3">
          <div class="select form-control select-action-dropdown">
            <select [(ngModel)]="state" required id="selectStatus" name="selectStatus">
              <option *ngFor="let action of actionOptions" selected [value]="action.value">{{action.text}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group row" *ngIf="configuration?.planning.paymentMethod">
        <div class="clr-col-12 clr-col-lg-4">
          <label for="paymentMethod">Betaal methode</label>
        </div>
        <div class="clr-col-12 clr-col-lg-3">
          <div class="select form-control select-action-dropdown">
            <select [(ngModel)]="selectedPaymentMethod" required id="paymentMethod" name="paymentMethod">
              <option *ngFor="let paymentMethod of paymentMethodTypes" selected [value]="paymentMethod">
                {{parsePaymentMethodTypeReadable(paymentMethod)}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="clr-col-12 clr-col-lg-4">
          <label for="comment">Commentaar</label>
        </div>
        <div class="clr-col-12 clr-col-lg-6">
          <textarea required type="text" id="comment" class="form-control" name="comment"
                    [(ngModel)]="comment" [ngModelOptions]="{standalone: true}">
            </textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="sendCancel()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="finishPlannedPlanningItem()">Ok</button>
  </div>
</clr-modal>
