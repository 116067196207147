"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIQuoteOverviewPerUserPerPeriod = void 0;
const iuser_entity_1 = require("./iuser-entity");
function parseIQuoteOverviewPerUserPerPeriod(data) {
    if (data.sold_by_user !== null && typeof data.sold_by_user === 'object')
        data.sold_by_user = (0, iuser_entity_1.parseIUserEntity)(data.sold_by_user);
    return data;
}
exports.parseIQuoteOverviewPerUserPerPeriod = parseIQuoteOverviewPerUserPerPeriod;
