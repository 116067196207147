import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class RoutingState {
    history = [];

    constructor(private router: Router) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                if (this.history.length > 200) {
                    this.history.splice(0, 100);
                }
                this.history.push(urlAfterRedirects);
            });
    }
}
