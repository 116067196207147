import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

@Component({
    selector: 'pr-delivery-page',
    styleUrls: ['./delivery-page.component.scss'],
    templateUrl: './delivery-page.component.html',
})
export class DeliveryPageComponent implements OnInit {
    newDeliveryTab = false;
    plannedTab = false;
    completedTab = false;

    constructor(private route: ActivatedRoute, private router: Router) {
        this.route.queryParams
            .pipe(
                tap(params => {
                    if (!params.tab) {
                        this.setTab('newDeliveryTab');
                    }
                }),
                filter(params => params.tab),
            )
            .subscribe(params => {
                this.disableTabs();
                if (params.tab === 'newDeliveryTab') {
                    return (this.newDeliveryTab = true);
                }
                if (params.tab === 'plannedTab') {
                    return (this.plannedTab = true);
                }
                if (params.tab === 'completedTab') {
                    return (this.completedTab = true);
                }
                return this.newDeliveryTab;
            });
    }

    ngOnInit(): void {}

    setTab(tab: string) {
        const queryParams: any = this.route.queryParams;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { ...queryParams._value, tab: tab, pageNo: 1 },
            queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }

    disableTabs() {
        this.newDeliveryTab = false;
        this.plannedTab = false;
        this.completedTab = false;
    }
}
