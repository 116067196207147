import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { IEmailObjectEntity } from '@phrospr/lib-models';
import * as toastr from 'toastr';

declare const Quill: any;

@Component({
    selector: 'pr-send-mail-card',
    templateUrl: './send-mail-card.component.html',
    styleUrls: ['./send-mail-card.component.scss'],
})
export class SendMailCardComponent implements AfterViewInit {
    @Input() visible: boolean;
    email_object: IEmailObjectEntity;

    @Input('email_object')
    get emailObject(): IEmailObjectEntity {
        return this.email_object;
    }

    quillEditor: any;

    @Output() ok = new EventEmitter<any>();
    @Output() cancel = new EventEmitter<any>();
    @Output() language = new EventEmitter<string>();

    newEmailObject: IEmailObjectEntity = null;

    set emailObject(emailObjectEntity) {
        this.email_object = emailObjectEntity;
        this.newEmailObject = JSON.parse(JSON.stringify(this.email_object));
        this.setEditorText();
    }

    ngAfterViewInit(): void {
        this.quillEditor = new Quill('#send-mail-quill-editor', { modules: { toolbar: true }, theme: 'snow' });
        this.setEditorText();
    }

    setEditorText() {
        if (this.quillEditor && this.newEmailObject && this.newEmailObject.content) {
            this.quillEditor.pasteHTML(this.newEmailObject.content);
        }
    }

    getMailTemplateMaintenancePlanned(): void {
        this.language.emit(this.newEmailObject.language);
    }

    confirmMailTemplate() {
        this.newEmailObject.content = document.querySelector('#send-mail-quill-editor .ql-editor').innerHTML;
        // STILL TO ADD LOGIC THAT CHECKS IF EMAIL ADDRESS IS FILLED IN AND IS VALID
        if (this.newEmailObject.subject === '' || this.newEmailObject.content === '') {
            toastr.error('Vul alle velden in');
        } else {
            this.visible = false;
            this.ok.emit(this.newEmailObject);
        }
    }

    cancelMailTemplate() {
        this.cancel.emit();
        this.visible = false;
    }

    addEmail() {
        this.newEmailObject.receivers.push({ email: '' });
    }

    deleteEmail(index: number) {
        this.newEmailObject.receivers.splice(index, 1);
    }
}
