import Decimal from 'decimal.js-light';
import { IQuoteEntity, IQuoteLineCreateEntity, IQuoteLineEntity } from '@phrospr/lib-models';
import * as toastr from 'toastr';

export function fillQuotePrices(quote: IQuoteEntity, quoteLines?: IQuoteLineCreateEntity[]) {
    const { discount_amount, discount_percentage, btw_percentage } = quote.data;
    const priceResult = calculateQuotePrices(
        quoteLines ?? quote.quote_lines,
        quote.placement_cost,
        btw_percentage,
        discount_amount,
        discount_percentage,
    );
    quote.sub_total_price = priceResult.subTotalPrice;
    quote.data.discount_amount = priceResult.discountAmount;
    quote.data.btw_amount = priceResult.btwAmount;
    quote.final_price_to_pay = priceResult.finalPriceToPay;
}

export function calculateQuotePrices(
    quoteLines: Array<IQuoteLineEntity | IQuoteLineCreateEntity>,
    placementCost: number,
    btwPercentage: number,
    discountAmountRaw: number = 0,
    discountPercentage?: number,
) {
    const subTotalPrice = calculateSubTotalPrice(quoteLines).toNumber();
    if (btwPercentage > 0.5) throw new Error('invalid btw percentage supplied, must be smaller then 0.5');

    let total = new Decimal(subTotalPrice);

    // discount
    let discountAmount = new Decimal(0);
    if (discountPercentage !== undefined) {
        if (discountPercentage > 100) toastr.error('invalid discount_percentage supplied, must be smaller then 100');
        if (discountPercentage) {
            const discountFactor = new Decimal((discountPercentage ?? 0) / 100);
            discountAmount = total.times(discountFactor).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
        }
    } else {
        if (discountAmountRaw > subTotalPrice) toastr.error('discount amount is larger then total price');
        if (discountAmountRaw) {
            discountAmount = new Decimal(discountAmountRaw).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
        }
    }
    total = total.minus(discountAmount);

    // BTW percentage
    const btwFactor = new Decimal(btwPercentage);
    const btwAmount = total.times(btwFactor).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
    total = total.plus(btwAmount);

    // placement costs
    total = total.plus(placementCost);

    const finalPriceToPay = total.toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber();

    return {
        subTotalPrice,
        btwAmount: btwAmount.toNumber(),
        finalPriceToPay,
        discountAmount: discountAmount.toNumber(),
    };
}

export function calculateSubTotalPrice(quoteLines: Array<IQuoteLineEntity | IQuoteLineCreateEntity>) {
    let total = new Decimal(0);
    quoteLines.forEach(line => (total = total.plus(calculateQuoteLinePrice(line))));
    total = total.toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
    return total;
}

export function calculateQuoteLinePrice(quoteLine: IQuoteLineEntity | IQuoteLineCreateEntity) {
    const { quantity, unit_price } = quoteLine;
    const total = new Decimal(quantity).times(unit_price).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
    quoteLine.final_price_to_pay = total.toNumber();
    return quoteLine.final_price_to_pay;
}
