"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIPlanningItemDataEntity = void 0;
const iaddress_1 = require("./iaddress");
function parseIPlanningItemDataEntity(data) {
    if (typeof data.suggested_date === 'string')
        data.suggested_date = new Date(data.suggested_date);
    if (typeof data.water_meter_stand_updated === 'string')
        data.water_meter_stand_updated = new Date(data.water_meter_stand_updated);
    if (data.delivery_address !== null && typeof data.delivery_address === 'object')
        data.delivery_address = (0, iaddress_1.parseIAddress)(data.delivery_address);
    return data;
}
exports.parseIPlanningItemDataEntity = parseIPlanningItemDataEntity;
