import { Component, EventEmitter, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { ActivityType, IActivitySearchFilterEntity, PlanningItemType } from '@phrospr/api-backend';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as moment from 'moment';
import { getEnumKeys, validateActivityItemSearchFilterKey } from '@phrospr/lib-core';
import { CLR_DATE_FORMAT } from '../../../../../constants';

@Component({
    selector: 'pr-activity-filter',
    styleUrls: ['./activity-filter.component.scss'],
    templateUrl: './activity-filter.component.html',
    providers: [{ provide: LOCALE_ID, useValue: 'fr' }],
})
export class ActivityFilterComponent implements OnInit {
    @Input() activityItemSearchFilter: IActivitySearchFilterEntity;
    @Input() createdDatePicker = false;
    @Input() createdByName = true;

    @Output() updateActivityItemSearchFilter = new EventEmitter<IActivitySearchFilterEntity>();

    filterChangeSubject$: Subject<void> = new Subject<void>();

    fromCreatedDateTrigger = '';
    toCreatedDateTrigger = '';
    activityTypeTrigger: ActivityType[] = [];
    createdByUserNameTrigger = '';
    ignoreDevelopmentTrigger = true;

    activityTypes = getEnumKeys(ActivityType);

    filterKeys = {
        FROM_CREATED_DATE: 'from_created_date',
        TO_CREATED_DATE: 'to_created_date',
        PLANNING_ITEM_TYPE: PlanningItemType,
        CREATED_BY_USER_NAME: 'created_by_user_name',
        IGNORE_DEVELOPMENT: 'ignore_development',
        ACTIVITY_TYPES: 'activity_types',
    };

    constructor() {}

    ngOnInit(): void {
        if (!this.activityItemSearchFilter) {
            return console.log('missing quoteItemsFilter');
        }

        this.filterChangeSubject$.pipe(debounceTime(1000)).subscribe(_ => this.sendNewFilter());
    }

    triggerFilter(filterName, event) {
        if (!validateActivityItemSearchFilterKey(filterName))
            return console.log('invalid filter key', filterName, event);
        if (filterName === this.filterKeys.ACTIVITY_TYPES && event && event.length === 0) event = null;
        this.activityItemSearchFilter[filterName] = event;
        this.filterChangeSubject$.next();
    }

    triggerDateFilter(filterName, event) {
        let date = moment(event, CLR_DATE_FORMAT);
        if (filterName.startsWith('to')) date = moment(date).endOf('day');
        this.triggerFilter(filterName, date.toISOString());
    }

    sendNewFilter() {
        this.updateActivityItemSearchFilter.emit(this.activityItemSearchFilter);
    }

    resetFilters() {
        for (const localFilterKey in this.filterKeys) {
            const filterKey = this.filterKeys[localFilterKey];
            this.activityItemSearchFilter[filterKey] = undefined;
        }
        this.fromCreatedDateTrigger = null;
        this.toCreatedDateTrigger = null;
        this.createdByUserNameTrigger = null;
        this.activityTypeTrigger = null;
        this.ignoreDevelopmentTrigger = true;
        this.sendNewFilter();
    }
}
