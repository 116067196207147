import {
    IPlanningItemEntity,
    Language,
    PlanningItemPlanBodyPlanningItemMail,
    PlanningItemType,
} from '@phrospr/api-backend';
import { CalendarItemEngine, getMailTemplatePlanningItem, MailEngine } from '@phrospr/lib-core';
import { IEmailObjectEntity } from '@phrospr/lib-models';
import { SlotInfo } from 'react-big-calendar';
import { Environment } from '..';
import { CalendarEvent } from '../../../reusable/Calendar/Calendar.types';
import { IPortalUserEntity } from '../../../types/entity';
import { configuration, configuration$ } from '@phrospr/lib-web-core';

const MS_PER_MINUTE = 60_000;

export function getEvent(
    slotInfo: SlotInfo,
    planningItem: IPlanningItemEntity,
    users: IPortalUserEntity[],
    events: CalendarEvent[],
) {
    const selectedUsers = users.filter(x => x.selected);

    return {
        id: events.length,
        subject: CalendarItemEngine.getTitleForCalendarEvent(
            planningItem.planning_item_type,
            configuration.planning.titlePrefixCalendar,
            planningItem.client,
        ),
        start: slotInfo.start,
        end: getEnd(planningItem, slotInfo),
        meta: {
            tmpEvent: true,
        },
        draggable: false,
        resizable: {
            beforeStart: false,
            afterEnd: false,
        },
        user: selectedUsers.length === 1 ? selectedUsers[0].email : null,
        content: MailEngine.getPlanningMailContent(planningItem, planningItem.client),
        location: planningItem.data.delivery_address
            ? (planningItem.data.delivery_address.zip_code ? planningItem.data.delivery_address.zip_code : '') +
              ' ' +
              (planningItem.data.delivery_address.city ? planningItem.data.delivery_address.city : '') +
              ' ' +
              (planningItem.data.delivery_address.address ? planningItem.data.delivery_address.address : '')
            : '',
    };
}

export function getEnd({ planning_item_type }: IPlanningItemEntity, { start, end, action }: SlotInfo) {
    if (action === 'select') return end;

    const defaultMinutes = planning_item_type === PlanningItemType.placement ? 120 : 60;
    return new Date(start.getTime() + defaultMinutes * MS_PER_MINUTE);
}

export async function getEmailConfig(
    environment: Environment,
    planningItem: IPlanningItemEntity,
    event: CalendarEvent,
): Promise<PlanningItemPlanBodyPlanningItemMail> {
    const { content, subject }: IEmailObjectEntity = getMailTemplatePlanningItem(
        planningItem,
        event.start,
        Language.nl,
        (await configuration$).companyName,
    );

    const emailConfig: PlanningItemPlanBodyPlanningItemMail = {
        subject,
        content,
        receivers: getReceivers(environment, planningItem),
        senders: (await configuration$).mailing.defaultSenders.map(email => ({ email })),
    };

    return JSON.parse(JSON.stringify(emailConfig));
}

function getReceivers(environment: Environment, planningItem: IPlanningItemEntity) {
    if (!environment.production) {
        return [{ email: 'teo.deconinck@phrospr.com' }, { email: 'hendrik.hamerlinck@phrospr.com' }];
    }

    return planningItem.client?.client_data.email ? [{ email: planningItem.client.client_data.email }] : [];
}
