import '@cds/core/icon/register.js';
import {
    angleIcon,
    bugIcon,
    bundleIcon,
    calendarIcon,
    chatBubbleIcon,
    checkCircleIcon,
    checkIcon,
    circleIcon,
    ClarityIcons,
    clockIcon,
    crosshairsIcon,
    cursorHandClickIcon,
    dotCircleIcon,
    downloadIcon,
    envelopeIcon,
    errorStandardIcon,
    euroIcon,
    exclamationCircleIcon,
    exclamationTriangleIcon,
    floppyIcon,
    happyFaceIcon,
    infoCircleIcon,
    linkIcon,
    mapMarkerIcon,
    pencilIcon,
    phoneHandsetIcon,
    playIcon,
    plusIcon,
    searchIcon,
    successStandardIcon,
    timesIcon,
    trashIcon,
    truckIcon,
    userIcon,
    viewListIcon,
    warningStandardIcon,
    wrenchIcon,
    formIcon,
} from '@cds/core/icon';

ClarityIcons.addIcons(
    angleIcon,
    bugIcon,
    bundleIcon,
    calendarIcon,
    chatBubbleIcon,
    checkCircleIcon,
    checkIcon,
    circleIcon,
    clockIcon,
    crosshairsIcon,
    cursorHandClickIcon,
    dotCircleIcon,
    downloadIcon,
    envelopeIcon,
    errorStandardIcon,
    euroIcon,
    exclamationCircleIcon,
    exclamationTriangleIcon,
    floppyIcon,
    happyFaceIcon,
    infoCircleIcon,
    linkIcon,
    mapMarkerIcon,
    pencilIcon,
    phoneHandsetIcon,
    playIcon,
    plusIcon,
    searchIcon,
    successStandardIcon,
    timesIcon,
    trashIcon,
    truckIcon,
    userIcon,
    viewListIcon,
    warningStandardIcon,
    wrenchIcon,
    formIcon,
);
