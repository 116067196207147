export enum QuoteCommentColumns {
    comment_id = 'comment_id',
    quote_id = 'quote_id',
}

export interface QuoteCommentAttributes {
    comment_id: number;
    quote_id: number;
}

export type QuoteCommentCreate = QuoteCommentAttributes;
