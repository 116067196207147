import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/system';
import { IconStyle } from './Stepper';

export const Connector = styled(StepConnector)<{ withConnectors: boolean }>(({ withConnectors }) => ({
    [`& .${stepConnectorClasses.lineVertical}`]: {
        marginLeft: 6,
        borderLeft: `1px solid ${withConnectors ? '#8c8c8c' : '#fff'}`,
    },
}));

export const StepIcon = styled('div')<{
    style?: IconStyle;
}>(({ style }) => ({
    zIndex: 1,
    color: style?.color ?? '#fff',
    border: `2px solid ${style?.borderColor ?? style?.backgroundColor ?? '#fff'}`,
    backgroundColor: style?.backgroundColor ?? '#fff',
    width: 32,
    height: 32,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20,
}));
