import { ICalendarEventLite } from '@phrospr/lib-models';
import moment from 'moment';
import { parse } from 'node-html-parser';
import { CalendarEvent } from '../../../../reusable/Calendar/Calendar.types';
import { IPortalUserEntity } from '../../../../types/entity';

export function parseCalendarEventLite(event: ICalendarEventLite, user: IPortalUserEntity): CalendarEvent {
    return {
        subject: event.subject,
        user: user.email,
        id: Math.floor(Math.random() * 10000),
        start: moment.utc(event.start.dateTime).toDate(),
        end: moment.utc(event.end.dateTime).toDate(),
        draggable: false,
        color: user.data.secondary,
        resizable: { beforeStart: false, afterEnd: false },
        location: event.location.displayName,
        content: parse(event.body.content).text,
        recurringNumberOfDays: event.recurrence ? event.recurrence.range.numberOfOccurrences : null,
    };
}

export function splitEventsInWeeks(events: CalendarEvent[]): {
    [startOfWeekDate: string]: CalendarEvent[];
} {
    const output: { [startOfWeekDate: string]: CalendarEvent[] } = {};
    events.forEach(event => {
        const startOfWeekDate = moment(event.start).startOf('week').format('YYYY-MM-DD');
        if (!output[startOfWeekDate]) output[startOfWeekDate] = [];
        output[startOfWeekDate].push(event);
    });
    return output;
}

export function getStartDate(
    user: IPortalUserEntity,
    previousWeek: moment.Moment,
    previousWeekDate: string,
    startOfWeek: moment.Moment,
) {
    return user.weekEvents[previousWeekDate] ? startOfWeek.toDate() : previousWeek.toDate();
}

export function getEndDate(
    user: IPortalUserEntity,
    endOfNextWeek: moment.Moment,
    nextWeekDate: string,
    startOfWeek: moment.Moment,
) {
    return user.weekEvents[nextWeekDate] ? moment(startOfWeek).endOf('week').toDate() : endOfNextWeek.toDate();
}
