<div>
  <clr-tabs>
    <clr-tab>
      <button (click)="setTab('newLeadsTab')" clrTabLink>NEW LEADS</button>
      <ng-template [(clrIfActive)]="newLeadsTab">
        <clr-tab-content>
          <pr-new-leads-page></pr-new-leads-page>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab>
      <button (click)="setTab('plannedTab')" clrTabLink>PLANNED</button>
      <ng-template [(clrIfActive)]="plannedTab">
        <clr-tab-content>
          <pr-planned-leads-page></pr-planned-leads-page>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab>
      <button (click)="setTab('completedNoOfferTab')" clrTabLink>COMPLETED NO OFFER</button>
      <ng-template [(clrIfActive)]="completedNoOfferTab">
        <clr-tab-content>
          <pr-completed-no-offer-page></pr-completed-no-offer-page>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab>
      <button (click)="setTab('completedWithOfferTab')" clrTabLink>COMPLETED WITH OFFER</button>
      <ng-template [(clrIfActive)]="completedWithOfferTab">
        <clr-tab-content>
          <pr-completed-with-offer-page></pr-completed-with-offer-page>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab>
      <button (click)="setTab('LeadCompletedAndQuoteApprovedTab')" clrTabLink>COMPLETED AND APPROVED</button>
      <ng-template [(clrIfActive)]="leadCompletedAndQuoteApprovedTab">
        <clr-tab-content>
          <pr-lead-completed-and-quote-approved></pr-lead-completed-and-quote-approved>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab>
      <button (click)="setTab('LeadCompletedAndQuoteCancelledTab')" clrTabLink>COMPLETED QUOTE CANCELLED</button>
      <ng-template [(clrIfActive)]="leadCompletedAndQuoteCancelledTab">
        <clr-tab-content>
          <pr-lead-completed-and-quote-cancelled></pr-lead-completed-and-quote-cancelled>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab>
      <button (click)="setTab('protestTab')" clrTabLink>PROTEST</button>
      <ng-template [(clrIfActive)]="protestTab">
        <clr-tab-content>
          <pr-protest-leads-page></pr-protest-leads-page>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
  </clr-tabs>
</div>




