import { Link } from '@mui/material';
import React from 'react';

export interface LinkWithRouterProps {
    routeNavigator?: (url: string) => void;
    url: string;
    text: string;
}

export function LinkWithRouter({ routeNavigator, url, text }: LinkWithRouterProps) {
    if (routeNavigator) {
        return (
            <Link
                onClick={e => {
                    e.stopPropagation();
                    routeNavigator(url);
                }}
                style={{ cursor: 'pointer' }}
            >
                {text}
            </Link>
        );
    }
    return <Link href={url}>{text}</Link>;
}
