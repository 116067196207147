import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Rating,
    Select,
    Stack,
    Switch,
    TextareaAutosize,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { IQuoteEntity, QuoteStatus, QuoteWarranty, QuoteWarrantyCode } from '@phrospr/lib-models';
import React from 'react';
import * as toastr from 'toastr';
import './QuoteInfo.scss';
import { QuoteInfoProps, QuoteInfoState } from './QuoteInfo.types';
import { QuoteApi, UserApi } from '@phrospr/api-backend';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export class QuoteInfo extends React.Component<QuoteInfoProps, QuoteInfoState> {
    constructor(props: QuoteInfoProps) {
        super(props);
        const { quote } = props;
        this.state = {
            selectedLanguage: quote?.data?.language ?? 'nl',
            users: [],
            selectedUserId: quote?.sold_by_user?.user_id ?? null,
            placementDate: quote?.data?.preferred_placement_date
                ? new Date(quote?.data?.preferred_placement_date)
                : null,
            remarks: quote?.data?.remarks ?? '',
            sendEmailToCustomer: quote?.mailQuoteToClient ?? true,
            isSold: quote?.sold ?? false,
            technischeFiche: quote?.data?.technicalFiche ?? {
                analyse_f: '',
                leidingen: '',
                keer_klep: '',
                stopcontact: '',
                afvoer: '',
            },
            attachments: quote?.data?.attachments ?? [],
            selectedAttachments: quote?.data?.attachments ?? [],
            selectedWarranties: quote?.data?.quote_warranty ?? [QuoteWarranty.W1],
            ratingValue: quote?.data?.rating?.value ?? 0,
        };
        this.loadUserList();
        this.loadAttachmentList();
    }

    async loadAttachmentList() {
        try {
            const attachments = (await QuoteApi.getAttachmentList()).data;
            let selectedAttachments = [];
            if (this.props.quote) {
                const currentSelectedAttachments = this.state.selectedAttachments;
                selectedAttachments = attachments.filter(x => currentSelectedAttachments.includes(x));
            }
            this.setState({ attachments, selectedAttachments });
        } catch (e) {
            console.log('failed to load attachment list', e);
        }
    }

    async loadUserList() {
        try {
            const users = (await UserApi.listUsers()).data.filter(user => !user.data.ignoreUserInSellerList);
            this.setState({ users: users });
        } catch (e) {
            console.log(e);
            toastr.error('something went wrong');
        }
    }

    updateQuote() {
        const quote: IQuoteEntity = {
            quote_id: undefined,
            creation_date: undefined,
            status: QuoteStatus.created,
            final_price_to_pay: undefined,
            pdf_raw: null,
            sold: this.state.isSold,
            mailQuoteToClient: this.state.sendEmailToCustomer,
            data: {
                sold_by_user_id: this.state.selectedUserId,
                // to change still, to have correct user
                created_by_user_id: this.props.quote?.data.created_by_user_id ?? null,
                language: this.state.selectedLanguage,
                remarks: this.state.remarks,
                technicalFiche: this.state.technischeFiche,
                attachments: this.state.selectedAttachments,
                preferred_placement_date: this.state.placementDate?.toISOString() ?? undefined,
                quote_warranty: this.state.selectedWarranties,
                rating: { max: 5, value: this.state.ratingValue },
            },
        };
        this.props.onChange({ quote });
    }

    render() {
        return (
            <div>
                <Card sx={{ minWidth: 275 }} style={{ maxWidth: '700px' }}>
                    <CardHeader title={'Offerte info'} />
                    <CardContent>
                        <Grid
                            container
                            spacing={1}
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                        >
                            <Grid item xs={12} md={6}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Typography>Rating</Typography>
                                    <Rating
                                        name="simple-controlled"
                                        value={this.state.ratingValue}
                                        onChange={(event, newValue) => {
                                            this.setState(
                                                {
                                                    ratingValue: newValue,
                                                },
                                                () => this.updateQuote(),
                                            );
                                        }}
                                    />
                                </Stack>
                            </Grid>
                            {!!this.props.quote?.created_by_user ? (
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Typography>Gemaakt door</Typography>
                                        <Chip
                                            label={`${this.props.quote?.created_by_user?.first_name} ${this.props.quote?.created_by_user?.last_name}`}
                                            variant={'outlined'}
                                            color={'primary'}
                                            avatar={<Avatar>T</Avatar>}
                                            size={'small'}
                                        />
                                    </Stack>
                                </Grid>
                            ) : undefined}

                            <Grid item xs={12}></Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel id={'quote-sales-user-label'}>Verkoper</InputLabel>
                                    <Select
                                        size={'small'}
                                        labelId={'quote-sales-user-label'}
                                        id={'quote-sales-user'}
                                        label={'Verkoper'}
                                        value={this.state.selectedUserId}
                                        onChange={event =>
                                            this.setState({ selectedUserId: event.target.value as number }, () =>
                                                this.updateQuote(),
                                            )
                                        }
                                    >
                                        {this.state.users.map((user, index) => {
                                            return (
                                                <MenuItem value={user.user_id ?? undefined} key={index}>
                                                    {user.first_name} {user.last_name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel id={'quote-sales-user-label'}>Garantie</InputLabel>
                                    <Select
                                        size={'small'}
                                        labelId={'quote-warranties-label'}
                                        id={'quote-warranties'}
                                        multiple
                                        label={'Warranty'}
                                        value={this.state.selectedWarranties}
                                        onChange={event =>
                                            this.setState(
                                                { selectedWarranties: event.target.value as QuoteWarranty[] },
                                                () => this.updateQuote(),
                                            )
                                        }
                                        input={<OutlinedInput label={'Garanties'} />}
                                        renderValue={(selected: string[]) => `${selected.length} geselecteerd`}
                                        MenuProps={MenuProps}
                                    >
                                        {Object.keys(QuoteWarranty).map(warranty => (
                                            <MenuItem key={warranty} value={QuoteWarranty[warranty]}>
                                                <Checkbox
                                                    checked={this.state.selectedWarranties.includes(
                                                        QuoteWarranty[warranty],
                                                    )}
                                                />
                                                <ListItemText primary={QuoteWarrantyCode[warranty]} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={4} id={'preferred-date-cell'}>
                                <FormControl fullWidth size={'small'} style={{ marginTop: '0!important' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            label={'Voorkeur plaatsing datum'}
                                            value={this.state.placementDate}
                                            onChange={date =>
                                                this.setState({ placementDate: date }, () => this.updateQuote())
                                            }
                                            renderInput={params => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id={'quote-info-taal-label'}>Taal</InputLabel>
                                    <Select
                                        size={'small'}
                                        labelId={'quote-info-taal-label'}
                                        id={'quote-info-taal'}
                                        label={'Taal'}
                                        value={this.state.selectedLanguage}
                                        defaultValue={this.state.selectedLanguage}
                                        onChange={event =>
                                            this.setState({ selectedLanguage: event.target.value as string }, () =>
                                                this.updateQuote(),
                                            )
                                        }
                                    >
                                        <MenuItem value={'nl'}>Nederlands</MenuItem>
                                        <MenuItem value={'fr'}>Frans</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id={'attachments-multiple-checkbox-label'}>Bijlages</InputLabel>
                                    <Select
                                        size={'small'}
                                        labelId={'attachments-multiple-checkbox-label'}
                                        id={'attachments-multiple-checkbox'}
                                        multiple
                                        value={this.state.selectedAttachments}
                                        onChange={event => {
                                            this.setState({ selectedAttachments: event.target.value as string[] }, () =>
                                                this.updateQuote(),
                                            );
                                        }}
                                        input={<OutlinedInput label={'Bijlages'} />}
                                        renderValue={(selected: string[]) => `${selected.length} geselecteerd`}
                                        MenuProps={MenuProps}
                                    >
                                        {this.state.attachments.map(attachment => (
                                            <MenuItem key={attachment} value={attachment}>
                                                <Checkbox
                                                    checked={this.state.selectedAttachments.includes(attachment)}
                                                />
                                                <ListItemText primary={attachment} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextareaAutosize
                                    aria-label={'minimum height'}
                                    minRows={4}
                                    defaultValue={this.state.remarks}
                                    onChange={event =>
                                        this.setState({ remarks: event.target.value }, () => this.updateQuote())
                                    }
                                    placeholder={'Type u opmerkingen of notities voor de offerte hier.'}
                                    style={{ width: 600 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size={'small'}
                                            defaultChecked={this.state.sendEmailToCustomer}
                                            onChange={(event, sendEmailToCustomer) =>
                                                this.setState({ sendEmailToCustomer }, () => this.updateQuote())
                                            }
                                        />
                                    }
                                    label={'Email offerte naar klant'}
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size={'small'}
                                            defaultChecked={this.state.isSold}
                                            onChange={(event, isSold) =>
                                                this.setState({ isSold }, () => this.updateQuote())
                                            }
                                        />
                                    }
                                    label={'Verkocht'}
                                />
                            </Grid>
                        </Grid>
                        <Box
                            component={'form'}
                            sx={{
                                '& .MuiTextField-root': { m: 0.5, width: '14ch' },
                            }}
                            noValidate
                            autoComplete={'off'}
                        >
                            <TextField
                                size={'small'}
                                id={'analyse_f'}
                                label={'Analyse °F'}
                                variant={'standard'}
                                defaultValue={this.state.technischeFiche.analyse_f}
                                onChange={event =>
                                    this.setState(
                                        {
                                            technischeFiche: {
                                                ...this.state.technischeFiche,
                                                analyse_f: event.target.value,
                                            },
                                        },
                                        () => this.updateQuote(),
                                    )
                                }
                            />
                            <TextField
                                size={'small'}
                                id={'keer_klep'}
                                label={'Keer klep'}
                                variant={'standard'}
                                defaultValue={this.state.technischeFiche.keer_klep}
                                onChange={event =>
                                    this.setState(
                                        {
                                            technischeFiche: {
                                                ...this.state.technischeFiche,
                                                keer_klep: event.target.value,
                                            },
                                        },
                                        () => this.updateQuote(),
                                    )
                                }
                            />
                            <TextField
                                size={'small'}
                                id={'leidingen'}
                                label={'Leidingen'}
                                variant={'standard'}
                                defaultValue={this.state.technischeFiche.leidingen}
                                onChange={event =>
                                    this.setState(
                                        {
                                            technischeFiche: {
                                                ...this.state.technischeFiche,
                                                leidingen: event.target.value,
                                            },
                                        },
                                        () => this.updateQuote(),
                                    )
                                }
                            />
                            <TextField
                                size={'small'}
                                id={'stopcontact'}
                                label={'Stopcontact'}
                                variant={'standard'}
                                defaultValue={this.state.technischeFiche.stopcontact}
                                onChange={event =>
                                    this.setState(
                                        {
                                            technischeFiche: {
                                                ...this.state.technischeFiche,
                                                stopcontact: event.target.value,
                                            },
                                        },
                                        () => this.updateQuote(),
                                    )
                                }
                            />
                            <TextField
                                size={'small'}
                                id={'afvoer'}
                                label={'Afvoer'}
                                variant={'standard'}
                                defaultValue={this.state.technischeFiche.afvoer}
                                onChange={event =>
                                    this.setState(
                                        {
                                            technischeFiche: {
                                                ...this.state.technischeFiche,
                                                afvoer: event.target.value,
                                            },
                                        },
                                        () => this.updateQuote(),
                                    )
                                }
                            />
                        </Box>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default QuoteInfo;
