import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ROUTING } from '../../app.routing';
import { SharedModule } from '../shared/shared.module';
import { ClarityModule } from '@clr/angular';
import { WorkOrderOverviewPageComponent } from './work-order-overview-page/work-order-overview-page.component';

const components = [];

const containers = [WorkOrderOverviewPageComponent];

const imports = [CommonModule, FormsModule, ReactiveFormsModule, ROUTING, SharedModule, ClarityModule];

const sandboxes = [];

const services = [];

@NgModule({
    imports: [...imports],
    declarations: [...containers, ...components],
    providers: [...sandboxes, ...services],
    exports: [...containers],
})
export class WorkOrderModule {}
