export enum ExactMetaActivityColumns {
    activity_id = 'activity_id',
    exact_meta_id = 'exact_meta_id',
}

export interface ExactMetaActivityAttributes {
    activity_id: number;
    exact_meta_id: number;
}

export type ExactMetaActivityCreate = ExactMetaActivityAttributes;
