"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseICalendarEvent = void 0;
const icalendar_event_lite_attendees_1 = require("./icalendar-event-lite-attendees");
const icalendar_event_lite_body_1 = require("./icalendar-event-lite-body");
const icalendar_event_lite_status_1 = require("./icalendar-event-lite-status");
const icalendar_event_location_1 = require("./icalendar-event-location");
const icalendar_event_organizer_1 = require("./icalendar-event-organizer");
const icalendar_event_start_1 = require("./icalendar-event-start");
function parseICalendarEvent(data) {
    if (data.responseStatus !== null && typeof data.responseStatus === 'object')
        data.responseStatus = (0, icalendar_event_lite_status_1.parseICalendarEventLiteStatus)(data.responseStatus);
    if (data.body !== null && typeof data.body === 'object')
        data.body = (0, icalendar_event_lite_body_1.parseICalendarEventLiteBody)(data.body);
    if (data.start !== null && typeof data.start === 'object')
        data.start = (0, icalendar_event_start_1.parseICalendarEventStart)(data.start);
    if (data.end !== null && typeof data.end === 'object')
        data.end = (0, icalendar_event_start_1.parseICalendarEventStart)(data.end);
    if (data.location !== null && typeof data.location === 'object')
        data.location = (0, icalendar_event_location_1.parseICalendarEventLocation)(data.location);
    if (data.attendees !== null && typeof data.attendees === 'object')
        data.attendees = data.attendees.map((x) => (0, icalendar_event_lite_attendees_1.parseICalendarEventLiteAttendees)(x));
    if (data.organizer !== null && typeof data.organizer === 'object')
        data.organizer = (0, icalendar_event_organizer_1.parseICalendarEventOrganizer)(data.organizer);
    return data;
}
exports.parseICalendarEvent = parseICalendarEvent;
