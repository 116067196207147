import * as Icon from '@mui/icons-material';
import { IPlanningItemEntity, Language, PlanningState } from '@phrospr/api-backend';
import React from 'react';
import { defaultTheme, PhrosprThemeProvider } from '../../theme';
import Card from '../Card';
import { Stepper, StepperProps } from '../Stepper/Stepper';
import './TimeFlow.scss';
import * as Style from './TimeFlow.style';
import { getNameOfPlanningItemTypePipe } from './util';

export interface TimeFlowProps {
    items?: IPlanningItemEntity[];
    goToPlanningItem: (id: number) => void;
}

export function TimeFlow({ items, goToPlanningItem }: TimeFlowProps) {
    function getContent() {
        return (
            <Style.Content>
                <Stepper
                    steps={items}
                    onRenderLabel={x => (
                        <Style.Date>
                            {x.assigned_calendar_item?.date_time_start.toLocaleDateString(undefined, {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            })}
                        </Style.Date>
                    )}
                />
                <Stepper steps={items} onRenderLabel={onRenderLabel} onRenderIcon={onRenderIcon} />
            </Style.Content>
        );
    }

    function onRenderIcon({
        state,
    }: IPlanningItemEntity): ReturnType<StepperProps<IPlanningItemEntity>['onRenderIcon']> {
        switch (state) {
            case PlanningState.toBePlanned:
                return { element: <></>, style: { borderColor: '#8c8c8c' } };
            case PlanningState.planned:
                return {
                    element: <Icon.Circle fontSize="large" />,
                    style: { color: '#0072a3', borderColor: '#0072a3' },
                };
            case PlanningState.completed:
            case PlanningState.completedAndQuoteApproved:
            case PlanningState.completedWithOffer:
            case PlanningState.completedNoOffer:
                return { element: <Icon.Check fontSize="large" />, style: { backgroundColor: 'green' } };
            case PlanningState.inactive:
            case PlanningState.protest:
                return { element: <Icon.ErrorOutline fontSize="large" />, style: { backgroundColor: 'red' } };
            default:
                return;
        }
    }

    function onRenderLabel(item: IPlanningItemEntity) {
        return (
            <>
                <Style.Label onClick={() => goToPlanningItem(item.planning_item_id)}>
                    {getNameOfPlanningItemTypePipe(item.planning_item_type, Language.nl)}:
                    <Style.PlanningItemNumber>{item.planning_item_type_number}</Style.PlanningItemNumber>
                    <Style.Watermeterstand>
                        {item.data.water_meter_stand !== undefined
                            ? `(watermeterstand: ${item.data.water_meter_stand})`
                            : undefined}
                    </Style.Watermeterstand>
                </Style.Label>
            </>
        );
    }

    return (
        <PhrosprThemeProvider>
            <Card title="Klant historiek">{getContent()}</Card>
        </PhrosprThemeProvider>
    );
}

export default TimeFlow;
