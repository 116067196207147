"use strict";
/* tslint:disable */
/* eslint-disable */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanningItemApi = exports.PlanningItemApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
// @ts-ignore
const models_1 = require("../models");
// @ts-ignore
const models_2 = require("../models");
/**
 * PlanningItemApi - axios parameter creator
 * @export
 */
const PlanningItemApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {RequestPlanningItemApproveLeadQuoteBody} requestPlanningItemApproveLeadQuoteBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvePlanningItemLeadQuote: (requestPlanningItemApproveLeadQuoteBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'requestPlanningItemApproveLeadQuoteBody' is not null or undefined
            (0, common_1.assertParamExists)('approvePlanningItemLeadQuote', 'requestPlanningItemApproveLeadQuoteBody', requestPlanningItemApproveLeadQuoteBody);
            const localVarPath = `/api/planning-item/approve-lead-quote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestPlanningItemApproveLeadQuoteBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {RequestPlanningItemCreateBody} requestPlanningItemCreateBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlanningItem: (requestPlanningItemCreateBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'requestPlanningItemCreateBody' is not null or undefined
            (0, common_1.assertParamExists)('createPlanningItem', 'requestPlanningItemCreateBody', requestPlanningItemCreateBody);
            const localVarPath = `/api/planning-item/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestPlanningItemCreateBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {RequestPlanningItemFinishManualBody} requestPlanningItemFinishManualBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishManualPlanningItem: (requestPlanningItemFinishManualBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'requestPlanningItemFinishManualBody' is not null or undefined
            (0, common_1.assertParamExists)('finishManualPlanningItem', 'requestPlanningItemFinishManualBody', requestPlanningItemFinishManualBody);
            const localVarPath = `/api/planning-item/finish-manual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestPlanningItemFinishManualBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {RequestPlanningItemFinishBody} requestPlanningItemFinishBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishPlanningItem: (requestPlanningItemFinishBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'requestPlanningItemFinishBody' is not null or undefined
            (0, common_1.assertParamExists)('finishPlanningItem', 'requestPlanningItemFinishBody', requestPlanningItemFinishBody);
            const localVarPath = `/api/planning-item/finish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestPlanningItemFinishBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} clientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanningItemClientHistory: (clientId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'clientId' is not null or undefined
            (0, common_1.assertParamExists)('getPlanningItemClientHistory', 'clientId', clientId);
            const localVarPath = `/api/planning-item/get-client-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} [calendarItemId]
         * @param {number} [planningItemId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanningItemDetail: (calendarItemId, planningItemId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/planning-item/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (calendarItemId !== undefined) {
                localVarQueryParameter['calendar_item_id'] = calendarItemId;
            }
            if (planningItemId !== undefined) {
                localVarQueryParameter['planning_item_id'] = planningItemId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {InlineObject} inlineObject
         * @param {boolean} [inCalendarOfUser]
         * @param {boolean} [inCalendarOfUser2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlanningItems: (inlineObject, inCalendarOfUser, inCalendarOfUser2, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'inlineObject' is not null or undefined
            (0, common_1.assertParamExists)('listPlanningItems', 'inlineObject', inlineObject);
            const localVarPath = `/api/planning-item/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (inCalendarOfUser !== undefined) {
                localVarQueryParameter['in_calendar_of_user'] = inCalendarOfUser;
            }
            if (inCalendarOfUser2 !== undefined) {
                localVarQueryParameter['inCalendarOfUser'] = inCalendarOfUser2;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inlineObject, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PlanningItemPlanBody} planningItemPlanBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planPlanningItem: (planningItemPlanBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'planningItemPlanBody' is not null or undefined
            (0, common_1.assertParamExists)('planPlanningItem', 'planningItemPlanBody', planningItemPlanBody);
            const localVarPath = `/api/planning-item/plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(planningItemPlanBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {RequestPlanningItemUpdateBody} requestPlanningItemUpdateBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlanningItem: (requestPlanningItemUpdateBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'requestPlanningItemUpdateBody' is not null or undefined
            (0, common_1.assertParamExists)('updatePlanningItem', 'requestPlanningItemUpdateBody', requestPlanningItemUpdateBody);
            const localVarPath = `/api/planning-item/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestPlanningItemUpdateBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} planningItemId
         * @param {RequestUpdatePlanningItemDataBody} requestUpdatePlanningItemDataBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlanningItemData: (planningItemId, requestUpdatePlanningItemDataBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'planningItemId' is not null or undefined
            (0, common_1.assertParamExists)('updatePlanningItemData', 'planningItemId', planningItemId);
            // verify required parameter 'requestUpdatePlanningItemDataBody' is not null or undefined
            (0, common_1.assertParamExists)('updatePlanningItemData', 'requestUpdatePlanningItemDataBody', requestUpdatePlanningItemDataBody);
            const localVarPath = `/api/planning-item/update/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (planningItemId !== undefined) {
                localVarQueryParameter['planningItemId'] = planningItemId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestUpdatePlanningItemDataBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} planningItemId
         * @param {string} description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlanningItemDescription: (planningItemId, description, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'planningItemId' is not null or undefined
            (0, common_1.assertParamExists)('updatePlanningItemDescription', 'planningItemId', planningItemId);
            // verify required parameter 'description' is not null or undefined
            (0, common_1.assertParamExists)('updatePlanningItemDescription', 'description', description);
            const localVarPath = `/api/planning-item/update-description`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (planningItemId !== undefined) {
                localVarQueryParameter['planning_item_id'] = planningItemId;
            }
            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} planningItemId
         * @param {number} waterMeterStand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlanningItemWaterMeterStand: (planningItemId, waterMeterStand, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'planningItemId' is not null or undefined
            (0, common_1.assertParamExists)('updatePlanningItemWaterMeterStand', 'planningItemId', planningItemId);
            // verify required parameter 'waterMeterStand' is not null or undefined
            (0, common_1.assertParamExists)('updatePlanningItemWaterMeterStand', 'waterMeterStand', waterMeterStand);
            const localVarPath = `/api/planning-item/update-water-meter-stand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (planningItemId !== undefined) {
                localVarQueryParameter['planning_item_id'] = planningItemId;
            }
            if (waterMeterStand !== undefined) {
                localVarQueryParameter['water_meter_stand'] = waterMeterStand;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.PlanningItemApiAxiosParamCreator = PlanningItemApiAxiosParamCreator;
/**
 * PlanningItemApi -
 */
var PlanningItemApi;
(function (PlanningItemApi) {
    /**
     *
     * @param {RequestPlanningItemApproveLeadQuoteBody} requestPlanningItemApproveLeadQuoteBody
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof PlanningItemApi
     * url /api/planning-item/approve-lead-quote
     */
    function approvePlanningItemLeadQuote(requestPlanningItemApproveLeadQuoteBody, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.PlanningItemApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.approvePlanningItemLeadQuote(requestPlanningItemApproveLeadQuoteBody, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration));
        });
    }
    PlanningItemApi.approvePlanningItemLeadQuote = approvePlanningItemLeadQuote;
    /**
     *
     * @param {RequestPlanningItemCreateBody} requestPlanningItemCreateBody
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof PlanningItemApi
     * url /api/planning-item/create
     */
    function createPlanningItem(requestPlanningItemCreateBody, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.PlanningItemApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.createPlanningItem(requestPlanningItemCreateBody, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_1.parseIPlanningItemEntity)(res.data);
                return res;
            });
        });
    }
    PlanningItemApi.createPlanningItem = createPlanningItem;
    /**
     *
     * @param {RequestPlanningItemFinishManualBody} requestPlanningItemFinishManualBody
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof PlanningItemApi
     * url /api/planning-item/finish-manual
     */
    function finishManualPlanningItem(requestPlanningItemFinishManualBody, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.PlanningItemApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.finishManualPlanningItem(requestPlanningItemFinishManualBody, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration));
        });
    }
    PlanningItemApi.finishManualPlanningItem = finishManualPlanningItem;
    /**
     *
     * @param {RequestPlanningItemFinishBody} requestPlanningItemFinishBody
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof PlanningItemApi
     * url /api/planning-item/finish
     */
    function finishPlanningItem(requestPlanningItemFinishBody, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.PlanningItemApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.finishPlanningItem(requestPlanningItemFinishBody, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration));
        });
    }
    PlanningItemApi.finishPlanningItem = finishPlanningItem;
    /**
     *
     * @param {number} clientId
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof PlanningItemApi
     * url /api/planning-item/get-client-history
     */
    function getPlanningItemClientHistory(clientId, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.PlanningItemApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.getPlanningItemClientHistory(clientId, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = res.data.map((x) => (0, models_1.parseIPlanningItemEntity)(x));
                return res;
            });
        });
    }
    PlanningItemApi.getPlanningItemClientHistory = getPlanningItemClientHistory;
    /**
     *
     * @param {number} [calendarItemId]
     * @param {number} [planningItemId]
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof PlanningItemApi
     * url /api/planning-item/detail
     */
    function getPlanningItemDetail(calendarItemId, planningItemId, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.PlanningItemApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.getPlanningItemDetail(calendarItemId, planningItemId, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_1.parseIPlanningItemEntity)(res.data);
                return res;
            });
        });
    }
    PlanningItemApi.getPlanningItemDetail = getPlanningItemDetail;
    /**
     *
     * @param {InlineObject} inlineObject
     * @param {boolean} [inCalendarOfUser]
     * @param {boolean} [inCalendarOfUser2]
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof PlanningItemApi
     * url /api/planning-item/list
     */
    function listPlanningItems(inlineObject, inCalendarOfUser, inCalendarOfUser2, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.PlanningItemApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.listPlanningItems(inlineObject, inCalendarOfUser, inCalendarOfUser2, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_2.parseInlineResponse200)(res.data);
                return res;
            });
        });
    }
    PlanningItemApi.listPlanningItems = listPlanningItems;
    /**
     *
     * @param {PlanningItemPlanBody} planningItemPlanBody
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof PlanningItemApi
     * url /api/planning-item/plan
     */
    function planPlanningItem(planningItemPlanBody, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.PlanningItemApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.planPlanningItem(planningItemPlanBody, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration));
        });
    }
    PlanningItemApi.planPlanningItem = planPlanningItem;
    /**
     *
     * @param {RequestPlanningItemUpdateBody} requestPlanningItemUpdateBody
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof PlanningItemApi
     * url /api/planning-item/update
     */
    function updatePlanningItem(requestPlanningItemUpdateBody, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.PlanningItemApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePlanningItem(requestPlanningItemUpdateBody, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_1.parseIPlanningItemEntity)(res.data);
                return res;
            });
        });
    }
    PlanningItemApi.updatePlanningItem = updatePlanningItem;
    /**
     *
     * @param {number} planningItemId
     * @param {RequestUpdatePlanningItemDataBody} requestUpdatePlanningItemDataBody
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof PlanningItemApi
     * url /api/planning-item/update/data
     */
    function updatePlanningItemData(planningItemId, requestUpdatePlanningItemDataBody, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.PlanningItemApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePlanningItemData(planningItemId, requestUpdatePlanningItemDataBody, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_1.parseIPlanningItemEntity)(res.data);
                return res;
            });
        });
    }
    PlanningItemApi.updatePlanningItemData = updatePlanningItemData;
    /**
     *
     * @param {number} planningItemId
     * @param {string} description
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof PlanningItemApi
     * url /api/planning-item/update-description
     */
    function updatePlanningItemDescription(planningItemId, description, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.PlanningItemApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePlanningItemDescription(planningItemId, description, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_1.parseIPlanningItemEntity)(res.data);
                return res;
            });
        });
    }
    PlanningItemApi.updatePlanningItemDescription = updatePlanningItemDescription;
    /**
     *
     * @param {number} planningItemId
     * @param {number} waterMeterStand
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof PlanningItemApi
     * url /api/planning-item/update-water-meter-stand
     */
    function updatePlanningItemWaterMeterStand(planningItemId, waterMeterStand, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.PlanningItemApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePlanningItemWaterMeterStand(planningItemId, waterMeterStand, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_1.parseIPlanningItemEntity)(res.data);
                return res;
            });
        });
    }
    PlanningItemApi.updatePlanningItemWaterMeterStand = updatePlanningItemWaterMeterStand;
})(PlanningItemApi = exports.PlanningItemApi || (exports.PlanningItemApi = {}));
