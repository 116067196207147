"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseILeadAquagroupSiteBody = exports.ILeadAquagroupSiteBodyGenderEnum = void 0;
const ilead_aquagroup_site_body_additional_data_1 = require("./ilead-aquagroup-site-body-additional-data");
const ilead_aquagroup_site_body_created_at_1 = require("./ilead-aquagroup-site-body-created-at");
const ilead_aquagroup_site_body_products_1 = require("./ilead-aquagroup-site-body-products");
const ilead_data_entity_location_1 = require("./ilead-data-entity-location");
const ilead_doubles_1 = require("./ilead-doubles");
/**
    * @export
    * @enum {string}
    */
var ILeadAquagroupSiteBodyGenderEnum;
(function (ILeadAquagroupSiteBodyGenderEnum) {
    ILeadAquagroupSiteBodyGenderEnum["M"] = "M";
    ILeadAquagroupSiteBodyGenderEnum["F"] = "F";
})(ILeadAquagroupSiteBodyGenderEnum = exports.ILeadAquagroupSiteBodyGenderEnum || (exports.ILeadAquagroupSiteBodyGenderEnum = {}));
function parseILeadAquagroupSiteBody(data) {
    if (typeof data.lead_viewed === 'string')
        data.lead_viewed = new Date(data.lead_viewed);
    if (data.created_at !== null && typeof data.created_at === 'object')
        data.created_at = (0, ilead_aquagroup_site_body_created_at_1.parseILeadAquagroupSiteBodyCreatedAt)(data.created_at);
    if (data.additional_data !== null && typeof data.additional_data === 'object')
        data.additional_data = data.additional_data.map((x) => (0, ilead_aquagroup_site_body_additional_data_1.parseILeadAquagroupSiteBodyAdditionalData)(x));
    if (data.products !== null && typeof data.products === 'object')
        data.products = data.products.map((x) => (0, ilead_aquagroup_site_body_products_1.parseILeadAquagroupSiteBodyProducts)(x));
    if (data.location !== null && typeof data.location === 'object')
        data.location = (0, ilead_data_entity_location_1.parseILeadDataEntityLocation)(data.location);
    if (data.lead_doubles !== null && typeof data.lead_doubles === 'object')
        data.lead_doubles = (0, ilead_doubles_1.parseILeadDoubles)(data.lead_doubles);
    return data;
}
exports.parseILeadAquagroupSiteBody = parseILeadAquagroupSiteBody;
