export enum PlanningItemQuoteColumns {
    planning_item_id = 'planning_item_id',
    quote_id = 'quote_id',
}

export interface PlanningItemQuoteAttributes {
    planning_item_id: number;
    quote_id: number;
}

export type PlanningItemQuoteCreate = PlanningItemQuoteAttributes;
