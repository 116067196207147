<div>
    <div class="card-content-search card-content search-content">
        <div class="search-item grid-item" *ngIf="createdDatePicker">
            <input
                (ngModelChange)="triggerDateFilter(filterKeys.FROM_CREATED_DATE, $event)"
                [(ngModel)]="fromCreatedDateTrigger"
                clrDate
                placeholder="From"
                type="date"
            />
            <input
                (ngModelChange)="triggerDateFilter(filterKeys.TO_CREATED_DATE, $event)"
                [(ngModel)]="toCreatedDateTrigger"
                clrDate
                placeholder="To"
                type="date"
            />
        </div>

        <div class="search-item grid-item filter-text">
            <div>
                <input
                    (ngModelChange)="triggerFilter(filterKeys.QUOTE_ID, $event)"
                    [(ngModel)]="quoteId"
                    id="quoteId"
                    type="text"
                    placeholder="Zoek op quote ID"
                    style="width: 200px"
                />
            </div>
        </div>
        <div class="search-item grid-item filter-text">
            <div *ngIf="!searchLeadName">
                <input
                    (ngModelChange)="triggerFilter(filterKeys.CLIENT_NAME, $event)"
                    [(ngModel)]="clientNameTrigger"
                    id="clientName"
                    type="text"
                    placeholder="Zoek op klant naam"
                    style="width: 200px"
                />
            </div>
            <div *ngIf="searchLeadName">
                <input
                    (ngModelChange)="triggerFilter(filterKeys.LEAD_NAME, $event)"
                    [(ngModel)]="leadNameTrigger"
                    id="leadName"
                    type="text"
                    placeholder="Zoek op naam"
                    style="width: 200px"
                />
            </div>
        </div>
        <div class="search-item grid-item filter-text">
            <div>
                <input
                    (ngModelChange)="triggerFilter(filterKeys.POSTCODE, $event)"
                    [(ngModel)]="zipCodeTrigger"
                    id="zipCode"
                    placeholder="Zoek postcode"
                    style="width: 200px"
                    type="text"
                />
            </div>
        </div>
        <div class="search-item grid-item filter-text" *ngIf="createdByName">
            <div>
                <input
                    (ngModelChange)="triggerFilter(filterKeys.CREATED_BY_USER_NAME, $event)"
                    [(ngModel)]="createdByUserNameTrigger"
                    id="created_by_user_name"
                    placeholder="Zoek op gemaakt door"
                    style="width: 200px"
                    type="text"
                />
            </div>
        </div>
        <div class="search-item grid-item filter-text" *ngIf="soldByName">
            <div>
                <input
                    (ngModelChange)="triggerFilter(filterKeys.SOLD_BY_USER_NAME, $event)"
                    [(ngModel)]="soldByUserNameTrigger"
                    id="sold_by_user_name"
                    placeholder="Verkocht door"
                    style="width: 200px"
                    type="text"
                />
            </div>
        </div>

        <div class="search-item grid-item">
            <div>
                <clr-checkbox-container style="margin-top: 0">
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            clrCheckbox
                            [checked]="quoteStatusCreatedChecked"
                            (ngModelChange)="checkboxStatusClicked(QuoteStatus.created, $event)"
                            [(ngModel)]="quoteStatusCreatedChecked"
                            name="QuoteStatus"
                        />
                        <label>Created</label>
                    </clr-checkbox-wrapper>
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            clrCheckbox
                            (ngModelChange)="checkboxStatusClicked(QuoteStatus.approved, $event)"
                            [(ngModel)]="quoteStatusApprovedChecked"
                            name="QuoteStatus"
                        />
                        <label>Approved</label>
                    </clr-checkbox-wrapper>
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            clrCheckbox
                            (ngModelChange)="checkboxStatusClicked(QuoteStatus.cancelled, $event)"
                            [(ngModel)]="quoteStatusCancelledChecked"
                            name="QuoteStatus"
                        />
                        <label>Cancelled</label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
            </div>
        </div>

        <div class="search-item grid-item">
            <button (click)="resetFilters()" class="btn btn-link btn-sm">
                <cds-icon shape="times"></cds-icon>
                Clear filters
            </button>
        </div>
    </div>
</div>
