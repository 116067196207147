import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'pr-delete-modal',
    templateUrl: './modalDelete.component.html',
    styleUrls: ['./modalDelete.component.scss'],
})
export class ModalDeleteComponent implements OnInit {
    @Input() visible;

    @Output() ok = new EventEmitter<any>();
    @Output() cancel = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}

    sendOk() {
        this.ok.emit();
        this.visible = false;
    }

    sendCancel() {
        this.cancel.emit();
        this.visible = false;
    }
}
