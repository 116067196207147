<div>
  <clr-tabs>
    <clr-tab>
      <button (click)="setTab('newTab')" clrTabLink>NEW REPAIR</button>
      <ng-template [(clrIfActive)]="newTab">
        <clr-tab-content>
          <pr-new-repair-page></pr-new-repair-page>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab>
      <button (click)="setTab('plannedTab')" clrTabLink>PLANNED</button>
      <ng-template [(clrIfActive)]="plannedTab">
        <clr-tab-content>
          <pr-planned-repair-page></pr-planned-repair-page>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab>
      <button (click)="setTab('completedTab')" clrTabLink>COMPLETED</button>
      <ng-template [(clrIfActive)]="completedTab">
        <clr-tab-content>
          <pr-completed-repair-page></pr-completed-repair-page>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
  </clr-tabs>
</div>




