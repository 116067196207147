import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../../../shared/services/quote.service';
import { Router } from '@angular/router';

@Component({
    selector: 'pr-quote-detail-page',
    styleUrls: ['./quote-detail-page.component.scss'],
    templateUrl: './quote-detail-page.component.html',
})
export class QuoteDetailPageComponent implements OnInit {
    constructor(private quoteService: QuoteService, private router: Router) {}

    ngOnInit(): void {}
}
