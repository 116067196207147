import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IProductSearchFilterEntity } from '@phrospr/api-backend';
import { validateProductItemSearchFilterKey } from '@phrospr/lib-core';

@Component({
    selector: 'pr-product-filter',
    styleUrls: ['./product-filter.component.scss'],
    templateUrl: './product-filter.component.html',
})
export class ProductFilterComponent implements OnInit {
    @Input() productItemSearchFilter: IProductSearchFilterEntity;
    @Output() updateProductItemSearchFilter = new EventEmitter<IProductSearchFilterEntity>();

    filterChangeSubject$: Subject<void> = new Subject<void>();

    productNumber = '';
    productNameTrigger = '';
    productActive = true;
    isService = false;

    filterKeys = {
        PRODUCT_NUMBER: 'product_number',
        PRODUCT_NAME: 'name',
        PRODUCT_ACTIVE: 'active',
    };

    constructor() {}

    ngOnInit(): void {
        if (!this.productItemSearchFilter) {
            return console.log('missing productItemsFilter');
        }
        this.filterChangeSubject$.pipe(debounceTime(1000)).subscribe(_ => this.sendNewFilter());
    }

    public checkboxIsServiceClicked(checked: boolean) {
        this.productItemSearchFilter.isService = checked;
        this.sendNewFilter();
    }

    public checkboxProductActiveClicked(checked: boolean) {
        this.productItemSearchFilter.active = checked;
        this.sendNewFilter();
    }

    triggerFilter(filterName, event) {
        if (!validateProductItemSearchFilterKey(filterName))
            return console.log('invalid filter key for product filter', filterName, event);
        this.productItemSearchFilter[filterName] = event;
        this.filterChangeSubject$.next();
    }

    sendNewFilter() {
        this.updateProductItemSearchFilter.emit(this.productItemSearchFilter);
    }

    resetFilters() {
        for (const localFilterKey in this.filterKeys) {
            const filterKey = this.filterKeys[localFilterKey];
            this.productItemSearchFilter[filterKey] = undefined;
        }
        this.productNumber = '';
        this.productNameTrigger = '';
        this.sendNewFilter();
    }
}
