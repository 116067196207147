"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseICommentEntity = void 0;
const icomment_data_entity_1 = require("./icomment-data-entity");
const iuser_entity_1 = require("./iuser-entity");
function parseICommentEntity(data) {
    if (typeof data.creation_date === 'string')
        data.creation_date = new Date(data.creation_date);
    if (data.data !== null && typeof data.data === 'object')
        data.data = (0, icomment_data_entity_1.parseICommentDataEntity)(data.data);
    if (data.created_by_user !== null && typeof data.created_by_user === 'object')
        data.created_by_user = (0, iuser_entity_1.parseIUserEntity)(data.created_by_user);
    return data;
}
exports.parseICommentEntity = parseICommentEntity;
