export enum ClientActivityColumns {
    activity_id = 'activity_id',
    client_id = 'client_id',
}

export interface ClientActivityAttributes {
    activity_id: number;
    client_id: number;
}

export type ClientActivityCreate = ClientActivityAttributes;
