"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIClientEntity = void 0;
const iactivity_entity_1 = require("./iactivity-entity");
const iclient_data_entity_1 = require("./iclient-data-entity");
const iclient_settings_entity_1 = require("./iclient-settings-entity");
const ilead_data_entity_1 = require("./ilead-data-entity");
function parseIClientEntity(data) {
    if (typeof data.exact_last_sync === 'string')
        data.exact_last_sync = new Date(data.exact_last_sync);
    if (typeof data.creation_data === 'string')
        data.creation_data = new Date(data.creation_data);
    if (data.client_data !== null && typeof data.client_data === 'object')
        data.client_data = (0, iclient_data_entity_1.parseIClientDataEntity)(data.client_data);
    if (data.client_settings !== null && typeof data.client_settings === 'object')
        data.client_settings = (0, iclient_settings_entity_1.parseIClientSettingsEntity)(data.client_settings);
    if (data.lead_data !== null && typeof data.lead_data === 'object')
        data.lead_data = (0, ilead_data_entity_1.parseILeadDataEntity)(data.lead_data);
    if (data.activities !== null && typeof data.activities === 'object')
        data.activities = data.activities.map((x) => (0, iactivity_entity_1.parseIActivityEntity)(x));
    return data;
}
exports.parseIClientEntity = parseIClientEntity;
