import {
    Box,
    Button,
    Chip,
    ChipProps,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField,
} from '@mui/material';
import React, { Component } from 'react';
import * as toastr from 'toastr';
import {
    IActivitySearchEntitySort,
    InventoryApi,
    IProductEntity,
    RequestSort,
    WorkOrderStatus,
} from '@phrospr/api-backend';
import { DataGrid, GridActionsCellItem, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { amber, blue, green, red, yellow } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import DoneIcon from '@mui/icons-material/Done';
import { GridEnrichedColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { CustomNoRowsOverlay } from './InventoryOverviewEmpty';
import CheckIcon from '@mui/icons-material/Check';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { InventoryOverviewProps, InventoryOverviewState } from './InventoryOverview.types';
import InventoryFilter from './InventoryFilter/InventoryFilter';
import {
    IInventorySearchEntity,
    IInventorySearchFilterEntity,
} from '@phrospr/lib-models/src/inventory/inventory-filter.entity';
import DeleteIcon from '@mui/icons-material/Delete';
import { IInventoryEntity, IInventoryExtendedEntity, ILocationsEntity } from '@phrospr/lib-models';
import AddIcon from '@mui/icons-material/Add';
import ProductSearch from '../product/ProductSearch/ProductSearch';
import LocationSearch from '../location/LocationSearch/LocationSearch';
import * as XLSX from 'xlsx';
import DownloadIcon from '@mui/icons-material/Download';

export class InventoryOverview extends Component<InventoryOverviewProps, InventoryOverviewState> {
    inventoryFilter: IInventorySearchEntity = {
        filter: {
            product_name: '',
        },
        limit: 20,
        show: {
            product: true,
            location: true,
        },
        sort: { column: 'inventory_id', order: RequestSort.DESC },
    };

    datagrid: {
        rowLength: 10;
        maxColumns: 11;
    };

    columns: GridEnrichedColDef[] = [
        // { field: 'inventory_id', headerName: 'ID', width: 70 },

        {
            field: 'product_number',
            headerName: 'Product nr',
            width: 70,
            flex: 1,
            editable: false,
            valueGetter: this.getProductNumber,
            sortable: false,
        },
        {
            field: 'product_name',
            headerName: 'Product Naam',
            width: 150,
            flex: 1,
            editable: false,
            valueGetter: this.getProductName,
            sortable: false,
        },
        { field: 'quantity_on_hand', headerName: 'Stock aantal', width: 150 },
        { field: 'location', headerName: 'Locatie', width: 150, valueGetter: this.getLocationName },
        { field: 'min_stock', headerName: 'Min Stock', width: 100, valueGetter: this.getMinStock },
        {
            field: 'actions',
            type: 'actions',
            minWidth: 105,
            getActions: params => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={() => this.showEditInventory(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="verwijder locatie"
                    onClick={() => this.showDeleteInventory(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<AddIcon />}
                    label="add stock"
                    onClick={() => this.showAddInventory()}
                    showInMenu
                />,
            ],
        },
    ];

    /*cancelWorkOrderModal = false;*/

    constructor(props: InventoryOverviewProps) {
        super(props);
        this.state = {
            editMode: false,
            cancelModal: false,
            inventory: [],
            cancelInventory: null,
            editInventory: null,
            loading: false,
            QuantityInventoryToChange: null,
            editInventoryQuantityModal: false,
            deleteInventoryModal: false,
            inventoryToDelete: null,
            inventoryToAdd: null,
            addInventoryModal: false,
        };
        this.calculateTotalStockValue();
    }

    async exportInventoryToExcel() {
        const inventory = (
            await InventoryApi.getInventoryListPost({
                inventory_filter: { limit: 100000, show: { product: true, location: true } },
            })
        ).data.records;

        const flatData = inventory.map(item => ({
            Product_number: item.product.product_number,
            Product_name: item.product.name,
            Quantity: item.quantity_on_hand,
            Location: item.location_id ? item.location.location_name : '',
            Min_stock: item.product.min_stock,
            unit_amount: item.product.pricing ? item.product.pricing.unit_amount : '',
            unit_type: item.product.pricing ? item.product.pricing.unit_type : '',
            Cost_per_unit: item.product.pricing ? item.product.pricing.cost_per_unit : '',
            Total_Value: item.product.pricing ? item.product.pricing.cost_per_unit * item.quantity_on_hand : '',
        }));
        // Step 1: Convert the data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(flatData);

        // Step 2: Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Step 3: Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Inventory');

        // Step 4: Generate and download the Excel file
        XLSX.writeFile(workbook, 'inventoryOverview.xlsx');
    }

    handleSortModeChange = (data: { field: string; sort: 'asc' | 'desc' }[]) => {
        let searchSort: IActivitySearchEntitySort = { column: 'inventory_id', order: RequestSort.DESC };
        if (data.length) {
            const { field, sort } = data[0];
            const order = sort === 'asc' ? RequestSort.ASC : RequestSort.DESC;
            searchSort = { column: field, order };
        }
        if (this.state.orderState?.order !== searchSort.order || this.state.orderState?.column !== searchSort.column) {
            this.setState({ orderState: searchSort }, this.loadInventory);
        }
    };

    getChipProps(params: GridRenderCellParams): ChipProps {
        if (params.row.status === WorkOrderStatus.cancelled) {
            return {
                icon: <WarningIcon style={{ fill: red[500] }} />,
                label: params.value,
                style: {
                    borderColor: red[500],
                },
            };
        } else if (params.row.status === WorkOrderStatus.created) {
            return {
                icon: <InfoIcon style={{ fill: blue[500] }} />,
                label: params.value,
                style: {
                    borderColor: blue[500],
                },
            };
        } else if (params.row.status === WorkOrderStatus.invoiced) {
            return {
                icon: <ReceiptIcon style={{ fill: green[500] }} />,
                label: params.value,
                style: {
                    borderColor: green[500],
                },
            };
        } else if (params.row.status === WorkOrderStatus.finished) {
            return {
                icon: <CheckIcon style={{ fill: yellow[700] }} />,
                label: params.value,
                style: {
                    borderColor: yellow[700],
                },
            };
        } else {
            return {
                icon: <DoneIcon style={{ fill: amber[500] }} />,
                label: params.value,
                style: {
                    borderColor: amber[500],
                },
            };
        }
    }

    getProductName(params: GridValueGetterParams) {
        return `${params.row.product.name || ''}`;
    }

    getProductNumber(params: GridValueGetterParams) {
        return `${params.row.product.product_number || ''}`;
    }

    getCreatedByUserName(params: GridValueGetterParams) {
        return `${params.row.created_by_user.first_name || ''}`;
    }

    getLocationName(params: GridValueGetterParams) {
        return `${params.row.location?.location_name || ''}`;
    }
    getMinStock(params: GridValueGetterParams) {
        return `${params.row.product?.min_stock || ''}`;
    }

    async setInventorySearchFilter(inventorySearchFilter: IInventorySearchFilterEntity) {
        this.inventoryFilter.filter = inventorySearchFilter;
        await this.loadInventory();
    }

    loadInventory = async () => {
        this.setState({ loading: true });
        // if (this.state.orderState !== undefined) this.workOrderFilter.sort = this.state.orderState;
        const { total, records } = (await InventoryApi.getInventoryListPost({ inventory_filter: this.inventoryFilter }))
            .data;
        this.setState({ inventory: records, editMode: true, loading: false, total });
    };

    showEditInventory(inventory: IInventoryExtendedEntity) {
        if (inventory) {
            this.setState({ QuantityInventoryToChange: inventory });
            this.setState({ editInventoryQuantityModal: true });
        } else {
            toastr.error('Er is iets misgegaan met aanpassen van stock level');
        }
    }

    showAddInventory() {
        this.setState({
            inventoryToAdd: {
                inventory_id: null,
                product_id: null,
                location_id: null,
                quantity_on_hand: null,
                data: null,
            },
        });
        this.setState({ addInventoryModal: true });
    }

    cancelAddInventory = async () => {
        this.setState({ inventoryToAdd: null, addInventoryModal: false });
    };

    addInventory = async () => {
        if (
            this.state.inventoryToAdd.quantity_on_hand === null ||
            !this.state.inventoryToAdd.product_id ||
            this.state.inventoryToAdd.quantity_on_hand === 0
        ) {
            toastr.error('Geen aantal of product ingegeven');
            return;
        } else {
            try {
                const inventoryToAdd: IInventoryEntity = {
                    inventory_id: null,
                    product_id: this.state.inventoryToAdd.product_id,
                    location_id: this.state.inventoryToAdd.location_id,
                    quantity_on_hand: this.state.inventoryToAdd.quantity_on_hand,
                    data: this.state.inventoryToAdd.data,
                };
                await InventoryApi.upsertInventory(inventoryToAdd);
                await this.loadInventory();
                this.setState({ addInventoryModal: false });
            } catch (err) {
                console.error(err);
                toastr.error(
                    'Fout bij het toevoegen van stock. De locatie bestaat. Ga naar het bestaande record en pas daar de stock aan.',
                );
            }
            return;
        }
    };

    showDeleteInventory(inventory: IInventoryExtendedEntity) {
        if (inventory.inventory_id) {
            this.setState({ inventoryToDelete: inventory });
            this.setState({ deleteInventoryModal: true });
        } else {
            toastr.error('Er is iets misgegaan met het verwijderen van de stock op deze lcoatie');
        }
    }

    deleteInventory = async () => {
        try {
            await InventoryApi.deleteInventory(this.state.inventoryToDelete.inventory_id);
            await this.loadInventory();
            this.setState({ deleteInventoryModal: false, inventoryToDelete: null });
        } catch (e) {
            console.error(e);
            toastr.error('Een probleem is opgedoken tijdens het verwijderen van de sotkc op deze locatie' + e);
        }
        return;
    };

    cancelDeleteInventory = async () => {
        this.setState({ inventoryToDelete: null, deleteInventoryModal: false });
    };

    handleClose() {
        console.log('closed');
    }

    changeInventoryQuantity = async () => {
        if (this.state.QuantityInventoryToChange.quantity_on_hand === undefined) {
            toastr.error('Geen aantal ingegeven');
        } else {
            try {
                const InventoryToUpdateDB: IInventoryEntity = {
                    inventory_id: this.state.QuantityInventoryToChange.inventory_id,
                    product_id: this.state.QuantityInventoryToChange.product_id,
                    location_id: this.state.QuantityInventoryToChange.location_id,
                    quantity_on_hand: this.state.QuantityInventoryToChange.quantity_on_hand,
                    data: this.state.QuantityInventoryToChange.data,
                };
                await InventoryApi.upsertInventory(InventoryToUpdateDB);
                await this.loadInventory();
                this.setState({ editInventoryQuantityModal: false });
            } catch (err) {
                console.error(err);
                toastr.error('Fout bij het aanpassen van stock level');
            }
            return;
        }
    };

    cancelEditInventoryQuantity = async () => {
        this.setState({ QuantityInventoryToChange: null });
        this.setState({ editInventoryQuantityModal: false });
    };

    setProduct(product: IProductEntity) {
        if (!product) return;
        console.log('product set');
        this.setState({
            inventoryToAdd: {
                ...this.state.inventoryToAdd,
                product_id: product.product_id,
            },
        });
    }

    setLocation(location: ILocationsEntity) {
        if (!location) return;
        console.log('location set');
        this.setState({
            inventoryToAdd: {
                ...this.state.inventoryToAdd,
                location_id: location.location_id,
            },
        });
    }

    calculateTotalStockValue = async () => {
        try {
            this.setState({
                totalStockValue: (await InventoryApi.calculateTotalInventoryValue()).data,
            });
        } catch (err) {
            console.error(err);
            toastr.error('Fout bij het berekenen van de stock value');
        }
        return;
    };

    render() {
        return (
            <div>
                <div>
                    <Chip
                        label={`${'Stock value: '} ${
                            this.state.totalStockValue
                                ? this.state.totalStockValue.toFixed(2).replace('.', ',') + ' €'
                                : '0,00 €'
                        }`}
                        variant={'outlined'}
                        color={'primary'}
                    />
                    <Button size={'small'} startIcon={<DownloadIcon />} onClick={() => this.exportInventoryToExcel()}>
                        Download inventory
                    </Button>
                </div>
                <div style={{ padding: '5px' }}>
                    <InventoryFilter
                        onChange={inventorySearchFilter => this.setInventorySearchFilter(inventorySearchFilter)}
                    ></InventoryFilter>
                </div>
                <div
                    data-testid="WorkOrderOverviewDataGrid"
                    style={{ display: 'flex', height: '100%', padding: '5px' }}
                >
                    <Box sx={{ height: 750, width: '100%', flexGrow: 1 }}>
                        <DataGrid
                            sx={{ width: '100%' }}
                            disableColumnFilter
                            getRowId={row => row.inventory_id}
                            {...this.datagrid}
                            loading={this.state.loading}
                            rowHeight={30}
                            sortingMode="server"
                            onSortModelChange={this.handleSortModeChange}
                            rows={this.state.inventory}
                            columns={this.columns}
                            paginationMode="server"
                            rowCount={this.state.total ?? 0}
                            pageSize={this.inventoryFilter.limit}
                            onPageChange={newPage => {
                                // starts from 0
                                this.inventoryFilter.offset = this.inventoryFilter.limit * newPage;
                                this.loadInventory();
                            }}
                            rowsPerPageOptions={[5]}
                            {...this.state.inventory}
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}
                        />
                    </Box>
                    <Dialog open={this.state.editInventoryQuantityModal} onClose={this.handleClose}>
                        <DialogContent>
                            <DialogContentText>
                                Pas het stock level aan voor product:{' '}
                                {this.state.QuantityInventoryToChange?.product.name} op locatie{' '}
                                {this.state.QuantityInventoryToChange?.location?.location_name}
                                .
                                <TextField
                                    value={this.state.QuantityInventoryToChange?.quantity_on_hand || ''}
                                    inputProps={{
                                        step: '0.01',
                                    }}
                                    autoFocus
                                    type="number"
                                    margin="dense"
                                    id="quantity_on_hand"
                                    label="Stock aantal"
                                    fullWidth
                                    variant="standard"
                                    onChange={event =>
                                        this.setState({
                                            QuantityInventoryToChange: {
                                                ...this.state.QuantityInventoryToChange,
                                                quantity_on_hand: parseFloat(event.target.value),
                                            },
                                        })
                                    }
                                />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button size="small" variant="text" onClick={this.cancelEditInventoryQuantity}>
                                Cancel
                            </Button>
                            <Button
                                color="success"
                                variant="contained"
                                size="small"
                                onClick={this.changeInventoryQuantity}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.deleteInventoryModal} onClose={this.handleClose}>
                        <DialogContent>
                            <DialogContentText>
                                Ben je zeker dat je {this.state.inventoryToDelete?.quantity_on_hand} stock voor product{' '}
                                {this.state.inventoryToDelete?.product.name} op locatie{' '}
                                {this.state.inventoryToDelete?.location?.location_name} wilt verwijderen?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button size="small" variant="text" onClick={this.cancelDeleteInventory}>
                                Nee
                            </Button>
                            <Button color="success" variant="contained" size="small" onClick={this.deleteInventory}>
                                Ja
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.addInventoryModal} onClose={this.handleClose}>
                        <DialogContent>
                            <DialogContentText>
                                Stock toevoegen
                                <ProductSearch onProductChange={product => this.setProduct(product)}></ProductSearch>
                                <br></br>
                                <LocationSearch
                                    onLocationChange={location => this.setLocation(location)}
                                ></LocationSearch>
                                <TextField
                                    value={this.state.inventoryToAdd?.quantity_on_hand || ''}
                                    autoFocus
                                    type="number"
                                    margin="dense"
                                    id="quantity_on_hand"
                                    label="Stock aantal"
                                    fullWidth
                                    variant="standard"
                                    onChange={event =>
                                        this.setState({
                                            inventoryToAdd: {
                                                ...this.state.inventoryToAdd,
                                                quantity_on_hand: parseInt(event.target.value),
                                            },
                                        })
                                    }
                                />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button size="small" variant="text" onClick={this.cancelAddInventory}>
                                Nee
                            </Button>
                            <Button color="success" variant="contained" size="small" onClick={this.addInventory}>
                                Ja
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }
}

export default InventoryOverview;
