<div *ngIf="planningItem" id="planning-item-detail">
    <div class="card-header">
        <a (click)="backToOverview()" class="lighter pointer">
            <cds-icon shape="angle" direction="left"></cds-icon>
            <p class="lighter" style="margin: 10px 0px">Terug</p>
        </a>
        <div class="border-bottom-header">
            <div class="bold shipment-external-id">
                {{ planningItem.planning_item_type | getNameOfPlanningItemTypePipe : 'nl' }}
                : {{ planningItem.planning_item_type_number }}

                <div class="row">
                    <div *ngIf="planningItem.state === PlanningState.toBePlanned" class="maintenance-inactive">
                        <span class="label label-info">TO BE PLANNED</span>
                    </div>
                    <div *ngIf="planningItem.state === PlanningState.planned" class="maintenance-inactive">
                        <span class="label label-warning">PLANNED</span>
                    </div>
                    <div
                        *ngIf="
                            planningItem.planning_item_type === PlanningItemType.maintenance &&
                            planningItem.state === PlanningState.inactive
                        "
                        class="maintenance-inactive"
                    >
                        <span class="label label-danger">INACTIEF</span>
                    </div>
                    <div
                        *ngIf="
                            planningItem.state === PlanningState.completed ||
                            planningItem.state === PlanningState.completedAndQuoteApproved
                        "
                        class="maintenance-completed"
                    >
                        <span class="label label-success">COMPLETED</span>
                    </div>

                    <div *ngIf="planningItem.state === PlanningState.completedWithOffer" class="maintenance-completed">
                        <span class="label label-success">COMPLETED With offer</span>
                    </div>
                    <div *ngIf="planningItem.state === PlanningState.completedNoOffer" class="maintenance-completed">
                        <span class="label label-success">COMPLETED NO OFFER</span>
                    </div>
                    <div
                        *ngIf="planningItem.state === PlanningState.completedAndQuoteCancelled"
                        class="maintenance-completed"
                    >
                        <span class="label label-danger">COMPLETED - Quote Cancelled</span>
                    </div>
                    <div
                        *ngIf="planningItem.state === PlanningState.completedAndQuoteApproved"
                        class="maintenance-completed"
                    >
                        <span class="label label-success">OFFER APPROVED</span>
                    </div>
                    <div *ngIf="planningItem.state === PlanningState.completedWithOffer" class="maintenance-completed">
                        <span class="label label-warning">OFFER CREATED</span>
                    </div>
                    <div
                        *ngIf="
                            planningItem.planning_item_type === PlanningItemType.lead &&
                            planningItem.state === PlanningState.protest
                        "
                        class="maintenance-inactive"
                    >
                        <span class="label label-danger">PROTEST</span>
                    </div>
                </div>
            </div>
            <div class="command-bar">
<!--                <button class="btn btn-menu btn-sm" [disabled]="true">-->
<!--                    <cds-icon shape="pencil" class="button-icon"></cds-icon>-->
<!--                    Edit-->
<!--                </button>-->
                <button
                    (click)="planPlanningItem()"
                    class="btn btn-group-prospr btn-menu btn-sm"
                    [disabled]="planningItem.state !== PlanningState.toBePlanned"
                >
                    Plan {{ planningItem.planning_item_type | getNameOfPlanningItemTypePipe : 'nl' }}
                </button>
                <clr-dropdown>
                    <button class="btn btn-outline-primary btn-menu btn-sm" clrDropdownTrigger type="button">
                        Meer
                        <cds-icon shape="angle" direction="down"></cds-icon>
                    </button>
                    <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                        <div class="dropdown-divider"></div>
                        <clr-dropdown *ngIf="planningItem.planning_item_type === PlanningItemType.maintenance">
                            <button
                                *ngIf="planningItem.client.client_settings.maintenanceSettings.active"
                                (click)="setInactive()"
                                clrDropdownItem
                                type="button"
                            >
                                Set inactive
                            </button>
                            <button
                                *ngIf="!planningItem.client.client_settings.maintenanceSettings.active"
                                (click)="setActive()"
                                clrDropdownItem
                                type="button"
                            >
                                Set Active
                            </button>
                        </clr-dropdown>
                        <button
                            [disabled]="
                                planningItem.planning_item_type === PlanningItemType.lead ||
                                planningItem.state !== PlanningState.toBePlanned
                            "
                            (click)="showFinishManualPlanningItemModal()"
                            clrDropdownItem
                            type="button"
                        >
                            {{ planningItem.planning_item_type | getNameOfPlanningItemTypePipe : 'nl' }} afwerken zonder
                            afspraak
                        </button>
                        <button
                            *ngIf="planningItem.planning_item_type === PlanningItemType.lead"
                            (click)="createQuoteForLead2()"
                            [disabled]="
                                !(
                                    planningItem.state === PlanningState.planned ||
                                    planningItem.state === PlanningState.toBePlanned
                                )
                            "
                            clrDropdownItem
                            type="button"
                        >
                            Maak offerte
                        </button>
                        <button
                            *ngIf="planningItem.planning_item_type === PlanningItemType.lead"
                            [disabled]="planningItem.state !== PlanningState.toBePlanned"
                            clrDropdownItem
                            type="button"
                            (click)="protestLead()"
                        >
                            Protest Lead
                        </button>
                        <button
                            clrDropdownItem
                            type="button"
                            *ngIf="planningItem.planning_item_type !== PlanningItemType.lead"
                            (click)="goToClient()"
                            disabled
                        >
                            Ga naar klant
                        </button>
                        <button
                            clrDropdownItem
                            type="button"
                            *ngIf="
                                planningItem.planning_item_type === PlanningItemType.lead &&
                                (planningItem.state === PlanningState.completedNoOffer ||
                                    planningItem.state === PlanningState.protest)
                            "
                            (click)="moveLeadToLeadToBePlanned()"
                        >
                            Lead terug naar new leads
                        </button>
                        <button clrDropdownItem type="button" (click)="goToHistoryClient(planningItem.client_id)">
                            View History
                        </button>
                    </clr-dropdown-menu>
                </clr-dropdown>
                <clr-dropdown class="command-bar-right">
                    <button class="btn btn-outline-primary btn-menu btn-sm" clrDropdownTrigger type="button">
                        <cds-icon shape="download"></cds-icon>
                        Export
                        <cds-icon shape="angle" direction="down"></cds-icon>
                    </button>
                    <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                        <button clrDropdownItem type="button" disabled>Printable</button>
                        <button clrDropdownItem type="button" disabled>JSON</button>
                    </clr-dropdown-menu>
                </clr-dropdown>
            </div>
        </div>

        <div class="sub-header" *ngIf="planningItem.client">
            <p class="bold">
                {{ planningItem.client.exact_client_number }} -
                {{
                    planningItem.client.client_data.first_name +
                        ' ' +
                        (planningItem.client.client_data.last_name ? planningItem.client.client_data.last_name : '')
                }}
            </p>
            <div class="dates">
                <p style="margin: 0px">
                    <span class="lighterHeader"
                        >Voorgestelde
                        {{ planningItem.planning_item_type | getNameOfPlanningItemTypePipe : 'nl' }} datum</span
                    >
                    <span *ngIf="!!planningItem.data?.suggested_date">
                        {{
                            planningItem.data.suggested_date
                                ? (planningItem.data.suggested_date | dateFormatOnlyDate)
                                : ''
                        }}
                    </span>
                </p>
                <p style="margin: 0">
                    <span class="lighterHeader">Ingeplande datum</span>
                    <span *ngIf="!!planningItem?.assigned_calendar_item">
                        {{ planningItem.assigned_calendar_item.date_time_start | dateFormatOnlyDate }}
                    </span>
                </p>
                <p style="margin: 0">
                    <span class="lighterHeader">Ingepland / uitgevoerd bij</span>
                    <span *ngIf="!!planningItem?.assigned_calendar_item">
                        {{ planningItem.assigned_calendar_item.in_calendar_of_user.first_name }}
                    </span>
                </p>
                <p style="margin: 0">
                    <span class="lighterHeader">Laatste onderhoud datum</span>
                    <span *ngIf="!!planningItem?.last_completed_planning_item_maintenance?.assigned_calendar_item">
                        {{
                            planningItem.last_completed_planning_item_maintenance.assigned_calendar_item
                                ?.date_time_start | dateFormatOnlyDate
                        }}
                    </span>
                </p>
            </div>
        </div>
    </div>

    <div class="card-content detail-content" *ngIf="planningItem.client">
        <div class="row3">
            <div class="card">
                <div class="title">
                    <div class="grid-item">
                        <p class="bold" *ngIf="planningItem.client.state === ClientState.client">Klant details</p>
                        <p class="bold" *ngIf="planningItem.client.state === ClientState.lead">Lead details</p>
                    </div>
                    <div class="grid-item btn-edit">
                        <div
                            class="grid-item btn-edit"
                            *ngIf="
                                planningItem.planning_item_type === PlanningItemType.lead &&
                                planningItem.client.state === ClientState.lead &&
                                (planningItem.state === PlanningState.toBePlanned ||
                                    planningItem.state === PlanningState.protest)
                            "
                        >
                            <button
                                class="btn btn-sm btn-link btn-edit"
                                (click)="editModeLeadDetailsClicked()"
                                *ngIf="!this.editModeLeadDetails"
                            >
                                <cds-icon shape="pencil"></cds-icon>
                                edit
                            </button>
                            <button
                                class="btn btn-sm btn-link btn-edit"
                                (click)="saveClicked()"
                                *ngIf="this.editModeLeadDetails"
                            >
                                <cds-icon shape="floppy"></cds-icon>
                                save
                            </button>
                            <button
                                class="btn btn-sm btn-link btn-edit"
                                (click)="cancelEditLeadDetails()"
                                *ngIf="this.editModeLeadDetails"
                            >
                                <cds-icon shape="times"></cds-icon>
                                cancel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-content card-content-2">
                    <div class="grid-item">
                        <p class="sub-title">Klant contact</p>
                        <div class="info-row">
                            <div class="info-content lighter">Klant nummer:</div>
                            <div class="info-content">
                                <a class="pointer" (click)="goToClientDetail(planningItem.client.client_id)">
                                    {{
                                        planningItem.client.exact_client_number
                                            ? planningItem.client.exact_client_number
                                            : 'ClientID-' + planningItem.client.client_id
                                    }}
                                </a>
                            </div>
                        </div>
                        <div class="info-row">
                            <div class="info-content lighter">Naam</div>
                            <div>
                                <label required="required" type="text" id="name" *ngIf="!this.editModeLeadDetails">
                                    {{
                                        planningItem.client.client_data.first_name +
                                            ' ' +
                                            planningItem.client.client_data.last_name
                                    }}
                                </label>
                                <div *ngIf="this.editModeLeadDetails" class="row">
                                    <input
                                        name="first_name_edit"
                                        type="text"
                                        id="firstNameEdit"
                                        [(ngModel)]="planningItem.client.client_data.first_name"
                                        class="half-length-screen"
                                        label="first_name"
                                    />
                                    <input
                                        name="last_name_edit"
                                        type="text"
                                        label="last_name"
                                        id="lastNameEdit"
                                        [(ngModel)]="planningItem.client.client_data.last_name"
                                        class="half-length-screen"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="info-row">
                            <div class="info-content lighter">Email</div>
                            <div>
                                <label required="required" type="text" id="email" *ngIf="!this.editModeLeadDetails">
                                    {{
                                        planningItem.client.client_data.email
                                            ? planningItem.client.client_data.email
                                            : ''
                                    }}
                                </label>
                                <div *ngIf="this.editModeLeadDetails" class="row">
                                    <input
                                        name="email_edit"
                                        type="text"
                                        label="email"
                                        id="emailEdit"
                                        [(ngModel)]="planningItem.client.client_data.email"
                                        class="full-length-screen"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="info-row">
                            <div class="info-content lighter">Gsm nummer</div>
                            <div class="info-content">
                                <label
                                    required="required"
                                    type="text"
                                    id="_mobile_phone"
                                    *ngIf="!this.editModeLeadDetails"
                                >
                                    {{
                                        planningItem.client.client_data.mobile_phone
                                            ? planningItem.client.client_data.mobile_phone
                                            : ''
                                    }}
                                </label>
                                <div *ngIf="this.editModeLeadDetails" class="row">
                                    <input
                                        name="mobile_phone_edit"
                                        type="text"
                                        id="mobilePhoneEdit"
                                        [(ngModel)]="planningItem.client.client_data.mobile_phone"
                                        class="half-length-screen"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="info-row">
                            <div class="info-content lighter">Telefoon</div>
                            <div>
                                <label required="required" type="text" id="phone" *ngIf="!this.editModeLeadDetails">
                                    {{
                                        planningItem.client.client_data.telephone_phone
                                            ? planningItem.client.client_data.telephone_phone
                                            : ''
                                    }}
                                </label>
                                <div *ngIf="this.editModeLeadDetails" class="row">
                                    <input
                                        name="telephone_phone_edit"
                                        type="text"
                                        id="telephonePhoneEdit"
                                        [(ngModel)]="planningItem.client.client_data.telephone_phone"
                                        class="half-length-screen"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid-item">
                        <p class="sub-title">Hoofd address</p>
                        <div class="info-row">
                            <div class="info-content lighter">Straat</div>
                            <div>
                                <label required="required" type="text" id="adress_1" *ngIf="!this.editModeLeadDetails">
                                    {{
                                        planningItem.client.client_data.main_address.address
                                            ? planningItem.client.client_data.main_address.address
                                            : ''
                                    }}
                                </label>
                                <div *ngIf="this.editModeLeadDetails" class="row">
                                    <input
                                        name="address_edit"
                                        type="text"
                                        id="addressEdit"
                                        [(ngModel)]="planningItem.client.client_data.main_address.address"
                                        class="full-length-screen"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="info-row">
                            <div class="info-content lighter">Postcode</div>
                            <div>
                                <label required="required" type="text" id="postcode" *ngIf="!this.editModeLeadDetails">
                                    {{
                                        planningItem.client.client_data.main_address.zip_code
                                            ? planningItem.client.client_data.main_address.zip_code
                                            : ''
                                    }}
                                </label>
                                <div *ngIf="this.editModeLeadDetails" class="row">
                                    <input
                                        name="zip_code_edit"
                                        type="text"
                                        id="zipCodeEdit"
                                        [(ngModel)]="planningItem.client.client_data.main_address.zip_code"
                                        class="full-length-screen"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="info-row">
                            <div class="info-content lighter">Gemeente</div>
                            <div class="info-row">
                                <div *ngIf="!this.editModeLeadDetails">
                                    <label required="required" type="text" id="city">
                                        {{
                                            planningItem.client.client_data.main_address.city
                                                ? planningItem.client.client_data.main_address.city
                                                : ''
                                        }}
                                    </label>
                                    <label required="required" type="text" id="state"> </label>
                                </div>

                                <div *ngIf="this.editModeLeadDetails" class="row">
                                    <input
                                        name="city"
                                        type="text"
                                        id="city_edit"
                                        [(ngModel)]="planningItem.client.client_data.main_address.city"
                                        class="full-length-screen"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="info-row">
                            <div class="info-content lighter">Land</div>
                            <div>
                                <label required="required" type="text" id="country" *ngIf="!this.editModeLeadDetails">
                                    {{
                                        planningItem.client.client_data.main_address.country
                                            ? planningItem.client.client_data.main_address.country
                                            : ''
                                    }}
                                </label>
                                <div *ngIf="this.editModeLeadDetails" class="row">
                                    <input
                                        name="country_edit"
                                        type="text"
                                        id="country_edit"
                                        [(ngModel)]="planningItem.client.client_data.main_address.country"
                                        class="full-length-screen"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="info-row">
                            <div class="info-content lighter">Provincie</div>
                            <div class="info-content">
                                {{
                                    planningItem.client.client_data.main_address.state
                                        ? planningItem.client.client_data.main_address.state
                                        : '/'
                                }}
                                -
                                {{
                                    planningItem.client.client_data.main_address.stateName
                                        ? planningItem.client.client_data.main_address.stateName
                                        : '/'
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="title">
                    <div class="grid-item">
                        <p class="bold">Klant adres (adres regel 2)</p>
                    </div>
                    <div class="grid-item btn-edit">
                        <button class="btn btn-sm btn-link btn-edit"></button>
                    </div>
                </div>
                <div class="card-content card-content-1">
                    <div
                        class="grid-item"
                        *ngFor="
                            let deliveryAddress of planningItem.client.client_data.delivery_addresses;
                            let i = index
                        "
                    >
                        <div class="info-row">
                            <div class="info-content lighter">Straat</div>
                            <div>
                                <label
                                    required="required"
                                    type="text"
                                    id="{{ 'address' + i }}"
                                    *ngIf="!this.editModeLeadDetails"
                                >
                                    {{ deliveryAddress.address ? deliveryAddress.address : '' }}
                                </label>
                                <div *ngIf="this.editModeLeadDetails" class="row">
                                    <input
                                        name="address_edit"
                                        type="text"
                                        id="{{ 'addressEdit' + i }}"
                                        [(ngModel)]="planningItem.client.client_data.delivery_addresses[i].address"
                                        class="full-length-screen"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="info-row">
                            <div class="info-content lighter">Postcode</div>
                            <div>
                                <label
                                    required="required"
                                    type="text"
                                    id="{{ 'zip_code' + i }}"
                                    *ngIf="!this.editModeLeadDetails"
                                >
                                    {{ deliveryAddress.zip_code ? deliveryAddress.zip_code : '' }}
                                </label>
                                <div *ngIf="this.editModeLeadDetails" class="row">
                                    <input
                                        name="zip_code_edit"
                                        type="text"
                                        id="{{ 'zipCodeEdit' + i }}"
                                        [(ngModel)]="planningItem.client.client_data.delivery_addresses[i].zip_code"
                                        class="full-length-screen"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="info-row">
                            <div class="info-content lighter">Gemeente</div>
                            <div class="info-row">
                                <div *ngIf="!this.editModeLeadDetails">
                                    <label required="required" type="text" id="{{ 'city' + i }}">
                                        {{
                                            planningItem.client.client_data.main_address.city
                                                ? planningItem.client.client_data.delivery_addresses[i].city
                                                : ''
                                        }}
                                    </label>
                                    <label required="required" type="text" id="{{ 'state' + i }}"> </label>
                                </div>

                                <div *ngIf="this.editModeLeadDetails" class="row">
                                    <input
                                        name="city"
                                        type="text"
                                        id="{{ 'city_edit' + i }}"
                                        [(ngModel)]="planningItem.client.client_data.delivery_addresses[i].city"
                                        class="full-length-screen"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="info-row">
                            <div class="info-content lighter">Land</div>
                            <div>
                                <label
                                    required="required"
                                    type="text"
                                    id="{{ 'country' + i }}"
                                    *ngIf="!this.editModeLeadDetails"
                                >
                                    {{
                                        planningItem.client.client_data.delivery_addresses[i].country
                                            ? planningItem.client.client_data.delivery_addresses[i].country
                                            : ''
                                    }}
                                </label>
                                <div *ngIf="this.editModeLeadDetails" class="row">
                                    <input
                                        name="country_edit"
                                        type="text"
                                        id="{{ 'countryEdit' + i }}"
                                        [(ngModel)]="planningItem.client.client_data.delivery_addresses[i].country"
                                        class="full-length-screen"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="info-row">
                            <div class="info-content lighter">Provincie</div>
                            <div class="info-content">
                                {{
                                    planningItem.client.client_data.delivery_addresses[i].state
                                        ? planningItem.client.client_data.delivery_addresses[i].state
                                        : '/'
                                }}
                                -
                                {{
                                    planningItem.client.client_data.delivery_addresses[i].stateName
                                        ? planningItem.client.client_data.delivery_addresses[i].stateName
                                        : '/'
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row2">
            <div class="card">
                <div class="title">
                    <div class="grid-item">
                        <p class="bold">Lead details</p>
                    </div>
                </div>
                <div class="card-content card-content">
                    <div class="grid-item">
                        <div class="info-row">
                            <div class="info-content lighter">LeadID {{ configuration.companyName }}</div>
                            <div>
                                <label required="required" type="text" id="lead_id">
                                    {{ planningItem.planning_item_type_number }}
                                </label>
                            </div>
                        </div>
                        <div class="info-row">
                            <div class="info-content lighter">
                                Lead ID
                                {{ planningItem.client.lead_data ? planningItem.client.lead_data.lead_type : '' }}
                            </div>
                            <div>
                                <label required="required" type="text" id="exact_custom_id">
                                    {{
                                        planningItem.client.lead_data
                                            ? planningItem.client.lead_data.lead_custom_id
                                            : ''
                                    }}
                                </label>
                            </div>
                        </div>
                        <div class="info-row">
                            <div class="info-content lighter">Creatie Datum Lead</div>
                            <div>
                                <label
                                    required="required"
                                    type="text"
                                    id="creation_date"
                                    *ngIf="planningItem.client.lead_data && planningItem.client.lead_data.created_at"
                                >
                                    {{
                                        planningItem.client.lead_data.created_at.date
                                            ? (planningItem.client.lead_data.created_at.date | dateFormatWithTime)
                                            : ''
                                    }}
                                </label>
                            </div>
                        </div>

                        <div class="info-row">
                            <div class="info-content lighter">Type klant</div>
                            <div>
                                <label required="required" type="text" id="type">
                                    {{ planningItem.client.lead_data ? planningItem.client.lead_data.type : '' }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="title">
                    <div class="grid-item">
                        <p class="bold">Company details</p>
                    </div>
                </div>
                <div class="card-content card-content-2" *ngIf="planningItem.client.client_data.company_data">
                    <div class="grid-item">
                        <div class="info-row">
                            <div class="info-content lighter">Company naam</div>
                            <div>
                                <label
                                    required="required"
                                    type="text"
                                    id="company_name"
                                    *ngIf="!this.editModeLeadDetails"
                                >
                                    {{
                                        planningItem.client.client_data.company_data.company_name
                                            ? planningItem.client.client_data.company_data.company_name
                                            : ''
                                    }}
                                </label>
                                <div *ngIf="this.editModeLeadDetails" class="row">
                                    <input
                                        name="company_name_edit"
                                        type="text"
                                        id="companyNameEdit"
                                        [(ngModel)]="planningItem.client.client_data.company_data.company_name"
                                        class="full-length-screen"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="info-row">
                            <div class="info-content lighter">VAT nummer</div>
                            <div>
                                <label required="required" type="text" id="vat" *ngIf="!this.editModeLeadDetails">
                                    {{
                                        planningItem.client.client_data.company_data.vat_number
                                            ? planningItem.client.client_data.company_data.vat_number
                                            : ''
                                    }}
                                </label>
                                <div *ngIf="this.editModeLeadDetails" class="row">
                                    <input
                                        name="vat_number_edit"
                                        type="text"
                                        id="vatNumberEdit"
                                        [(ngModel)]="planningItem.client.client_data.company_data.vat_number"
                                        class="full-length-screen"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="true">
            <div class="card">
                <div class="title">
                    <div class="grid-item">
                        <p class="bold">Attachments</p>
                    </div>
                </div>
                <div class="card-content">
                    <div *ngIf="showAddAttachment">
                        <div class="row">
                            <input id="attachmentFile" multiple name="file_to_upload" type="file" />
                            <div>
                                <button class="btn btn-sm btn-icon" (click)="uploadFile()">Upload</button>
                                <button class="btn btn-sm btn-icon" (click)="showAddAttachment = false">Cancel</button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button
                            (click)="showAddAttachment = true"
                            *ngIf="!showAddAttachment"
                            class="btn btn-primary btn-sm"
                        >
                            <cds-icon shape="plus" title="home"></cds-icon>
                            Add
                        </button>
                    </div>

                    <div
                        *ngFor="let attachment of planningItem.attachments; let i = index"
                        class="row2"
                        style="border-top: 1px solid #ccc; padding: 10px"
                    >
                        <div class="grid-item">
                            <div
                                (click)="openPicture(attachment)"
                                class="card-img"
                                style="cursor: pointer; padding: 5px"
                            >
                                <img
                                    [attr.alt]="attachment.storage_url"
                                    [src]="attachment.storage_url | authImage | async"
                                    style="width: 75px; object-fit: cover"
                                />
                            </div>
                        </div>
                        <div class="grid-item">
                            <button
                                (click)="downloadAttachment(attachment.storage_url)"
                                class="btn btn-outline btn-sm btn-icon"
                            >
                                <cds-icon shape="download"></cds-icon>
                            </button>
                            <button
                                class="btn btn-icon btn-warning btn-sm"
                                aria-label="warning"
                                (click)="openDeleteConfirmationModal(attachment)"
                            >
                                <cds-icon shape="trash" title="home"></cds-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="card">
                <div class="title">
                    <div class="grid-item">
                        <p class="bold">Remarks Lead</p>
                    </div>
                    <div
                        class="grid-item btn-edit"
                        *ngIf="
                            planningItem.planning_item_type === PlanningItemType.lead &&
                            planningItem.client.state === ClientState.lead &&
                            (planningItem.state === PlanningState.toBePlanned ||
                                planningItem.state === PlanningState.protest)
                        "
                    >
                        <button
                            class="btn btn-sm btn-link btn-edit"
                            (click)="editModeLeadRemarksClicked()"
                            *ngIf="!this.editModeLeadRemarks"
                        >
                            <cds-icon shape="pencil"></cds-icon>
                            edit
                        </button>
                        <button
                            class="btn btn-sm btn-link btn-edit"
                            (click)="saveLeadRemarksClicked()"
                            *ngIf="this.editModeLeadRemarks"
                        >
                            <cds-icon shape="floppy"></cds-icon>
                            save
                        </button>
                        <button
                            class="btn btn-sm btn-link btn-edit"
                            (click)="cancelEditLeadRemarks()"
                            *ngIf="this.editModeLeadRemarks"
                        >
                            <cds-icon shape="times"></cds-icon>
                            cancel
                        </button>
                    </div>
                </div>
                <div class="card-content">
                    <div class="grid-item">
                        <label
                            required="required"
                            type="text"
                            id="remarksLead"
                            *ngIf="!this.editModeLeadRemarks && planningItem.client.lead_data"
                        >
                            {{
                                planningItem.client.lead_data.description
                                    ? planningItem.client.lead_data.description
                                    : ''
                            }}
                            <br />
                            {{ planningItem.client.lead_data.detail ? planningItem.client.lead_data.detail : '' }}
                        </label>
                        <div *ngIf="this.editModeLeadRemarks" class="row">
                            <clr-textarea-container class="no-margin full-length-screen">
                                <textarea
                                    clrTextarea
                                    [(ngModel)]="planningItem.client.lead_data.description"
                                    name="description"
                                    class="textarea-custom"
                                    placeholder="beschrijving toevoegen"
                                >
                                </textarea>
                            </clr-textarea-container>
                            <clr-textarea-container class="no-margin">
                                <textarea
                                    clrTextarea
                                    [(ngModel)]="planningItem.client.lead_data.detail"
                                    name="description"
                                    class="textarea-custom"
                                    placeholder="beschrijving toevoegen"
                                >
                                </textarea>
                            </clr-textarea-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            *ngIf="
                planningItem.planning_item_type === PlanningItemType.lead ||
                planningItem.data.created_from_lead_planning_item_id ||
                planningItem.planning_item_type === PlanningItemType.placement
            "
        >
            <div class="card">
                <div class="title">
                    <div class="grid-item">
                        <p class="bold">Offerte info</p>
                    </div>
                </div>

                <div class="card-content" *ngIf="quotesForLead">
                    <div class="grid-item">
                        <div *ngIf="quotesForLead.records.length > 0">
                            <div *ngFor="let quote of quotesForLead.records" class="card-content-3 card-content">
                                <div class="grid-item">
                                    <div class="info-row info-content">
                                        <div class="info-content" style="font-weight: 600">Status</div>
                                        <div *ngIf="quote.status === QuoteStatus.created" class="maintenance-completed">
                                            <span class="label label-warning">Created</span>
                                        </div>
                                        <div
                                            *ngIf="quote.status === QuoteStatus.approved"
                                            class="maintenance-completed"
                                        >
                                            <span class="label label-success">Approved</span>
                                        </div>
                                    </div>
                                    <div class="info-row info-content">
                                        <div class="info-content" style="font-weight: 600">Aangemaakt bij Lead:</div>
                                        <div *ngIf="quote.lead_id">
                                            <button
                                                class="btn btn-link btn-sm btn-icon"
                                                (click)="goToPlanningItemByLeadId(quote.lead_id)"
                                                style="margin: 0"
                                            >
                                                {{
                                                    'LEAD-' +
                                                        (quote.planning_item_lead
                                                            ? quote.planning_item_lead.planning_item_type_number
                                                            : '-')
                                                }}
                                            </button>
                                        </div>
                                        <div *ngIf="!quote.lead_id">/</div>
                                    </div>
                                    <div class="info-row info-content">
                                        <div class="info-content" style="font-weight: 600">Aangemaakt door:</div>
                                        <div *ngIf="quote.created_by_user">
                                            <label>{{
                                                quote.created_by_user.first_name + ' ' + quote.created_by_user.last_name
                                            }}</label>
                                        </div>
                                    </div>
                                    <div class="info-row info-content">
                                        <div class="info-content" style="font-weight: 600">Verkocht door:</div>
                                        <div *ngIf="quote.sold_by_user">
                                            <label>{{
                                                quote.sold_by_user.first_name + ' ' + quote.sold_by_user.last_name
                                            }}</label>
                                        </div>
                                    </div>
                                    <div class="info-row">
                                        <div class="info-content lighter">Offerte nummer</div>
                                        <div>
                                            <label required="required" type="text" id="quote_id">
                                                {{ quote.quote_id }}
                                            </label>
                                            <button
                                                class="btn btn-link btn-sm btn-icon"
                                                (click)="downloadQuote(quote)"
                                                style="margin: 0"
                                            >
                                                <cds-icon shape="download"></cds-icon>
                                                (pdf)
                                            </button>
                                        </div>
                                    </div>
                                    <div class="info-row">
                                        <div class="info-content lighter">Creatie Datum</div>
                                        <div>
                                            <label required="required" type="text" id="quote_creation_date">
                                                {{ quote.creation_date | dateFormatWithTime }}
                                            </label>
                                        </div>
                                    </div>

                                    <div id="oldQuote" *ngIf="quote.data.quote_create">
                                        <p class="sub-title">Type Toestel</p>
                                        <div class="info-row">
                                            <div class="info-content lighter">Klep</div>
                                            <div>
                                                <label required="required" type="text" id="klep">
                                                    {{
                                                        quote.data.quote_create.machineFiche.klep
                                                            ? quote.data.quote_create.machineFiche.klep
                                                            : '/'
                                                    }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="info-row">
                                            <div class="info-content lighter">bypass</div>
                                            <div>
                                                <label required="required" type="text" id="bypass">
                                                    {{
                                                        quote.data.quote_create.machineFiche.bypass
                                                            ? quote.data.quote_create.machineFiche.bypass
                                                            : '/'
                                                    }}
                                                </label>
                                            </div>
                                        </div>

                                        <div class="info-row">
                                            <div class="info-content lighter">Aantal liters</div>
                                            <div>
                                                <label required="required" type="text" id="aantal-liters">
                                                    {{
                                                        quote.data.quote_create.machineFiche.n_liters
                                                            ? quote.data.quote_create.machineFiche.n_liters
                                                            : '/'
                                                    }}
                                                </label>
                                            </div>
                                        </div>

                                        <div class="info-row">
                                            <div class="info-content lighter">Type Harsen</div>
                                            <div>
                                                <label required="required" type="text" id="type-harsen">
                                                    {{
                                                        quote.data.quote_create.machineFiche.type_harsen
                                                            ? quote.data.quote_create.machineFiche.type_harsen
                                                            : '/'
                                                    }}
                                                </label>
                                            </div>
                                        </div>

                                        <div class="info-row">
                                            <div class="info-content lighter">Voorfliter</div>
                                            <div>
                                                <label required="required" type="text" id="voorfilter">
                                                    {{
                                                        quote.data.quote_create.machineFiche.filter
                                                            ? quote.data.quote_create.machineFiche.filter
                                                            : '/'
                                                    }}
                                                </label>
                                            </div>
                                        </div>

                                        <div class="info-row">
                                            <div class="info-content lighter">KDF filter</div>
                                            <div>
                                                <label required="required" type="text" id="kfd-filter">
                                                    {{
                                                        quote.data.quote_create.machineFiche.kdf_filters
                                                            ? quote.data.quote_create.machineFiche.kdf_filters
                                                            : '/'
                                                    }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="info-row">
                                            <div class="info-content lighter">Drukregelaar</div>
                                            <div>
                                                <label required="required" type="text" id="drukregelaar">
                                                    {{
                                                        quote.data.quote_create.machineFiche.drukregelaar
                                                            ? quote.data.quote_create.machineFiche.drukregelaar
                                                            : '/'
                                                    }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="info-row">
                                            <div class="info-content lighter">Vlotter</div>
                                            <div>
                                                <label required="required" type="text" id="vlotter">
                                                    {{
                                                        quote.data.quote_create.machineFiche.vlotter
                                                            ? quote.data.quote_create.machineFiche.vlotter
                                                            : '/'
                                                    }}
                                                </label>
                                            </div>
                                        </div>

                                        <div class="info-row">
                                            <div class="info-content lighter">Plaatsing kost</div>
                                            <div>
                                                <label required="required" type="text" id="placement-cost">
                                                    {{ quote.placement_cost ? quote.placement_cost : '/' }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="info-row">
                                            <div class="info-content lighter">Zout</div>
                                            <div>
                                                <label required="required" type="text" id="zout">
                                                    {{
                                                        quote.data.quote_create.machineFiche.zout
                                                            ? quote.data.quote_create.machineFiche.zout
                                                            : '/'
                                                    }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="info-row">
                                            <div class="info-content lighter">Subtotal</div>
                                            <div>
                                                <label required="required" type="text" id="subtotal">
                                                    {{ quote.data.quote_create.machineFiche.subtotal }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="info-row">
                                            <div class="info-content lighter">btw</div>
                                            <div>
                                                <label required="required" type="text" id="btw">
                                                    {{ quote.data.quote_create.machineFiche.btw }}
                                                </label>
                                            </div>
                                        </div>

                                        <div class="info-row">
                                            <div class="info-content lighter">Total price</div>
                                            <div>
                                                <label required="required" type="text" id="total-price">
                                                    {{ quote.data.quote_create.machineFiche.total }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="info-row">
                                            <div class="info-content lighter">Voorkeur plaatsing</div>
                                            <div>
                                                <label required="required" type="text" id="placement_date">
                                                    {{
                                                        quote.data.quote_create.machineFiche.voorkeurs_plaatsing_datum
                                                            | dateFormatOnlyDate
                                                    }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="quote.quote_lines">
                                        <p class="sub-title">Products</p>
                                        <table class="table table-compact">
                                            <thead>
                                                <tr>
                                                    <th class="left">Product nummer</th>
                                                    <th class="left">Beschrijving</th>
                                                    <th class="left">Aantal</th>
                                                    <th class="left">Stuk prijs</th>
                                                    <th class="left">Totaal</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let quote_line of quote.quote_lines">
                                                    <td class="left">{{ quote_line.product.product_number }}</td>
                                                    <td class="left">{{ quote_line.product.name }}</td>
                                                    <td class="left">{{ quote_line.quantity }}</td>
                                                    <td class="left">{{ quote_line.unit_price }}</td>
                                                    <td class="left">{{ quote_line.final_price_to_pay }}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div
                                            class="info-row info-content"
                                            *ngIf="configuration.quotes.placementCostEnabled"
                                        >
                                            <div class="info-content" style="font-weight: 600">Plaatsing kost</div>
                                            <div>
                                                <label>{{ quote.placement_cost ? quote.placement_cost : '0' }}</label>
                                            </div>
                                        </div>
                                        <div class="info-row info-content">
                                            <div class="info-content" style="font-weight: 600">Korting</div>
                                            <div>
                                                <label>{{
                                                    '(' && quote.data.discount_percentage * 100
                                                        ? quote.data.discount_percentage
                                                        : '0' && '%)' - quote.data.discount_amount
                                                        ? quote.data.discount_amount
                                                        : '0' && '€'
                                                }}</label>
                                            </div>
                                        </div>
                                        <div class="info-row info-content">
                                            <div class="info-content" style="font-weight: 600">Btw</div>
                                            <div>
                                                <label>{{
                                                    '(' && quote.data.btw_percentage * 100
                                                        ? quote.data.btw_percentage
                                                        : '0' && '%)' - quote.data.btw_amount
                                                        ? quote.data.btw_amount
                                                        : '0' && '€'
                                                }}</label>
                                            </div>
                                        </div>
                                        <div class="info-row info-content">
                                            <div class="info-content" style="font-weight: 600">Totaal</div>
                                            <div>
                                                <label>{{ quote.final_price_to_pay }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="grid-item" *ngIf="quote.data.quote_create">
                                        <p class="sub-title">Technische analyse</p>
                                        <div class="info-row">
                                            <div class="info-content lighter">Analyse °F</div>
                                            <div>
                                                <label required="required" type="text" id="analyse-f">
                                                    {{ quote.data.quote_create.technicalFiche?.analyse_f ?? '/' }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="info-row">
                                            <div class="info-content lighter">Leidingen</div>
                                            <div>
                                                <label required="required" type="text" id="leidingen">
                                                    {{ quote.data.quote_create.technicalFiche?.leidingen ?? '/' }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="info-row">
                                            <div class="info-content lighter">Keer klep</div>
                                            <div>
                                                <label required="required" type="text" id="keer-klep">
                                                    {{ quote.data.quote_create.technicalFiche?.keer_klep ?? '/' }}
                                                </label>
                                            </div>
                                        </div>

                                        <div class="info-row">
                                            <div class="info-content lighter">StopContact</div>
                                            <div>
                                                <label required="required" type="text" id="stop-contact">
                                                    {{ quote.data.quote_create.technicalFiche?.stopcontact ?? '/' }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="info-row">
                                            <div class="info-content lighter">Afvoer</div>
                                            <div>
                                                <label required="required" type="text" id="afvoer">
                                                    {{ quote.data.quote_create.technicalFiche?.afvoer ?? '/' }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="grid-item" *ngIf="!quote.data.quote_create">
                                        <p class="sub-title">Technische analyse</p>
                                        <div class="info-row">
                                            <div class="info-content lighter">Analyse °F</div>
                                            <div>
                                                <label required="required" type="text" id="analyse-f2">
                                                    {{ quote.data.technicalFiche?.analyse_f ?? '/' }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="info-row">
                                            <div class="info-content lighter">Leidingen</div>
                                            <div>
                                                <label required="required" type="text" id="leidingen2">
                                                    {{ quote.data.technicalFiche?.leidingen ?? '/' }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="info-row">
                                            <div class="info-content lighter">Keer klep</div>
                                            <div>
                                                <label required="required" type="text" id="keer-klep2">
                                                    {{ quote.data.technicalFiche?.keer_klep ?? '/' }}
                                                </label>
                                            </div>
                                        </div>

                                        <div class="info-row">
                                            <div class="info-content lighter">StopContact</div>
                                            <div>
                                                <label required="required" type="text" id="stop-contact2">
                                                    {{ quote.data.technicalFiche?.stopcontact ?? '/' }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="info-row">
                                            <div class="info-content lighter">Afvoer</div>
                                            <div>
                                                <label required="required" type="text" id="afvoer2">
                                                    {{ quote.data.technicalFiche?.afvoer ?? '/' }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <label
                            required="required"
                            type="text"
                            id="quote_info"
                            *ngIf="quotesForLead.records.length === 0"
                        >
                            Nog geen offertes in het systeem
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="configuration.workOrders && workOrders">
            <div class="card">
                <div class="title">
                    <div class="grid-item">
                        <p class="bold">Werkbon info</p>
                    </div>
                </div>

                <div class="card-content" *ngIf="workOrders">
                    <div class="grid-item">
                        <div *ngIf="workOrders.records.length > 0">
                            <div *ngFor="let workOrder of workOrders.records" class="card-content">
                                <div class="grid-item">
                                    <div class="info-row info-content">
                                        <div class="info-content" style="font-weight: 600">Status</div>
                                        <div
                                            *ngIf="workOrder.status === WorkOrderStatus.created"
                                            class="maintenance-completed"
                                        >
                                            <span class="label label-warning">Created</span>
                                        </div>
                                        <div
                                            *ngIf="workOrder.status === WorkOrderStatus.finished"
                                            class="maintenance-completed"
                                        >
                                            <span class="label label-success">Finished</span>
                                        </div>
                                        <div
                                            *ngIf="workOrder.status === WorkOrderStatus.cancelled"
                                            class="maintenance-completed"
                                        >
                                            <span class="label label-danger">Cancelled</span>
                                        </div>
                                    </div>
                                    <div class="info-row info-content">
                                        <div class="info-content" style="font-weight: 600">Werkbon Type:</div>
                                        <div *ngIf="workOrder.work_order_type">
                                            <label>{{ workOrder.work_order_type }}</label>
                                        </div>
                                    </div>
                                    <div class="info-row info-content">
                                        <div class="info-content" style="font-weight: 600">Aangemaakt door:</div>
                                        <div *ngIf="workOrder.created_by_user">
                                            <label>{{
                                                workOrder.created_by_user.first_name +
                                                    ' ' +
                                                    workOrder.created_by_user.last_name
                                            }}</label>
                                        </div>
                                    </div>
                                    <div class="info-row info-content">
                                        <div class="info-content" style="font-weight: 600">Technieker:</div>
                                        <div *ngIf="workOrder.work_order_technician_user">
                                            <label>{{
                                                workOrder.work_order_technician_user.first_name +
                                                    ' ' +
                                                    workOrder.work_order_technician_user.last_name
                                            }}</label>
                                        </div>
                                    </div>
                                    <div class="info-row">
                                        <div class="info-content lighter">Werkbon nummer</div>
                                        <div>
                                            <label required="required" type="text" id="work_order_id">
                                                {{ workOrder.work_order_id }}
                                            </label>
                                            <button
                                                class="btn btn-link btn-sm btn-icon"
                                                (click)="downloadWorkOrder(workOrder.work_order_id)"
                                                style="margin: 0"
                                            >
                                                <cds-icon shape="download"></cds-icon>
                                                (pdf)
                                            </button>
                                        </div>
                                    </div>
                                    <div class="info-row">
                                        <div class="info-content lighter">Creatie Datum</div>
                                        <div>
                                            <label required="required" type="text" id="work_order_creation_date">
                                                {{ workOrder.creation_date | dateFormatWithTime }}
                                            </label>
                                        </div>
                                    </div>

                                    <div *ngIf="workOrder.work_order_lines.length">
                                        <p class="sub-title">Products</p>
                                        <table class="table table-compact" style="max-width: 600px">
                                            <thead>
                                                <tr>
                                                    <th class="left">Product nummer</th>
                                                    <th class="left">Beschrijving</th>
                                                    <th class="left">Aantal</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let work_order_line of workOrder.work_order_lines">
                                                    <td class="left">{{ work_order_line.product.product_number }}</td>
                                                    <td class="left">{{ work_order_line.product.name }}</td>
                                                    <td class="left">{{ work_order_line.quantity }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div *ngIf="workOrder.data.work_time_registration.length">
                                        <p class="sub-title">Werk registratie</p>
                                        <table class="table table-compact" style="max-width: 600px">
                                            <thead>
                                                <tr>
                                                    <th class="left">Start</th>
                                                    <th class="left">Eind</th>
                                                    <th class="left">Opmerking</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let registration of workOrder.data.work_time_registration">
                                                    <td class="left">
                                                        {{ registration.start_date | dateFormatWithTime }}
                                                    </td>
                                                    <td class="left">
                                                        {{ registration.end_date | dateFormatWithTime }}
                                                    </td>
                                                    <td class="left">{{ registration.description_work }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <label
                            required="required"
                            type="text"
                            id="work_order_info"
                            *ngIf="workOrders.records.length === 0"
                        >
                            Nog geen werkbonnen in het systeem
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="row1"
            *ngIf="
                planningItem.planning_item_type === PlanningItemType.delivery ||
                planningItem.planning_item_type === PlanningItemType.placement ||
                planningItem.planning_item_type === PlanningItemType.repair
            "
        >
            <div class="card">
                <div class="title">
                    <div class="grid-item">
                        <p class="bold">
                            {{ planningItem.planning_item_type | getNameOfPlanningItemTypePipe : 'nl' }} opmerkingen
                        </p>

                    </div>
                    <div class="grid-item btn-edit">
                    <div
                        class="grid-item btn-edit"

                    >
                        <button
                            class="btn btn-sm btn-link btn-edit"
                            (click)="editModeDescriptionPlanningItemClicked()"
                            *ngIf="!this.editModeDescriptionPlanningItem"
                        >
                            <cds-icon shape="pencil"></cds-icon>
                            edit
                        </button>
                        <button
                            class="btn btn-sm btn-link btn-edit"
                            (click)="saveDescriptionPlanningItemClicked()"
                            *ngIf="this.editModeDescriptionPlanningItem"
                        >
                            <cds-icon shape="floppy"></cds-icon>
                            save
                        </button>
                        <button
                            class="btn btn-sm btn-link btn-edit"
                            (click)="cancelEditDescriptionPlanningItem()"
                            *ngIf="this.editModeDescriptionPlanningItem"
                        >
                            <cds-icon shape="times"></cds-icon>
                            cancel
                        </button>
                    </div>
                </div>
                </div>
                <div class="card-content">
                    <div class="grid-item" *ngIf="!this.editModeDescriptionPlanningItem" >
                        <label required="required" type="text" id="remarks">
                            {{ planningItem.data.description ? planningItem.data.description : '-' }}
                        </label>
                    </div>
                    <div *ngIf="this.editModeDescriptionPlanningItem" class="row">
                        <clr-textarea-container class="no-margin full-length-screen">
                                <textarea
                                    clrTextarea
                                    [(ngModel)]="planningItem.data.description"
                                    name="description"
                                    class="textarea-custom"
                                    placeholder="beschrijving toevoegen"
                                >
                                </textarea>
                        </clr-textarea-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="row2">
            <pr-time-flow
                style="margin-top: 25px"
                *ngIf="historyOfClient && historyOfClient.length"
                [items]="historyOfClient"
                (goToPlanningItem)="goToPlanningItem($event)"
            ></pr-time-flow>

            <div class="card">
                <div class="title">
                    <div class="grid-item">
                        <p class="bold">Klant info</p>
                    </div>
                    <div class="grid-item btn-edit">
<!--                        <button class="btn btn-sm btn-link btn-edit">-->
<!--                            &lt;!&ndash; <cds-icon shape="pencil"></cds-icon>&ndash;&gt;-->
<!--                        </button>-->
                    </div>
                </div>
                <div class="card-content card-content-1">
                    <div class="grid-item">
                        <div class="info-row">
                            <div class="info-content lighter">Opmerkingen</div>
                            <div class="info-content">{{ planningItem.client.client_data.remarks }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <pr-planning-item-finish-modal
        [planningItem]="planningItem"
        [visible]="finishManualModal"
        (cancel)="cancelManualFinish()"
        (createPlanningItemReport)="finishManualPlanningItem($event)"
    >
    </pr-planning-item-finish-modal>

    <!--reports will need to be the calender_items as we included the reports in that object -->
    <pr-activity-section
        [planningItem]="planningItem"
        [comments]="planningItem.comments"
        [reports]="planningItem.visit_reports"
        [activities]="planningItem.activities"
        (refreshComments)="getNewComments(planningItem)"
    >
    </pr-activity-section>

    <pr-picture-modal
        (close)="showPictureDialog = false"
        [fileName]="pictureAttachment ? pictureAttachment.storage_url : null"
        [visible]="showPictureDialog"
    >
    </pr-picture-modal>

    <pr-delete-modal
        (ok)="deleteAttachment()"
        (cancel)="showDeleteConfirmationModal = false"
        [visible]="showDeleteConfirmationModal"
    >
        Are you sure you want to delete the attachment?
    </pr-delete-modal>
</div>
