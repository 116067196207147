import { Orientation, Step, StepConnector, StepIconProps, StepLabel, Stepper as BaseStepper } from '@mui/material';
import React from 'react';
import { PhrosprThemeProvider } from '../../theme';
import './Stepper.scss';
import * as Style from './Stepper.style';

export interface StepperProps<T> {
    steps: T[];
    orientation?: Orientation;
    onRenderLabel: (step: T) => JSX.Element;
    onRenderIcon?: (step: T) => { element: JSX.Element; style?: IconStyle };
}

export interface IconStyle {
    color?: React.CSSProperties['color'];
    backgroundColor?: React.CSSProperties['backgroundColor'];
    borderColor?: React.CSSProperties['borderColor'];
}

export function Stepper<T>({ steps, orientation = 'vertical', onRenderLabel, onRenderIcon }: StepperProps<T>) {
    function StepIcon(props: StepIconProps, step: T) {
        const icon = onRenderIcon?.(step);
        return (
            <Style.StepIcon className={props.className} style={icon?.style}>
                {icon?.element}
            </Style.StepIcon>
        );
    }

    return (
        <PhrosprThemeProvider>
            <BaseStepper
                activeStep={-1}
                orientation={orientation}
                connector={<Style.Connector withConnectors={onRenderIcon !== undefined} />}
            >
                {steps.map((step, i) => (
                    <Step key={i}>
                        <StepLabel StepIconComponent={props => StepIcon(props, step)}>
                            {onRenderLabel?.(step)}
                        </StepLabel>
                    </Step>
                ))}
            </BaseStepper>
        </PhrosprThemeProvider>
    );
}

export default Stepper;
