export enum CalendarItemColumns {
    calendar_item_id = 'calendar_item_id',
    appointment_type = 'appointment_type',
    date_time_start = 'date_time_start',
    date_time_stop = 'date_time_stop',
    created_by_user_id = 'created_by_user_id',
    active = 'active',
    in_calendar_of_user_id = 'in_calendar_of_user_id',
    data = 'data',
    planning_item_id = 'planning_item_id',
}

export interface CalendarItemAttributes {
    calendar_item_id: number;
    appointment_type: string | null;
    date_time_start: Date | null;
    date_time_stop: Date | null;
    created_by_user_id: number | null;
    active: boolean | null;
    in_calendar_of_user_id: number | null;
    data: any | null;
    planning_item_id: number | null;
}

export type CalendarItemOptionalAttributes =
    | 'calendar_item_id'
    | 'appointment_type'
    | 'date_time_start'
    | 'date_time_stop'
    | 'created_by_user_id'
    | 'active'
    | 'in_calendar_of_user_id'
    | 'data'
    | 'planning_item_id';
export interface CalendarItemCreate {
    calendar_item_id?: number;
    appointment_type?: string | null;
    date_time_start?: Date | null;
    date_time_stop?: Date | null;
    created_by_user_id?: number | null;
    active?: boolean | null;
    in_calendar_of_user_id?: number | null;
    data?: any | null;
    planning_item_id?: number | null;
}
