export enum PlanningItemCommentColumns {
    comment_id = 'comment_id',
    planning_item_id = 'planning_item_id',
}

export interface PlanningItemCommentAttributes {
    comment_id: number;
    planning_item_id: number;
}

export type PlanningItemCommentCreate = PlanningItemCommentAttributes;
