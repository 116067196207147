import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateFormatOnlyDate',
})
export class DateFormatOnlyDatePipe implements PipeTransform {
    transform(value: string | Date, format?: string) {
        return (format ? moment(value, format) : moment(value)).format('DD/MM/YYYY');
    }
}
