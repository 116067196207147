export enum CommentColumns {
    comment_id = 'comment_id',
    creation_date = 'creation_date',
    created_by_user_id = 'created_by_user_id',
    data = 'data',
}

export interface CommentAttributes {
    comment_id: number;
    creation_date: Date | null;
    created_by_user_id: number | null;
    data: any | null;
}

export type CommentOptionalAttributes = 'comment_id' | 'creation_date' | 'created_by_user_id' | 'data';
export interface CommentCreate {
    comment_id?: number;
    creation_date?: Date | null;
    created_by_user_id?: number | null;
    data?: any | null;
}
