"use strict";
/* tslint:disable */
/* eslint-disable */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadExternalApi = exports.LeadExternalApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
// @ts-ignore
const models_1 = require("../models");
/**
 * LeadExternalApi - axios parameter creator
 * @export
 */
const LeadExternalApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {ILeadAquagroupSiteBody} iLeadAquagroupSiteBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAquagroupSite: (iLeadAquagroupSiteBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iLeadAquagroupSiteBody' is not null or undefined
            (0, common_1.assertParamExists)('createAquagroupSite', 'iLeadAquagroupSiteBody', iLeadAquagroupSiteBody);
            const localVarPath = `/api/lead/aquagroup-site`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iLeadAquagroupSiteBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {BobexLeadBody} bobexLeadBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBobexLead: (bobexLeadBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'bobexLeadBody' is not null or undefined
            (0, common_1.assertParamExists)('createBobexLead', 'bobexLeadBody', bobexLeadBody);
            const localVarPath = `/api/lead/bobex`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(bobexLeadBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {SolvariLeadBody} solvariLeadBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSolvariLead: (solvariLeadBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'solvariLeadBody' is not null or undefined
            (0, common_1.assertParamExists)('createSolvariLead', 'solvariLeadBody', solvariLeadBody);
            const localVarPath = `/api/solvari/lead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(solvariLeadBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.LeadExternalApiAxiosParamCreator = LeadExternalApiAxiosParamCreator;
/**
 * LeadExternalApi -
 */
var LeadExternalApi;
(function (LeadExternalApi) {
    /**
     *
     * @param {ILeadAquagroupSiteBody} iLeadAquagroupSiteBody
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof LeadExternalApi
     * url /api/lead/aquagroup-site
     */
    function createAquagroupSite(iLeadAquagroupSiteBody, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.LeadExternalApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.createAquagroupSite(iLeadAquagroupSiteBody, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_1.parseInlineResponse2002)(res.data);
                return res;
            });
        });
    }
    LeadExternalApi.createAquagroupSite = createAquagroupSite;
    /**
     *
     * @param {BobexLeadBody} bobexLeadBody
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof LeadExternalApi
     * url /api/lead/bobex
     */
    function createBobexLead(bobexLeadBody, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.LeadExternalApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.createBobexLead(bobexLeadBody, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_1.parseInlineResponse2002)(res.data);
                return res;
            });
        });
    }
    LeadExternalApi.createBobexLead = createBobexLead;
    /**
     *
     * @param {SolvariLeadBody} solvariLeadBody
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof LeadExternalApi
     * url /api/solvari/lead
     */
    function createSolvariLead(solvariLeadBody, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.LeadExternalApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.createSolvariLead(solvariLeadBody, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_1.parseInlineResponse2002)(res.data);
                return res;
            });
        });
    }
    LeadExternalApi.createSolvariLead = createSolvariLead;
})(LeadExternalApi = exports.LeadExternalApi || (exports.LeadExternalApi = {}));
