import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LeadManager } from '../../modules/shared/manager/lead.manager';
import * as toastr from 'toastr';
import { getNamePlanningItemType, newClientEntity, newPlanningItem } from '@phrospr/lib-core';
import { IClientEntity, IPlanningItemEntity, PlanningItemType, PlanningState } from '@phrospr/lib-models';
import { LeadApi, PlanningItemApi } from '@phrospr/api-backend';
import { configuration$ } from '@phrospr/lib-web-core';

@Component({
    selector: 'pr-create-modal',
    templateUrl: './create-modal.component.html',
    styleUrls: ['./create-modal.component.scss'],
})
export class CreateModalComponent implements OnInit {
    creationTypes: { key: PlanningItemType; value: string }[] = [];
    selectedType: PlanningItemType | undefined;
    PlanningItemType = PlanningItemType;

    planningItemToAdd: IPlanningItemEntity = newPlanningItem();
    leadToAdd: IClientEntity = newClientEntity(true);

    loading = false;

    @Input() visible;

    @Output() ok = new EventEmitter<any>();
    @Output() cancel = new EventEmitter<any>();

    constructor(private leadManager: LeadManager) {}

    async ngOnInit(): Promise<void> {
        const { planningItemTypes } = await configuration$;
        this.creationTypes = planningItemTypes
            .filter(x => x !== PlanningItemType.maintenance)
            .map(x => ({ key: x, value: getNamePlanningItemType(x) }));
        this.selectedType = this.creationTypes[0]?.key;
    }

    sendCreate() {
        // todo This is a dirty fix that still needs to be changed
        this.loading = true;
        if (this.selectedType === PlanningItemType.lead) {
            if (
                !this.leadToAdd ||
                !this.leadToAdd.lead_data.lead_type ||
                !this.leadToAdd.client_data.first_name ||
                !this.leadToAdd.client_data.last_name ||
                !this.leadToAdd.lead_data.description ||
                !this.leadToAdd.client_data.main_address.address ||
                !this.leadToAdd.client_data.main_address.zip_code ||
                !this.leadToAdd.client_data.main_address.city
            ) {
                toastr.error(
                    'Volgende velden zijn verplicht: Leadtype, Voornaam, Achternaam, omschrijving, adres, postcode, stad',
                );
                this.loading = false;
            } else {
                this.leadToAdd.lead_data.created_at.date = new Date();
                LeadApi.createManualLead(this.leadToAdd).then(_ => {
                    this.ok.emit();
                    this.clearAndHideModal();
                });
            }
        } else if (
            this.selectedType === PlanningItemType.delivery ||
            this.selectedType === PlanningItemType.repair ||
            this.selectedType === PlanningItemType.placement
        ) {
            if (
                !this.planningItemToAdd ||
                !this.planningItemToAdd.data.description ||
                !this.planningItemToAdd.client_id ||
                !this.planningItemToAdd.data.suggested_date
            ) {
                toastr.error('Volgende velden zijn verplicht: Klant, leverdatum, omschrijving');
                this.loading = false;
            } else {
                this.planningItemToAdd.planning_item_type = this.selectedType;
                this.planningItemToAdd.state = PlanningState.toBePlanned;
                PlanningItemApi.createPlanningItem({ planningItem: this.planningItemToAdd }).then(_ => {
                    this.ok.emit();
                    this.clearAndHideModal();
                });
            }
        } else {
            toastr.error('Dit is nog niet inbegrepen');
            this.loading = false;
        }
    }

    sendCancel() {
        this.clearAndHideModal();
        this.cancel.emit();
    }

    setLeadObject(lead: IClientEntity) {
        if (this.selectedType === PlanningItemType.lead) {
            this.leadToAdd = lead;
        }
    }

    setPlanningObject(planningItem: IPlanningItemEntity) {
        if (
            this.selectedType === PlanningItemType.delivery ||
            this.selectedType === PlanningItemType.repair ||
            this.selectedType === PlanningItemType.placement
        ) {
            this.planningItemToAdd = planningItem;
        }
    }

    async clearAndHideModal() {
        const { planningItemTypes } = await configuration$;
        this.creationTypes = planningItemTypes
            .filter(x => x !== PlanningItemType.maintenance)
            .map(x => ({ key: x, value: getNamePlanningItemType(x) }));
        this.selectedType = this.creationTypes[0]?.key;
        this.planningItemToAdd = newPlanningItem();
        this.leadToAdd = null;
        this.visible = false;
        this.loading = false;
    }
}
