import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '../../../../environments/environment';
import { IClientEntity } from '@phrospr/api-backend';
import { Observable } from 'rxjs';

@Injectable()
export class LeadService {
    constructor(private http: HttpClient) {}

    public updateLead(lead: IClientEntity, planning_item_id: number, viewed?: boolean): Observable<any> {
        const params: any = { lead_id: lead.client_id };
        if (viewed) {
            params.viewed = true;
        }
        return this.http.post(`${BASE_URL}api/lead/update`, { lead, planning_item_id }, { params });
    }

    public getNewLeadCount(): Promise<number> {
        return this.http.get(`${BASE_URL}api/lead/count-new`).toPromise() as Promise<number>;
    }
}
