import { Component, Input, OnInit } from '@angular/core';
import {
    IPlanningItemEntity,
    IPlanningItemSearchEntity,
    IPlanningItemSearchFilterEntity,
    IPlanningItemSearchShowEntity,
    PlanningItemApi,
    PlanningItemType,
    PlanningState,
    RequestSort,
} from '@phrospr/api-backend';
import { PlanningItemService } from '../../../shared/services/planning-item.service';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-planning-item-list-container',
    styleUrls: ['./planning-item-list.container.scss'],
    templateUrl: './planning-item-list.container.html',
})
export class PlanningItemListContainer implements OnInit {
    PlanningItemType = PlanningItemType;
    PlanningState = PlanningState;

    @Input() planningItemShow: IPlanningItemSearchShowEntity;
    @Input() planningItemFilter: IPlanningItemSearchFilterEntity;
    @Input() planningItemFilterUpdate$: ReplaySubject<void>;

    planningItemsSearch: IPlanningItemSearchEntity;

    planningItems: IPlanningItemEntity[] = [];
    totalPlanningItems = 0;

    loading = false;

    pageSizes = [25, 50, 100, 150];
    currentPageSize = 25;
    currentPageIndex = 0;
    maxPages = 1;

    constructor(private planningItemService: PlanningItemService) {}

    ngOnInit(): void {
        if (!this.planningItemShow || !this.planningItemFilter) {
            return console.log('missing planningItemsFilter');
        }
        if (!this.planningItemFilter.planning_item_type) return console.log('missing planning item type');
        if (!this.planningItemFilter.state) return console.log('missing planning item state');

        this.planningItemFilterUpdate$.subscribe(() => {
            this.currentPageIndex = 0;
            this.maxPages = 1;
            this.setPage(0);
        });
        this.setPage(0);
    }

    async loadPlanningItemList() {
        this.planningItems = [];
        this.loading = true;

        try {
            const { data } = await PlanningItemApi.listPlanningItems({
                planning_item_filter: this.planningItemsSearch,
            });
            this.totalPlanningItems = data.total;

            this.maxPages = Math.ceil(this.totalPlanningItems / this.currentPageSize);
            this.planningItems = data.records;

            for (let i = 0; i < this.planningItems.length; i++) {
                if (this.planningItems[i].comments)
                    this.planningItems[i].comments.sort((a, b) => Number(a.creation_date) - Number(b.creation_date));
            }
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    updatePageSize(pageSize: Event) {
        this.setPage(0);
    }

    async setPage(pageIndex: number) {
        if (pageIndex < 0) return;
        if (pageIndex > this.maxPages - 1) return console.log('page to high', pageIndex, this.maxPages);
        this.currentPageIndex = pageIndex;

        this.planningItemsSearch = {
            limit: this.currentPageSize,
            offset: this.currentPageSize * this.currentPageIndex,
            filter: this.planningItemFilter,
            show: this.planningItemShow,
            sort: this.getSortForPlanningItemType(),
        };

        await this.loadPlanningItemList();
    }

    getSortForPlanningItemType(): { order: RequestSort; column: string } {
        if (this.planningItemFilter.planning_item_type === PlanningItemType.lead) {
            return { order: RequestSort.DESC, column: 'leadCreationDate' };
        } else {
            return { order: RequestSort.ASC, column: 'suggestedDate' };
        }
    }
}
