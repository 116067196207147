import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

@Component({
    selector: 'pr-maintenance',
    styleUrls: ['./maintenance-page.component.scss', '../../../../app.component.css'],
    templateUrl: './maintenance-page.component.html',
})
export class MaintenancePageComponent implements OnInit {
    toBePlannedTab = false;
    plannedTab = false;
    completedTab = false;
    allTab = false;
    inactiveTab = false;
    invoicedTab = false;

    constructor(private route: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.route.queryParams
            .pipe(
                tap(params => {
                    if (!params.tab) {
                        this.setTab('toBePlanned');
                    }
                }),
                filter(params => params.tab),
            )
            .subscribe(params => {
                this.disableTabs();
                if (params.tab === 'toBePlanned') {
                    return (this.toBePlannedTab = true);
                }
                if (params.tab === 'plannedTab') {
                    return (this.plannedTab = true);
                }
                if (params.tab === 'completedTab') {
                    return (this.completedTab = true);
                }
                if (params.tab === 'allTab') {
                    return (this.allTab = true);
                }
                if (params.tab === 'inactiveTab') {
                    return (this.inactiveTab = true);
                }
                if (params.tab === 'invoicedTab') {
                    return (this.invoicedTab = true);
                }
                return this.toBePlannedTab;
            });
    }

    setTab(tab: string) {
        const queryParams: any = this.route.queryParams;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { ...queryParams._value, tab: tab, offset: 1 },
            queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }

    disableTabs() {
        this.toBePlannedTab = false;
        this.plannedTab = false;
        this.completedTab = false;
        this.allTab = false;
        this.inactiveTab = false;
        this.inactiveTab = false;
    }
}
