import { Pipe, PipeTransform } from '@angular/core';
import { ActivityType } from '@phrospr/api-backend';

@Pipe({
    name: 'parseActivityType',
})
export class ParseActivityType implements PipeTransform {
    transform(activityType: ActivityType) {
        if (activityType === ActivityType.PLANNED) return 'Ingepland';
        if (activityType === ActivityType.PLANNED_CONFIRMED) return 'Planning afgewerkt';
        if (activityType === ActivityType.QUOTE_CREATED) return 'Offerte aangemaakt';
        if (activityType === ActivityType.QUOTE_UPDATED) return 'Offerte aangepast';
        if (activityType === ActivityType.PROTEST_LEAD) return 'Lead protest';
        if (activityType === ActivityType.LEAD_VIEWED) return 'Lead bekeken';
        if (activityType === ActivityType.ADD_COMMENT) return 'Opmerking toegevoegd';
        if (activityType === ActivityType.MANUAL_FINISHED) return 'Manueel afgewerkt';
        return activityType;
    }
}
