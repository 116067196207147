export enum ActivityColumns {
    activity_id = 'activity_id',
    activity_type = 'activity_type',
    object_id = 'object_id',
    date_time = 'date_time',
    activity_name = 'activity_name',
    user_id = 'user_id',
    activity_data = 'activity_data',
    creation_date = 'creation_date',
}

export interface ActivityAttributes {
    activity_id: number;
    activity_type: string | null;
    object_id: number | null;
    date_time: Date | null;
    activity_name: string | null;
    user_id: number | null;
    activity_data: any | null;
    creation_date: Date | null;
}

export type ActivityOptionalAttributes =
    | 'activity_id'
    | 'activity_type'
    | 'object_id'
    | 'date_time'
    | 'activity_name'
    | 'user_id'
    | 'activity_data'
    | 'creation_date';
export interface ActivityCreate {
    activity_id?: number;
    activity_type?: string | null;
    object_id?: number | null;
    date_time?: Date | null;
    activity_name?: string | null;
    user_id?: number | null;
    activity_data?: any | null;
    creation_date?: Date | null;
}
