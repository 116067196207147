import { Button, DialogActions, FormControl, Grid, Input, InputAdornment, TextareaAutosize } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IProductEntity, IQuoteLineEntity } from '@phrospr/api-backend';
import React from 'react';
import toastr from 'toastr';
import ProductSearch from '../ProductSearch/ProductSearch';
import './ProductAdd.scss';
import { ProductAddProps, ProductAddState } from './ProductAdd.types';
import { IQuoteLineCreateEntity } from '@phrospr/lib-models';

export class ProductAdd extends React.Component<ProductAddProps, ProductAddState> {
    constructor(props: ProductAddProps) {
        super(props);
        this.state = {
            open: this.props.showDialogAddProduct,
            quoteLine: {
                product_id: null,
                quantity: 1,
                unit_price: 0,
                unit: null,
                final_price_to_pay: 0,
                data: null,
                quote_line_id: undefined,
                discount_price: undefined,
                product_document_free_entry: undefined,
                vat_tarif: undefined,
            },
            selectedProduct: null,
            quantity: null,
            description: null,
            unitPrice: null,
        };
    }

    cancel() {
        this.setState({ open: false });
        if (this.props.dialogClosed) this.props.dialogClosed();
        console.log(this.state);
    }

    setProduct(product: IProductEntity) {
        if (!product) return;
        console.log('product set');
        this.setState({
            selectedProduct: product,
            quoteLine: {
                product_id: product.product_id ?? -1,
                quantity: 1,
                unit_price: product.pricing?.price_per_unit,
                final_price_to_pay: product.pricing?.price_per_unit,
                product: product,
                unit: product.pricing.unit_type,
                data: {},
                product_document_free_entry: null,
            },
            quantity: 1,
            unitPrice: product.pricing?.price_per_unit,
            description: product.specifications.description ?? null,
        });
    }

    upsertProduct() {
        console.log(this.state.quoteLine);
        if (!this.state.quoteLine.product_id || typeof this.state.unitPrice !== 'number' || !this.state.quantity) {
            toastr.error('Volgende velden zijn verplicht: product, eenheid prijs, aantal en totaal');
            return;
        }
        const newQuote: IQuoteLineCreateEntity = {
            ...this.state.quoteLine,
            quantity: this.state.quantity,
            unit_price: this.state.unitPrice,
            final_price_to_pay: this.state.quantity * this.state.unitPrice,
        };
        this.setState({
            ...this.state,
            quoteLine: newQuote,
        });
        this.props.upsertQuoteLine(newQuote);
    }

    render() {
        return (
            <div>
                <Dialog open={this.state.open} onClose={this.cancel} maxWidth={'md'}>
                    <DialogTitle>Add product</DialogTitle>
                    <DialogContent>
                        <Grid
                            container
                            spacing={3}
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                        >
                            <Grid item xs={4}>
                                <div>Select product</div>
                            </Grid>
                            <Grid item xs={8}>
                                <ProductSearch onProductChange={product => this.setProduct(product)}></ProductSearch>
                            </Grid>
                            <Grid item xs={4}>
                                <div>Beschrijving</div>
                            </Grid>
                            <Grid item xs={8}>
                                <TextareaAutosize
                                    aria-label={'minimum height'}
                                    minRows={4}
                                    placeholder={'Type u opmerkingen of notities voor de offerte hier.'}
                                    style={{ width: 400 }}
                                    defaultValue={this.state.description ?? ''}
                                    onChange={event => this.setState({ description: event.target.value })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <div>Aantal</div>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ m: 1, width: '80px' }} variant={'outlined'}>
                                    <Input
                                        size={'small'}
                                        id={'outlined-adornment-weight'}
                                        endAdornment={<InputAdornment position={'end'}> </InputAdornment>}
                                        aria-describedby={'outlined-weight-helper-text'}
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                        type={'number'}
                                        value={this.state.quantity}
                                        onChange={event => this.setState({ quantity: parseFloat(event.target.value) })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <div>Stuk prijs</div>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ m: 1, width: '80px' }} variant={'outlined'}>
                                    <Input
                                        size={'small'}
                                        id={'outlined-adornment-weight'}
                                        endAdornment={<InputAdornment position={'end'}>€</InputAdornment>}
                                        aria-describedby={'outlined-weight-helper-text'}
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                        type={'number'}
                                        value={this.state.unitPrice}
                                        onChange={event => this.setState({ unitPrice: parseFloat(event.target.value) })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <div>Totaal</div>
                            </Grid>
                            <Grid item xs={8}>
                                <label>
                                    {this.state.unitPrice === null || this.state.quantity === null
                                        ? '/'
                                        : this.state.unitPrice * this.state.quantity}
                                </label>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.cancel()}>Cancel</Button>
                        <Button onClick={() => this.upsertProduct()}>Add</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ProductAdd;
