<div class="card">
    <div>
        <div
            class="card-content"
            [class.card-content-4]="planningItem.planning_item_type === PlanningItemType.maintenance"
            [class.card-content-5]="planningItem.planning_item_type != PlanningItemType.maintenance"
        >
            <div class="hover">
                <div class="planning-item-row">
                    <div (click)="goToPlanningItemDetail()" class="bold btn btn-link btn-icon">
                        {{ planningItem.planning_item_type | getNameOfPlanningItemTypePipe : 'nl' }}
                        {{ planningItem.planning_item_type_number }}
                    </div>
                    <div class="planning-item-img" (click)="goToPlanningItemDetail()">
                        <pr-planning-item-type-image
                            [planningItemType]="planningItem.planning_item_type"
                            [leadType]="
                                planningItem.client && planningItem.client.lead_data
                                    ? planningItem.client.lead_data.lead_type
                                    : ''
                            "
                            [big]="true"
                        >
                        </pr-planning-item-type-image>
                    </div>
                    <div class="planning-item-plan">
                        <button (click)="planPlanningItem()" class="btn btn-outline btn-sm btn-menu">
                            Plan {{ planningItem.planning_item_type | getNameOfPlanningItemTypePipe : 'nl' }}
                        </button>
                    </div>
                </div>
            </div>

            <div
                *ngIf="
                    planningItem.planning_item_type === PlanningItemType.lead &&
                    planningItem.client &&
                    planningItem.client.lead_data
                "
            >
                <p class="sub-title">Lead info</p>
                <div class="info-row">
                    <div class="info-content lighter">
                        Lead nr.
                        {{
                            planningItem && planningItem.client.lead_data
                                ? (planningItem.client.lead_data.lead_type | getNameOfLeadTypePipe)
                                : '/'
                        }}
                    </div>
                    <div class="info-content">{{ planningItem.client.lead_data.lead_custom_id }}</div>
                </div>
                <div class="info-row">
                    <div class="info-content lighter">Lead Datum</div>
                    <div class="info-content">
                        {{
                            planningItem.client.lead_data.created_at && planningItem.client.lead_data.created_at.date
                                ? (planningItem.client.lead_data.created_at.date | dateFormatWithTime)
                                : '/'
                        }}
                    </div>
                </div>
                <div class="info-row">
                    <div class="info-content lighter">Concurrenten</div>
                    <div class="info-content">{{ planningItem.client.lead_data.competitors }}</div>
                </div>
                <div class="info-row">
                    <div class="info-content lighter">Op welk moment</div>
                    <div class="info-content">Zo rap mogelijk</div>
                </div>
            </div>

            <div>
                <p class="sub-title">Klant info</p>
                <div *ngIf="planningItem.client && planningItem.client.client_data">
                    <div class="info-row">
                        <div class="info-content lighter">Klant nr.</div>
                        <div class="info-content">
                            <a class="pointer" (click)="goToClientDetail(planningItem.client.client_id)">
                                {{ planningItem.client.exact_client_number }}
                            </a>
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Naam</div>
                        <div class="info-content">
                            {{
                                planningItem.client.client_data.first_name +
                                    ' ' +
                                    planningItem.client.client_data.last_name
                            }}
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Hoofd adres</div>
                        <div class="info-content" *ngIf="planningItem.client.client_data.main_address">
                            {{
                                planningItem.client.client_data
                                    ? planningItem.client.client_data.main_address.address
                                    : ''
                            }}
                            -
                            {{
                                planningItem.client.client_data
                                    ? planningItem.client.client_data.main_address.zip_code
                                    : ''
                            }}
                            {{
                                planningItem.client.client_data ? planningItem.client.client_data.main_address.city : ''
                            }}
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Tel nr.</div>
                        <div class="info-content">
                            {{
                                planningItem.client.client_data.telephone_phone
                                    ? planningItem.client.client_data.telephone_phone + ' - '
                                    : ''
                            }}
                            {{
                                planningItem.client.client_data.mobile_phone
                                    ? planningItem.client.client_data.mobile_phone
                                    : ''
                            }}
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Email</div>
                        <div class="info-content">
                            <a
                                [href]="'mailto:' + planningItem.client.client_data.email"
                                *ngIf="planningItem.client && planningItem.client.client_data"
                            >
                                {{ planningItem.client ? planningItem.client.client_data.email : '' }}
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="
                        planningItem.client &&
                        planningItem.client.lead_data &&
                        planningItem.planning_item_type === PlanningItemType.lead
                    "
                >
                    <clr-tooltip
                        *ngIf="
                            planningItem.client.lead_data.lead_doubles &&
                            (planningItem.client.lead_data.lead_doubles.name_double ||
                                planningItem.client.lead_data.lead_doubles.address_double)
                        "
                    >
                        <div role="tooltip" aria-haspopup="true" clrTooltipTrigger>
                            <cds-icon
                                class="alert-icon"
                                shape="exclamation-triangle"
                                style="color: red; margin-top: 5px; margin-bottom: 5px"
                            ></cds-icon>
                        </div>
                        <div>
                            <clr-tooltip-content
                                clrPosition="top-right"
                                clrSize="lg"
                                *clrIfOpen
                                style="max-height: 300px"
                            >
                                <span
                                    *ngIf="
                                        planningItem.client.lead_data.lead_doubles &&
                                        planningItem.client.lead_data.lead_doubles.name_double
                                    "
                                >
                                    De naam van deze lead komt meerdere keren voor
                                    <br />
                                </span>
                                <span
                                    *ngIf="
                                        planningItem.client.lead_data.lead_doubles &&
                                        planningItem.client.lead_data.lead_doubles.address_double
                                    "
                                >
                                    Het adres van deze lead komt meerdere keren voor
                                    <br />
                                </span>
                                <span> Planning items waar deze lead ook in voorkomt is/zijn: </span>
                                <span *ngFor="let lead of planningItem.client.lead_data.lead_doubles.lead_list">
                                    <b
                                        (click)="goToPlanningItemDetail(lead.planning_item_type_number)"
                                        class="bold btn btn-link btn-icon"
                                    >
                                        {{
                                            lead.planning_item_type_number ? lead.planning_item_type_number + ', ' : ''
                                        }}
                                    </b>
                                </span>
                            </clr-tooltip-content>
                        </div>
                    </clr-tooltip>
                </div>
                <div *ngIf="planningItem.planning_item_type !== PlanningItemType.lead && !planningItem.client">
                    Geen klant informatie
                </div>
            </div>

            <div *ngIf="planningItem.planning_item_type === PlanningItemType.maintenance">
                <p class="sub-title">Onderhoud info</p>
                <div class="info-row">
                    <div class="info-content lighter">Laatste onderhoud</div>
                    <div *ngIf="planningItem.last_completed_planning_item" class="info-content">
                        {{
                            planningItem?.last_completed_planning_item?.assigned_calendar_item?.date_time_start
                                | dateFormatOnlyDate
                        }}
                    </div>
                </div>
                <div class="info-row">
                    <div class="info-content lighter">Voorgesteld onderhoud</div>
                    <div *ngIf="!showEditSuggestedMaintenance" class="info-content">
                        {{ planningItem.data.suggested_date | dateFormatOnlyDate }}
                        <button
                            (click)="showEditSuggestedMaintenance = !showEditSuggestedMaintenance"
                            class="btn btn-sm btn-link btn-edit"
                        >
                            <cds-icon shape="pencil"></cds-icon>
                        </button>
                    </div>
                    <div *ngIf="showEditSuggestedMaintenance" class="info-content" id="suggested-maintenance-date">
                        <input [(clrDate)]="editSuggestedMaintenanceDateValue" clrDate type="date" />
                        <div class="btn-group btn-sm">
                            <button (click)="editSuggestedPlanningItemDate()" class="btn btn-primary btn-sm btn-icon">
                                <cds-icon shape="check"></cds-icon>
                            </button>
                            <button
                                (click)="showEditSuggestedMaintenance = false"
                                class="btn btn-outline btn-sm btn-icon"
                            >
                                <cds-icon shape="times"></cds-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div
                *ngIf="
                    planningItem.planning_item_type === PlanningItemType.delivery ||
                    planningItem.planning_item_type === PlanningItemType.repair ||
                    planningItem.planning_item_type === PlanningItemType.placement
                "
            >
                <p class="sub-title">
                    {{ planningItem.planning_item_type | getNameOfPlanningItemTypePipe : 'nl' }} info
                </p>

                <div class="info-row">
                    <div class="info-content lighter">Levering Datum</div>
                    <div class="info-content">
                        {{
                            planningItem.data.suggested_date
                                ? (planningItem.data.suggested_date | dateFormatOnlyDate)
                                : '-'
                        }}
                    </div>
                </div>
                <div class="info-row">
                    <div class="info-content lighter">Uitvoerings adres</div>
                    <div class="info-content">/</div>
                </div>
                <ng-container *ngIf="planningItem.last_approved_quote_slim">
                    <p class="sub-title">Offerte Info</p>

                    <div class="info-row">
                        <div class="info-content lighter">Rating</div>
                        <div
                            class="info-content"
                            *ngIf="
                                planningItem.last_approved_quote_slim.data &&
                                planningItem.last_approved_quote_slim.data.rating
                            "
                        >
                            <ngb-rating
                                [readonly]="true"
                                [rate]="planningItem.last_approved_quote_slim.data.rating.value"
                                [max]="planningItem.last_approved_quote_slim.data.rating.max"
                                style="color: orange"
                            ></ngb-rating>
                        </div>
                        <div
                            class="info-content"
                            *ngIf="
                                !(
                                    planningItem.last_approved_quote_slim.data &&
                                    planningItem.last_approved_quote_slim.data.rating
                                )
                            "
                        >
                            /
                        </div>
                    </div>
                </ng-container>
            </div>

            <div
                *ngIf="
                    planningItem.planning_item_type === PlanningItemType.delivery ||
                    planningItem.planning_item_type === PlanningItemType.repair ||
                    planningItem.planning_item_type === PlanningItemType.placement
                "
            >
                <p class="sub-title">Beschrijving</p>
                <div class="text-overflow">
                    <label name="description" class="textarea-custom">
                        {{ planningItem.data.description }}
                    </label>
                </div>
            </div>

            <div
                *ngIf="
                    planningItem.planning_item_type === PlanningItemType.lead &&
                    planningItem.client &&
                    planningItem.client.lead_data
                "
            >
                <clr-tooltip style="display: flex; height: 100%; flex-flow: column">
                    <p class="sub-title" style="flex: 0 0 24px">Beschrijving</p>
                    <div
                        class="text-overflow"
                        role="tooltip"
                        aria-haspopup="true"
                        clrTooltipTrigger
                        style="flex: 1 0 100px"
                    >
                        <label name="description" class="textarea-custom">
                            {{ planningItem.client.lead_data.description }}
                            <br />
                            {{ planningItem.client.lead_data.detail }}
                        </label>
                    </div>
                    <div *ngIf="planningItem.client.lead_data.description || planningItem.client.lead_data.detail">
                        <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                            <span>
                                {{ planningItem.client.lead_data.description }}
                                <br />
                                {{ planningItem.client.lead_data.detail }}
                            </span>
                        </clr-tooltip-content>
                    </div>
                </clr-tooltip>
            </div>

            <div>
                <pr-comment-section-small
                    [planningItem]="planningItem"
                    [comments]="planningItem.comments ? planningItem.comments : []"
                    (refresh)="getNewComments(planningItem)"
                    (createComment)="getNewComments(planningItem)"
                >
                </pr-comment-section-small>
            </div>
        </div>
    </div>
</div>
