<div>
    <div class="card-content-search card-content search-content">
        <div class="search-item grid-item" *ngIf="createdDatePicker">
            <input
                (ngModelChange)="triggerDateFilter(filterKeys.FROM_CREATED_DATE, $event)"
                [(ngModel)]="fromCreatedDateTrigger"
                clrDate
                placeholder="From"
                type="date"
            />
            <input
                (ngModelChange)="triggerDateFilter(filterKeys.TO_CREATED_DATE, $event)"
                [(ngModel)]="toCreatedDateTrigger"
                clrDate
                placeholder="To"
                type="date"
            />
        </div>

        <div class="search-item grid-item filter-text" *ngIf="createdByName">
            <div>
                <input
                    (ngModelChange)="triggerFilter(filterKeys.CREATED_BY_USER_NAME, $event)"
                    [(ngModel)]="createdByUserNameTrigger"
                    id="created_by_user_name"
                    placeholder="Gebruiker"
                    style="width: 200px"
                    type="text"
                />
            </div>
        </div>
        <div class="search-item grid-item filter-text">
            <div>
                <mat-form-field appearance="fill" id="activity_types_select">
                    <mat-label>Activiteiten</mat-label>
                    <mat-select
                        (valueChange)="triggerFilter(filterKeys.ACTIVITY_TYPES, $event)"
                        [(value)]="activityTypeTrigger"
                        multiple
                    >
                        <mat-option *ngFor="let activityType of activityTypes" [value]="activityType">{{
                            activityType | parseActivityType
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="search-item grid-item">
            <div>
                <clr-checkbox-container style="margin-top: 0">
                    <clr-checkbox-wrapper style="margin: 0px">
                        <input
                            type="checkbox"
                            clrCheckbox
                            (ngModelChange)="triggerFilter(filterKeys.IGNORE_DEVELOPMENT, $event)"
                            [(ngModel)]="ignoreDevelopmentTrigger"
                            name="QuoteStatus"
                        />
                        <label>Verberg Development activities</label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
            </div>
        </div>

        <div class="search-item grid-item">
            <button (click)="resetFilters()" class="btn btn-link btn-sm">
                <cds-icon shape="times"></cds-icon>
                Clear filters
            </button>
        </div>
    </div>
</div>
