import React from 'react';
import { QuoteSearchFilterProps, QuoteSearchFilterState } from './QuoteSearchFilter.types';
import './QuoteSearchFilter.scss';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { QuoteStatus } from '@phrospr/lib-models';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export class QuoteSearchFilter extends React.Component<QuoteSearchFilterProps, QuoteSearchFilterState> {
    constructor(props: QuoteSearchFilterProps) {
        super(props);
        this.state = {
            quoteSearchFilter: {
                quote_states: [QuoteStatus.created, QuoteStatus.cancelled, QuoteStatus.approved],
            },
        };
        this.props.onChange(this.state.quoteSearchFilter);
    }

    updateSearch = async () => {
        this.props.onChange(this.state.quoteSearchFilter);
    };

    resetFilters = async () => {
        await this.setState({
            quoteSearchFilter: {
                quote_states: [QuoteStatus.created, QuoteStatus.cancelled, QuoteStatus.approved],
            },
        });
        return this.updateSearch();
    };

    render() {
        return (
            <div>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid spacing={2} container direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item xs>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={'From'}
                                    value={this.state.quoteSearchFilter.from_created_date}
                                    onChange={event =>
                                        this.setState(
                                            {
                                                quoteSearchFilter: {
                                                    ...this.state.quoteSearchFilter,
                                                    from_created_date: event,
                                                },
                                            },
                                            () => this.updateSearch(),
                                        )
                                    }
                                    renderInput={params => <TextField size="small" {...params} />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={'to'}
                                    value={this.state.quoteSearchFilter.to_created_date}
                                    onChange={event =>
                                        this.setState(
                                            {
                                                quoteSearchFilter: {
                                                    ...this.state.quoteSearchFilter,
                                                    to_created_date: event,
                                                },
                                            },
                                            () => this.updateSearch(),
                                        )
                                    }
                                    renderInput={params => <TextField size="small" {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                type="number"
                                size="small"
                                id="work-order-id-search"
                                label="Zoek op ID"
                                variant="standard"
                                value={this.state.quoteSearchFilter.quote_id ?? ''}
                                onChange={event =>
                                    this.setState(
                                        {
                                            quoteSearchFilter: {
                                                ...this.state.quoteSearchFilter,
                                                quote_id: event.target.value,
                                            },
                                        },
                                        () => this.updateSearch(),
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                size="small"
                                id="name-search"
                                label="Zoek op naam"
                                variant="standard"
                                value={this.state.quoteSearchFilter.lead_name ?? ''}
                                onChange={event =>
                                    this.setState(
                                        {
                                            quoteSearchFilter: {
                                                ...this.state.quoteSearchFilter,
                                                lead_name: event.target.value,
                                            },
                                        },
                                        () => this.updateSearch(),
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                size="small"
                                id="zip-code-search"
                                label="Zoek op postcode"
                                variant="standard"
                                value={this.state.quoteSearchFilter.postcode ?? ''}
                                onChange={event =>
                                    this.setState(
                                        {
                                            quoteSearchFilter: {
                                                ...this.state.quoteSearchFilter,
                                                postcode: event.target.value,
                                            },
                                        },
                                        () => this.updateSearch(),
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                size="small"
                                id="sold-by-search"
                                label="Zoek op verkoper"
                                variant="standard"
                                value={this.state.quoteSearchFilter.sold_by_user_name ?? ''}
                                onChange={event =>
                                    this.setState(
                                        {
                                            quoteSearchFilter: {
                                                ...this.state.quoteSearchFilter,
                                                sold_by_user_name: event.target.value,
                                            },
                                        },
                                        () => this.updateSearch(),
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs>
                            <FormControl sx={{ m: 1, width: 180 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
                                <Select
                                    size="small"
                                    labelId="status-checkbox"
                                    id="status-multiple-checkbox"
                                    multiple
                                    label={'status'}
                                    value={this.state.quoteSearchFilter.quote_states}
                                    onChange={event =>
                                        this.setState(
                                            {
                                                quoteSearchFilter: {
                                                    ...this.state.quoteSearchFilter,
                                                    quote_states: event.target.value as QuoteStatus[],
                                                },
                                            },
                                            () => this.updateSearch(),
                                        )
                                    }
                                    input={<OutlinedInput label="status" />}
                                    renderValue={(selected: string[]) => `${selected.length} geselecteerd`}
                                    MenuProps={MenuProps}
                                >
                                    {Object.keys(QuoteStatus).map(status => (
                                        <MenuItem key={status} value={QuoteStatus[status]}>
                                            <Checkbox
                                                checked={this.state.quoteSearchFilter.quote_states.includes(
                                                    QuoteStatus[status],
                                                )}
                                            />
                                            <ListItemText primary={QuoteStatus[status]} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid>
                            <Button size={'small'} startIcon={<CloseIcon />} onClick={() => this.resetFilters()}>
                                Reset filter
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    }
}

export default QuoteSearchFilter;
