"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIQuoteDataEntity = void 0;
const iaddress_1 = require("./iaddress");
const iquote_create_entity_1 = require("./iquote-create-entity");
const iquote_data_rating_1 = require("./iquote-data-rating");
const iquote_data_technical_form_entity_1 = require("./iquote-data-technical-form-entity");
function parseIQuoteDataEntity(data) {
    if (data.quote_create !== null && typeof data.quote_create === 'object')
        data.quote_create = (0, iquote_create_entity_1.parseIQuoteCreateEntity)(data.quote_create);
    if (data.technicalFiche !== null && typeof data.technicalFiche === 'object')
        data.technicalFiche = (0, iquote_data_technical_form_entity_1.parseIQuoteDataTechnicalFormEntity)(data.technicalFiche);
    if (data.rating !== null && typeof data.rating === 'object')
        data.rating = (0, iquote_data_rating_1.parseIQuoteDataRating)(data.rating);
    if (data.delivery_address !== null && typeof data.delivery_address === 'object')
        data.delivery_address = (0, iaddress_1.parseIAddress)(data.delivery_address);
    return data;
}
exports.parseIQuoteDataEntity = parseIQuoteDataEntity;
