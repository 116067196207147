import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import * as React from 'react';
import { ICommentEntity } from '@phrospr/api-backend';
import * as ReactDom from 'react-dom/client';
import { CommentAdd, ShadowDom } from '@phrospr/frontend-react';
import { REACT_CONTAINER } from '../../../../../constants';

@Component({
    selector: 'pr-comment-add',
    styleUrls: ['./comment-add.scss'],
    template: `<span #${REACT_CONTAINER}></span>`,
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class CommentAddComponent implements OnChanges, OnDestroy, AfterViewInit {
    @ViewChild(REACT_CONTAINER, { static: true }) containerRef: ElementRef;
    private component: ReactDom.Root;
    element: HTMLElement;

    @Input() public planningItemId: number;
    @Input() public quoteId: number;
    @Output() public cancelClick = new EventEmitter<void>();
    @Output() public saveCommentClick = new EventEmitter<ICommentEntity>();

    async ngAfterViewInit() {
        this.element = this.containerRef.nativeElement as HTMLElement;
        this.component = ReactDom.createRoot(this.element);
        await this.render();
    }

    async ngOnChanges(changes: SimpleChanges) {
        await this.render();
    }

    ngOnDestroy(): void {
        this.component.unmount();
    }

    cancelClickEvent = () => {
        this.cancelClick.emit();
    };

    commentAddedEvent = (comment: ICommentEntity) => {
        this.saveCommentClick.emit(comment);
    };

    private render() {
        if (!this.component) return console.log('failed to render, no component yet', typeof this);
        this.component.render(
            <ShadowDom element={this.element}>
                <CommentAdd
                    planningItemId={this.planningItemId}
                    onCancel={this.cancelClickEvent}
                    onAdd={this.commentAddedEvent}
                />
            </ShadowDom>,
        );
    }
}
