export enum PlanningItemActivityColumns {
    activity_id = 'activity_id',
    planning_item_id = 'planning_item_id',
}

export interface PlanningItemActivityAttributes {
    activity_id: number;
    planning_item_id: number;
}

export type PlanningItemActivityCreate = PlanningItemActivityAttributes;
