<div>
    <button (click)="newClient()" class="btn btn-primary">+ Nieuwe Klant</button>
    <button class="btn btn-secondary" (click)="updateAllClientsInDB()">Update all clients from Exact</button>
    <Label *ngIf="updateClientsLoading">
        <span class="spinner spinner-sm"> </span>
        Loading... Kan enkele minuten duren
    </Label>
</div>

<div>
    <div class="card-content-search card-content search-content">
        <!--<div class="search-item grid-item">
      <div>
        <input (ngModelChange)="searchClientNumberField$.next(true)" [(ngModel)]="clientNumberTrigger"
               id="client_number" type="text" placeholder="Klant nummer" style="width: 200px;">
      </div>
    </div>-->
        <div class="search-item grid-item filter-text">
            <div>
                <input
                    (ngModelChange)="searchClientNameField$.next(true)"
                    [(ngModel)]="clientNameTrigger"
                    id="name"
                    type="text"
                    placeholder="Naam of klant nummer"
                    style="width: 200px"
                />
            </div>
        </div>
        <div class="search-item grid-item">
            <div>
                <clr-checkbox-container style="margin-top: 0">
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            clrCheckbox
                            [checked]="clientStateClientChecked"
                            (ngModelChange)="checkboxClientStateClicked(ClientState.client, $event)"
                            [(ngModel)]="clientStateClientChecked"
                            name="clientState"
                        />
                        <label>Klant</label>
                    </clr-checkbox-wrapper>
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            clrCheckbox
                            (ngModelChange)="checkboxClientStateClicked(ClientState.lead, $event)"
                            [(ngModel)]="clientStateLeadChecked"
                            name="leadState"
                        />
                        <label>Lead</label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
            </div>
        </div>

        <div class="search-item grid-item">
            <button (click)="clearFilters()" class="btn btn-link btn-sm">
                <cds-icon shape="times"></cds-icon>
                Clear filters
            </button>
        </div>
    </div>
</div>

<div>
    <ngx-datatable
        class="material striped"
        [rows]="clients"
        [columns]="columns"
        [columnMode]="'force'"
        [rowHeight]="30"
        [headerHeight]="50"
        [footerHeight]="50"
        [selectionType]="'single'"
        [externalPaging]="true"
        [externalSorting]="true"
        (activate)="onActivate($event)"
        [count]="page.count"
        [offset]="page.offset"
        [limit]="page.limit"
        [sortType]="'single'"
        (page)="pageCallback($event)"
        (sort)="sortCallback($event)"
        [rowClass]="getRowClass"
        [reorderable]="reorderable"
    >
    </ngx-datatable>
</div>
