import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IPlanningItemEntity, PlanningItemApi } from '@phrospr/api-backend';

@Component({
    selector: 'pr-planning-item-history-page',
    styleUrls: ['./planning-item-history-page.container.scss'],
    templateUrl: './planning-item-history-page.container.html',
})
export class PlanningItemHistoryPageContainer implements OnInit {
    planningItemsClientHistory: IPlanningItemEntity[];

    planningItemID: number = null;

    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            if (!params.client_id) {
                return;
            }
            /*this.planningItemID = params['planning_item_id'];*/
            this.getClientHistory(params.client_id);
        });
    }

    async getClientHistory(client_id: number) {
        this.planningItemsClientHistory = (await PlanningItemApi.getPlanningItemClientHistory(client_id)).data;
    }

    goToPlanningItemDetail(planningItemID: number) {
        this.router.navigateByUrl('/planning-item-detail?planning_item_id=' + planningItemID);
    }
}
