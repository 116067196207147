export enum QuoteActivityColumns {
    activity_id = 'activity_id',
    quote_id = 'quote_id',
}

export interface QuoteActivityAttributes {
    activity_id: number;
    quote_id: number;
}

export type QuoteActivityCreate = QuoteActivityAttributes;
