"use strict";
/* tslint:disable */
/* eslint-disable */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderApi = exports.WorkOrderApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
// @ts-ignore
const models_1 = require("../models");
// @ts-ignore
const models_2 = require("../models");
/**
 * WorkOrderApi - axios parameter creator
 * @export
 */
const WorkOrderApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} workOrderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelWorkOrder: (workOrderId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workOrderId' is not null or undefined
            (0, common_1.assertParamExists)('cancelWorkOrder', 'workOrderId', workOrderId);
            const localVarPath = `/api/work-order/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (workOrderId !== undefined) {
                localVarQueryParameter['work_order_id'] = workOrderId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} workOrderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadWorkOrder: (workOrderId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workOrderId' is not null or undefined
            (0, common_1.assertParamExists)('downloadWorkOrder', 'workOrderId', workOrderId);
            const localVarPath = `/api/work-order/{workOrderId}.pdf`
                .replace(`{${"workOrderId"}}`, encodeURIComponent(String(workOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IWorkOrderEntity} iWorkOrderEntity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishWorkOrder: (iWorkOrderEntity, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iWorkOrderEntity' is not null or undefined
            (0, common_1.assertParamExists)('finishWorkOrder', 'iWorkOrderEntity', iWorkOrderEntity);
            const localVarPath = `/api/work-order/finish-work-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iWorkOrderEntity, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} workOrderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkOrder: (workOrderId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workOrderId' is not null or undefined
            (0, common_1.assertParamExists)('getWorkOrder', 'workOrderId', workOrderId);
            const localVarPath = `/api/work-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (workOrderId !== undefined) {
                localVarQueryParameter['workOrderId'] = workOrderId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {InlineObject1} inlineObject1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkOrderListPost: (inlineObject1, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'inlineObject1' is not null or undefined
            (0, common_1.assertParamExists)('getWorkOrderListPost', 'inlineObject1', inlineObject1);
            const localVarPath = `/api/work-order/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inlineObject1, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} planningItemId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkOrdersFromPlanningItem: (planningItemId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'planningItemId' is not null or undefined
            (0, common_1.assertParamExists)('getWorkOrdersFromPlanningItem', 'planningItemId', planningItemId);
            const localVarPath = `/api/work-order/work-orders-from-planning-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (planningItemId !== undefined) {
                localVarQueryParameter['planningItemId'] = planningItemId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IWorkOrderEntity} iWorkOrderEntity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceWorkOrder: (iWorkOrderEntity, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iWorkOrderEntity' is not null or undefined
            (0, common_1.assertParamExists)('invoiceWorkOrder', 'iWorkOrderEntity', iWorkOrderEntity);
            const localVarPath = `/api/work-order/invoice-work-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iWorkOrderEntity, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IWorkOrderEntity} iWorkOrderEntity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertWorkOrder: (iWorkOrderEntity, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iWorkOrderEntity' is not null or undefined
            (0, common_1.assertParamExists)('upsertWorkOrder', 'iWorkOrderEntity', iWorkOrderEntity);
            const localVarPath = `/api/work-order/upsert-work-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iWorkOrderEntity, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.WorkOrderApiAxiosParamCreator = WorkOrderApiAxiosParamCreator;
/**
 * WorkOrderApi -
 */
var WorkOrderApi;
(function (WorkOrderApi) {
    /**
     *
     * @param {number} workOrderId
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof WorkOrderApi
     * url /api/work-order/cancel
     */
    function cancelWorkOrder(workOrderId, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.WorkOrderApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.cancelWorkOrder(workOrderId, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                return res;
            });
        });
    }
    WorkOrderApi.cancelWorkOrder = cancelWorkOrder;
    /**
     *
     * @param {number} workOrderId
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof WorkOrderApi
     * url /api/work-order/{workOrderId}.pdf
     */
    function downloadWorkOrder(workOrderId, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.WorkOrderApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.downloadWorkOrder(workOrderId, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                return res;
            });
        });
    }
    WorkOrderApi.downloadWorkOrder = downloadWorkOrder;
    /**
     *
     * @param {IWorkOrderEntity} iWorkOrderEntity
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof WorkOrderApi
     * url /api/work-order/finish-work-order
     */
    function finishWorkOrder(iWorkOrderEntity, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.WorkOrderApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.finishWorkOrder(iWorkOrderEntity, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_2.parseIWorkOrderEntity)(res.data);
                return res;
            });
        });
    }
    WorkOrderApi.finishWorkOrder = finishWorkOrder;
    /**
     *
     * @param {number} workOrderId
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof WorkOrderApi
     * url /api/work-order
     */
    function getWorkOrder(workOrderId, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.WorkOrderApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.getWorkOrder(workOrderId, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_2.parseIWorkOrderEntity)(res.data);
                return res;
            });
        });
    }
    WorkOrderApi.getWorkOrder = getWorkOrder;
    /**
     *
     * @param {InlineObject1} inlineObject1
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof WorkOrderApi
     * url /api/work-order/list
     */
    function getWorkOrderListPost(inlineObject1, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.WorkOrderApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.getWorkOrderListPost(inlineObject1, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_1.parseBatchResponseIWorkOrderEntity)(res.data);
                return res;
            });
        });
    }
    WorkOrderApi.getWorkOrderListPost = getWorkOrderListPost;
    /**
     *
     * @param {number} planningItemId
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof WorkOrderApi
     * url /api/work-order/work-orders-from-planning-item
     */
    function getWorkOrdersFromPlanningItem(planningItemId, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.WorkOrderApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.getWorkOrdersFromPlanningItem(planningItemId, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = res.data.map((x) => (0, models_2.parseIWorkOrderEntity)(x));
                return res;
            });
        });
    }
    WorkOrderApi.getWorkOrdersFromPlanningItem = getWorkOrdersFromPlanningItem;
    /**
     *
     * @param {IWorkOrderEntity} iWorkOrderEntity
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof WorkOrderApi
     * url /api/work-order/invoice-work-order
     */
    function invoiceWorkOrder(iWorkOrderEntity, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.WorkOrderApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.invoiceWorkOrder(iWorkOrderEntity, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_2.parseIWorkOrderEntity)(res.data);
                return res;
            });
        });
    }
    WorkOrderApi.invoiceWorkOrder = invoiceWorkOrder;
    /**
     *
     * @param {IWorkOrderEntity} iWorkOrderEntity
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof WorkOrderApi
     * url /api/work-order/upsert-work-order
     */
    function upsertWorkOrder(iWorkOrderEntity, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.WorkOrderApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.upsertWorkOrder(iWorkOrderEntity, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_2.parseIWorkOrderEntity)(res.data);
                return res;
            });
        });
    }
    WorkOrderApi.upsertWorkOrder = upsertWorkOrder;
})(WorkOrderApi = exports.WorkOrderApi || (exports.WorkOrderApi = {}));
