export enum PlanningItemWorkOrderColumns {
    planning_item_id = 'planning_item_id',
    work_order_id = 'work_order_id',
}

export interface PlanningItemWorkOrderAttributes {
    planning_item_id: number;
    work_order_id: number;
}

export type PlanningItemWorkOrderCreate = PlanningItemWorkOrderAttributes;
