import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ROUTING } from '../../app.routing';
import { SharedModule } from '../shared/shared.module';
import { ClarityModule, ClrButtonGroupModule } from '@clr/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserModule } from '@angular/platform-browser';
import { DeliveryPageComponent } from './containers/delivery-page/delivery-page.component';
import { CreateDeliveryComponent } from './containers/create-delivery/create-delivery.component';
import { NewDeliveryPageComponent } from './containers/new-delivery-page/new-delivery-page.component';
import { PlannedDeliveryPageComponent } from './containers/planned-delivery-page/planned-delivery-page.component';
import { CompletedDeliveryPageComponent } from './containers/completed-delivery-page/completed-delivery-page.component';
import { PlanningItemModule } from '../planning-item/planning-item.module';

const components = [];

const containers = [
    DeliveryPageComponent,
    CreateDeliveryComponent,
    NewDeliveryPageComponent,
    PlannedDeliveryPageComponent,
    CompletedDeliveryPageComponent,
];

const imports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ROUTING,
    SharedModule,
    ClarityModule,
    ClrButtonGroupModule,
    NgxDatatableModule,
    BrowserModule,
    PlanningItemModule,
];

const sandboxes = [];

const services = [];

@NgModule({
    imports: [...imports],
    declarations: [...containers, ...components],
    providers: [...sandboxes, ...services],
    exports: [...containers],
})
export class DeliveryModule {}
