<div style="margin: 15px">
  <div class="row">
    <div class="icon-modal">
      <label class="clr-control-label">Lead via</label>
    </div>
    <div class="group-section">
      <div class="input-select full-length-screen">
        <div class="clr-select-wrapper full-length-screen" style="padding-right: 16px;">
          <select id="test" class="clr-select group-element no-border full-length-screen"
                  (change)="sendLeadToComponent()" [(ngModel)]="newLead.lead_data.lead_type">
            <option *ngFor="let type of LeadType | keyvalue" [ngValue]="type.value">
             {{type.key | getNameOfLeadTypePipe}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="newLead.lead_data.lead_type !== LeadType.client && newLead.lead_data.lead_type !== LeadType.manual ">
    <div class="icon-modal">
      <label class="clr-control-label">Custom leadID</label>
    </div>
    <div class="group-section">
      <div class="group-element" style="width: 95%">
        <input name="leadCustomID" type="text"
               id="leadCustomID"
               (change)="sendLeadToComponent()" [(ngModel)]="newLead.lead_data.lead_custom_id"
               class="full-length-screen no-border">
      </div>
    </div>
  </div>
  <div *ngIf="newLead.lead_data.lead_type === LeadType.client">
    <div class="icon-modal">
      <label class="clr-control-label">Via klant</label>
    </div>
    <div class="group-section">
      <div class="group-element" style="width: 95%">
        <pr-client-search-modal
          (client)="setClient($event)">
        </pr-client-search-modal>
      <!--  <input name="leadCustomID" type="text"
               id="leadCustomID"
               (change)="sendLeadToComponent()" [(ngModel)]="newLead.data.lead_custom_id"
               class="full-length-screen no-border">-->
      </div>
    </div>
  </div>
  <div>
    <div class="icon-modal">
      <label class="clr-control-label">Voornaam</label>
    </div>
    <div class="group-section">
      <div class="group-element" style="width: 95%">
        <input name="location" type="text"
               id="first_name"
               [(ngModel)]="newLead.client_data.first_name" (change)="sendLeadToComponent()"
               class="full-length-screen no-border">
      </div>
    </div>
  </div>
  <div>
    <div class="icon-modal">
      <label class="clr-control-label">Achternaam</label>
    </div>
    <div class="group-section">
      <div class="group-element" style="width: 95%">
        <input name="location" type="text"
               id="last_name"
               [(ngModel)]="newLead.client_data.last_name" (change)="sendLeadToComponent()"
               class="full-length-screen no-border">
      </div>
    </div>
  </div>
  <div>
    <div class="icon-modal">
      <label class="clr-control-label">Email</label>
    </div>
    <div class="group-section">
      <div class="group-element" style="width: 95%">
        <input name="location" type="text"
               id="emailNew"
               [(ngModel)]="newLead.client_data.email" (change)="sendLeadToComponent()"
               class="full-length-screen no-border">
      </div>
    </div>
  </div>
  <div>
    <div class="icon-modal">
      <label class="clr-control-label">Adres</label>
    </div>
    <div class="group-section">
      <div class="group-element" style="width: 95%">
        <input name="location" type="text"
               id="addressNew"
               [(ngModel)]="newLead.client_data.main_address.address" (change)="sendLeadToComponent()"
               class="full-length-screen no-border">
      </div>
    </div>
  </div>
  <div>
    <div class="icon-modal">
      <label class="clr-control-label">Stad</label>
    </div>
    <div class="group-section">
      <div class="group-element" style="width: 95%">
        <input name="location" type="text"
               id="cityNew"
               [(ngModel)]="newLead.client_data.main_address.city" (change)="sendLeadToComponent()"
               class="full-length-screen no-border">
      </div>
    </div>
  </div>
  <div>
    <div class="icon-modal">
      <label class="clr-control-label">Postcode</label>
    </div>
    <div class="group-section">
      <div class="group-element" style="width: 95%">
        <input name="location" type="text"
               id="zip_code_new"
               [(ngModel)]="newLead.client_data.main_address.zip_code" (change)="sendLeadToComponent()"
               class="full-length-screen no-border">
      </div>
    </div>
  </div>
  <div>
    <div class="icon-modal">
      <label class="clr-control-label">Gsm nr.</label>
    </div>
    <div class="group-section">
      <div class="group-element" style="width: 95%">
        <input  name="location" type="text"
                id="mobile_phone_new"
                [(ngModel)]="newLead.client_data.mobile_phone" (change)="sendLeadToComponent()"
                class="full-length-screen no-border">
      </div>
    </div>
  </div>
  <div>
    <div class="icon-modal">
      <label class="clr-control-label">Omschrijving</label>
    </div>
    <div class="group-section">
      <div class="group-element" style="width: 95%; margin-bottom: 6px;">
        <clr-textarea-container class="no-margin">
                <textarea clrTextarea
                          [(ngModel)]="newLead.lead_data.description" (change)="sendLeadToComponent()"
                          name="description" class="full-length-screen no-border textarea-custom"
                          placeholder="beschrijving toevoegen">
              </textarea>
        </clr-textarea-container>
      </div>
    </div>
  </div>
</div>
