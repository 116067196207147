import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    ActivityApi,
    IActivityEntity,
    IActivitySearchEntity,
    IActivitySearchFilterEntity,
    RequestSort,
} from '@phrospr/api-backend';

@Component({
    selector: 'pr-activity-overview-page',
    styleUrls: ['./activity-overview.component.scss'],
    templateUrl: './activity-overview.component.html',
})
export class ActivityOverviewComponent implements OnInit {
    activitySearch: IActivitySearchEntity = {
        show: {},
        sort: { order: RequestSort.DESC, column: 'activity_id' },
        filter: {
            activity_types: null,
            created_by_user_id: null,
            created_by_user_name: null,
            from_created_date: null,
            to_created_date: null,
            planning_item_type: null,
            ignore_development: true,
        },
    };

    page: {
        limit: number;
        count: number;
        offset: number;
        sort?: { order: 'ASC' | 'DESC'; column: string };
    } = {
        limit: 25,
        count: 0,
        offset: 0,
        sort: { order: 'DESC', column: 'creation_date' },
    };

    loading = false;

    activityData: IActivityEntity[] = [];

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.pageCallBack(this.page).catch(err => console.log(err));
    }

    async loadActivities(activitySearch: IActivitySearchEntity) {
        this.activityData = [];
        this.loading = true;

        try {
            const { records, total } = (await ActivityApi.getActivityList({ search_filter: activitySearch })).data;
            this.activityData = records;
            this.page.count = total;
        } catch (err) {
            console.log(err);
        }
        this.loading = false;
    }

    async pageCallBack(pageInfo: { count?: number; pageSize?: number; limit?: number; offset?: number }) {
        const activitySearch: IActivitySearchEntity = {
            limit: pageInfo.limit,
            offset: pageInfo.offset * pageInfo.pageSize,
            filter: {
                ...this.activitySearch.filter,
            },
            show: {
                user: true,
                planning_item: true,
            },
        };

        await this.loadActivities(activitySearch);
    }

    async updateFilters(filters: IActivitySearchFilterEntity) {
        this.activitySearch.filter = filters;
        await this.pageCallBack(this.page);
    }

    async goToPlanningItemDetail(planningItemId: number) {
        await this.router.navigateByUrl('/planning-item-detail?planning_item_id=' + planningItemId);
    }
}
