import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'pr-picture-modal',
    templateUrl: './modal-picture.component.html',
    styleUrls: ['./modal-picture.component.scss'],
})
export class ModalPictureComponent implements OnInit {
    @Input() visible;
    @Input() fileName: string;

    @Output() close = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}

    sendClose() {
        this.close.emit();
        this.visible = false;
    }
}
