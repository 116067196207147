import { Component } from '@angular/core';

@Component({
    selector: 'pr-loading-page',
    styleUrls: ['./loading-page.component.scss'],
    templateUrl: './loading-page.component.html',
})
export class LoadingPageComponent {
    constructor() {}
}
