import { Pipe, PipeTransform } from '@angular/core';
import { LeadType } from '@phrospr/api-backend';

@Pipe({
    name: 'getNameOfLeadTypePipe',
})
export class GetNameOfLeadType implements PipeTransform {
    transform(leadType: LeadType) {
        if (leadType === LeadType.solvari) {
            return 'Solvari';
        }
        if (leadType === LeadType.bobex) {
            return 'Bobex';
        }
        if (leadType === LeadType.client) {
            return 'Klant';
        }
        if (leadType === LeadType.offrea) {
            return 'Offrea';
        }
        if (leadType === LeadType.leadAngel) {
            return 'Lead angel';
        }
        if (leadType === LeadType.leadadviseur) {
            return 'Lead Adviseur';
        }
        if (leadType === LeadType.manual) {
            return 'Manueel';
        }
        if (leadType === LeadType.OFFERTE_BE) {
            return 'Offerte.be';
        }
        if (leadType === LeadType.AQUAGROUP_SITE) {
            return 'Aquagroup site';
        } else {
            return 'undefined pipe for lead_type';
        }
    }
}
