import { ClientState, IAddress, IClientDataEntity, IClientEntity, Language } from '@phrospr/lib-models';

export function newDeliveryAddress(): IAddress {
    return {
        address: '',
        city: '',
        zip_code: '',
        country: null,
        countryName: null,
        state: null,
        stateName: null,
        location: {
            lat: null,
            lng: null,
        },
        deleted: false,
    };
}

export function getDeliveryAddresses(client: IClientEntity): IAddress[] {
    const output: IAddress[] = [];
    const { main_address, delivery_addresses } = client?.client_data ?? {};
    if (main_address) output.push(main_address);
    if (delivery_addresses) output.push(...delivery_addresses);
    return output;
}

export function getPhoneNumber({ client_data }: IClientEntity, noPhoneValue = '/'): string {
    if (client_data === undefined) return noPhoneValue;
    return client_data.telephone_phone ?? client_data.mobile_phone ?? noPhoneValue;
}

export function newClientDataEntity(): IClientDataEntity {
    return {
        first_name: null,
        last_name: null,
        email: null,
        mobile_phone: null,
        telephone_phone: null,
        language: Language.nl,
        remarks: null,
        main_address: {
            address: null,
            city: null,
            zip_code: null,
            country: null,
        },
        company_data: {},
    };
}

export function newClientEntity(isLead?: boolean): IClientEntity {
    let client: IClientEntity = {
        client_id: null,
        exact_client_number: null,
        exact_last_sync: null,
        state: ClientState.client,
        inactive: false,
        client_data: {
            first_name: null,
            last_name: null,
            email: null,
            main_address: {
                address: null,
                city: null,
                zip_code: null,
                deleted: false,
            },
            company_data: {},
        },
        client_settings: {
            mailSettings: {
                disableSendMailForPlannedNotification: false,
                disableSendMailForUpcomingMaintenance: false,
                disableSendMailNotifyPlanningItemForHisAppointment: false,
            },
            maintenanceSettings: {
                active: false,
            },
        },
    };

    if (isLead === true) {
        client = {
            ...client,
            state: ClientState.lead,
            lead_data: {
                lead_type: null,
                created_at: {
                    date: new Date(),
                },
                description: '',
            },
        };
    }

    return client;
}
