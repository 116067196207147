import { Autocomplete, Box, CircularProgress, Stack, TextField } from '@mui/material';
import { IProductEntity, ProductApi, RequestSort } from '@phrospr/api-backend';
import React from 'react';
import * as toastr from 'toastr';
import './ProductSearch.scss';
import { ProductSearchProps, ProductSearchState } from './ProductSearch.types';
import { AutoCompleteChip } from './ProductSearch.style';

export class ProductSearch extends React.Component<ProductSearchProps, ProductSearchState> {
    constructor(props: ProductSearchProps) {
        super(props);
        this.state = { open: false, loading: false, productOptions: [] };
        this.loadProductOptions();
    }

    async loadProductOptions(searchValue?: string) {
        this.setState({ loading: true });
        try {
            const products = (
                await ProductApi.listProducts({
                    filter: {
                        product_number: searchValue?.toString() ?? undefined,
                        name: searchValue?.toString() ?? undefined,
                        active: true,
                    },
                    limit: 10,
                    offset: 0,
                    sort: {
                        column: 'product_id',
                        order: RequestSort.ASC,
                    },
                })
            ).data.records;
            this.setState({ productOptions: products });
        } catch (e) {
            console.log(e);
            toastr.error('something went wrong');
        }
        this.setState({ loading: false });
    }

    selectProduct(values: (string | IProductEntity)[]) {
        if (values.length) {
            const value = values[0];
            if (typeof value === 'string') return;
            return this.props.onProductChange(value);
        }
        this.props.onProductChange(values as any);
    }

    render() {
        return (
            <div>
                <Autocomplete
                    sx={{ width: 300 }}
                    onFocus={() => this.setState({ open: true })}
                    open={this.state.open}
                    onOpen={() => this.setState({ open: true })}
                    onClose={() => this.setState({ open: false })}
                    isOptionEqualToValue={(option, value) => option.product_id === value.product_id}
                    getOptionLabel={(p: IProductEntity) =>
                        `${p.name ?? ''} (${p.product_number ?? ''}) (ID: ${p.product_id ?? ''})`
                    }
                    renderOption={(props, option, extra) => (
                        <li {...props}>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                sx={{ maxWidth: '100%' }}
                                alignItems="center"
                            >
                                <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }} component="span">{`${
                                    option.product_id
                                }, ${option.name ?? ''} (${option.product_number ?? ''})`}</Box>
                                {option.specifications?.service ? (
                                    <Box
                                        sx={{
                                            flexShrink: 0,
                                        }}
                                    >
                                        <AutoCompleteChip
                                            label="Service"
                                            size="small"
                                            color="primary"
                                        ></AutoCompleteChip>
                                    </Box>
                                ) : undefined}
                            </Stack>
                        </li>
                    )}
                    options={this.state.productOptions}
                    loading={this.state.loading}
                    onChange={(event, value) => this.selectProduct(value)}
                    onInputChange={(_, value) => this.loadProductOptions(value)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Zoek product..."
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
        );
    }
}

export default ProductSearch;
