<button (click)="newProduct()" class="btn btn-primary">+ Nieuw product</button>

<pr-product-filter
    [productItemSearchFilter]="productSearch.filter"
    (updateProductItemSearchFilter)="updateFilters($event)"
>
</pr-product-filter>

<div *ngIf="products">
    <ngx-datatable
        class="material striped"
        [rows]="products"
        [columnMode]="'flex'"
        [rowHeight]="30"
        [headerHeight]="50"
        [footerHeight]="50"
        [selectionType]="'single'"
        [externalPaging]="true"
        [externalSorting]="true"
        (activate)="onActivate($event)"
        [count]="page.count"
        [offset]="page.offset"
        [limit]="page.limit"
        [sortType]="'single'"
        (page)="pageCallback($event)"
        (sort)="sortCallback($event)"
        [rowClass]="getRowClass"
        [reorderable]="reorderable"
        [loadingIndicator]="loading"
    >
        <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="product_number">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer"> Product nummer </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.product_number }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="name">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer"> Product Naam </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.name }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="unit">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer"> Eenheid </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{
                    (row.pricing.unit_amount ? row.pricing.unit_amount : '') +
                        ' ' +
                        (row.pricing.unit_type ? row.pricing.unit_type : '')
                }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="price">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer"> Prijs </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.pricing.price_per_unit ? row.pricing.price_per_unit + ' €' : '/' }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="price">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer"> Service / Dienst </span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                <cds-icon style="margin-left: 50px" shape="check" *ngIf="row.specifications?.service"></cds-icon>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="1" name="approved">
            <ng-template let-column="column" ngx-datatable-header-template></ng-template>
            <ng-template class="datatable-body-cell" let-row="row" ngx-datatable-cell-template>
                <div>
                    <button
                        (click)="$event.preventDefault(); $event.stopPropagation(); editProduct(row)"
                        class="btn btn-outline btn-sm btn-icon"
                        title="Edit"
                        *ngIf="row.active"
                    >
                        <cds-icon shape="pencil"></cds-icon>
                    </button>
                    <button
                        (click)="$event.preventDefault(); $event.stopPropagation(); showProductDeactivateModal(row)"
                        class="btn btn-warning btn-sm btn-icon"
                        *ngIf="row.active"
                    >
                        <cds-icon shape="trash"></cds-icon>
                    </button>
                    <button
                        (click)="$event.preventDefault(); $event.stopPropagation(); showProductActivateModal(row)"
                        class="btn btn-success btn-sm btn-icon"
                        *ngIf="!row.active"
                        title="activeer product"
                    >
                        <cds-icon shape="play"></cds-icon>
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>

    <pr-delete-modal [visible]="deactivateProductModal" (cancel)="cancelDeActivateProduct()" (ok)="deActiveProduct()">
        Bent u zeker dat u het product wilt verwijderen?
    </pr-delete-modal>
    <pr-delete-modal [visible]="activateProductModal" (cancel)="cancelActivateProduct()" (ok)="activateProduct()">
        Bent u zeker dat u het product wilt activeren?
    </pr-delete-modal>
</div>
