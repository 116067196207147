export enum PlanningItemAttachmentColumns {
    planning_item_attachment_id = 'planning_item_attachment_id',
    creation_date = 'creation_date',
    created_by_user_id = 'created_by_user_id',
    calendar_item_id = 'calendar_item_id',
    planning_item_id = 'planning_item_id',
    storage_url = 'storage_url',
}

export interface PlanningItemAttachmentAttributes {
    planning_item_attachment_id: number;
    creation_date: Date | null;
    created_by_user_id: number | null;
    calendar_item_id: number | null;
    planning_item_id: number | null;
    storage_url: string | null;
}

export type PlanningItemAttachmentOptionalAttributes =
    | 'planning_item_attachment_id'
    | 'creation_date'
    | 'created_by_user_id'
    | 'calendar_item_id'
    | 'planning_item_id'
    | 'storage_url';
export interface PlanningItemAttachmentCreate {
    planning_item_attachment_id?: number;
    creation_date?: Date | null;
    created_by_user_id?: number | null;
    calendar_item_id?: number | null;
    planning_item_id?: number | null;
    storage_url?: string | null;
}
