import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from '../../../../environments/environment';
import { IClientExactEntity } from '@phrospr/lib-models';

@Injectable()
export class ClientService {
    constructor(private http: HttpClient) {}

    public updateClientInfoInExact(client: IClientExactEntity): Observable<any> {
        const params: any = { client_number: client.clientNumber };
        return this.http.put(`${BASE_URL}api/exact/updateClients`, client, { params });
    }
}
