"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIWorkOrderDataEntity = void 0;
const iaddress_1 = require("./iaddress");
const iwork_order_time_registration_entity_1 = require("./iwork-order-time-registration-entity");
function parseIWorkOrderDataEntity(data) {
    if (data.address !== null && typeof data.address === 'object')
        data.address = (0, iaddress_1.parseIAddress)(data.address);
    if (data.work_time_registration !== null && typeof data.work_time_registration === 'object')
        data.work_time_registration = data.work_time_registration.map((x) => (0, iwork_order_time_registration_entity_1.parseIWorkOrderTimeRegistrationEntity)(x));
    return data;
}
exports.parseIWorkOrderDataEntity = parseIWorkOrderDataEntity;
