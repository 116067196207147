"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseRequestQuoteCreateBody = void 0;
const iquote_create_entity_1 = require("./iquote-create-entity");
function parseRequestQuoteCreateBody(data) {
    if (data.quote !== null && typeof data.quote === 'object')
        data.quote = (0, iquote_create_entity_1.parseIQuoteCreateEntity)(data.quote);
    return data;
}
exports.parseRequestQuoteCreateBody = parseRequestQuoteCreateBody;
