<div style="width: 100%" class="client-search">
  <input type="text" placeholder="Zoek klant .." id="myClientList" autocomplete="off"
         (ngModelChange)="searchClientField$.next(true)" [(ngModel)]="clientSearchTrigger"
         style="width: 100%; border-bottom: 1px solid #9a9a9a" [value]="selectedClient.client_data.first_name
         ? selectedClient.client_data.first_name +
          (selectedClient.exact_client_number ? ' - (' + selectedClient.exact_client_number +')' :
          '('+ selectedClient.client_id +')' ) : ''">
  <div id="clientList" *ngIf="clients" class="dropdown-content"
       [ngClass]="{'hide-dropdown-content': hideContent}">
    <a (click)="selectClient(client)" *ngFor="let client of clients">{{client.client_data.first_name}}
      {{client.client_data.last_name}}
      ({{client.exact_client_number}})</a>
  </div>
</div>


