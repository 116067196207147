<img
    *ngIf="planningItemType === PlanningItemType.maintenance"
    src="assets/images/onderhoud3.png"
    [class.quick-search-item-image]="!big"
    [class.planning-item-list-detail-card-logo]="big"
/>
<img
    *ngIf="planningItemType === PlanningItemType.delivery"
    src="assets/images/delivery/Truck_Blue.png"
    [class.quick-search-item-image]="!big"
    [class.planning-item-list-detail-card-logo]="big"
/>
<img
    *ngIf="planningItemType === PlanningItemType.repair"
    src="assets/images/repair/Repair.png"
    [class.quick-search-item-image]="!big"
    [class.planning-item-list-detail-card-logo]="big"
/>
<img
    *ngIf="planningItemType === PlanningItemType.placement"
    src="assets/images/placement/Placement.png"
    [class.quick-search-item-image]="!big"
    [class.planning-item-list-detail-card-logo]="big"
/>
<img
    *ngIf="!leadType && planningItemType === PlanningItemType.lead"
    src="assets/images/web_logo.png"
    [class.quick-search-item-image]="!big"
    [class.planning-item-list-detail-card-logo]="big"
/>
<div *ngIf="planningItemType === PlanningItemType.lead && leadType">
    <img
        *ngIf="leadType === LeadType.bobex"
        src="assets/images/leads/Bobex_400x400.jpg"
        [class.quick-search-item-image]="!big"
        [class.planning-item-list-detail-card-logo]="big"
    />
    <img
        *ngIf="leadType === LeadType.solvari"
        src="assets/images/leads/Solvari_800x800.png"
        [class.quick-search-item-image]="!big"
        [class.planning-item-list-detail-card-logo]="big"
    />
    <img
        *ngIf="leadType === LeadType.manual"
        src="assets/images/web_logo.png"
        [class.quick-search-item-image]="!big"
        [class.planning-item-list-detail-card-logo]="big"
    />
    <img
        *ngIf="leadType === LeadType.leadAngel"
        src="assets/images/leads/leadAngels_194x64.png"
        [class.quick-search-item-image]="!big"
        [class.planning-item-list-detail-card-logo]="big"
    />
    <img
        *ngIf="leadType === LeadType.offrea"
        [class.quick-search-item-image]="!big"
        src="assets/images/leads/offrea_200x200.png"
        [class.planning-item-list-detail-card-logo]="big"
    />
    <img
        *ngIf="leadType === LeadType.leadadviseur"
        src="assets/images/leads/offerteadviseur_199x199.png"
        [class.quick-search-item-image]="!big"
        [class.planning-item-list-detail-card-logo]="big"
    />
    <img
        *ngIf="leadType === LeadType.AQUAGROUP_SITE"
        src="assets/images/leads/Aquagroup_Site_Form566x590.png"
        [class.quick-search-item-image]="!big"
        [class.planning-item-list-detail-card-logo]="big"
    />
    <img
        *ngIf="leadType === LeadType.OFFERTE_BE"
        src="assets/images/leads/offerte_be.png"
        [class.quick-search-item-image]="!big"
        [class.planning-item-list-detail-card-logo]="big"
    />
</div>
