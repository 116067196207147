import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientService } from '../services/client.service';
import { IClientExactEntity } from '@phrospr/lib-models';

@Injectable({
    providedIn: 'root',
})
export class ClientManager {
    constructor(private clientService: ClientService) {}

    public updateClientInfoInExact(client: IClientExactEntity): Observable<any> {
        return this.clientService.updateClientInfoInExact(client);
    }
}
