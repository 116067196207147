import { Component, Input, OnInit } from '@angular/core';
import { LeadType, PlanningItemType } from '@phrospr/api-backend';

@Component({
    selector: 'pr-planning-item-type-image',
    templateUrl: './planning-item-type-image.component.html',
    styleUrls: ['./planning-item-type-image.component.scss'],
})
export class PlanningItemTypeImageComponent implements OnInit {
    PlanningItemType = PlanningItemType;
    LeadType = LeadType;

    @Input() planningItemType: PlanningItemType;
    @Input() leadType?: LeadType;
    @Input() big: boolean;

    constructor() {}

    ngOnInit(): void {}
}
