"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseICalendarEventLiteRecurrence = void 0;
const icalendar_event_lite_recurrence_pattern_1 = require("./icalendar-event-lite-recurrence-pattern");
const icalendar_event_lite_recurrence_range_1 = require("./icalendar-event-lite-recurrence-range");
function parseICalendarEventLiteRecurrence(data) {
    if (data.pattern !== null && typeof data.pattern === 'object')
        data.pattern = (0, icalendar_event_lite_recurrence_pattern_1.parseICalendarEventLiteRecurrencePattern)(data.pattern);
    if (data.range !== null && typeof data.range === 'object')
        data.range = (0, icalendar_event_lite_recurrence_range_1.parseICalendarEventLiteRecurrenceRange)(data.range);
    return data;
}
exports.parseICalendarEventLiteRecurrence = parseICalendarEventLiteRecurrence;
