export enum ProductActivityColumns {
    activity_id = 'activity_id',
    product_id = 'product_id',
}

export interface ProductActivityAttributes {
    activity_id: number;
    product_id: number;
}

export type ProductActivityCreate = ProductActivityAttributes;
