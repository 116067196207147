<div class="grid-item" style="width: 100%">
    <p class="sub-title">Comments ({{ comments.length ? comments.length : '0' }})</p>
    <div *ngIf="comments && comments.length">
        <div
            *ngFor="let comment of comments | slice : -3"
            class="custom-remark comment-grid"
            style="border-bottom: 1px solid rgba(0, 0, 0, 0.125)"
        >
            <cds-icon direction="right" shape="angle" class="button-icon"></cds-icon>
            <div class="text-overflow">
                {{
                    comment.created_by_user.first_name +
                        ' - ' +
                        (comment.creation_date | dateFormatOnlyDate) +
                        ' - ' +
                        (comment.data.comment ? comment.data.comment : '')
                }}
            </div>

            <div class="icon-section">
                <cds-icon
                    shape="phone-handset"
                    class="phone-clicked is-solid"
                    style="margin-left: 5px"
                    *ngIf="comment.data.contacted_info && comment.data.contacted_info.phone"
                ></cds-icon>
                <cds-icon
                    shape="envelope"
                    class="mail-clicked is-solid"
                    style="margin-left: 5px"
                    *ngIf="comment.data.contacted_info && comment.data.contacted_info.mailed"
                >
                </cds-icon>
            </div>
        </div>
    </div>

    <div (click)="showAddComment = true" *ngIf="!showAddComment" class="btn btn-outline btn-sm btn-menu">
        <cds-icon shape="chat-bubble"></cds-icon>
        Comment
    </div>
    <div>
        <pr-comment-add
            *ngIf="showAddComment"
            [planningItemId]="planningItem.planning_item_id"
            (cancelClick)="showAddComment = false"
            (saveCommentClick)="addComment()"
        >
        </pr-comment-add>
    </div>
</div>
