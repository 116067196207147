import PropTypes from 'prop-types';
import React from 'react';
import { Navigate, stringOrDate, ViewProps } from 'react-big-calendar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import { FunctionalViewComponent } from './types';
import { configuration } from '@phrospr/lib-web-core';

let _minDate: Date;
const getMinDate = () => {
    if (_minDate) return _minDate;
    _minDate = new Date();
    _minDate.setHours(configuration.planning.minHour, 0, 0, 0);
    return _minDate;
};

let _maxDate: Date;
const getMaxDate = () => {
    if (_maxDate) return _maxDate;
    _maxDate = new Date();
    _maxDate.setHours(configuration.planning.maxHour, 0, 0, 0);
    return _maxDate;
};

export const CustomWeekView: FunctionalViewComponent = ({
    date,
    localizer,
    min = getMinDate(),
    max = getMaxDate(),
    ...props
}: ViewProps) => {
    const currRange = React.useMemo(() => CustomWeekView.range(date, { localizer }), [date, localizer]);

    return (
        <TimeGrid date={date} eventOffset={15} localizer={localizer} max={max} min={min} range={currRange} {...props} />
    );
};

CustomWeekView.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    localizer: PropTypes.object,
    max: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    scrollToTime: PropTypes.instanceOf(Date),
};

CustomWeekView.range = (date: stringOrDate, { localizer }) => {
    const start = getStartOfWeek(date);
    const end = localizer.add(start, 6, 'day');

    let current = start;
    const range = [];

    while (localizer.lte(current, end, 'day')) {
        range.push(current);
        current = localizer.add(current, 1, 'day');
    }

    return range;
};

CustomWeekView.navigate = (date: stringOrDate, action, { localizer }) => {
    switch (action) {
        case Navigate.PREVIOUS:
            return localizer.add(date, -7, 'day');
        case Navigate.NEXT:
            return localizer.add(date, 7, 'day');
        default:
            return date;
    }
};

CustomWeekView.title = (date: stringOrDate, { localizer }) => {
    const [start, ...rest] = CustomWeekView.range(date, { localizer });
    return localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat');
};

function getStartOfWeek(d: stringOrDate) {
    const date = new Date(d);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? 6 : 1);

    const firstDayOfWeek = new Date(date.setDate(diff));
    firstDayOfWeek.setHours(0, 0, 0, 0);

    return firstDayOfWeek;
}
