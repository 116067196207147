import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import React, { Component } from 'react';
import * as toastr from 'toastr';
import './UserContainer.scss';
import { UserContainerProps, UserContainerState } from './UserContainer.types';
import UserEditDialog from '../UserEditDialog/UserEditDialog';
import { UserApi } from '@phrospr/api-backend';

export class UserContainer extends Component<UserContainerProps, UserContainerState> {
    constructor(props: UserContainerProps) {
        super(props);
        this.state = { editMode: false, deleteMode: false };
        this.loadUsers();
    }

    loadUsers = async () => {
        const users = (await UserApi.listUsers()).data;
        users.sort((a, b) => {
            if (a.first_name < b.first_name) return -1;
            else if (a.first_name > b.first_name) return 1;
            return 0;
        });
        this.setState({ users });
    };

    handleClose() {
        console.log('closed');
    }

    deleteUser = async () => {
        if (!this.state.deleteUser) throw new Error('No user selected');
        const user = this.state.deleteUser;
        try {
            await UserApi.deleteUser(user.user_id);
            await this.loadUsers();
            this.setState({ deleteMode: false, deleteUser: undefined });
        } catch (e) {
            console.log(e);
            toastr.error('something went wrong');
        }
    };

    updateUsersOffice365 = async () => {
        await UserApi.addNewUsersFrom365();
        return this.loadUsers();
    };

    render() {
        return (
            <div data-testid="UserContainer" style={{ padding: '5px' }}>
                <Button
                    variant="contained"
                    style={{ margin: '10px 0', float: 'right' }}
                    onClick={this.updateUsersOffice365}
                >
                    Update users from Office 365
                </Button>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Voornaam</TableCell>
                                <TableCell align="right">Achternaam</TableCell>
                                <TableCell align="right">Email</TableCell>
                                <TableCell align="right">Tel. nummer</TableCell>
                                <TableCell align="right">Rol</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.users?.map(row => (
                                <TableRow key={row.user_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {row.first_name}
                                    </TableCell>
                                    <TableCell align="right">{row.last_name}</TableCell>
                                    <TableCell align="right">{row.email}</TableCell>
                                    <TableCell align="right">{row.phone_number}</TableCell>
                                    <TableCell align="right">{row.roles.roles?.[0]}</TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            size="small"
                                            aria-label="edit"
                                            onClick={() =>
                                                this.setState({
                                                    editMode: true,
                                                    editUser: JSON.parse(JSON.stringify(row)),
                                                })
                                            }
                                        >
                                            <Edit />
                                        </IconButton>
                                        <IconButton
                                            disabled={row.deleted}
                                            size="small"
                                            aria-label="edit"
                                            onClick={() => this.setState({ deleteMode: true, deleteUser: row })}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.state.editUser ? (
                    <UserEditDialog
                        open={this.state.editMode}
                        user={this.state.editUser}
                        userSaved={this.loadUsers}
                        dialogClosed={() => this.setState({ editMode: false, editUser: undefined })}
                    />
                ) : undefined}
                <Dialog open={this.state.deleteMode} onClose={this.handleClose}>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete user {this.state.deleteUser?.first_name}{' '}
                            {this.state.deleteUser?.last_name}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" variant="text" onClick={() => this.setState({ deleteMode: false })}>
                            Cancel
                        </Button>
                        <Button color="error" variant="contained" size="small" onClick={this.deleteUser}>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default UserContainer;
