"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIPlanningItemReportEntity = void 0;
const icalendar_item_entity_1 = require("./icalendar-item-entity");
const iplanning_item_entity_1 = require("./iplanning-item-entity");
const iplanning_item_report_entity_data_1 = require("./iplanning-item-report-entity-data");
const iuser_entity_1 = require("./iuser-entity");
function parseIPlanningItemReportEntity(data) {
    if (typeof data.creation_date === 'string')
        data.creation_date = new Date(data.creation_date);
    if (data.data !== null && typeof data.data === 'object')
        data.data = (0, iplanning_item_report_entity_data_1.parseIPlanningItemReportEntityData)(data.data);
    if (data.created_by_user !== null && typeof data.created_by_user === 'object')
        data.created_by_user = (0, iuser_entity_1.parseIUserEntity)(data.created_by_user);
    if (data.calendar_item !== null && typeof data.calendar_item === 'object')
        data.calendar_item = (0, icalendar_item_entity_1.parseICalendarItemEntity)(data.calendar_item);
    if (data.planning_item !== null && typeof data.planning_item === 'object')
        data.planning_item = (0, iplanning_item_entity_1.parseIPlanningItemEntity)(data.planning_item);
    return data;
}
exports.parseIPlanningItemReportEntity = parseIPlanningItemReportEntity;
