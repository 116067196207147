<clr-modal [(clrModalOpen)]="visible" [clrModalClosable]="false" class="modal-content">
    <!--<h4 class="modal-title">Delete</h4>-->
    <div class="modal-body">
        <ng-content></ng-content>
    </div>
    <div class="modal-footer">
        <button class="btn btn-sm btn-success" (click)="sendOk()">Yes</button>
        <button class="btn btn-sm btn-link" (click)="sendCancel()">Cancel</button>
    </div>

</clr-modal>
