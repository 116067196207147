import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import {
    PlanningItemType,
    PlanningState,
    IPlanningItemSearchFilterEntity,
    IPlanningItemSearchShowEntity,
} from '@phrospr/api-backend';

@Component({
    selector: 'pr-completed-no-offer-page',
    styleUrls: ['./completed-no-offer-page.component.scss'],
    templateUrl: './completed-no-offer-page.component.html',
})
export class CompletedNoOfferPageComponent implements OnInit {
    planningItemShow: IPlanningItemSearchShowEntity = {
        client: true,
        assigned_calendar_item: true,
        comments: false,
        activities: false,
        calendar_items: false,
        last_completed_planning_item: false,
    };
    planningItemSearchFilter: IPlanningItemSearchFilterEntity = {
        planning_item_type: PlanningItemType.lead,
        state: PlanningState.completedNoOffer,
        client_name: null,
        client_nullable: true,
        lead_nullable: false,
    };

    planningItemSearchFilter$: ReplaySubject<void> = new ReplaySubject();

    constructor() {}

    ngOnInit(): void {}

    updateFilters(filters: IPlanningItemSearchFilterEntity) {
        this.planningItemSearchFilter = filters;
        this.planningItemSearchFilter$.next();
    }
}
