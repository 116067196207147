import {
    AfterViewInit,
    Component,
    ElementRef,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import * as ReactDom from 'react-dom/client';
import * as React from 'react';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteUpsertPage, ShadowDom } from '@phrospr/frontend-react';
import { REACT_CONTAINER } from '../../../../../constants';

@Component({
    selector: 'pr-upsert-quote-page2-component',
    template: `<span #${REACT_CONTAINER}></span>`,
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class UpsertQuotePage2Component implements OnChanges, OnDestroy, AfterViewInit {
    @ViewChild(REACT_CONTAINER, { static: true }) containerRef: ElementRef;
    private component: ReactDom.Root;
    element: HTMLElement;

    private readonly quoteId$: Promise<number>;
    private readonly planningItemId$: Promise<number>;
    private readonly clientId$: Promise<number>;

    constructor(private router: Router, private route: ActivatedRoute) {
        this.quoteId$ = this.route.queryParams
            .pipe(first())
            .toPromise()
            .then(params => {
                if (params.quote_id !== undefined) return parseInt(params.quote_id, 10);
                return undefined;
            });
        this.planningItemId$ = this.route.queryParams
            .pipe(first())
            .toPromise()
            .then(params => {
                if (params.planning_item_id !== undefined) return parseInt(params.planning_item_id, 10);
                return undefined;
            });
        this.clientId$ = this.route.queryParams
            .pipe(first())
            .toPromise()
            .then(params => {
                if (params.client_id !== undefined) return parseInt(params.client_id, 10);
                return undefined;
            });
    }

    async ngAfterViewInit() {
        this.element = this.containerRef.nativeElement as HTMLElement;
        this.component = ReactDom.createRoot(this.element);
        await this.render();
    }

    async ngOnChanges(changes: SimpleChanges) {
        await this.render();
    }

    ngOnDestroy(): void {
        this.component.unmount();
    }

    private async render() {
        if (!this.component) return console.log('failed to render, no component yet', typeof this);
        this.component.render(
            <ShadowDom element={this.element}>
                <QuoteUpsertPage
                    quoteId={await this.quoteId$}
                    planningItemId={await this.planningItemId$}
                    clientId={await this.clientId$}
                    onSuccess={() => this.router.navigateByUrl('/offer')}
                    onCancel={() => this.router.navigateByUrl('/offer')}
                />
            </ShadowDom>,
        );
    }
}
