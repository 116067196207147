<clr-modal [(clrModalOpen)]="visible" [clrModalClosable]="false" [clrModalSize]="'xl'" class="content modal-xl">
  <div class="modal-body">
    <img *ngIf="visible" [attr.alt]="fileName" [src]="fileName | authImage | async"
         style="width: 100%; object-fit: contain"/>
  </div>
  <div class="modal-footer">
    <button (click)="sendClose()" class="btn btn-link">Close</button>

  </div>
</clr-modal>
