"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseILeadDoubles = void 0;
const ilead_doubles_lead_list_1 = require("./ilead-doubles-lead-list");
function parseILeadDoubles(data) {
    if (data.lead_list !== null && typeof data.lead_list === 'object')
        data.lead_list = data.lead_list.map((x) => (0, ilead_doubles_lead_list_1.parseILeadDoublesLeadList)(x));
    return data;
}
exports.parseILeadDoubles = parseILeadDoubles;
