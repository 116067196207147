import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateFormatWithTime',
})
export class DateFormatWithTimePipe implements PipeTransform {
    transform(value: string | Date, format?: string) {
        return (format ? moment(value, format) : moment(value)).format('DD/MM/YYYY HH:mm');
    }
}
