import { Box, Button, CircularProgress, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ClientApi, IQuoteEntity, IQuoteLineEntity, QuoteApi, QuoteStatus } from '@phrospr/api-backend';
import React, { Component } from 'react';
import * as toastr from 'toastr';
import QuoteProducts from '../QuoteProducts/QuoteProducts';
import QuoteClientFiche from '../QuoteClientFiche/QuoteClientFiche';
import QuoteInfo from '../QuoteInfo/QuoteInfo';
import './QuoteUpsertPage.scss';
import { QuoteUpsertPageProps, QuoteUpsertState } from './QuoteUpsertPage.types';

let isUpserting = false;

export class QuoteUpsertPage extends Component<QuoteUpsertPageProps, QuoteUpsertState> {
    message: string;
    quoteId?: number;
    leadPlanningItemId?: number;
    clientId?: number;
    rawQuote: IQuoteEntity;

    constructor(props: QuoteUpsertPageProps) {
        super(props);
        this.quoteId = props.quoteId;
        this.leadPlanningItemId = props.planningItemId;
        this.clientId = props.clientId;
        this.state = {
            loading: true,
            message: '',
            selectedClient: undefined,
            selectedAddress: undefined,
            isNewClient: false,
            quote: {
                quote_id: props.quoteId,
                data: {
                    created_by_user_id: null,
                    sold_by_user_id: null,
                },
                status: QuoteStatus.created,
                final_price_to_pay: undefined,
                pdf_raw: null,
            },
            quoteLines: [],
            btwPercentage: undefined,
            discountAmount: undefined,
            discountPercentage: undefined,
            placementCost: undefined,
        };
    }

    async componentDidMount() {
        if (this.quoteId !== undefined) {
            const quote = (await QuoteApi.getQuote(this.quoteId)).data;
            this.rawQuote = quote;
            this.setState({
                selectedClient: quote.client,
                selectedAddress: quote.data.delivery_address,
                btwPercentage: quote.data.btw_percentage,
                discountAmount: quote.data.discount_amount,
                discountPercentage: quote.data.discount_percentage,
                isNewClient: false,
                quote,
                quoteLines: quote.quote_lines ?? [],
                placementCost: quote.placement_cost,
            });
        }

        if (this.clientId) {
            const client = (await ClientApi.getClient(this.clientId)).data;
            this.setState({ selectedClient: client, selectedAddress: client.client_data.main_address });
        }

        this.setState({ loading: false });
    }

    async upsertQuote() {
        if (isUpserting) return;
        this.setState({});
        isUpserting = true;
        if (this.state.quoteLines.length <= 0) {
            toastr.error('Geen product lijnen geselecteerd');
            isUpserting = false;
            return;
        }
        if (!this.state.selectedClient) {
            toastr.error('Geen klant geselecteerd');
            isUpserting = false;
            return;
        }
        if (!this.state.quote.data.sold_by_user_id) {
            toastr.error('Geen verkoper geselecteerd');
            isUpserting = false;
            return;
        }
        if (!this.state.selectedAddress) {
            toastr.error('Geen leveradres geselecteerd');
            isUpserting = false;
            return;
        }

        if (this.state.isNewClient && !this.state.selectedClient.client_data.email) {
            toastr.error('Geen email adres opgegeven voor aanmaak nieuwe lead/klant');
            isUpserting = false;
            return;
        }

        try {
            const quote = this.state.quote;
            if (!quote.data)
                quote.data = {
                    created_by_user_id: null,
                    sold_by_user_id: null,
                };
            quote.quote_lines = this.state.quoteLines as IQuoteLineEntity[];
            quote.data.btw_percentage = this.state.btwPercentage;
            quote.data.discount_percentage = this.state.discountPercentage;
            quote.data.discount_amount = this.state.discountAmount;
            quote.placement_cost = this.state.placementCost;
            quote.sub_total_price = 0;
            quote.final_price_to_pay = 0;
            quote.data.delivery_address = this.state.selectedAddress;
            await QuoteApi.upsertQuote({
                newClient: this.state.isNewClient ? this.state.selectedClient?.client_data : undefined,
                client_id: this.state.selectedClient?.client_id,
                upsertQuote: {
                    quoteLines: this.state.quoteLines,
                    placementCost: this.state.placementCost ?? 0,
                    quoteId: this.quoteId,
                    deliveryAddress: this.state.selectedAddress,
                    data: this.state.quote.data,
                    sendEmailToCustomer: this.state.quote.mailQuoteToClient ?? false,
                    sold: this.state.quote.sold,
                },
                lead_planning_id: this.leadPlanningItemId,
            });
            this.props.onSuccess();
        } catch (e) {
            console.error(e);
        }
        isUpserting = false;
    }

    cancel() {
        this.props.onCancel();
    }

    render() {
        if (this.state.loading)
            return (
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            );
        return (
            <div>
                <Grid
                    container
                    spacing={3}
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                >
                    <Grid item>
                        <QuoteClientFiche
                            selectedClient={this.state.selectedClient}
                            selectedAddress={this.state.selectedAddress}
                            onChange={({ client: selectedClient, address, isNewClient }) => {
                                this.setState({ selectedClient, selectedAddress: address, isNewClient });
                            }}
                        ></QuoteClientFiche>
                    </Grid>
                    <Grid item>
                        <QuoteInfo
                            quote={this.rawQuote}
                            onChange={({ quote }) => this.setState({ quote: { ...this.state.quote, ...quote } })}
                        ></QuoteInfo>
                    </Grid>
                </Grid>
                <QuoteProducts
                    rawQuote={this.rawQuote}
                    quoteLines={this.rawQuote?.quote_lines ?? []}
                    onChange={quoteLines => this.setState({ ...quoteLines })}
                />
                <Grid
                    sx={{ marginTop: '10px' }}
                    container
                    direction={'row'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-start'}
                >
                    <Grid item>
                        <Stack spacing={2} direction={'row'}>
                            <Button variant={'outlined'} onClick={() => this.cancel()}>
                                Cancel
                            </Button>
                            <Button variant={'contained'} onClick={() => this.upsertQuote()}>
                                Opslaan
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default QuoteUpsertPage;
