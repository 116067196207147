import { Checkbox } from '@mui/material';
import React from 'react';
import { PhrosprThemeProvider } from '../../theme';
import { IPortalUserEntity } from '../../types/entity';
import { Calendar, CalendarProps } from '../Calendar/Calendar';
import './Agenda.scss';
import * as Style from './Agenda.style';

export type AgendaProps = {
    users: IPortalUserEntity[];
    onSelectUser: (userId: number, selected: boolean) => void;
} & CalendarProps;

export function Agenda({ users, onSelectUser, ...calendarProps }: AgendaProps) {
    return (
        <PhrosprThemeProvider>
            <Style.Agenda>
                <Style.TitleAndUsers>
                    <Style.Title>Andere agenda's</Style.Title>
                    <Style.Users>
                        {users.map(({ user_id, first_name, selected, data: { secondary } }) => (
                            <Style.User key={user_id} color={secondary}>
                                <Checkbox
                                    checked={selected ?? false}
                                    onChange={(_, checked) => onSelectUser(user_id, checked)}
                                    style={{ padding: '6px 6px 6px 0' }}
                                />
                                {first_name}
                            </Style.User>
                        ))}
                    </Style.Users>
                </Style.TitleAndUsers>
                <Calendar {...calendarProps} />
            </Style.Agenda>
        </PhrosprThemeProvider>
    );
}

export default Agenda;
