import { IQuoteLineCreateEntity } from '@phrospr/lib-models';
import { getNewProduct } from './product.engine';

export function newQuoteLine(): IQuoteLineCreateEntity {
    return {
        product_id: null,
        creation_date: null,
        quantity: null,
        unit_price: null,
        unit: null,
        product_document_free_entry: null,
        product: null,
        final_price_to_pay: null,
    };
}
