import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import {
    IPlanningItemSearchFilterEntity,
    IPlanningItemSearchShowEntity,
    PlanningItemType,
    PlanningState,
} from '@phrospr/api-backend';

@Component({
    selector: 'pr-planned-repair-page',
    styleUrls: ['./planned-repair-page.component.scss'],
    templateUrl: './planned-repair-page.component.html',
})
export class PlannedRepairPageComponent implements OnInit {
    planningItemShow: IPlanningItemSearchShowEntity = {
        client: true,
        assigned_calendar_item: true,
        comments: false,
        activities: false,
        calendar_items: false,
        last_completed_planning_item: true,
    };
    planningItemSearchFilter: IPlanningItemSearchFilterEntity = {
        planning_item_type: PlanningItemType.repair,
        state: PlanningState.planned,
        client_name: null,
        client_nullable: false,
    };

    planningItemSearchFilter$: ReplaySubject<void> = new ReplaySubject();

    constructor() {}

    ngOnInit(): void {}

    updateFilters(filters: IPlanningItemSearchFilterEntity) {
        this.planningItemSearchFilter = filters;
        this.planningItemSearchFilter$.next();
    }
}
