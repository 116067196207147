import { PlanningItemType } from '@phrospr/api-backend';
import * as moment from 'moment';

export interface WithHour {
    hour: string;
}

export const HOURS: WithHour[] = Array.from({ length: 48 }, (_, i) => ({
    hour: moment.utc((i + 1) * 30 * 60 * 1000).format('HH:mm'),
}));

export const PLANNING_ITEM_TYPE_ROUTES = {
    [PlanningItemType.maintenance]: '/maintenance',
    [PlanningItemType.delivery]: '/deliveries',
    [PlanningItemType.lead]: '/leads',
    [PlanningItemType.placement]: '/placement',
    [PlanningItemType.repair]: '/repair',
};
