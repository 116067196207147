import { IPlanningItemEntity } from '@phrospr/api-backend';
import { CalendarEvent } from '@phrospr/frontend-react/dist/reusable/Calendar/Calendar.types';
import { CalendarItemEngine } from '@phrospr/lib-core';
import { configuration } from '@phrospr/lib-web-core';

export function getContent(event: CalendarEvent, { planning_item_type, client }: IPlanningItemEntity) {
    return (
        getBaseContent(event) +
        CalendarItemEngine.getTitleForCalendarEvent(
            planning_item_type ? planning_item_type : '',
            configuration.planning.titlePrefixCalendar,
            client,
        )
    );
}

export function getBaseContent(event: CalendarEvent) {
    if (!event.content) return '<p></p>';
    return '<p>' + event.content.replace(/\n{,}/g, '</p><p>').replace(/\n/g, '<br>') + '</p>';
}

export function getNewTime(event: CalendarEvent, newTime: string, isStart = true) {
    const time = isStart ? event.start : event.end;
    time.setHours(parseFloat(newTime), parseFloat(newTime.substring(3)));

    return time;
}
