import { Component, OnInit } from '@angular/core';
import * as toastr from 'toastr';
import { Router } from '@angular/router';
import {
    IProductEntity,
    IProductSearchEntity,
    IProductSearchFilterEntity,
    ProductApi,
    RequestSort,
} from '@phrospr/api-backend';

@Component({
    selector: 'pr-product-page',
    styleUrls: ['./product-page.component.scss'],
    templateUrl: './product-page.component.html',
})
export class ProductPageComponent implements OnInit {
    products: IProductEntity[];

    productSearch: IProductSearchEntity = {
        show: {
            test: false,
        },
        sort: { order: RequestSort.ASC, column: 'product_id' },
        filter: {
            active: true,
        },
    };

    reorderable: true;
    loading = false;

    page: {
        limit: number;
        count: number;
        offset: number;
        sort?: { order: RequestSort; column: string };
    } = {
        limit: 25,
        count: 0,
        offset: 0,
        sort: { order: RequestSort.DESC, column: 'creation_date' },
    };

    productToDelete: IProductEntity;
    deactivateProductModal = false;

    productToActivate: IProductEntity;
    activateProductModal = false;

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.pageCallback(this.page);
    }

    onActivate(event) {
        if (event.type === 'click') {
            this.router.navigateByUrl(`/product-upsert?product_id=${event.row.product_id}`);
        }
    }

    pageCallback(pageInfo: { count?: number; pageSize?: number; limit?: number; offset?: number }) {
        const offset = pageInfo.offset !== null ? pageInfo.offset : 0;
        this.page.offset = offset;
        this.productSearch.offset = offset ? offset * pageInfo.pageSize : 0;
        this.productSearch.limit = pageInfo.limit;
        this.loadProductList();
    }

    sortCallback(sortInfo: {
        sorts: { dir: 'ASC' | 'DESC'; prop: string };
        column: { name: string };
        prevValue: string;
        newValue: string;
    }) {
        // there will always be one "sort" object if "sortType" is set to "single"
        // FYI it's always the column prop that is filled in the sorting
        this.productSearch.sort.order = sortInfo.sorts[0].dir.toUpperCase();
        this.productSearch.sort.column = sortInfo.column.name;
        this.loadProductList();
    }

    async loadProductList() {
        this.loading = true;
        try {
            const productFilter: IProductSearchEntity = {
                ...this.productSearch,
            };
            const data = await ProductApi.listProducts(productFilter);
            this.products = data.data.records;
            this.page.count = data.data.total;
        } catch (err) {
            console.error(err);
            toastr.error('Een probleem is opgedoken tijdens het opvragen van de producten');
        } finally {
            this.loading = false;
        }
    }

    getRowClass(row: IProductEntity) {
        return {
            'row-style-datatable': row.product_id !== null,
        };
    }

    async editProduct(product) {
        await this.router.navigateByUrl(`/product-upsert?product_id=${product.product_id}&edit_mode=true`);
    }

    async updateFilters(filters: IProductSearchFilterEntity) {
        this.productSearch.filter = filters;
        await this.pageCallback(this.page);
    }

    async newProduct() {
        await this.router.navigateByUrl('/product-upsert');
    }

    showProductDeactivateModal(product: IProductEntity) {
        this.productToDelete = product;
        this.deactivateProductModal = true;
    }

    showProductActivateModal(product: IProductEntity) {
        this.productToActivate = product;
        this.activateProductModal = true;
    }

    cancelActivateProduct() {
        this.productToActivate = null;
        this.activateProductModal = false;
    }
    async activateProduct() {
        this.productToActivate.active = true;
        await ProductApi.updateProduct(this.productToActivate);
        this.pageCallback(this.page);
        this.activateProductModal = false;
    }

    cancelDeActivateProduct() {
        this.productToDelete = null;
        this.deactivateProductModal = false;
    }

    async deActiveProduct() {
        this.productToDelete.active = false;
        await ProductApi.updateProduct(this.productToDelete);
        this.deactivateProductModal = false;
        this.pageCallback(this.page);
    }
}
