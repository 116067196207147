import { styled } from '@mui/system';

export const Event = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 2px;
    color: #1e90ff;
`;

export const EventTitle = styled('div')`
    display: block;
    white-space: no-break;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const EventLocation = styled('div')`
    display: block;
    white-space: no-break;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const Tooltip = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 12px;
`;

export const TooltipRow = styled('div')``;
