import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { ClientState, IClientEntity, RequestSort } from '@phrospr/lib-models';
import React from 'react';
import * as toastr from 'toastr';
import { ClientAutoSearchProps, ClientAutoSearchState } from './ClientAutoSearch.types';
import { ClientApi } from '@phrospr/api-backend';

export class ClientAutoSearch extends React.Component<ClientAutoSearchProps, ClientAutoSearchState> {
    constructor(props: ClientAutoSearchProps) {
        super(props);
        this.state = { open: false, loading: false, clientOptions: [] };
    }

    async loadClientOptions(event) {
        const searchValue = event.target?.value ?? '';
        if (searchValue.length < 2) return;
        this.setState({ loading: true });
        try {
            const clients = (
                await ClientApi.listClients({
                    filter: {
                        clientNumber: searchValue,
                        clientName: searchValue,
                        state: ClientState.client,
                    },
                    limit: 10,
                    offset: 0,
                    sort: {
                        column: 'client_number',
                        order: RequestSort.ASC,
                    },
                })
            ).data.clients;
            this.setState({ clientOptions: clients });
        } catch (e) {
            console.log(e);
            toastr.error('something went wrong');
        }
        this.setState({ loading: false });
    }

    selectClient(client: (string | IClientEntity)[]) {
        if (client.length) {
            const value = client[0];
            if (typeof value === 'string') return;
            return this.props.onClientChange(value);
        }
        this.props.onClientChange(client as any);
    }

    render() {
        return (
            <div>
                <Autocomplete
                    size="small"
                    sx={{ width: 300 }}
                    open={this.state.open}
                    onOpen={() => this.setState({ open: true })}
                    onClose={() => this.setState({ open: false })}
                    isOptionEqualToValue={(option, value) => option.client_id === value.client_id}
                    getOptionLabel={(c: IClientEntity) =>
                        `${c.client_data?.first_name ?? ''} ${c.client_data?.last_name ?? ''} (${
                            c.exact_client_number ?? 'LEAD - ID: ' + c.client_id
                        })`
                    }
                    options={this.state.clientOptions}
                    loading={this.state.loading}
                    onChange={(event, value) => this.selectClient(value)}
                    onInputChange={event => this.loadClientOptions(event)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Zoek klant..."
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
        );
    }
}

export default ClientAutoSearch;
