import moment from 'moment';
import { IEmailObjectEntity, IPlanningItemEntity, Language, PlanningItemType } from '@phrospr/lib-models';
import { getNamePlanningItemType, PlanningItemEngine } from './planning-item.engine';
import { defaultMailFooter } from './mail-template-footer.engine';

export function getMailTemplatePlanningItem(
    planningItem: IPlanningItemEntity,
    calendarEventToAddStartDate: Date,
    language: Language,
    companyName: string,
): IEmailObjectEntity {
    return getMailTemplateAppointmentPlanned(planningItem, calendarEventToAddStartDate, language, companyName);
}

export function getMailFooter() {
    return defaultMailFooter;
}

export function getMailTemplateAppointmentPlanned(
    planningItem: IPlanningItemEntity,
    calendarEventToAddStartDate: Date,
    language: Language,
    companyName: string,
): IEmailObjectEntity {
    if (companyName?.toLowerCase() === 'aquagroup') {
        const email_object: IEmailObjectEntity = {
            subject: '',
            content: '',
            receivers: [{ email: 'teodeconinck@gmail.com' }],
            senders: [{ email: 'info@aquagroup.be' }],
            language,
        };

        let address = '';
        let clientName = '';
        const plannedDateTime = moment(calendarEventToAddStartDate).format('DD/MM/YYYY HH:mm:ss');

        clientName = PlanningItemEngine.getClientName(planningItem.client);
        address = planningItem.client.client_data.main_address.address;
        if (planningItem.planning_item_type === PlanningItemType.lead) {
            if (language === Language.nl) {
                email_object.content = getTemplateAppointmentNl(clientName, plannedDateTime);
                email_object.subject = 'Aquagroup - Bevestiging van afspraak';
            } else if (language === Language.fr) {
                email_object.content = getTemplateAppointmentFr(clientName, plannedDateTime);
                email_object.subject = 'Aquagroup - Confirmation de rendez-vous';
            }
        } else if (planningItem.planning_item_type === PlanningItemType.repair) {
            if (language === Language.nl) {
                email_object.content = getTemplateRepairPlannedAppointmentNl(clientName, plannedDateTime);
                email_object.subject =
                    'Aquagroup - Bevestiging van ' + getNamePlanningItemType(planningItem.planning_item_type, language);
            } else if (language === Language.fr) {
                email_object.content = getTemplateRepairPlannedAppointmentFr(clientName, plannedDateTime);
                email_object.subject =
                    'Aquagroup - Confirmation de ' +
                    getNamePlanningItemType(planningItem.planning_item_type, language) +
                    ' planifiée';
            }
        } else {
            if (language === Language.nl) {
                email_object.content = getTemplateNl(
                    clientName,
                    plannedDateTime,
                    getNamePlanningItemType(planningItem.planning_item_type, language),
                    planningItem.planning_item_type,
                );
                email_object.subject =
                    'Aquagroup - Bevestiging van ' + getNamePlanningItemType(planningItem.planning_item_type, language);
            } else if (language === Language.fr) {
                email_object.content = getTemplateFr(
                    clientName,
                    plannedDateTime,
                    getNamePlanningItemType(planningItem.planning_item_type, language),
                    planningItem.planning_item_type,
                );
                email_object.subject =
                    'Aquagroup - Confirmation de ' +
                    getNamePlanningItemType(planningItem.planning_item_type, language) +
                    ' planifiée';
            }
        }

        return email_object;
    } else if (companyName?.toLowerCase() === 'parydel') {
        const email_object: IEmailObjectEntity = {
            subject: '',
            content: '',
            receivers: [{ email: 'teodeconinck@gmail.com' }],
            senders: [{ email: 'info@parydel.be' }],
            language,
        };

        let address = '';
        let clientName = '';
        const plannedDateTime = moment(calendarEventToAddStartDate).format('DD/MM/YYYY HH:mm:ss');

        clientName = PlanningItemEngine.getClientName(planningItem.client);
        address = planningItem.client.client_data.main_address.address;
        if (language === Language.nl) {
            email_object.content = getTemplateNlParydel(
                clientName,
                plannedDateTime,
                getNamePlanningItemType(planningItem.planning_item_type, language),
                planningItem.planning_item_type,
            );
            email_object.subject =
                'Parydel - Bevestiging van ' + getNamePlanningItemType(planningItem.planning_item_type, language);
        } else if (language === Language.fr) {
            email_object.content = getTemplateFrParydel(
                clientName,
                plannedDateTime,
                getNamePlanningItemType(planningItem.planning_item_type, language),
                planningItem.planning_item_type,
            );
            email_object.subject =
                'Parydel - Confirmation de ' +
                getNamePlanningItemType(planningItem.planning_item_type, language) +
                ' planifiée';
        }

        return email_object;
    } else {
        const email_object: IEmailObjectEntity = {
            subject: '',
            content: '',
            receivers: [{ email: 'teodeconinck@gmail.com' }],
            senders: [{ email: 'teodeconinck@gmail.com' }],
            language,
        };
        return email_object;
    }
}

function getTemplateNlParydel(
    clientName: string,
    plannedDateTime: string,
    type: string,
    planningItemType: PlanningItemType,
): string {
    let emailHtml: string = null;

    emailHtml = `
    <p style="margin-bottom:5px;">Beste ${clientName},</p>
    <p>Graag bevestig ik uw afspraak die gepland staat op ${plannedDateTime}.</p>
    <p style="margin-bottom:5px;">Rekening houdend met het wijzigen van het voorgestelde uur door verkeer/ andere omstandigheden.
change email tem    <br/>
    <p>Gelieve ons te verwittigen indien de afspraak niet kan doorgaan.</p>
    <br/>
    <p>Indien bijkomende service en/of producten gewenst, kan u steeds mailen naar info@parydel.be</p>
     <br/>
    <p>Hopend u hiermee van dienst te zijn.</p>

`;

    return emailHtml;
}

function getTemplateFrParydel(
    clientName: string,
    plannedDateTime: string,
    type: string,
    planningItemType: PlanningItemType,
): string {
    let emailHtml: string = null;

    emailHtml = `
    <p style="margin-bottom:5px;">Cher ${clientName},</p>
    <p>Je voudrais confirmer votre rendez-vous qui est programmée le ${plannedDateTime}.</p>
    <p style="margin-bottom:5px;">Prise en compte de la variation de l'heure proposée en raison du trafic ou d'autres circonstances.
    <br/>Veuillez nous informer si le rendez-vous ne peut avoir lieu.
    <br/>Merci d'avance pour votre confiance et à bientôt !</p>
`;
    return emailHtml;
}

function getTemplateNl(
    clientName: string,
    plannedDateTime: string,
    type: string,
    planningItemType: PlanningItemType,
): string {
    let emailHtml: string = null;

    emailHtml = `
    <p style="margin-bottom:5px;">Beste ${clientName},</p>
    <p>Graag bevestig ik uw ${type} die gepland staat op ${plannedDateTime}.</p>
    <p style="margin-bottom:5px;">Rekening houdend met het wijzigen van het voorgestelde uur door verkeer/ andere omstandigheden.
    <br/>Gelieve ons te verwittigen indien de afspraak niet kan doorgaan.
    <br/>Alvast bedankt voor het vertrouwen en graag tot dan!
    </p>
`;

    if (planningItemType === PlanningItemType.maintenance) {
        emailHtml += getTemplateExtraInfoMaintenanceNL();
    }

    return emailHtml;
}

function getTemplateFr(
    clientName: string,
    plannedDateTime: string,
    type: string,
    planningItemType: PlanningItemType,
): string {
    let emailHtml: string = null;

    emailHtml = `
    <p style="margin-bottom:5px;">Cher ${clientName},</p>
    <p>Je voudrais confirmer votre ${type} qui est programmée le ${plannedDateTime}.</p>
    <p style="margin-bottom:5px;">Prise en compte de la variation de l'heure proposée en raison du trafic ou d'autres circonstances.
    <br/>Veuillez nous informer si le rendez-vous ne peut avoir lieu.
    <br/>Merci d'avance pour votre confiance et à bientôt !</p>
`;

    if (planningItemType === PlanningItemType.maintenance) {
        emailHtml += getTemplateExtraInfoMaintenanceFR();
    }

    return emailHtml;
}

function getTemplateRepairPlannedAppointmentNl(clientName: string, plannedDateTime: string): string {
    return `
    <p>Beste ${clientName},</p>
    <br>
    <p>Graag bevestig ik de geplande afspraak op ${plannedDateTime}.</p>

      <p>Indien de afspraak niet kan doorgaan gelieve tijdig te verwittigen.</p>
      <p>Als de technieker vaststelt dat het toestel perfect werkt, en dus een nutteloze verplaatsing moet maken.
      Zijn wij genoodzaakt om een interventiefactuur te sturen van €70 excl. BTW.</p>
    <br>
    <p>
      Alvast bedankt voor het vertrouwen en graag tot dan!
    </p>

`;
}

function getTemplateRepairPlannedAppointmentFr(clientName: string, plannedDateTime: string): string {
    return `<p>Cher ${clientName},</p>
    <br>
    <p>Je voudrais confirmer le rendez-vous qui est programmée le ${plannedDateTime}.</p>

      <p>Si le rendez-vous ne peut pas aller de l'avant, veuillez aviser à temps s’il vous plaît. </p>
       <p>Si le technicien détermine que l'appareil fonctionne parfaitement, et doit donc faire un geste inutile.
           Nous sommes obligés d'adresser une facture d'intervention de 70 € (excl TVA).</p>
    <br>
    <p>
      Merci d'avance pour votre confiance et à bientôt!
    </p>
`;
}

function getTemplateAppointmentNl(clientName: string, plannedDateTime: string): string {
    return `
    <p>Beste ${clientName},</p>
    <br>
    <p>Graag bevestig ik de geplande afspraak op ${plannedDateTime}.</p>

      <p>Indien de afspraak niet kan doorgaan gelieve tijdig te verwittigen.</p>

    <br>
    <p>
      Alvast bedankt voor het vertrouwen en graag tot dan!
    </p>

`;
}

function getTemplateAppointmentFr(clientName: string, plannedDateTime: string): string {
    return `<p>Cher ${clientName},</p>
    <br>
    <p>Je voudrais confirmer le rendez-vous qui est programmée le ${plannedDateTime}.</p>

      <p>Si le rendez-vous ne peut pas aller de l'avant, veuillez aviser à temps s’il vous plaît. </p>

    <br>
    <p>
      Merci d'avance pour votre confiance et à bientôt!
    </p>
`;
}

export function getTemplateNotifyForUpcomingMaintenance(clientName: string): string {
    return `
    <p>Beste ${clientName},</p>
    <br>
    <p>Het is ondertussen bijna een jaar geleden dat het jaarlijks onderhoud uitgevoerd werd op uw ontharder.</p>

      <p> Binnenkort bellen wij u op om het jaarlijks onderhoud in te plannen.</p>

    <br>
    <p>
      Als u geen onderhoud of zelf een datum wenst voor te stellen kan je antwoorden op deze mail of bellen op ons
       nummer 056 61 51 62.
    </p>
    ${getTemplateExtraInfoMaintenanceNL()}
    <hr/>
    <br/>
    <p>Cher ${clientName},</p>
    <br>
    <p>Cela fait presque un an que l'entretien annuel a été effectué sur votre adoucisseur d'eau..</p>

      <p>Bientôt, nous vous appellerons pour programmer l'entretien annuel.</p>

    <br>
    <p>
      Si vous ne souhaitez pas d’entretien ou si vous voulez proposer une date vous-même, vous pouvez répondre à ce courrier ou nous appeler au 056 61 51 62.
    </p>
    ${getTemplateExtraInfoMaintenanceFR()}
`;
}

export function getTemplateNotifyAppointmentDayBefore(
    clientName: string,
    plannedDateTime: string,
    planningItemType: PlanningItemType,
): string {
    let emailHtml: string = null;

    emailHtml = `
    <p style="margin-bottom:5px;">Beste ${clientName},</p>
    <p>Graag herinner ik u aan uw afspraak die gepland staat op ${plannedDateTime}.</p>
    <p style="margin-bottom:5px;">Rekening houdend met het wijzigen van het voorgestelde uur door verkeer/ andere omstandigheden.
    <br/>Gelieve ons te verwittigen indien de afspraak niet kan doorgaan.
    <br/>Alvast bedankt voor het vertrouwen en graag tot dan!
    </p>
  `;

    if (planningItemType === PlanningItemType.maintenance) {
        emailHtml += getTemplateExtraInfoMaintenanceNL();
    }

    emailHtml += `
    <hr/>
    <br/>
    <p style="margin-bottom:5px;">Beste ${clientName},</p>
    <p>Je vous rappelle votre rendez-vous qui est prévue le ${plannedDateTime}</p>
    <p style="margin-bottom:5px;">Prise en compte de la variation de l'heure proposée en raison du trafic ou d'autres circonstances.
    <br/>Veuillez nous informer si le rendez-vous ne peut avoir lieu.
    <br/>Merci d'avance pour votre confiance et à bientôt !
    </p>
  `;

    if (planningItemType === PlanningItemType.maintenance) {
        emailHtml += getTemplateExtraInfoMaintenanceFR();
    }

    return emailHtml;
}

export function getTemplateExtraInfoMaintenanceNL() {
    return `
       <br/>
    <p style="font-size: 12px">
      <i>
      OPGELET!<br/>
      Al onze prijzen zijn sinds 01/01/2023 met 12% geïndexeerd. <br/>
      Gezien de blijvende prijsstijgingen, zijn we genoodzaakt om de prijs van het onderhoud te verhogen naar €89,60 excl. BTW. <br/>
      Deze wijziging zal plaatsvinden vanaf 01/01/2023.<br/>
      Mocht u hiermee niet akkoord gaan, graag een seintje dan komen wij niet meer langs.
      Dit om discussies te vermijden.
      </i>
    </p>
  `;
}

export function getTemplateExtraInfoMaintenanceFR() {
    return `
 <br>
    <p style="font-size: 12px">
      <i>
        ATTENTION!<br/>
        Tous nos prix sont indexés de 12% depuis le 01/01/2023. <br/>
        Compte tenu des augmentations tarifaires continues, nous sommes contraints d'augmenter le prix de la maintenance à 89,60 € HT. <br/>
        Ce changement interviendra à partir du 01/01/2023.<br/>
        Si vous n'êtes pas d'accord avec cela, veuillez nous le faire savoir et nous ne viendrons plus. C'est pour éviter les discussions.
      </i>
    </p>
    `;
}

export function getTemplateFinishedPlanningItemFeedback(clientName: string) {
    return `
    <p>Beste ${clientName},</p>
    <br>
    <p>Naar aanleiding van ons bezoek i.v.m. de waterontharder sturen wij u dit bericht.</p>
    <p>Heeft u opmerkingen of eventuele tips naar de toekomst toe?</p>
    <br>
    <p>Wij kijken alvast uit naar uw feedback om onze service blijven te optimaliseren.</p>
    <br/>

   <p>NIEUWE PROMO</p>
   <p>Ontvang 100 kg gratis bij u volgend onderhoud!</p>
 <br/>
 <p>Meer informatie via deze link.</p>
 <a href="https://www.waterverzachteraquagroup.be/gratis-zout" >https://www.waterverzachteraquagroup.be/gratis-zout</a>
  <br/>

    <hr/>
    <br/>
    <p>Cher ${clientName},</p>
    <br>
    <p>Suite à notre visite concernant l'adoucisseur d'eau, nous vous envoyons ce message.</p>

    <p>Avez-vous des commentaires ou des conseils pour l'avenir ?</p>
    <br>
    <p>Nous attendons avec impatience votre retour d'expérience afin de continuer à optimiser notre service.</p>
    <br/>

   <p>NOUVELLE PROMOTION</p>
   <p>Obtenez 100 kg gratuits lors de votre prochain entretien!</p>
 <p>Plus d'informations sur ce lien.</p>
 <a href="https://www.waterverzachteraquagroup.be/fr-be/gratis-zout" >https://www.waterverzachteraquagroup.be/fr-be/gratis-zout</a>
  <br/>
  `;
}

export function getTemplateQuoteCreated(clientName: string) {
    return `
Beste ${clientName},<br>
<br>
Aquagroup biedt u de volgende offerte aan, zie bijlage!<br>
U kan deze offerte via mail bevestigen. Indien er nog iets gewijzigd moet worden gelieve ons te contacteren.<br>
<br>
<br>
----------------------------------------
<br>
Cher ${clientName},<br>
<br>
Aquagroup vous propose l'offre suivante, voir pièce jointe! <br>
Vous pouvez confirmer ce devis par mail. Si quelque chose doit être changé, veuillez nous contacter.<br>
<br>
<br>`;
}
