import { Box, Button, ChipProps, Dialog, DialogActions, DialogContent, DialogContentText, Rating } from '@mui/material';
import React, { Component } from 'react';
import * as toastr from 'toastr';
import { QuoteOverviewProps, QuoteOverviewState } from './QuoteOverview.types';
import {
    ClientState,
    IActivitySearchEntitySort,
    IPlanningItemEntity,
    IQuoteEntity,
    IQuoteSearchEntity,
    IQuoteSearchFilterEntity,
    PlanningItemApi,
    QuoteApi,
    QuoteCommentApi,
    QuoteStatus,
    RequestSort,
} from '@phrospr/api-backend';
import { DataGrid, GridActionsCellItem, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import MessageIcon from '@mui/icons-material/Message';
import Chip from '@mui/material/Chip';
import { amber, blue, green, red } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import DoneIcon from '@mui/icons-material/Done';
import QuoteSearchFilter from './QuoteSearchFilter/QuoteSearchFilter';
import { GridEnrichedColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { downloadFileFromApi } from '@phrospr/lib-web-core';
import { CustomNoRowsOverlay } from './QuoteOverviewEmpty';
import { IClientEntity } from '@phrospr/lib-models';
import { getQuoteVersion, PlanningItemEngine } from '@phrospr/lib-core';
import CheckIcon from '@mui/icons-material/Check';
import ReceiptIcon from '@mui/icons-material/Receipt';
import moment from 'moment';
import QuoteApproveDialog from '../quote/QuoteApproveDialog/QuoteApproveDialog';
import getClientName = PlanningItemEngine.getClientName;

export class QuoteOverview extends Component<QuoteOverviewProps, QuoteOverviewState> {
    quoteFilter: IQuoteSearchEntity = {
        filter: {
            client_name: '',
            lead_name: '',
        },
        limit: 20,
        show: {
            client: true,
            sold_by_user: true,
            created_by_user: true,
            planning_item_lead: true,
            planning_item_placement: true,
            pdf_raw: true,
        },
        sort: { column: 'quote_id', order: RequestSort.DESC },
    };

    datagrid: {
        rowLength: 20;
        maxColumns: 9;
    };

    columns: GridEnrichedColDef[] = [
        { field: 'quote_id', headerName: 'ID', width: 70 },
        {
            field: 'client_id',
            headerName: 'Voornaam',
            width: 150,
            flex: 1,
            editable: false,
            valueGetter: this.getFirstAndLastName,
            sortable: false,
        },
        {
            field: 'postcode',
            headerName: 'Postcode',
            width: 100,
            flex: 0.6,
            editable: false,
            valueGetter: this.getZipCodeAndCity,
            sortable: false,
        },
        {
            field: 'creation_date',
            headerName: 'Datum',
            type: 'date',
            width: 110,
            editable: false,
            sortable: false,
        },
        {
            field: 'total',
            headerName: 'Totaal (excl btw)',
            width: 60,
            flex: 0.5,
            editable: false,
            valueGetter: this.getTotal,
            sortable: false,
        },

        {
            field: 'soldbyUser',
            headerName: 'Verkocht door',
            width: 80,
            flex: 0.8,
            valueGetter: this.getSoldByUserName,
            sortable: false,
        },
        {
            field: 'planning_item_lead',
            headerName: 'Lead item',
            width: 120,
            hide: false,
            valueGetter: (params: GridValueGetterParams) => params.row.planning_item_lead,
            renderCell: params => {
                const planning_item_lead = params.value as IPlanningItemEntity;
                if (planning_item_lead) {
                    return (
                        <Button
                            size="small"
                            onClick={() => this.props.goToPlanningItem(planning_item_lead.planning_item_id)}
                        >
                            Lead {planning_item_lead.planning_item_type_number ?? ''}
                        </Button>
                    );
                }
            },
        },
        {
            field: 'exact_client_number',
            headerName: 'Exact nr.',
            width: 120,
            hide: false,
            valueGetter: (params: GridValueGetterParams) => params.row.client,
            renderCell: params => {
                const client = params.value as IClientEntity;
                if (client && client.state === ClientState.client) {
                    return (
                        <Button size="small" onClick={() => this.props.goToClient(client.client_id)}>
                            Klant {client.exact_client_number ?? ''}
                        </Button>
                    );
                } else return '';
            },
        },
        {
            field: 'planning_item_placement',
            headerName: 'Plaatsing item',
            width: 130,
            hide: false,
            valueGetter: (params: GridValueGetterParams) => params.row.planning_item_placement,
            renderCell: params => {
                const planning_item_placement = params.value as IPlanningItemEntity;
                if (planning_item_placement) {
                    return (
                        <Button
                            size="small"
                            onClick={() => this.props.goToPlanningItem(planning_item_placement.planning_item_id)}
                        >
                            Plaatsing {planning_item_placement.planning_item_type_number ?? ''}
                        </Button>
                    );
                }
            },
        },

        {
            field: 'Rating',
            headerName: 'rating',
            width: 120,
            renderCell: params => {
                return (
                    <Rating
                        name="size-small"
                        value={params.row.data.rating ? params.row.data.rating.value : 0}
                        max={params?.row.data.rating ? params?.row.data.rating.max : 5}
                        onChange={(event, newValue) => {
                            this.setState({ showQuoteRatingModal: true });
                            this.setState({ quoteIdUpdateRating: params.row.quote_id });
                            this.setState({ updateQuoteRating: { value: newValue, max: 5 } });
                        }}
                    />
                );
            },
            sortable: false,
        },

        {
            field: 'status',
            headerName: 'Status',
            width: 120,
            renderCell: params => {
                return <Chip label="Small" size="small" variant="outlined" {...this.getChipProps(params)} />;
            },
            sortable: false,
        },

        {
            field: 'actions',
            type: 'actions',
            minWidth: 105,
            getActions: params => [
                <GridActionsCellItem
                    icon={<DownloadIcon />}
                    label="Download"
                    onClick={() => this.downloadQuote(params.row)}
                />,
                <GridActionsCellItem
                    icon={<CloseIcon />}
                    label="Delete"
                    onClick={() => this.showCancelQuote(params.row)}
                    disabled={params.row.status !== QuoteStatus.created}
                />,
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={() => this.editQuote(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<MessageIcon />}
                    label="Comments"
                    onClick={() => this.showComments(params.row.quote_id)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<CheckIcon />}
                    label="Finish Offerte"
                    onClick={() => this.showApproveQuoteModal(params.row)}
                    showInMenu
                    disabled={params.row.status !== QuoteStatus.created}
                />,
            ],
        },
    ];

    constructor(props: QuoteOverviewProps) {
        super(props);
        this.state = {
            editMode: false,
            cancelModal: false,
            quotes: [],
            cancelQuote: null,
            editQuote: null,
            loading: false,
            quoteComments: null,
            showCommentsModal: false,
            showQuoteRatingModal: false,
            updateQuoteRating: {
                value: 0,
                max: 5,
            },
            showApproveQuoteModal: false,
        };
    }

    handleSortModeChange = (data: { field: string; sort: 'asc' | 'desc' }[]) => {
        let searchSort: IActivitySearchEntitySort = { column: 'quote_id', order: RequestSort.DESC };
        if (data.length) {
            const { field, sort } = data[0];
            const order = sort === 'asc' ? RequestSort.ASC : RequestSort.DESC;
            searchSort = { column: field, order };
        }
        if (this.state.orderState?.order !== searchSort.order || this.state.orderState?.column !== searchSort.column) {
            this.setState({ orderState: searchSort }, this.loadQuotes);
        }
    };

    getChipProps(params: GridRenderCellParams): ChipProps {
        if (params.row.status === QuoteStatus.cancelled) {
            return {
                icon: <WarningIcon style={{ fill: red[500] }} />,
                label: params.value,
                style: {
                    borderColor: red[500],
                },
            };
        } else if (params.row.status === QuoteStatus.created) {
            return {
                icon: <InfoIcon style={{ fill: blue[500] }} />,
                label: params.value,
                style: {
                    borderColor: blue[500],
                },
            };
        } else if (params.row.status === QuoteStatus.approved) {
            return {
                icon: <ReceiptIcon style={{ fill: green[500] }} />,
                label: params.value,
                style: {
                    borderColor: green[500],
                },
            };
        } else {
            return {
                icon: <DoneIcon style={{ fill: amber[500] }} />,
                label: params.value,
                style: {
                    borderColor: amber[500],
                },
            };
        }
    }

    getFirstAndLastName(params: GridValueGetterParams) {
        if (params.row && params.row.client.client_data.first_name) {
            return getClientName(params.row.client);
        }
    }
    getPlanningItemLead(params: GridValueGetterParams) {
        return `${params.row.client.client_data.first_name + ' ' + params.row.client.client_data.last_name || ''}`;
    }
    getTotal(params: GridValueGetterParams) {
        return `${params.row.sub_total_price + params.row.placement_cost + ' €' || ''}`;
    }
    getZipCodeAndCity(params: GridValueGetterParams) {
        if (params.row.data && !!params.row.data.quote_create) {
            const { zip_code, city } = params.row.data.quote_create.clientFiche ?? params.row.data.delivery_address;
            return `${(zip_code ? zip_code + ' - ' : '') + (city ? city : '') || ''}`;
        }
    }

    getSoldByUserName(params: GridValueGetterParams) {
        if (params.row.sold_by_user) {
            return `${params.row.sold_by_user.first_name || ''}`;
        }
    }

    async setQuoteSearchFilter(quoteSearchFilter: IQuoteSearchFilterEntity) {
        this.quoteFilter.filter = quoteSearchFilter;
        await this.loadQuotes();
    }

    loadQuotes = async () => {
        await this.setState({ loading: true });
        if (this.state.orderState !== undefined) this.quoteFilter.sort = this.state.orderState;
        const { total, records } = (await QuoteApi.getQuoteListPost({ quote_filter: this.quoteFilter })).data;
        this.setState({ quotes: records, editMode: true, loading: false, total });
    };

    async editQuote(quote: IQuoteEntity) {
        const quoteVersion: number = getQuoteVersion(quote);
        this.props.goToEditQuote(quote.quote_id, quoteVersion);
    }

    async showApproveQuoteModal(quoteToApprove: IQuoteEntity) {
        await this.setState({
            quoteToApprove: quoteToApprove,
            showApproveQuoteModal: true,
        });
    }

    async approveQuote(planningItemToCreate: IPlanningItemEntity): Promise<void> {
        try {
            await PlanningItemApi.approvePlanningItemLeadQuote({
                planning_item_id: this.state.quoteToApprove.planning_item_lead
                    ? this.state.quoteToApprove.planning_item_lead.planning_item_id
                    : undefined,
                planning_item_placement_to_create: planningItemToCreate,
                quote_id: this.state.quoteToApprove.quote_id,
            });
            this.setState({ showApproveQuoteModal: false, quoteToApprove: null });
            await this.loadQuotes();
        } catch (err) {
            console.error(err);
            toastr.error('Fout bij het afwerken van offerte');
        }
    }

    handleClose() {
        this.setState({ showApproveQuoteModal: false, quoteToApprove: null });
        console.log('closed');
    }

    async downloadQuote({ quote_id }: IQuoteEntity) {
        try {
            await downloadFileFromApi(await QuoteApi.downloadQuote(quote_id, { responseType: 'blob' }));
        } catch (err) {
            if (err && err.response.status === 404) {
                toastr.error('Geen PDF beschikbaar');
            } else {
                console.error(err);
                toastr.error('Fout tijdens het ophalen van de pdf');
            }
        }
    }

    updateRating = async () => {
        try {
            if (this.state.updateQuoteRating === undefined) throw new Error('updateQuoteRating undefined');
            await QuoteApi.rateQuote(this.state.quoteIdUpdateRating, this.state.updateQuoteRating);
            this.setState({ showQuoteRatingModal: false, quoteIdUpdateRating: null });
            await this.loadQuotes();
        } catch (err) {
            console.error(err);
            toastr.error('Fout tijdens het opslaan van de rating');
        }
    };

    cancelUpdateQuoteRating = async () => {
        try {
            this.setState({
                showQuoteRatingModal: false,
                updateQuoteRating: { value: 0, max: 5 },
                quoteIdUpdateRating: null,
            });
        } catch (e) {
            console.log(e);
        }
    };

    async showComments(id: number) {
        try {
            const comments = (await QuoteCommentApi.listQuoteComments(id)).data;
            this.setState({ quoteComments: comments, showCommentsModal: true });
        } catch (e) {
            console.error(e);
            toastr.error('Fout tijdens ophalen comments');
        }
    }

    cancelShowComments = async () => {
        this.setState({ quoteComments: null, showCommentsModal: false });
    };

    showCancelQuote = async (quote: IQuoteEntity) => {
        if (quote && quote.status !== QuoteStatus.cancelled) {
            this.setState({ cancelQuote: quote, cancelModal: true });
        } else {
            toastr.error('De quote is al geannuleerd of er ging iets mis met de cancel van de quote. Contacteer IT.');
        }
    };

    cancelQuote = async () => {
        try {
            await QuoteApi.cancelQuote(this.state.cancelQuote.quote_id);
            await this.loadQuotes();
            this.setState({ cancelModal: false, cancelQuote: null });
        } catch (e) {
            console.error(e);
            toastr.error('Een probleem is opgedoken tijdens het annuleren van de quote');
        }
        return;
    };

    cancelCancelQuote = async () => {
        this.setState({ cancelQuote: null, cancelModal: false });
    };

    render() {
        return (
            <div>
                <div style={{ padding: '5px' }}>
                    <QuoteSearchFilter
                        onChange={quoteSearchFilter => this.setQuoteSearchFilter(quoteSearchFilter)}
                    ></QuoteSearchFilter>
                </div>
                <div data-testid="QuoteOverviewDataGrid" style={{ display: 'flex', height: '100%', padding: '5px' }}>
                    <Box sx={{ height: 750, width: '100%', flexGrow: 1 }}>
                        <DataGrid
                            sx={{ width: '100%' }}
                            disableColumnFilter
                            getRowId={row => row.quote_id}
                            {...this.datagrid}
                            loading={this.state.loading}
                            rowHeight={30}
                            sortingMode="server"
                            onSortModelChange={this.handleSortModeChange}
                            rows={this.state.quotes}
                            columns={this.columns}
                            paginationMode="server"
                            rowCount={this.state.total ?? 0}
                            pageSize={this.quoteFilter.limit}
                            onPageChange={newPage => {
                                // starts from 0
                                this.quoteFilter.offset = this.quoteFilter.limit * newPage;
                                this.loadQuotes();
                            }}
                            rowsPerPageOptions={[5]}
                            {...this.state.quotes}
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                            }}
                        />
                    </Box>
                    <Dialog open={this.state.cancelModal} onClose={this.handleClose}>
                        <DialogContent>
                            <DialogContentText>
                                Ben je zeker dat je quote {this.state.cancelQuote?.quote_id} wilt annuleren?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button size="small" variant="text" onClick={this.cancelCancelQuote}>
                                Nee
                            </Button>
                            <Button color="success" variant="contained" size="small" onClick={this.cancelQuote}>
                                Ja
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.showCommentsModal} onClose={this.handleClose}>
                        <DialogContent>
                            <DialogContentText>
                                <div></div>
                                {this.state.quoteComments ? (
                                    <div>
                                        {this.state.quoteComments.map(comment => (
                                            <div>
                                                {moment(comment.creation_date).format('DD/MM/YYYY HH:mm')} [
                                                {comment.created_by_user.first_name}] - {comment.data?.comment}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    'Geen comments'
                                )}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button size="small" variant="text" onClick={this.cancelShowComments}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.showQuoteRatingModal} onClose={this.handleClose}>
                        <DialogContent>
                            <DialogContentText>
                                <div>Bent u zeker dat u de rating wilt aanpassen?</div>
                                <Rating
                                    name="size-small"
                                    value={this.state.updateQuoteRating.value}
                                    max={this.state.updateQuoteRating.max}
                                />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button size="small" variant="text" onClick={this.updateRating}>
                                Yes
                            </Button>
                            <Button size="small" variant="text" onClick={this.cancelUpdateQuoteRating}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {this.state.showApproveQuoteModal ? (
                        <QuoteApproveDialog
                            approveQuote={planningItemToCreate => this.approveQuote(planningItemToCreate)}
                            open={this.state.showApproveQuoteModal}
                            dialogClosed={() => this.handleClose()}
                            goToEditQuote={(quoteId, quoteVersion) => this.props.goToEditQuote(quoteId, quoteVersion)}
                            quoteToApprove={this.state.quoteToApprove}
                        ></QuoteApproveDialog>
                    ) : undefined}
                </div>
            </div>
        );
    }
}

export default QuoteOverview;
