<div style="margin: 15px">
    <div>
        <div class="icon-modal">
            <label class="clr-control-label">Klant</label>
        </div>
        <div class="group-section">
            <div class="group-element" style="width: 95%; margin-bottom: 6px">
                <div class="full-length-screen" style="padding-right: 16px">
                    <pr-client-search-modal (client)="setClient($event)"> </pr-client-search-modal>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="selectedClient.client_data && selectedClient.client_data.main_address">
        <div class="icon-modal">
            <label class="clr-control-label">Adres</label>
        </div>
        <div class="group-section">
            <div class="group-element" style="width: 95%; margin-bottom: 6px">
                <div class="full-length-screen" style="padding-right: 16px">
                    <label>{{ selectedClient.client_data.main_address.address }}</label>
                </div>
                <div class="full-length-screen" style="padding-right: 16px">
                    <label
                        >{{ selectedClient.client_data.main_address.city }}
                        {{ selectedClient.client_data.main_address.zip_code }}</label
                    >
                </div>
                <div class="full-length-screen" style="padding-right: 16px">
                    <label
                        >{{ selectedClient.client_data.main_address.country }} -
                        {{ selectedClient.client_data.main_address.countryName }}</label
                    >
                </div>
                <div class="full-length-screen" style="padding-right: 16px">
                    <label>{{ selectedClient.client_data.main_address.stateName }}</label>
                </div>
            </div>
        </div>
    </div>

    <div>
        <div class="icon-modal">
            <label class="clr-control-label">Gewenste Leverdatum</label>
        </div>
        <div class="group-section">
            <div class="group-element" style="width: 95%; margin-bottom: 6px">
                <div class="full-length-screen" style="padding-right: 16px">
                    <clr-date-container class="full-length-screen">
                        <input
                            type="date"
                            [(clrDate)]="newPlanningItemDelivery.data.suggested_date"
                            (change)="sendDeliveryToComponent()"
                        />
                    </clr-date-container>
                </div>
            </div>
        </div>
    </div>

    <div>
        <div class="icon-modal">
            <label class="clr-control-label">Omschrijving</label>
        </div>
        <div class="group-section">
            <div class="group-element" style="width: 95%; margin-bottom: 6px">
                <clr-textarea-container class="no-margin">
                    <textarea
                        clrTextarea
                        [(ngModel)]="newPlanningItemDelivery.data.description"
                        (change)="sendDeliveryToComponent()"
                        name="description"
                        class="full-length-screen no-border textarea-custom"
                        placeholder="beschrijving toevoegen"
                    >
                    </textarea>
                </clr-textarea-container>
            </div>
        </div>
    </div>
</div>
