import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as toastr from 'toastr';
import { LeadManager } from './modules/shared/manager/lead.manager';
import { IFEUserEntity } from './modules/shared/manager/entity/user.entity';
import { authentication$, configuration$, logout, user$ } from '@phrospr/lib-web-core';
import { IConfiguration, PlanningItemType } from '@phrospr/lib-models';
import { ExactControllerApi } from '@phrospr/api-backend';

@Component({
    selector: 'pr-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    title = 'app';

    user: IFEUserEntity;
    user$ = user$;

    visible = false;

    configuration: IConfiguration;
    PlanningItemType = PlanningItemType;

    initializedRoute = location.href.replace(location.origin, '').replace(/(.*)#.*/, '$1');

    constructor(public router: Router, public leadManager: LeadManager) {}

    async ngOnInit(): Promise<void> {
        if (typeof Storage === 'undefined') {
            toastr.error('Your browser is not supported!');
            return;
        }

        await this.loadSite();
    }

    async exactLogin() {
        location.href = (await ExactControllerApi.getAuthUrl()).data;
    }

    async loadSite() {
        try {
            await authentication$;
            await this.router.navigateByUrl('/');
            this.user = await user$;
            this.configuration = await configuration$;
            console.log('starting app', this.user, this.router.url, this.initializedRoute);
            const targetUrl = this.initializedRoute === '/' ? '/home' : this.initializedRoute;
            console.log(this.initializedRoute, targetUrl);
            await this.router.navigateByUrl(targetUrl);
        } catch (err) {
            console.log('load site failed');
            console.error(err);
        }
    }

    async logout() {
        await logout();
    }

    ok() {
        this.visible = false;
    }

    cancel() {
        this.visible = false;
    }
}
