export enum ExactMetaColumns {
    exact_meta_id = 'exact_meta_id',
    data = 'data',
    creation_date = 'creation_date',
}

export interface ExactMetaAttributes {
    exact_meta_id: number;
    data: any | null;
    creation_date: Date | null;
}

export type ExactMetaOptionalAttributes = 'exact_meta_id' | 'data' | 'creation_date';
export interface ExactMetaCreate {
    exact_meta_id?: number;
    data?: any | null;
    creation_date?: Date | null;
}
