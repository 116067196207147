export enum LeadType {
    bobex = 'bobex',
    solvari = 'solvari',
    leadAngel = 'leadAngel',
    leadadviseur = 'leadadviseur',
    offrea = 'offrea',
    manual = 'manual',
    client = 'client',
    AQUAGROUP_SITE = 'AQUAGROUP_SITE',
    OFFERTE_BE = 'OFFERTE_BE',
}
