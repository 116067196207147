<div *ngIf="!!planningItem">
  <div class="card-content detail-content">
    <div>
      <div class="horizontal" *ngIf="planningItem">
        <button class="btn btn-group-prospr btn-menu btn-sm target " [class.active-button]="state==='comment'"
                (click)="state='comment'" *ngIf="comments">
          Comments ({{comments? comments.length: 0}})
        </button>
        <button class="btn btn-group-prospr btn-menu btn-sm target " [class.active-button]="state==='report'"
                (click)="state='report'">
          Visit Report ({{reports ? reports.length: 0}})
        </button>
        <button class="btn btn-group-prospr btn-menu btn-sm " [class.active-button]="state==='activity'"
                (click)="state='activity'">
          Activity ({{activities? activities.length : 0}})
        </button>
      </div>

      <div class="content-section">
        <div class="card-content card-content-1" *ngIf="state==='comment' && comments">
          <div class="grid-item">
            <div class="message-container pointer" *ngIf="comments.length > 5 && olderComments">
              <a class="collapsed-comments" (click)="showAllComments()">
                <span class="collapsed-comments-line"></span>
                <span class="collapsed-comments-line"></span>
                <span class="show-more-comments" data-collapsed-count="37">
                  {{comments.length - 5}} older comments
                </span>
              </a>
            </div>
            <div *ngIf="comments && comments.length">
              <div
                   *ngFor="let comment of comments | slice:-numberOfCommentsToShow; let i = index"
                   class="comment-block expanded" id="comment-{{comment.comment_id}}">
                <div *ngIf="!comment.hide">
                  <div class="comment-section verbose">
                    <div class="comment-header" (click)="comment.hide = !comment.hide">
                      <a class="icon-caret-comment">
                        <cds-icon direction="down" shape="angle"></cds-icon>
                      </a>
                      <div class="action-links">
                        <a class="activitymodule-link issue-comment-action"
                           title="Right click and copy link for a permanent link to this comment.">
                          <cds-icon class="is-solid small" shape="link"></cds-icon>
                        </a>
                        <a class="issue-comment-action" id="edit_comment_id" title="Edit">
                          <cds-icon class="is-solid small" shape="pencil"></cds-icon>
                        </a>
                        <a class="issue-comment-action" id="delete_comment_id" title="Delete">
                          <cds-icon class="is-solid small" shape="trash"></cds-icon>
                        </a>
                      </div>
                      <div>
                        {{comment.created_by_user.first_name + ' added a comment ' + comment.creation_date.toLocaleDateString() + " " +
                      comment.creation_date.toLocaleTimeString() }}
                        <div class="icon-section">
                          <cds-icon shape="phone-handset" class="phone-clicked is-solid" style=" margin-left: 5px"
                                    *ngIf="comment.data.contacted_info && comment.data.contacted_info.phone"></cds-icon>
                          <cds-icon shape="envelope" class="mail-clicked is-solid" style="margin-left: 5px"
                                    *ngIf="comment.data.contacted_info && comment.data.contacted_info.mailed">
                          </cds-icon>
                        </div>
                      </div>

                    </div>
                    <div class="comment-body">
                      <p class="comment-paragraph">{{comment.data.comment}}</p>
                    </div>
                  </div>

                  <div class="comment-section concise">
                    <div class="comment-header">
                      <a class="icon-caret-comment">
                        <cds-icon direction="right" shape="angle"></cds-icon>
                      </a>
                      <div>
                        {{comment.created_by_user.first_name + ' added a comment ' + comment.creation_date.toLocaleDateString() + " " +
                      comment.creation_date.toLocaleTimeString() + ' - ' + comment.data.comment}}

                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="comment.hide">
                  <div class="comment-section verbose">
                    <div class="comment-header" (click)="comment.hide = !comment.hide">
                      <a class="icon-caret-comment">
                        <cds-icon direction="right" shape="angle"></cds-icon>
                      </a>
                      <div class="action-links">
                        <a class="activitymodule-link issue-comment-action"
                           title="Right click and copy link for a permanent link to this comment.">
                          <cds-icon class="is-solid small" shape="link"></cds-icon>
                        </a>
                        <a class="issue-comment-action" title="Edit">
                          <cds-icon class="is-solid small" shape="pencil"></cds-icon>
                        </a>
                        <a class="issue-comment-action" title="Delete">
                          <cds-icon class="is-solid small" shape="trash"></cds-icon>
                        </a>
                      </div>
                      <div class="comment-hidden">

                        {{comment.created_by_user.first_name + ' added a comment ' + comment.creation_date.toLocaleDateString() + " " +
                      comment.creation_date.toLocaleTimeString() + ' ' }}
                        <div class="icon-section">
                          <cds-icon shape="phone-handset" class="phone-clicked is-solid" style=" margin-left: 5px"
                                    *ngIf="comment.data.contacted_info.phone"></cds-icon>
                          <cds-icon shape="envelope" class="mail-clicked is-solid" style="margin-left: 5px"
                                    *ngIf="comment.data.contacted_info.mailed">
                          </cds-icon>
                        </div>
                        {{' ' + comment.data.comment}}

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="add-comment-section">
              <div (click)="showAddComment = true"
                   *ngIf="!showAddComment" class="btn btn-menu btn-sm">
                <cds-icon shape="chat-bubble"></cds-icon>
                Comment
              </div>
              <div *ngIf="showAddComment">
                <pr-comment-add
                  (cancelClick)="cancelComment()"
                  (saveCommentClick)="commentAdded()"
                  [planningItemId]="planningItem.planning_item_id"
                ></pr-comment-add>
              </div>
            </div>
          </div>
        </div>
        <div class="card-content card-content-1" *ngIf="state==='report' && reports">
          <div class="grid-item">
            <div>
              <div class="message-container pointer" *ngIf="reports.length > 5 && olderReports">
                <a class="collapsed-comments" (click)="showAllReports()">
                  <span class="collapsed-comments-line"></span>
                  <span class="collapsed-comments-line"></span>
                  <span class="show-more-comments" data-collapsed-count="37">
                  {{reports.length - 5}} older reports
                </span>
                </a>
              </div>
              <div *ngIf="reports && reports.length">
                <div
                     *ngFor="let report of reports | slice:-numberOfReportsToShow; let i = index"
                     class="comment-block expanded" id="report-{{report.planning_item_report_id}}">
                  <div *ngIf="!report.hide">
                    <div class="comment-section verbose">
                      <div class="comment-header" (click)="report.hide = !report.hide">
                        <a class="icon-caret-comment">
                          <cds-icon direction="down" shape="angle"></cds-icon>
                        </a>
                        <div class="action-links">
                          <a class="activitymodule-link issue-comment-action"
                             title="Right click and copy link for a permanent link to this comment.">
                            <cds-icon class="is-solid small" shape="link"></cds-icon>
                          </a>
                          <a class="issue-comment-action" id="edit_report_id" title="Edit">
                            <cds-icon class="is-solid small" shape="pencil"></cds-icon>
                          </a>
                          <a class="issue-comment-action" id="delete_report_id" title="Delete">
                            <cds-icon class="is-solid small" shape="trash"></cds-icon>
                          </a>
                        </div>
                        <div>
                          {{report.created_by_user.first_name + ' added a '}}
                          <b>{{report.state}}</b> {{' report - ' +
                        report.creation_date.toLocaleDateString() + " " + report.creation_date.toLocaleTimeString() }}

                        </div>
                      </div>
                      <div class="comment-body">
                        <p class="comment-paragraph">{{report.data.comment}}</p>
                        <p *ngIf="report.data.payment_method">
                          Betaalmethode: {{parsePaymentMethodTypeReadable(report.data.payment_method)}}</p>
                      </div>
                    </div>

                    <div class="comment-section concise">
                      <div class="comment-header">
                        <a class="icon-caret-comment">
                          <cds-icon direction="right" shape="angle"></cds-icon>
                        </a>
                        <div>
                          {{report.created_by_user.first_name + ' added a '}}
                          <b>{{report.state}}</b> {{' report - ' +
                        report.creation_date.toLocaleDateString() + " " + report.creation_date.toLocaleTimeString() + ' - ' +
                        report.data.comment }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="report.hide">
                    <div class="comment-section verbose">
                      <div class="comment-header" (click)="report.hide = !report.hide">
                        <a class="icon-caret-comment">
                          <cds-icon direction="right" shape="angle"></cds-icon>
                        </a>
                        <div class="action-links">
                          <a class="activitymodule-link issue-comment-action"
                             title="Right click and copy link for a permanent link to this comment.">
                            <cds-icon class="is-solid small" shape="link"></cds-icon>
                          </a>
                          <a class="issue-comment-action" title="Edit">
                            <cds-icon class="is-solid small" shape="pencil"></cds-icon>
                          </a>
                          <a class="issue-comment-action" title="Delete">
                            <cds-icon class="is-solid small" shape="trash"></cds-icon>
                          </a>
                        </div>
                        <div class="comment-hidden">
                          {{report.created_by_user.first_name + ' added a '}}
                          <b>{{report.state}}</b> {{' report - ' +
                        report.creation_date.toLocaleDateString() + " " + report.creation_date.toLocaleTimeString() + ' - ' +
                        report.data.comment }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!reports.length">
                Nog geen visit rapporten beschikbaar
              </div>
            </div>
          </div>
        </div>
        <div class="card-content card-content-1" *ngIf="state==='activity' && activities">
          <div class="grid-item">
            <div>
              <div class="message-container pointer" *ngIf="activities.length > 5 && olderActivities">
                <a class="collapsed-comments" (click)="showAllActivities()">
                  <span class="collapsed-comments-line"></span>
                  <span class="collapsed-comments-line"></span>
                  <span class="show-more-comments" data-collapsed-count="37">
                  {{activities.length - 5}} older activity
                </span>
                </a>
              </div>
              <div *ngIf="activities && activities.length">
                <div
                     *ngFor="let activity of activities | slice:-numberOfActivitiesToShow; let i = index"
                     class="comment-block expanded" id="activity-{{activity.activity_id}}">
                  <div *ngIf="activity.hide">
                    <div class="comment-section verbose">
                      <div class="comment-header" (click)="activity.hide = !activity.hide">
                        <a class="icon-caret-comment">
                          <cds-icon direction="down" shape="angle"></cds-icon>
                        </a>
                        <div class="action-links">
                          <a class="activitymodule-link issue-comment-action"
                             title="Right click and copy link for a permanent link to this comment.">
                            <cds-icon class="is-solid small" shape="link"></cds-icon>
                          </a>
                          <a class="issue-comment-action" id="edit_activity_id" title="Edit">
                            <cds-icon class="is-solid small" shape="pencil"></cds-icon>
                          </a>
                          <a class="issue-comment-action" id="delete_activity_id" title="Delete">
                            <cds-icon class="is-solid small" shape="trash"></cds-icon>
                          </a>
                        </div>
                        <div>
                          {{activity.created_by_user.first_name + ' made changes '}} <b>{{activity.activity_type}}</b> {{' - ' +
                        activity.creation_date.toLocaleDateString() + " " + activity.creation_date.toLocaleTimeString() }}

                        </div>
                      </div>
                      <div class="comment-body">
                        <p class="comment-paragraph">{{activity.activity_data | json}}</p>
                      </div>
                    </div>

                    <div class="comment-section concise">
                      <div class="comment-header">
                        <a class="icon-caret-comment">
                          <cds-icon direction="right" shape="angle"></cds-icon>
                        </a>
                        <div>
                          {{activity.created_by_user.first_name + ' made changes '}} <b>{{activity.activity_type}}</b> {{' - ' +
                        activity.creation_date.toLocaleDateString() + " " + activity.creation_date.toLocaleTimeString()  + ' - ' +
                        activity.activity_data}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!activity.hide">
                    <div class="comment-section verbose">
                      <div class="comment-header" (click)="activity.hide = !activity.hide">
                        <a class="icon-caret-comment">
                          <cds-icon direction="right" shape="angle"></cds-icon>
                        </a>
                        <div class="action-links">
                          <a class="activitymodule-link issue-comment-action"
                             title="Right click and copy link for a permanent link to this comment.">
                            <cds-icon class="is-solid small" shape="link"></cds-icon>
                          </a>
                          <a class="issue-comment-action" title="Edit">
                            <cds-icon class="is-solid small" shape="pencil"></cds-icon>
                          </a>
                          <a class="issue-comment-action" title="Delete">
                            <cds-icon class="is-solid small" shape="trash"></cds-icon>
                          </a>
                        </div>
                        <div class="comment-hidden">
                          {{activity.created_by_user.first_name + ' made changs '}}
                          <b>{{activity.activity_type}}</b> {{' - ' +
                        activity.creation_date.toLocaleDateString() + " " + activity.creation_date.toLocaleTimeString()}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!activities.length">
                Nog geen activiteit beschikbaar
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

